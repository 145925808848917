import { Organizer, Event, EventSummary, EventDealForm, EventStatus } from './typings';
import { VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';

const getOrganizers = (client: VacayzClient): VacayzResponse<Organizer[]> => {
  const url = `api/v1/organizers`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Organizer[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getEventsByOrganize = (client: VacayzClient, orgId: string): VacayzResponse<Event[]> => {
  const url = `api/v1/event/${orgId}/lists`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Event[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getEventSummary = (client: VacayzClient, eventId: string): VacayzResponse<EventSummary> => {
  const url = `api/v1/event/${eventId}/summary`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<EventSummary>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getEventStatus = (
  client: VacayzClient,
  eventId: string,
  year?: number,
  month?: number
): VacayzResponse<EventStatus> => {
  const qs = toQs({ year, month });
  const url = `api/v1/event/${eventId}/status${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<EventStatus>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getEventDetails = (client: VacayzClient, eventId: string): VacayzResponse<Event> => {
  const url = `api/v1/event/${eventId}/id/details`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Event>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getAllDeals = (client: VacayzClient): VacayzResponse<EventDealForm[]> => {
  const url = `api/v1/hotels/deals`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<EventDealForm[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getEventById = (client: VacayzClient, id: string): VacayzResponse<Event> => {
  const url = `api/v1/event/item/${id}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Event>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export {
  getOrganizers,
  getEventsByOrganize,
  getEventDetails,
  getEventSummary,
  getAllDeals,
  getEventById,
  getEventStatus,
};
