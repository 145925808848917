import { Brand, BrandDescription, CreateBrandDescriptionRequest, CreateBrandRequest } from './typings';
import { VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, toQuerystring as toQs, handleCancel } from '../common/utils';

const getBrandByName = (client: VacayzClient, brandName: string): VacayzResponse<Brand> => {
  const qs = toQs({ url_name: brandName });
  const url = `api/v1/brands/${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Brand>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBrandById = (client: VacayzClient, brandId: number): VacayzResponse<Brand> => {
  const url = `api/v1/brands/${brandId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Brand>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createBrand = (client: VacayzClient, fields: CreateBrandRequest): VacayzResponse<Brand> => {
  const url = 'api/v1/brands/';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<Brand>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const updateBrand = (
  client: VacayzClient,
  brandId: number,
  fields: Partial<CreateBrandRequest>
): VacayzResponse<Brand> => {
  const url = `api/v1/brands/${brandId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<Brand>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBrandDescriptionById = (client: VacayzClient, brandId: number): VacayzResponse<BrandDescription> => {
  const url = `api/v1/brands/${brandId}/description`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BrandDescription>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createBrandDescription = (
  client: VacayzClient,
  brandId: number,
  fields: CreateBrandDescriptionRequest
): VacayzResponse<BrandDescription> => {
  const url = `api/v1/brands/${brandId}/description`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<BrandDescription>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export { getBrandByName, getBrandById, createBrand, updateBrand, getBrandDescriptionById, createBrandDescription };
