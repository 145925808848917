import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    height: 44,
  },
  body: {
    padding: '8px 0',
    flexGrow: 1,
  },
  footer: {
    '& $cell': {
      height: 44,
    },
  },
  row: {
    padding: '2px 0',
  },
  cell: {
    height: 33,
  },
  skeleton: {
    width: '100%',
    height: '100%',
    transform: 'none',
  },
}));

const DataGridSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Skeleton className={classes.skeleton} />
      </div>
      <div className={classes.body}>
        {[1, 2, 3, 4].map((num) => (
          <Grid key={num} container spacing={1} className={classes.row}>
            <Grid item xs={1} className={classes.cell}>
              <Skeleton className={classes.skeleton} />
            </Grid>
            <Grid item xs={2} className={classes.cell}>
              <Skeleton className={classes.skeleton} />
            </Grid>
            <Grid item xs={2} className={classes.cell}>
              <Skeleton className={classes.skeleton} />
            </Grid>
            <Grid item xs={4} className={classes.cell}>
              <Skeleton className={classes.skeleton} />
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Skeleton className={classes.skeleton} />
            </Grid>
          </Grid>
        ))}
      </div>
      <div className={classes.footer}>
        <Grid container spacing={1} className={classes.row}>
          <Grid item xs={10} className={classes.cell}>
            <Skeleton className={classes.skeleton} />
          </Grid>
          <Grid item xs={2} className={classes.cell}>
            <Skeleton className={classes.skeleton} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { DataGridSkeleton };
