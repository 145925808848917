import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, List, ListItem, ListItemText, Button } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { Header } from '../Header/Header';
import { Routing } from '../../common/constants/routing';
import {
  getBookingRequestbyNanoId,
  CreateBookingRequestFormNew,
  budgetOptions,
  BookingRoom,
  OfferStatus,
  calcDateDiff,
  getStringToDate,
  formatDateRangeToString,
} from '@micro-frontends/vacayz-api-client';
import { useAuthContext } from '@micro-frontends/auth-context';
import { AlertContext, useAsyncCleanup } from '@micro-frontends/shared-components';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles({
  paymentContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    maxWidth: '650px',
  },

  dealsContent: {
    background: '#152A40',
    color: 'white',
    width: '30px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    display: 'flex',
    marginRight: '15px',
  },

  paymentButton: {
    whiteSpace: 'nowrap',
    '&.MuiButton-root': {
      borderRadius: '60px',
      minWidth: '150px',
    },
  },

  selectedRoom: {
    '&.MuiCard-root': {
      border: '1px solid #5BCCCC',
    },
  },
});

export const getBudgetToString = (val: number): string => {
  let strBuget = '';
  budgetOptions.forEach((budget) => {
    if (val >= budget.name && val < budget.name + 100) {
      strBuget = budget.text;
    }
  });

  if (val > 500) {
    strBuget = '$500 +';
  }

  return strBuget;
};

const RequestView: React.FC = () => {
  const { nanoid: _nanoid } = useParams<{ nanoid: string }>();
  const classes = useStyles();
  const history = useHistory();
  const [nanoid, setNanoid] = useState(_nanoid);
  const { isMounted, addCleanup } = useAsyncCleanup();
  const { client } = useAuthContext();
  const { alert } = useContext(AlertContext);
  const [dealsCount, setDealsCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [bookingRequest, setBookingRequest] = useState<CreateBookingRequestFormNew>();

  const getRequestData = useCallback(
    async (id: string) => {
      try {
        const [_request, cancel] = getBookingRequestbyNanoId(client, id);
        addCleanup(cancel);
        const request = await _request;
        if (!isMounted()) return;
        if (!request) return alert('Request Not Found', 'error');
        setBookingRequest(request);
      } catch (err) {
        if (err && isMounted()) {
          alert(err, 'error');
        }
      } finally {
      }
    },
    [addCleanup, alert, client, isMounted]
  );

  useEffect(() => {
    if (nanoid) {
      getRequestData(nanoid);
    }
  }, [nanoid, getRequestData]);

  useEffect(() => {
    let _selectedCounts = 0;
    let _totalPrice = 0;
    if (typeof bookingRequest !== 'undefined' && typeof bookingRequest.id !== 'undefined') {
      for (let i = 0; i < bookingRequest.destinations.length; i++) {
        for (let j = 0; j < bookingRequest.destinations[i].rooms.length; j++) {
          const room = bookingRequest.destinations[i].rooms[j];
          for (let k = 0; k < room.deals.length; k++) {
            if (room.deals[k].isDealSelected) {
              _selectedCounts++;
              _totalPrice += room.deals[k].price * room.quantity * calcDateDiff(room.checkOut, room.checkIn);
            }
          }
        }
      }
      setDealsCount(_selectedCounts);
      setTotalPrice(_totalPrice);
    }
  }, [bookingRequest]);

  useEffect(() => {
    setNanoid(_nanoid);
  }, [_nanoid]);

  const handleMoreOffers = (room: BookingRoom, dest: string) => {
    history.push({
      pathname: `/new/requests/${nanoid}/deals`,
      requestId: nanoid,
      dest,
      room,
      enable: bookingRequest?.status === OfferStatus.PENDING_CUSTOMER,
    });
  };

  const gotoPaymentDeals = () => {
    history.push({ pathname: Routing.viewPaymentDeals, requestId: nanoid, bookingRequest });
  };

  const hasSelectedDeals = useCallback((room: BookingRoom) => {
    for (let i = 0; i < room.deals.length; i++) {
      if (room.deals[i].isDealSelected) {
        return true;
      }
    }
    return false;
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid>
        <Header />
        <Grid marginTop="132px" textAlign="center">
          <Typography variant="h3" fontWeight="bold" marginBottom="21px">
            Here are your request offers
          </Typography>
          <Typography variant="body1" fontWeight="light" color="primary">
            Please select your preferred accommodation, To secure this rate you will need to order and complete payment
            in the next 48 hours!
          </Typography>
        </Grid>

        <Grid sx={{ mt: 4 }}>
          {bookingRequest?.destinations.map((destination, index) =>
            destination.id ? (
              <Grid key={index} container justifyContent="center">
                {destination.rooms.map((room, i) => (
                  <Grid key={i} item xs={12} marginBottom={2}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={12} md={6}>
                        <Card
                          className={hasSelectedDeals(room) ? classes.selectedRoom : ''}
                          variant="outlined"
                          onClick={() =>
                            handleMoreOffers(room, `${destination.city}, ${destination.state}, ${destination.region}`)
                          }
                        >
                          <CardContent>
                            <List>
                              <ListItem>
                                <ListItemText
                                  primary={`${destination.city}, ${destination.state}, ${destination.region}`}
                                />
                                <ListItemText
                                  primary={formatDateRangeToString(
                                    getStringToDate(room.checkIn),
                                    getStringToDate(room.checkOut)
                                  )}
                                />
                                <ListItemText primary={getBudgetToString(room.budget)} />

                                {hasSelectedDeals(room) ? (
                                  <CheckIcon color="success" />
                                ) : (
                                  <ArrowForwardIosIcon color="primary" />
                                )}
                              </ListItem>
                              {hasSelectedDeals(room) ? (
                                room.deals.map(
                                  (deal, j) =>
                                    deal.isDealSelected && (
                                      <ListItem key={j}>
                                        <ListItemText secondary={`${deal.name}, ${deal.roomType}`} />
                                      </ListItem>
                                    )
                                )
                              ) : (
                                <ListItem>
                                  <ListItemText secondary={`${room.deals.length} options`} />
                                </ListItem>
                              )}
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {dealsCount > 0 && (
                  <Grid className={classes.paymentContainer}>
                    <Card sx={{ borderRadius: '15px 15px 0 0' }}>
                      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid container alignItems="center">
                          <Grid className={classes.dealsContent}>{dealsCount}</Grid>
                          <Typography variant="body2">${totalPrice.toLocaleString()}</Typography>
                        </Grid>
                        <Button variant="contained" className={classes.paymentButton} onClick={gotoPaymentDeals}>
                          {'Confirm & Pay'}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            ) : null
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default RequestView;
export { RequestView };
