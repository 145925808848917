import { Brand, getBrandByName, VacayzClient } from '@micro-frontends/vacayz-api-client';
import React, { useCallback, useState } from 'react';

import constate from 'constate';

interface UseBrandProvider {
  brand: Brand | null;
  loader: boolean;
  setBrand: React.Dispatch<React.SetStateAction<Brand | null>>;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  getBrand: (client: VacayzClient, brandName: string) => void;
}

const useBrandProvider = (): UseBrandProvider => {
  const [brand, setBrand] = useState<Brand | null>(null);
  const [loader, setLoader] = useState(false);

  const getBrand = useCallback((client: VacayzClient, brandName: string) => {
    setLoader(true);
    const [promise] = getBrandByName(client, brandName);
    promise.then((receivedBrand) => {
      if (receivedBrand) {
        setBrand(receivedBrand);
      } else {
        setBrand(null);
      }
      setLoader(false);
    });
  }, []);

  return {
    brand,
    setBrand,
    getBrand,
    loader,
    setLoader,
  };
};

const [BrandProvider, useBrandContext] = constate(useBrandProvider);

export { BrandProvider, useBrandContext };
