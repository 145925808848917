import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';

interface Props {
  srcPath: string;
  open: boolean;
}

const ThreeSecretModal: React.FC<Props> = ({ srcPath, open }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setWidth(600);
      setHeight(window.innerHeight * 0.8);
    } else if (window.innerWidth > 512) {
      setWidth(500);
      setHeight(window.innerHeight * 0.8);
    } else {
      setWidth(250);
      setHeight(window.innerHeight * 0.8);
    }
  }, []);

  return (
    <Dialog open={open} id="secretDialog">
      <iframe src={srcPath} width={width} height={height} title="3D Secret"></iframe>
    </Dialog>
  );
};

export default ThreeSecretModal;
export { ThreeSecretModal };
