import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  BookingPaymentIntent,
  createBookingPaymentIntent,
  CreateBookingPaymentIntentRequest,
  createBookingStripeCharge,
  getBookingRequestOrderId,
  isVacayzError,
  updateUserInfo,
  toQuerystring,
  secretPaymentIntent,
  StripePaymentSecret,
  SecretPaymentIntentData,
  paymentFailedMethod,
} from '@micro-frontends/vacayz-api-client';
import { useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '@micro-frontends/auth-context';
import CircularProgress from '@mui/material/CircularProgress';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { BookingDealPaymentForm, BookingPaymentFormView } from './BookingPaymentForm.view';
import { SecretModal } from './SecretModal';
import { Routing } from '../../../../../common/constants/routing';
import { SelectedDealObjects } from '../../../Deals/OfferDeals.view';
interface Props {
  selectedDeals: SelectedDealObjects[];
  requestId: number;
  totalAmount: number;
  isPaid: boolean;
}

const parseSelectedDeals = (selectedDeals: SelectedDealObjects[]): number[] => {
  const dealsIds: number[] = [];
  selectedDeals.forEach((dealObjs) => {
    if (dealObjs.deal.id) dealsIds.push(dealObjs.deal.id);
  });
  return dealsIds;
};

const PaymentPageLogic: React.FC<Props> = ({ selectedDeals, requestId, totalAmount, isPaid }) => {
  const { client, currentUser } = useAuthContext();
  const { addCleanup, isMounted } = useAsyncCleanup();
  const { alert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingRequestOrderId, setBookingRequestOrderId] = useState<string>();
  const [secretSrc, setSecretSrc] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [receiveMessage, setReceiveMessage] = useState<boolean>(false);
  const [bookingPaymentId, setBookingPaymentId] = useState(0);

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const getRequestData = useCallback(async () => {
    try {
      setLoading(true);
      const [_requestOrderId, cancel] = getBookingRequestOrderId(client, requestId);
      addCleanup(cancel);
      const requestOrderId = await _requestOrderId;
      if (requestOrderId) {
        setBookingRequestOrderId(requestOrderId.orderId);
      }
    } catch (err) {
      if (err && isMounted() && isVacayzError(err)) {
        alert(err, 'error');
      }
    } finally {
      if (isMounted()) setLoading(false);
    }
  }, [addCleanup, client, alert, isMounted, requestId]);

  const confirmStripe = useCallback(
    async (secret: StripePaymentSecret) => {
      if (!stripe || !elements || !isMounted()) {
        return false;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return false;
      }

      try {
        setClientSecret(secret.clientSecret);
        const confirmCard = await stripe?.confirmCardPayment(
          secret.clientSecret,
          {
            payment_method: { card: cardElement },
            return_url:
              window.location.origin + Routing.secretRedirect + toQuerystring({ client_secret: secret.clientSecret }),
          },
          {
            handleActions: false,
          }
        );

        if (confirmCard.paymentIntent?.status === 'requires_action') {
          setSecretSrc(confirmCard.paymentIntent?.next_action?.redirect_to_url?.url ?? '');
          setOpen(true);
          return;
        } else if (confirmCard.paymentIntent?.status === 'succeeded') {
          const [secretPayment, cancel] = secretPaymentIntent(client, {
            id: confirmCard.paymentIntent?.id,
            status: confirmCard.paymentIntent?.status,
            amount: confirmCard.paymentIntent?.amount,
          });
          addCleanup(cancel);
          await secretPayment;

          history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: bookingRequestOrderId }));
          return;
        } else if (confirmCard.paymentIntent?.status === 'requires_payment_method') {
          alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
          return;
        }
      } catch (err) {
        if (err && isMounted() && isVacayzError(err)) {
          alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
          return;
        }
      }

      alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
      return;
    },
    [alert, addCleanup, bookingRequestOrderId, client, elements, history, isMounted, stripe]
  );

  const completeStripeCharge = useCallback(
    async (vacayzPaymentIntent: BookingPaymentIntent) => {
      if (!currentUser || loading || !bookingRequestOrderId || !vacayzPaymentIntent) {
        return false;
      }
      if (!stripe || !elements || !isMounted()) {
        return false;
      }
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        alert('The cared element is not exist. Please refresh the page to pay the deals again.', 'error');
        return false;
      }

      let secret;
      try {
        const paymentMethod = await stripe.createPaymentMethod({
          card: cardElement,
          billing_details: {
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            email: currentUser.email,
            phone: currentUser.phone,
            address: {
              line1: vacayzPaymentIntent.address,
            },
          },
          type: 'card',
        });

        if (!isMounted()) {
          return false;
        }

        if (paymentMethod.error || !paymentMethod.paymentMethod?.id) {
          alert('The payment is unsuccessful. The payment mothod is not working.', 'error');
          return false;
        }

        const [_stripeSecret, cancel] = createBookingStripeCharge(client, {
          bookingPaymentIntentId: vacayzPaymentIntent.id,
          userId: currentUser.id,
          paymentMethodId: paymentMethod.paymentMethod.id,
        });
        addCleanup(cancel);
        secret = await _stripeSecret;
      } catch (err) {
        alert('PaymentIntent client secret was invalid. Please retry with another card', 'error');
        return false;
      }

      if (!secret) {
        return false;
      }

      try {
        if (secret && secret.status === 'succeeded') {
          const [secretPayment, paymentCancel] = secretPaymentIntent(client, {
            id: secret.stripePaymentIntentId,
            status: secret.status,
            amount: vacayzPaymentIntent.amount,
          });
          addCleanup(paymentCancel);
          await secretPayment;

          history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: bookingRequestOrderId }));
          return true;
        }
      } catch (err) {
        alert('It is failed to complete the payment', 'error');
        return false;
      }

      setReceiveMessage(false);
      confirmStripe(secret);
      return true;
    },
    [
      addCleanup,
      alert,
      history,
      bookingRequestOrderId,
      client,
      confirmStripe,
      currentUser,
      elements,
      isMounted,
      loading,
      stripe,
      setReceiveMessage,
    ]
  );

  const handlePaymentIntent = useCallback(
    async (bookingFormData: BookingDealPaymentForm, creditAmount: number) => {
      if (currentUser?.id && !loading) {
        try {
          setLoading(true);
          const [userInfoPromise, useInfoCancel] = updateUserInfo(client, { phone: bookingFormData.phone });
          addCleanup(useInfoCancel);
          await userInfoPromise;

          const deals: number[] = parseSelectedDeals(selectedDeals);
          const paymentData: CreateBookingPaymentIntentRequest = {
            address: bookingFormData.address,
            bookingRequestId: requestId,
            country: bookingFormData.country,
            guestName: bookingFormData.guestName,
            dealsSelected: deals,
            userId: currentUser.id,
            isCredit: creditAmount > 0 ? true : false,
          };
          const [pmPromise, pmCancel] = createBookingPaymentIntent(client, paymentData);
          addCleanup(pmCancel);
          const _paymentIntent: BookingPaymentIntent | undefined = await pmPromise;
          if (!_paymentIntent || !isMounted()) {
            throw new Error('Error while processing payment');
          }

          setBookingPaymentId(_paymentIntent.id);

          if (_paymentIntent.amount === 0 && _paymentIntent.usedCredits > 0) {
            const intentData: SecretPaymentIntentData = {
              id: '',
              status: 'succeeded',
              amount: _paymentIntent.amount,
              bookingPaymentId: _paymentIntent.id,
            };
            const [secretPayment, cancel] = secretPaymentIntent(client, intentData);
            addCleanup(cancel);
            await secretPayment;
            setLoading(false);
            history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: bookingRequestOrderId }));
            return;
          }

          const bComplete = await completeStripeCharge(_paymentIntent);
          if (!bComplete || !isMounted()) {
            setLoading(false);
          }
        } catch (err) {
          if (err && isMounted() && isVacayzError(err)) {
            const errors = err.error.errors;
            if (errors.length > 0) {
              alert(errors[0].message, 'error');
            } else {
              alert(err, 'error');
            }
          }
          setLoading(false);
        }
      }
    },
    [
      addCleanup,
      client,
      completeStripeCharge,
      currentUser?.id,
      isMounted,
      alert,
      loading,
      requestId,
      selectedDeals,
      history,
      bookingRequestOrderId,
      setBookingPaymentId,
    ]
  );

  const submitRequest = useCallback(
    async (bookingFormData: BookingDealPaymentForm, creditAmount: number) => {
      handlePaymentIntent(bookingFormData, creditAmount);
    },
    [handlePaymentIntent]
  );

  const retrievePayment = useCallback(async () => {
    const result = await stripe?.retrievePaymentIntent(clientSecret);

    if (result?.error) {
      alert('PaymentIntent client secret was invalid', 'error');
    } else {
      if (result?.paymentIntent.status === 'succeeded') {
        const intentData: SecretPaymentIntentData = {
          id: result.paymentIntent?.id ?? '',
          status: result.paymentIntent?.status ?? '',
          amount: result.paymentIntent?.amount ?? 0,
        };

        const [secretPayment, cancel] = secretPaymentIntent(client, intentData);
        addCleanup(cancel);
        await secretPayment;
        setLoading(false);

        history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: bookingRequestOrderId }));
        return;
      } else if (result?.paymentIntent.status === 'requires_payment_method') {
        alert(' Authentication failed. Please retry with another payment method.', 'error');
      }
    }

    const [secretPaymentFailed, failedCancel] = paymentFailedMethod(
      client,
      bookingPaymentId,
      result?.error?.message ?? 'It is failed secret payment intent'
    );
    addCleanup(failedCancel);
    await secretPaymentFailed;

    setLoading(false);
  }, [addCleanup, alert, bookingRequestOrderId, client, clientSecret, history, stripe, bookingPaymentId, setLoading]);

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  useEffect(() => {
    if (!!receiveMessage) {
      setReceiveMessage(false);
      retrievePayment();
    }
  }, [receiveMessage, retrievePayment]);

  if (!bookingRequestOrderId) {
    return <CircularProgress color="secondary" size={33} />;
  }

  window.addEventListener('message', (e) => {
    if (e.origin !== window.origin || e.data.target !== 'secretDialog') {
      return;
    }

    if (e.data.message === clientSecret && !receiveMessage) {
      setReceiveMessage(true);
      setSecretSrc('');
      setOpen(false);
    }
  });

  return (
    <>
      <BookingPaymentFormView
        submitRequest={submitRequest}
        loading={loading}
        totalAmount={totalAmount}
        isPaid={isPaid}
      />
      {open && secretSrc && <SecretModal open={open} srcPath={secretSrc} />}
    </>
  );
};

export { PaymentPageLogic };
