import React from 'react';
import clsx from 'clsx';
import addDate from 'date-fns/add';
import dateFormat from 'date-fns/format';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Card, COLORS } from '@micro-frontends/shared-components';
import { DealSubmit } from '../Deal/_components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDealContext } from '../../common/contexts';
import { List, ListItem, ListItemIcon } from '@mui/material';

const TermListItem = ({ text }: { text: string }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <FiberManualRecordIcon />
      </ListItemIcon>
      <Typography>{text}</Typography>
    </ListItem>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    '&.alert': {
      color: COLORS.red,

      '& .MuiButtonBase-root': {
        color: COLORS.red,
      },
    },
  },
}));

const DealPurchaseTerms: React.FC = () => {
  const classes = useStyles();
  const { dealData, acceptedByUser, setAcceptedByUser, acceptedByUserAlert, setAcceptedByUserAlert } = useDealContext();

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptedByUser(event.target.checked);
    setAcceptedByUserAlert(false);
  };

  const getExpires = () => {
    return dateFormat(
      addDate(new Date(), { years: dealData?.validForYears, days: dealData?.validForDays }),
      'MM/dd/yyyy'
    );
  };

  return (
    <Card fullWidth>
      <Container disableGutters>
        <Box mb={3}>
          <Typography variant="h2" align="center">
            Travel Option Terms
          </Typography>
        </Box>

        <List>
          <TermListItem text={`Expires: ${getExpires()}`} />
          <TermListItem text={`For use with new bookings only`} />
          <TermListItem text={`Travel Options will be redeemed directly with the hotel upon booking`} />
          <TermListItem text={`Travel Options cannot be applied to bookings during blockout dates.`} />
          <TermListItem
            text={`Taxes and hotel resort fees are not included in the total price displayed. It will be charged directly by
              the hotel at the check-in.`}
          />
          <TermListItem
            text={`Travel Options are 100% refundable, transferable and available for resell only through Vacayz marketplace.`}
          />
          <TermListItem
            text={`Vacayz does not guarantee any availability at any hotel and will not be held liable for any inavailability
              or any interaction or experience you have with any hotels during your reservation process and stay.`}
          />
          <TermListItem text={`For use with new bookings only`} />
        </List>

        <Box textAlign="center" mt={3}>
          <FormControlLabel
            control={<Checkbox checked={acceptedByUser} onChange={handleCheckbox} name="acceptedByUser" />}
            label="I accept the terms & conditions"
            className={clsx(classes.checkbox, acceptedByUserAlert && 'alert')}
          />
        </Box>
        <Box textAlign="center" mt={1}>
          <DealSubmit />
        </Box>
      </Container>
    </Card>
  );
};

export { DealPurchaseTerms };
