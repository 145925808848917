import React, { useCallback, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CustomInput } from './DealsCreation';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  BookingDestinationFields,
  BookingRequestDestination,
  BookingRoom,
  BookingRoomFields,
  budgetOptions,
  getStringToDate,
  formatDateRangeToString,
} from '@micro-frontends/vacayz-api-client';
import { CloneIcon, TrashIcon } from '../../Deals/Components/CustomIcon';
import { CheckBox, FreeTextArea, QuantityInput } from './DestinationItem';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select } from '@mui/material';
import CheckDateModal from './CheckDateModal';
import Button from '@mui/material/Button';
import { bedTypes } from './DestinationItem';
import Typography from '@mui/material/Typography';
import { LocationAutocomplete } from '../../LocationAutocomplete/LocationAutocomplete';
import Box from '@mui/material/Box';

type Props = {
  destination: BookingRequestDestination;
  destinationIndex: number;
  actions: {
    roomEdit: (destinationIdx: number, roomIdx: number, p: BookingRoomFields, val: never) => void;
    roomAdding: (destinationIdx: number) => void;
    roomClone: (destinationIdx: number, roomIdx: number) => void;
    roomRemove: (destinationIdx: number, roomIdx: number) => void;
    destinationRemove: (destinationIdx: number) => void;
    destinationEdit: (p: BookingDestinationFields, e: string, index: number) => void;
  };
};

const MobileBookingRequestDestinationItem: React.FC<Props> = ({ actions, destination, destinationIndex }) => {
  const [openCheck, setOpenCheck] = useState(false);
  const [openRoomIndex, setOpenRoomIndex] = useState(0);
  const [checkinDate, setOpenCheckInDate] = useState(new Date());
  const [checkoutDate, setOpenCheckoutDate] = useState(new Date());

  const handleDateSelection = (
    destinationIdx: number,
    roomIdx: number,
    selectedDate: Date | null,
    direction: 'checkIn' | 'checkOut'
  ) => {
    const current = new Date();
    const handleDate = selectedDate
      ? `${selectedDate.getFullYear()}/${selectedDate.getMonth() + 1}/${selectedDate.getDate()}`
      : `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
    handleRoomEditing(destinationIndex, roomIdx, direction, handleDate);
  };

  const handleRemoveBookingRequestDestination = (destinationIdx: number) => {
    actions.destinationRemove(destinationIdx);
  };

  const handleAddRoom = (destinationIdx: number) => {
    actions.roomAdding(destinationIdx);
  };

  const handleRemoveRoom = (destinationIdx: number, roomIdx: number) => {
    actions.roomRemove(destinationIdx, roomIdx);
  };

  const handleCloneRoom = (destinationIdx: number, roomIdx: number) => {
    actions.roomClone(destinationIdx, roomIdx);
  };

  const handleRoomEditing = (destinationIdx: number, roomIdx: number, p: BookingRoomFields, val: unknown | never) => {
    actions.roomEdit(destinationIdx, roomIdx, p, val as never);
  };

  const handleBookingRequestDestinationEdit = useCallback(
    (p: BookingDestinationFields, e: React.ChangeEvent<HTMLInputElement>, destinationIdx: number) => {
      actions.destinationEdit(p, e.target.value, destinationIdx);
    },
    [actions]
  );

  const handleBookingRequestDestinationAddress = useCallback(
    (p: BookingDestinationFields, value: string, destinationIdx: number) => {
      actions.destinationEdit(p, value, destinationIdx);
    },
    [actions]
  );

  const isAdmin = () => false;

  const checkDatesUpdate = (destinationIdx: number, roomIdx: number, datein: Date, dateout: Date) => {
    handleDateSelection(destinationIdx, roomIdx, datein, 'checkIn');
    handleDateSelection(destinationIdx, roomIdx, dateout, 'checkOut');
    setOpenCheck(false);
  };

  const onOpenCheckDateModal = (roomIdx: number, inDate: Date, outDate: Date) => {
    setOpenRoomIndex(roomIdx);
    setOpenCheckInDate(inDate);
    setOpenCheckoutDate(outDate);
    setOpenCheck(true);
  };

  const setDestinationAddress = (city: string, stateAndCountry: string) => {
    handleBookingRequestDestinationAddress('city', city, destinationIndex);

    const lstAddress = stateAndCountry.split(',');
    handleBookingRequestDestinationAddress('region', lstAddress[lstAddress.length - 1], destinationIndex);

    handleBookingRequestDestinationAddress(
      'state',
      lstAddress.splice(lstAddress.length - 2, 1).join(','),
      destinationIndex
    );
  };

  return (
    <Container>
      {process.env.NODE_ENV === 'development' && (
        <Box sx={{ width: '100%' }}>
          <LocationAutocomplete
            label="Location"
            name="location"
            placeholder={'Please enter location'}
            error={[]}
            onChange={(city, stateAndCountry) => setDestinationAddress(city, stateAndCountry)}
          />
        </Box>
      )}

      <BookingRequestDestinationContainer>
        <MobileRequestDestinationItem>
          <MobileRequestDeleteDestinationItem>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
              {'Destination'}
            </Typography>
            {!isAdmin() && (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleRemoveBookingRequestDestination(destinationIndex)}
                startIcon={<TrashIcon />}
              >
                Delete Destination
              </Button>
            )}
          </MobileRequestDeleteDestinationItem>
          <CustomInput
            activated={isAdmin()}
            disabled={isAdmin()}
            placeholder="City"
            value={destination.city}
            onChange={(e) => {
              handleBookingRequestDestinationEdit('city', e, destinationIndex);
            }}
          />
        </MobileRequestDestinationItem>

        <MobileRequestDestinationItem>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
            {'State'}
          </Typography>
          <CustomInput
            activated={isAdmin()}
            disabled={isAdmin()}
            placeholder="State"
            value={destination.state}
            onChange={(e) => {
              handleBookingRequestDestinationEdit('state', e, destinationIndex);
            }}
          />
        </MobileRequestDestinationItem>

        <MobileRequestDestinationItem>
          <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
            {'Region'}
          </Typography>
          <CustomInput
            activated={isAdmin()}
            disabled={isAdmin()}
            placeholder="Region"
            value={destination.region}
            onChange={(e) => {
              handleBookingRequestDestinationEdit('region', e, destinationIndex);
            }}
          />
        </MobileRequestDestinationItem>
      </BookingRequestDestinationContainer>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {destination.rooms?.map((room: BookingRoom, roomIdx: number) => (
          <RoomAccordion key={roomIdx}>
            <RoomAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={'room-content_' + (roomIdx + 1)}
              id={'room-header_' + (roomIdx + 1)}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
                {'Room ' + (roomIdx + 1)}
              </Typography>
            </RoomAccordionSummary>
            <RoomAccordionDetails>
              <MobileRequestDestinationItem>
                <MobileCheckInput
                  type="string"
                  value={`${formatDateRangeToString(getStringToDate(room.checkIn), getStringToDate(room.checkOut))}`}
                  onClick={() =>
                    onOpenCheckDateModal(roomIdx, getStringToDate(room.checkIn), getStringToDate(room.checkOut))
                  }
                />
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem>
                <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                  {'Guests (per room)'}
                </Typography>
                <QuantityInput
                  style={{ width: '100%' }}
                  disabled={isAdmin()}
                  type="number"
                  value={
                    room.guests === 0
                      ? 0
                      : room.guests.toString().startsWith('0')
                      ? room.guests.toString().slice(1)
                      : room.guests
                  }
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'guests', +e.target.value)}
                />
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem>
                <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                  {'Budget (per night)'}
                </Typography>
                <MobileBudgetSelect
                  value={room.budget}
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'budget', e.target.value)}
                >
                  {budgetOptions.map((bitem, index) => (
                    <MobileBudgetMenuItem key={index} value={bitem.name}>
                      {bitem.text}
                    </MobileBudgetMenuItem>
                  ))}
                </MobileBudgetSelect>
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                    {'Desired location'}
                  </Typography>
                  <RequireField>*</RequireField>
                </div>

                <FreeTextArea
                  style={{ width: '100%' }}
                  placeholder="Your desire Location"
                  disabled={isAdmin()}
                  value={room.location}
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'location', e.target.value)}
                />
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                    {'Hotel preferences'}
                  </Typography>

                  <RequireField>*</RequireField>
                </div>
                <FreeTextArea
                  disabled={isAdmin()}
                  placeholder="Your preferred Hotel "
                  style={{ width: '100%' }}
                  value={room.preference}
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'preference', e.target.value)}
                />
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                    {'Room Type'}
                  </Typography>
                  <RequireField>*</RequireField>
                </div>
                <Select
                  value={room.roomSpecification}
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'roomSpecification', e.target.value)}
                >
                  {bedTypes.map((bed, i) => (
                    <MenuItem value={bed} key={i}>
                      {bed}
                    </MenuItem>
                  ))}
                </Select>
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CheckBox
                  disabled={isAdmin()}
                  type="checkbox"
                  checked={room.parking}
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'parking', e.target.checked)}
                />
                <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                  {'Parking'}
                </Typography>
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CheckBox
                  disabled={isAdmin()}
                  type="checkbox"
                  checked={room.breakfast}
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'breakfast', e.target.checked)}
                />
                <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                  {'Breakfast'}
                </Typography>
              </MobileRequestDestinationItem>
              <MobileRequestDestinationItem>
                <Typography variant="body1" sx={{ fontSize: '12px' }} component="div">
                  {'# Rooms'}
                </Typography>
                <QuantityInput
                  disabled={isAdmin()}
                  style={{ width: '100%' }}
                  type="number"
                  value={
                    room.quantity === 0
                      ? 0
                      : room.quantity.toString().startsWith('0')
                      ? room.quantity.toString().slice(1)
                      : room.quantity
                  }
                  onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'quantity', +e.target.value)}
                />
              </MobileRequestDestinationItem>
              <MobileRequestDuplicateRoomButton>
                <Button
                  style={{ justifyContent: 'center', width: '100%' }}
                  variant="outlined"
                  onClick={() => handleCloneRoom(destinationIndex, roomIdx)}
                >
                  <div>Duplicate Room</div>
                  <CloneIcon style={{ color: 'white', marginLeft: 10 }} />
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveRoom(destinationIndex, roomIdx)}
                  startIcon={<TrashIcon />}
                >
                  Delete Room
                </Button>
              </MobileRequestDuplicateRoomButton>
            </RoomAccordionDetails>
          </RoomAccordion>
        ))}
      </LocalizationProvider>
      <ActionsContainer>
        {!isAdmin() && (
          <>
            <Button
              style={{ justifyContent: 'center', width: '100%' }}
              onClick={() => handleAddRoom(destinationIndex)}
              variant="outlined"
            >
              <div>Add Room</div>
              <AddCircleOutlineIcon style={{ color: 'white', marginLeft: 10 }} />
            </Button>
          </>
        )}
      </ActionsContainer>
      {openCheck && (
        <CheckDateModal
          open={openCheck}
          destinationIdx={destinationIndex}
          roomIdx={openRoomIndex}
          checkin={checkinDate}
          checkout={checkoutDate}
          updateCheckDates={checkDatesUpdate}
        />
      )}
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1
  }
`;

const RequireField = styled.span`
  color: red;
`;

const RoomAccordion = styled(Accordion)`
  width: 100%;
  margin-bottom: 10px;
`;

const RoomAccordionSummary = styled(AccordionSummary)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const RoomAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

const MobileCheckInput = styled.input`
  width: 100%;
  border-radius: 22px;
  padding: 10px;
  border: none;
  background: #f8f8f8;
  :focus {
    border: none;
    outline: none;
    background: #e5e5e5;
  }
`;
const MobileBudgetSelect = styled(Select)`
  width: 100%;
`;
const MobileBudgetMenuItem = styled(MenuItem)`
  width: 100%;
`;

const MobileRequestDeleteDestinationItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
`;

const MobileRequestDuplicateRoomButton = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
`;

const MobileRequestDestinationItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const BookingRequestDestinationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 25px;
`;

const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  animation: ${fadeIn} 0.35s linear;
  box-shadow: 0 0 4px 0px #00000014;
  clip-path: inset(0px -15px 0px -15px);
  position: relative;
`;

export default MobileBookingRequestDestinationItem;
