import { PriceTypeAvailability } from '@micro-frontends/vacayz-api-client';
import { Availability } from '../../common/contexts';

export const HIGH_SEASON: PriceTypeAvailability = 'high_season';
export const LOW_SEASON: PriceTypeAvailability = 'low_season';
export const HOLIDAYS: PriceTypeAvailability = 'holidays';

const availabilityToPriceType = (
  availability: Availability | string
): { availability: PriceTypeAvailability; weekends: boolean } => {
  const priceType = (_availability: PriceTypeAvailability, weekends: boolean) => ({
    availability: _availability,
    weekends,
  });
  switch (availability) {
    case 'highSeasonWithWeekends':
      return priceType(HIGH_SEASON, true);
    case 'highSeasonWithoutWeekends':
      return priceType(HIGH_SEASON, false);
    case 'lowSeasonWithWeekends':
      return priceType(LOW_SEASON, true);
    case 'lowSeasonWithoutWeekends':
      return priceType(LOW_SEASON, false);
    case 'holidays':
      return priceType(HOLIDAYS, true);
    default:
      throw Error('unknown availability selected');
  }
};

export { availabilityToPriceType };
