import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS, FONT_WEIGHT, LazyBg, OptionKey } from '@micro-frontends/shared-components';
import { Option } from '../../common/types';
import { RoutingParams } from '../../App';
import { currencyValue } from '../../common/utils/Format';
import { DealData } from '../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  brandLogo: {
    paddingLeft: 0,
    justifyContent: 'center',

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  infoIco: {
    color: theme.palette.secondary.main,
    marginRight: 4,
    fontSize: 30,
  },
  capPriceLabel: {
    '&:after': {
      display: 'none',
    },
    '& svg': {
      color: theme.palette.secondary.main,
      marginRight: 4,
      fontSize: 30,
    },
  },
  capPriceValue: {
    '& s': {
      color: COLORS.grey,
      paddingRight: theme.spacing(1),
    },
    '& b': {
      color: COLORS.green,
      fontWeight: FONT_WEIGHT.bold,
    },
    '&:after': {
      display: 'none',
    },
  },
  infoText: {
    height: 111,
    alignItems: 'flex-start',
    fontSize: theme.typography.pxToRem(12),
    color: COLORS.grey,

    '&:after': {
      display: 'none',
    },
  },
  cardImage: {
    height: 111,
    width: '100%',
    padding: 0,

    '&:after': {
      display: 'none',
    },
  },
  viewMore: {
    padding: '0 6px',
    justifyContent: 'center',

    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.secondary.main,
      textDecoration: 'underline',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
}));

const getValidForValue = (years: number, days: number): string => {
  const months = Math.floor(days / 30);
  const daysReminder = days % 30;
  const yearsPart = years > 1 ? `${years} Years ` : '1 Year ';
  const monthsPart = months > 1 ? `${months} Months ` : '1 Month ';
  const daysPart = daysReminder > 1 ? `${daysReminder} Days` : '1 Day';
  return `${years > 0 ? yearsPart : ''}${months > 0 ? monthsPart : ''}${daysReminder > 0 ? daysPart : ''}`;
};

const BrandLogo = (data: Option, key: keyof Option): React.ReactNode => {
  const classes = useStyles();

  return (
    data && (
      <div className={classes.brandLogo}>
        <img src={data[key] as string} alt={key} />
      </div>
    )
  );
};

const BrandLogoDealData = (data: DealData, key: keyof DealData): React.ReactNode => {
  const classes = useStyles();

  return (
    data && (
      <div className={classes.brandLogo}>
        <img src={data[key] as string} alt={key} />
      </div>
    )
  );
};

const PercentText: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  const percent = Number(data[key]);
  return <Typography>{percent.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })}</Typography>;
};

const CapPriceLabel = (key: OptionKey<Option>): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.capPriceLabel}>
      <InfoIcon />
      {key.label}
    </div>
  );
};

const CapPriceLabelDealData = (key: OptionKey<DealData>): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.capPriceLabel}>
      <InfoIcon />
      {key.label}
    </div>
  );
};

const CapPriceValue = (data: Option, key: keyof Option): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.capPriceValue}>
      <s>{currencyValue(data.highSeasonWithWeekendsPrice, data.collectionCurrency)}</s>
      <b>
        {currencyValue(
          data.highSeasonWithWeekendsPrice - data.highSeasonWithWeekendsPrice * data.discount,
          data.collectionCurrency
        )}
      </b>
    </div>
  );
};

const CapPriceValueDealData = (data: DealData, key: keyof DealData): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.capPriceValue}>
      <s>{currencyValue(data.highSeasonWithWeekendsPrice, data.collectionCurrency)}</s>
      <b>
        {currencyValue(
          data.highSeasonWithWeekendsPrice - data.highSeasonWithWeekendsPrice * data.discount,
          data.collectionCurrency
        )}
      </b>
    </div>
  );
};

const InfoLabel = (): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.infoText}>
      The Guaranteed max nightly rate of your reservation, even if hotel prices go up. You’ll pay less if prices go
      down.
    </div>
  );
};

const CardImage = (data: Option, _key: keyof Option): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.cardImage}>
      <LazyBg src={data.collectionBackgroundImg} />
    </div>
  );
};

const CardImageDealData = (data: DealData, _key: keyof DealData): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.cardImage}>
      <LazyBg src={data.collectionBackgroundImg} />
    </div>
  );
};
const ViewMore = (data: Option, key: keyof Option): React.ReactNode => {
  const classes = useStyles();
  const history = useHistory();
  const { urlName }: RoutingParams = useParams();

  const handleClick = (e: React.MouseEvent, brandUrlName: string) => {
    e.stopPropagation();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (urlName) {
      history.push(`/b/${brandUrlName}/${urlName}`);
    } else {
      history.push(`/b/${brandUrlName}`);
    }
  };

  return (
    <div className={classes.viewMore} onClick={(e) => handleClick(e, data.brandUrlName)}>
      <span>View more from this chain</span>
    </div>
  );
};

const ValidForValue = (data: Option, key: keyof Option): React.ReactNode => {
  return <div>{getValidForValue(data.validForYears, data.validForDays)}</div>;
};

const Discount: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  const value = Number(data[key]);
  return (
    <Typography variant="h3">
      {value.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })} OFF
    </Typography>
  );
};

const ValidForValueDealData = (data: DealData, key: keyof DealData): React.ReactNode => {
  return <div>{getValidForValue(data.validForYears, data.validForDays)}</div>;
};

const Hotels: (data: Option, key: keyof Option) => JSX.Element = (data, _) => {
  const listings = data.listings;
  if (listings && listings?.length > 1) {
    const hotelsNum = listings.length + 'Hotels';
    return <div>{hotelsNum}</div>;
  } else {
    return <div>{listings && listings.length === 1 ? listings[0].name : data.locationName}</div>;
  }
};

const HotelsDealData: (data: DealData, key: keyof DealData) => JSX.Element = (data, _) => {
  const listings = data.listings;
  if (listings && listings?.length > 1) {
    const hotelsNum = listings.length + 'Hotels';
    return <div>{hotelsNum}</div>;
  } else if (listings && listings.length === 1) {
    return <div>{listings[0].name}</div>;
  } else {
    return <div></div>;
  }
};

export {
  getValidForValue,
  BrandLogo,
  PercentText,
  CapPriceLabel,
  CapPriceValue,
  InfoLabel,
  CardImage,
  ViewMore,
  ValidForValue,
  Discount,
  Hotels,
  BrandLogoDealData,
  HotelsDealData,
  ValidForValueDealData,
  CapPriceLabelDealData,
  CapPriceValueDealData,
  CardImageDealData,
};
