import React from 'react';
import clsx from 'clsx';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '../../components/_elements';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { State } from './usePagination';

const useStyles = makeStyles<Theme, { loader: boolean }>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: ({ loader }) => ({
    cursor: loader ? 'default' : 'pointer',
    opacity: loader ? 0.3 : 1,
    boxShadow: `0 0 2px ${theme.palette.secondary.main}`,
    padding: 8,

    '& + &': {
      marginLeft: theme.spacing(1),
    },
    '&.Mui-disabled': {
      opacity: 0.3,
    },
    '& svg': {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(22),
      transform: 'translateX(2px)',
    },
  }),
}));

interface Props {
  className?: string;
  handlePagination: (direction: 'left' | 'right') => void;
  state: State;
}

const Pagination: React.FC<Props> = ({ state, handlePagination, className }) => {
  const { currentPage, loader, lastPageNumber } = state;
  const classes = useStyles({ loader });

  return (
    <div className={clsx(classes.root, className)}>
      <IconButton
        className={classes.arrow}
        style={{ transform: 'rotate(180deg)' }}
        onClick={() => handlePagination('left')}
        disabled={currentPage === 1}
        size="large">
        <ArrowForwardIosIcon />
      </IconButton>
      <IconButton
        className={classes.arrow}
        onClick={() => handlePagination('right')}
        disabled={lastPageNumber === currentPage}
        size="large">
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

export { Pagination };
