import React, { useCallback, useEffect, useState } from 'react';
import constate from 'constate';

import {
  CollectionOptionPlanTable,
  EntityImage,
  Listing,
  ListingName,
  Metadata,
} from '@micro-frontends/vacayz-api-client';

interface UseDealProvider {
  acceptedByUser: boolean;
  acceptedByUserAlert: boolean;
  availability: Availability | '';
  calculator: Calculator | null;
  createCharge?: (paymentIntentId: number) => Promise<boolean>;
  dealData: DealData | null;
  dealPayload: DealPayload | null;
  loading: boolean;
  numberOfNights: string;
  resetDealContext: () => void;
  setAcceptedByUser: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptedByUserAlert: React.Dispatch<React.SetStateAction<boolean>>;
  setAvailability: React.Dispatch<React.SetStateAction<Availability | ''>>;
  setCalculator: React.Dispatch<React.SetStateAction<Calculator | null>>;
  setCreateCharge: React.Dispatch<React.SetStateAction<((paymentIntentId: number) => Promise<boolean>) | undefined>>;
  setDealData: React.Dispatch<React.SetStateAction<DealData | null>>;
  setDealPayload: React.Dispatch<React.SetStateAction<DealPayload | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setNumberOfNights: (val: string) => void;
}

interface DealPayload {
  collectionId: number;
  optionPlanId: number;
}

interface Calculator {
  maxPricePerNight: number;
  discount: number;
  upfront: number;
  total: number;
  saved: number;
  paynow: number;
}

export type Availability =
  | 'highSeasonWithWeekends'
  | 'highSeasonWithoutWeekends'
  | 'lowSeasonWithWeekends'
  | 'lowSeasonWithoutWeekends'
  | 'holidays';

export const AVAILABILITIES: Availability[] = [
  'highSeasonWithWeekends',
  'highSeasonWithoutWeekends',
  'lowSeasonWithWeekends',
  'lowSeasonWithoutWeekends',
  'holidays',
];

export interface DealData extends Omit<CollectionOptionPlanTable, 'parentLocationName'> {
  metadata: Metadata;
  listings?: Listing[];
  listingsNames: ListingName[];
  images: EntityImage[];
}

export const AVAILABILITY_TO_PRICE: { [key in Availability]: `${key}Price` } = {
  highSeasonWithWeekends: 'highSeasonWithWeekendsPrice',
  highSeasonWithoutWeekends: 'highSeasonWithoutWeekendsPrice',
  lowSeasonWithWeekends: 'lowSeasonWithWeekendsPrice',
  lowSeasonWithoutWeekends: 'lowSeasonWithoutWeekendsPrice',
  holidays: 'holidaysPrice',
};

export const AVAILABILITY_LABELS: { [key in Availability]: string } = {
  highSeasonWithWeekends: 'High season with weekends',
  highSeasonWithoutWeekends: 'High season without weekends',
  lowSeasonWithWeekends: 'Low season with weekends',
  lowSeasonWithoutWeekends: 'Low season without weekends',
  holidays: 'Holidays',
};

const DEFAULT_AVAILABILITY: Availability = 'highSeasonWithWeekends';

// S T O R E

const useDealProvider = (): UseDealProvider => {
  const [acceptedByUser, setAcceptedByUser] = useState<boolean>(false);
  const [acceptedByUserAlert, setAcceptedByUserAlert] = useState<boolean>(false);
  const [availability, setAvailability] = useState<Availability | ''>('');
  const [calculator, setCalculator] = useState<Calculator | null>(null);
  const [createCharge, setCreateCharge] = useState<(paymentIntent: number) => Promise<boolean>>();
  const [dealData, setDealData] = useState<DealData | null>(null);
  const [dealPayload, setDealPayload] = useState<DealPayload | null>(null);
  const [loading, setLoading] = useState(false);
  const [numberOfNights, _setNumberOfNights] = useState<string>('');
  const [selectedPricePerNight, setSelectedPricePerNight] = useState<number | null>(null);

  const setNumberOfNights = useCallback((val: string) => {
    if (/^[1-9][0-9]{0,2}$/.test(val) || val === '') {
      _setNumberOfNights(val);
    }
  }, []);

  const resetDealContext = useCallback(() => {
    setAcceptedByUser(false);
    setAcceptedByUserAlert(false);
    setAvailability('');
    setCalculator(null);
    setCreateCharge(undefined);
    setDealData(null);
    setDealPayload(null);
    setLoading(false);
    setNumberOfNights('');
  }, [setNumberOfNights]);

  useEffect(() => {
    if (dealData) {
      if (availability) setSelectedPricePerNight(dealData[AVAILABILITY_TO_PRICE[availability]]);
      else setAvailability(DEFAULT_AVAILABILITY);
    }
  }, [dealData, availability]);

  useEffect(() => {
    if (dealData) {
      if (numberOfNights && selectedPricePerNight) {
        setCalculator({
          maxPricePerNight: selectedPricePerNight,
          discount: dealData.discount,
          upfront: dealData.upfront,
          total: Number(numberOfNights) * selectedPricePerNight,
          saved: Number(numberOfNights) * selectedPricePerNight * dealData.discount,
          paynow: Number(numberOfNights) * selectedPricePerNight * dealData.upfront,
        });
      } else setCalculator(null);
    }
  }, [dealData, numberOfNights, selectedPricePerNight]);

  return {
    acceptedByUser,
    acceptedByUserAlert,
    availability,
    calculator,
    createCharge,
    dealData,
    dealPayload,
    loading,
    numberOfNights,
    resetDealContext,
    setAcceptedByUser,
    setAcceptedByUserAlert,
    setAvailability,
    setCalculator,
    setCreateCharge,
    setDealData,
    setDealPayload,
    setLoading,
    setNumberOfNights,
  };
};

const [DealProvider, useDealContext] = constate(useDealProvider);

export { DealProvider, useDealContext };
