import React from 'react';
import clsx from 'clsx';

import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = () =>
  createStyles({
    root: (props: TextFieldProps) => ({
      '&.rounded .MuiOutlinedInput-root': {
        borderRadius: props.size === 'small' ? 33 : 55,
      },
    }),
  });
// closing tag

interface Props {
  className?: string;
  rounded?: boolean;
}

const TextField = withStyles(styles)((props: WithStyles<typeof styles> & Omit<TextFieldProps, keyof Props> & Props) => {
  const { classes, rounded, className, ...other } = props;

  return (
    <MuiTextField
      className={clsx(classes.root, className, rounded && 'rounded')}
      variant="outlined"
      color="primary"
      size="small"
      fullWidth
      {...other}
    />
  );
});

export { TextField };
