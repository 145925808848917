import { useState, useEffect, useCallback } from 'react';

const STATE: State = {
  currentPage: 1,
  init: false,
  isEnd: false,
  lastPageNumber: null,
  loader: true,
  perPage: 0,
};

export interface State {
  currentPage: number;
  init: boolean;
  isEnd: boolean;
  lastPageNumber: number | null;
  loader: boolean;
  perPage: number;
}

interface Paginated<T> {
  data: T[] | null;
  fetchData: (currentPage: number) => void;
  handlePagination: (direction: 'left' | 'right') => void;
  pageData: T[];
  resetPagination: () => void;
  state: State;
}

const usePagination = <T>(
  fetcher: (page: number, perPage: number) => Promise<T[] | undefined>,
  perPage = 10
): Paginated<T> => {
  STATE.perPage = perPage;
  const [state, setState] = useState<State>({ ...STATE });
  const [data, setData] = useState<T[] | null>(null);
  const [pageData, setPageData] = useState<T[]>([]);
  const { currentPage, init, isEnd, loader } = state;

  const fetchData = useCallback(
    (requestedPage: number) => {
      updateState({ loader: true });
      fetcher(requestedPage, perPage)
        .then((res) => {
          if (res) {
            if (res.length !== 0) {
              setData((current: T[] | null) => (current ? [...current, ...res] : [...res]));
              updateState({ currentPage: requestedPage });
              if (res.length < perPage) updateState({ isEnd: true, lastPageNumber: requestedPage });
            } else updateState({ isEnd: true, lastPageNumber: requestedPage - 1 });
          }
        })
        .finally(() => updateState({ loader: false, init: true }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, perPage]
  );

  const updateState = useCallback((value: Partial<State>) => {
    setState((current) => ({ ...current, ...value }));
  }, []);

  const handlePagination = useCallback(
    (direction: 'left' | 'right') => {
      if (data && !loader) {
        let nextPage = currentPage;
        if (direction === 'left' && nextPage - 1 > 0) nextPage -= 1;
        else if (direction === 'right') nextPage += 1;

        const { length } = data.slice((nextPage - 1) * perPage, nextPage * perPage);
        if (length === perPage) updateState({ currentPage: nextPage });
        else if (length >= 0 && length < perPage) {
          if (length > 0 && isEnd) updateState({ currentPage: nextPage });
          if (!isEnd) fetchData(nextPage);
        }
      }
    },
    [data, loader, currentPage, perPage, updateState, isEnd, fetchData]
  );

  const resetPagination = useCallback(() => {
    setData(null);
    setState({ ...STATE });
  }, [setState, setData]);

  useEffect(() => {
    resetPagination();
  }, [fetchData, resetPagination]);

  useEffect(() => {
    if (!init) fetchData(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  useEffect(() => {
    setPageData(data ? data.slice((currentPage - 1) * perPage, currentPage * perPage) : []);
  }, [currentPage, data, perPage]);

  return { state, data, pageData, handlePagination, fetchData, resetPagination };
};

export { usePagination };
