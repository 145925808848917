import React, { useState } from 'react';
import constate from 'constate';

import { Brand, Collection, Confirm, Deal, Purchase, Root } from '../constants/Common';

export type RouterOption = Root | Collection | Brand | Deal | Purchase | Confirm | null;

interface UseAppProvider {
  routerOption: RouterOption;
  setRouterOption: React.Dispatch<React.SetStateAction<RouterOption>>;
}

const useAppProvider = (): UseAppProvider => {
  const [routerOption, setRouterOption] = useState<RouterOption>(null);

  return {
    routerOption,
    setRouterOption,
  };
};

const [AppProvider, useAppContext] = constate(useAppProvider);

export { AppProvider, useAppContext };
