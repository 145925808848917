import React, { useCallback, useContext, useState, useEffect } from 'react';
import { HEADER_HEIGHT, useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';
import {
  BookingforGuest,
  calcDateDiff,
  calcNumberToCurrency,
  CreateBookingRequestFormNew,
  isVacayzError,
  OfferStatus,
  saveBookingGuestList,
  toQuerystring,
} from '@micro-frontends/vacayz-api-client';
import { Header } from '../../../../containers/Header/Header';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '@micro-frontends/auth-context';
import { BookingPaymentForm } from './PaymentForm/BookingPaymentForm';
import { Routing } from '../../../../common/constants/routing';

import { TextField, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  calcDiscount,
  DealsSummery,
  NoteSection,
  OrderSection,
  OrderSummary,
  SelectedDealObjects,
  SummaryItem,
  MobileDealsSummery,
  MobileOrderSection,
  MobileSummaryItem,
} from '../../Deals/OfferDeals.view';
import {
  DealAddressItem,
  DealCard,
  DealInfoContainer,
  DealInfoItem,
  DealInfoSubItem,
  HotelName,
  renderDiscount,
} from '../../Deals/Components/OffersScreen';
import HotelIcon from '@mui/icons-material/Hotel';
import FlightIcon from '@mui/icons-material/Flight';

interface Props {
  requestId: number;
  selectedDeals: SelectedDealObjects[];
  bookingRequest: CreateBookingRequestFormNew;
  orderSummary: OrderSummary;
}

const BookingPaymentPageView: React.FC<Props> = ({ requestId, selectedDeals, bookingRequest, orderSummary }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const availablePay = bookingRequest.status
    ? bookingRequest.status === OfferStatus.PENDING_CUSTOMER ||
      bookingRequest.status === OfferStatus.PENDING_NEW_DEAL ||
      bookingRequest.status === OfferStatus.PENDING_MORE_OFFERS ||
      bookingRequest.status === OfferStatus.PENDING_BOOKING ||
      bookingRequest.status === OfferStatus.APPROVED
    : true;

  const isPaid = bookingRequest.status
    ? bookingRequest.status === OfferStatus.PENDING_BOOKING || bookingRequest.status === OfferStatus.APPROVED
    : false;

  if (!selectedDeals) return <div>No Deals found</div>;
  return (
    <>
      <Header />
      <Container padding={HEADER_HEIGHT}>
        {isMobile ? (
          <MobileDealsSummery>
            <MobileOrderSection>
              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Price'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${calcNumberToCurrency(orderSummary.totalPrice)}`}
                </Typography>
              </MobileSummaryItem>

              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Nights'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }} component="div">
                  {`${orderSummary.totalNights}`}
                </Typography>
              </MobileSummaryItem>

              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Deals Selected'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }} component="div">
                  {`${orderSummary.deals}`}
                </Typography>
              </MobileSummaryItem>
              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Discount'}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: '#56bd6d', textAlign: 'center' }}
                  component="div"
                >
                  {`${calcDiscount(orderSummary)}%`}
                </Typography>
              </MobileSummaryItem>
            </MobileOrderSection>
            <NoteSection>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                {'Notes'}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'lighter', wordWrap: 'break-word' }} component="div">
                {bookingRequest.adminNotes}
              </Typography>
            </NoteSection>
          </MobileDealsSummery>
        ) : (
          <DealsSummery>
            <OrderSection>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
                {'Order Summary'}
              </Typography>
              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Price'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${calcNumberToCurrency(orderSummary.totalPrice)}`}
                </Typography>
              </SummaryItem>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Nights'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${orderSummary.totalNights}`}
                </Typography>
              </SummaryItem>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Deals Selected'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${orderSummary.deals}`}
                </Typography>
              </SummaryItem>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Discount'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#56bd6d' }} component="div">
                  {`${calcDiscount(orderSummary)}%`}
                </Typography>
              </SummaryItem>
            </OrderSection>
            <NoteSection>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                {'Notes'}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'lighter', wordWrap: 'break-word' }} component="div">
                {bookingRequest.adminNotes}
              </Typography>
            </NoteSection>
          </DealsSummery>
        )}

        <DealPreviewList
          requestId={requestId}
          selectedDeals={selectedDeals}
          bookingRequest={bookingRequest}
          orderSummary={orderSummary}
        />
        {availablePay && (
          <PaymentForm>
            <BookingPaymentForm
              requestId={requestId}
              selectedDeals={selectedDeals}
              isPaid={isPaid}
              totalAmount={orderSummary.totalPrice}
            />
          </PaymentForm>
        )}
      </Container>
    </>
  );
};
const GuestItems: React.FC<{
  dealItem: SelectedDealObjects;
  requestId: number;
  onUpdatePageGuests: (guests: BookingforGuest[], dealId: number) => void;
}> = ({ dealItem, requestId, onUpdatePageGuests }) => {
  const { client } = useAuthContext();
  const { isMounted, addCleanup } = useAsyncCleanup();
  const { alert } = useContext(AlertContext);
  const [guests, setGuests] = useState<BookingforGuest[]>([]);

  useEffect(() => {
    if (dealItem.guests && dealItem.guests.length > 0) {
      if (dealItem.guests[0].roomNumber) {
        const _guests = [
          ...dealItem.guests,
          ...Array.from({ length: dealItem.room.guests * dealItem.room.quantity - dealItem.guests.length }, (_, i) => ({
            address: '',
            name: '',
            roomNumber: Math.trunc(i / dealItem.room.guests) + 1,
          })),
        ];
        setGuests(_guests);
      } else {
        const _guests = [
          ...dealItem.guests,
          ...Array.from({ length: dealItem.room.guests * dealItem.room.quantity - dealItem.guests.length }, (_) => ({
            address: '',
            name: '',
          })),
        ];
        setGuests(_guests);
      }
    } else {
      const _guests = Array.from({ length: dealItem.room.guests * dealItem.room.quantity }, (_, i) => ({
        address: '',
        name: '',
        roomNumber: Math.trunc(i / dealItem.room.guests) + 1,
      }));
      setGuests(_guests);
    }
  }, [dealItem]);

  function changeEvent(id: number, value: string, fieldName: string) {
    const newGuests: BookingforGuest[] = [...guests];
    if (fieldName === 'name') {
      newGuests[id].name = value;
    } else {
      newGuests[id].address = value;
    }
    setGuests(newGuests);
  }

  async function saveOrder() {
    try {
      const [confirm, cancel] = saveBookingGuestList(
        client,
        requestId,
        dealItem.room.id ?? 0,
        dealItem.deal.id ?? 0,
        guests
      );
      addCleanup(cancel);
      const success = await confirm;
      if (success && isMounted()) {
        onUpdatePageGuests(guests, dealItem.deal.id ?? 0);
        alert('Guests are saved.', 'success');
      }
    } catch (err) {
      if (err && isMounted()) {
        if (isVacayzError(err)) {
          alert(err, 'error');
        } else {
          alert("Couldn't save guests please try again later", 'error');
        }
      }
    }
  }

  return (
    <>
      <DealItemContainer>
        <Guests>
          {guests
            .filter((g) => g.roomNumber === null)
            .map((guest, index) => (
              <Row key={index}>
                <GuestTextField
                  label="Guest name"
                  value={guest.name}
                  variant="standard"
                  onChange={(e) => changeEvent(index, e.target.value, 'name')}
                />
                <GuestTextField
                  label="Guest email"
                  value={guest.address}
                  variant="standard"
                  onChange={(e) => changeEvent(index, e.target.value, 'address')}
                />
              </Row>
            ))}
          {Array.from(Array(dealItem.room.quantity), (_, index) => {
            return (
              <Grid container key={index}>
                <Typography>{`Room ${index + 1}`}</Typography>
                {guests
                  .filter((g) => g.roomNumber && g.roomNumber === index + 1)
                  .map((g, i) => (
                    <Row key={i}>
                      <GuestTextField
                        label="Guest name"
                        value={g.name}
                        variant="standard"
                        onChange={(e) => changeEvent(index * dealItem.room.guests + i, e.target.value, 'name')}
                      />
                      <GuestTextField
                        label="Guest email"
                        value={g.address}
                        variant="standard"
                        onChange={(e) => changeEvent(index * dealItem.room.guests + i, e.target.value, 'address')}
                      />
                    </Row>
                  ))}
              </Grid>
            );
          })}
        </Guests>
      </DealItemContainer>
      <DealSelection onClick={() => saveOrder()}>
        <Typography variant="h4" sx={{ color: '#f0f0f0' }} component="div">
          {'Save Guests'}
        </Typography>
      </DealSelection>
    </>
  );
};

const DealPreviewList: React.FC<Props> = ({ requestId, selectedDeals, bookingRequest, orderSummary }) => {
  const PAYMENTS_PAGE = Routing.offerPayment;
  const history = useHistory();
  const [selectedDeal, setSelectedDeal] = useState<number>(-1);

  const selectDeal = useCallback(
    (dealId: number | undefined) => {
      if (!dealId) return;

      if (selectedDeal !== dealId) {
        setSelectedDeal(dealId);
      }
    },
    [selectedDeal]
  );
  const onUpdatePageState = (guests: BookingforGuest[], dealId: number) => {
    const deals = [...selectedDeals];
    deals.filter((item) => item.deal.id === dealId)[0].guests = [...guests];
    const dataToSend = {
      selectedDeals: deals,
      bookingRequest: bookingRequest,
      orderSummary: orderSummary,
    };
    history.push(PAYMENTS_PAGE + toQuerystring({ requestId: requestId }), dataToSend);
  };

  return (
    <>
      <DealPreviewItemTitle>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
          {'Selected Deals'}
        </Typography>
      </DealPreviewItemTitle>

      <DealListContainer>
        {selectedDeals.map((dealItem) =>
          dealItem.deal.dealType === 'hotel' ? (
            <DealCard
              key={dealItem.deal.id}
              isSelected={dealItem.deal.id === selectedDeal}
              style={{ height: 'auto', width: '400px' }}
              onClick={() => selectDeal(dealItem.deal.id)}
            >
              <HotelName>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'lighter', textAlign: 'center', display: 'flex' }}
                  component="div"
                >
                  <HotelIcon sx={{ marginRight: 1 }} />
                  {dealItem.deal.name}
                </Typography>
              </HotelName>
              <DealInfoContainer>
                <DealAddressItem>
                  <Typography
                    variant="body1"
                    sx={{ marginTop: '20px', textAlign: 'center', color: '#a4a4a4' }}
                    component="div"
                  >
                    {dealItem.deal.address}
                  </Typography>
                </DealAddressItem>
                <DealAddressItem>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#a4a4a4', marginRight: '5px' }}
                    component="div"
                  >
                    {dealItem.room.checkIn}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#a4a4a4', marginRight: '5px' }}
                    component="div"
                  >
                    {'-'}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {dealItem.room.checkOut}
                  </Typography>
                </DealAddressItem>
                <DealInfoItem>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Room Type'}
                  </Typography>

                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {dealItem.deal.roomType}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '20px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Total Nights'}
                  </Typography>

                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {`${calcDateDiff(dealItem.room.checkOut, dealItem.room.checkIn) * dealItem.room.quantity}`}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <DealInfoSubItem>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                      {'Market Nights'}
                    </Typography>

                    <Typography variant="body1" sx={{ fontWeight: 'lighter', color: '#a4a4a4' }} component="div">
                      {`$${dealItem.deal.marketTotalPrice ?? 0}(per night)`}
                    </Typography>
                  </DealInfoSubItem>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', textDecoration: 'line-through' }}
                    component="div"
                  >
                    {`${calcNumberToCurrency(dealItem.deal.ourPriceTotal)}`}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Discount'}
                  </Typography>

                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#11B968' }} component="div">
                    {`${renderDiscount(dealItem.deal.marketTotalPrice ?? 0, dealItem.deal.ourPriceTotal)}`}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <DealInfoSubItem>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                      {`${process.env.REACT_APP_COMPANY_NAME} Price`}
                    </Typography>

                    <Typography variant="body2" sx={{ fontWeight: 'lighter', color: '#a4a4a4' }} component="div">
                      {`$${dealItem.deal.price}(per night)`}
                    </Typography>
                  </DealInfoSubItem>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#176FBF', display: 'flex', alignItems: 'center' }}
                    component="div"
                  >
                    {`${calcNumberToCurrency(dealItem.deal.ourPriceTotal)}`}
                  </Typography>
                </DealInfoItem>
                <DottedLine />
                {dealItem.deal.refundable && dealItem.deal.refundableUntil && (
                  <DealInfoItem style={{ marginTop: '10px' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                      {'Refundable Until'}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                      {dealItem.deal.refundableUntil ? dealItem.deal.refundableUntil : ``}
                    </Typography>
                  </DealInfoItem>
                )}

                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Total Guests'}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {`${dealItem.room.guests * dealItem.room.quantity}`}
                  </Typography>
                </DealInfoItem>
                <GuestItems dealItem={dealItem} requestId={requestId} onUpdatePageGuests={onUpdatePageState} />
              </DealInfoContainer>
            </DealCard>
          ) : (
            <DealCard
              key={dealItem.deal.id}
              isSelected={dealItem.deal.id === selectedDeal}
              style={{ height: 'auto', width: '400px' }}
              onClick={() => selectDeal(dealItem.deal.id)}
            >
              <HotelName>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'lighter', textAlign: 'center', display: 'flex' }}
                  component="div"
                >
                  <FlightIcon sx={{ marginRight: 1 }} />
                  {dealItem.deal.name}
                </Typography>
              </HotelName>
              <DealInfoContainer>
                <DealInfoItem>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Departure'}
                  </Typography>

                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {dealItem.deal.flightDetails?.airportDeparture}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem>
                  <Typography component="div" />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {dealItem.deal.flightDetails?.datetimeDeparture}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Arrival'}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {dealItem.deal.flightDetails?.airportArrival}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem>
                  <Typography component="div" />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {dealItem.deal.flightDetails?.datetimeArrival}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Flight Number'}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {dealItem.deal.flightDetails?.flightNumber}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Market Price'}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'lighter', color: '#a4a4a4' }} component="div">
                    {`$${dealItem.deal.marketTotalPrice?.toFixed(2)}`}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Discount'}
                  </Typography>

                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#11B968' }} component="div">
                    {`${renderDiscount(dealItem.deal.marketTotalPrice ?? 0, dealItem.deal.ourPriceTotal)}`}
                  </Typography>
                </DealInfoItem>
                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {`${process.env.REACT_APP_COMPANY_NAME} Price`}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#176FBF', display: 'flex', alignItems: 'center' }}
                    component="div"
                  >
                    {`${calcNumberToCurrency(dealItem.deal.ourPriceTotal)}`}
                  </Typography>
                </DealInfoItem>

                <DottedLine />
                {dealItem.deal.refundable && dealItem.deal.refundableUntil && (
                  <DealInfoItem style={{ marginTop: '10px' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                      {'Refundable Until'}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                      {dealItem.deal.refundableUntil ? dealItem.deal.refundableUntil : ``}
                    </Typography>
                  </DealInfoItem>
                )}

                <DealInfoItem style={{ marginTop: '10px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                    {'Total Guests'}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                    {`${dealItem.room.guests * dealItem.room.quantity}`}
                  </Typography>
                </DealInfoItem>
                <GuestItems dealItem={dealItem} requestId={requestId} onUpdatePageGuests={onUpdatePageState} />
              </DealInfoContainer>
            </DealCard>
          )
        )}
      </DealListContainer>
    </>
  );
};

const GuestTextField = styled(TextField)({
  marginLeft: 10,
  marginRight: 10,
});

const Guests = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    margin-top: 5px;
  }
`;

const DottedLine = styled.div`
  width: 90%;
  border: none;
  border-top: 3px dotted #a4a4a4;
  color: #fff;
  background-color: #fff;
  height: 2px;
  margin: 10px;
`;

const DealListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 80%;
  padding-top: 20px;
  margin: 0 auto;
`;

const DealSelection = styled.div`
  background: #176fbf;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DealItemContainer = styled.div`
  margin: 20px;
  text-align: center;
`;

const DealPreviewItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 125px;
  box-shadow: -3px 3px 12px 1px #00000014;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
`;

const PaymentForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div<{ padding: number }>`
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: ${({ padding }) => padding * 1.5}px;
  flex-direction: column;
  padding-bottom: 160px;
  @media screen and (max-width: 1024px) {
    padding-bottom: 180px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  color: gray;
`;
export { BookingPaymentPageView };
