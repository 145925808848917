import { CreatePaymentIntentRequest, Order, PaymentIntent, PayoutMethod } from './typings';
import { PaginatedResult, VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';

const createPaymentIntent = (
  client: VacayzClient,
  fields: CreatePaymentIntentRequest
): VacayzResponse<PaymentIntent> => {
  const url = 'api/v1/payments/payment_intents';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<PaymentIntent>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

// Update when orders are re-designed
const getBrandOrders = (
  client: VacayzClient,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<Order>> => {
  const qs = toQs({ page, per_page: perPage });
  const url = `api/v1/payments/payment_intents/orders${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<Order>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getUserPaymentIntents = (
  client: VacayzClient,
  userId: number,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<PaymentIntent>> => {
  const qs = toQs({ page, per_page: perPage, user_id: userId });
  const url = `api/v1/payments/payment_intents${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<PaymentIntent>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getPayoutMethods = (
  client: VacayzClient,
  filter: { userId?: number; brandId?: number }
): VacayzResponse<PayoutMethod> => {
  const qs = toQs({ user_id: filter.userId, brand_id: filter.brandId });
  const url = `api/v1/payments/payout_methods${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PayoutMethod>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createPayoutMethod = (client: VacayzClient, userId: number): VacayzResponse<PayoutMethod> => {
  const qs = toQs({ user_id: userId });
  const url = `api/v1/payments/payout_methods${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<PayoutMethod>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const paymentFailedMethod = (client: VacayzClient, paymentId: number, error: string): VacayzResponse<boolean> => {
  const url = `api/v1/payments/booking/payment_intents/failed`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(
      url,
      { booking_payment_intent_id: paymentId, error },
      {
        cancelToken: source.token,
      }
    );
    return response?.status === 201;
  };
  return [handleCancel(promise), source.cancel];
};

export {
  createPaymentIntent,
  getBrandOrders,
  getUserPaymentIntents,
  getPayoutMethods,
  createPayoutMethod,
  paymentFailedMethod,
};
