import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';

const MainContainer = withStyles(() => ({
  root: {
    maxWidth: 1440,
  },
}))(Container);

export { MainContainer };
