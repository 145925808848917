import React from 'react';

import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import { DealGallery } from '../DealGallery/DealGallery';
import { DealAvailability } from '../DealAvailability/DealAvailability.view';
import { DealBodyCalc } from '../DealBodyCalc/DealBodyCalc.view';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDealContext } from '../../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  gallery: {
    marginBottom: theme.spacing(3),
  },
}));

const DealBodyView: React.FC = () => {
  const classes = useStyles();
  const { dealData } = useDealContext();

  return (
    <div className={classes.root}>
      <Fade in={!!dealData}>
        <div>
          <DealGallery images={dealData?.images} />
        </div>
      </Fade>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <DealAvailability />
        </Grid>
        <Grid item xs={12} md={4}>
          <DealBodyCalc />
        </Grid>
      </Grid>
    </div>
  );
};

export { DealBodyView };
