import React from 'react';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import StripePurchaseForm from './StripePurchaseForm';
import { Card, TextField } from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import { usePurchaseWithCard } from '../../common/hooks';

const WIRE_TRANSFER = 'WIRE_TRANSFER';
const CREDIT_CARD = 'CREDIT_CARD';
export type PaymentMethod = 'WIRE_TRANSFER' | 'CREDIT_CARD';

const DealPurchasePayment: React.FC = () => {
  const { currentUser } = useAuthContext();
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>(WIRE_TRANSFER);
  const { loading, isCardPossible } = usePurchaseWithCard();

  const handleChange = (newValue: PaymentMethod) => {
    setPaymentMethod(newValue);
  };

  return (
    <Card fullWidth>
      <Container disableGutters maxWidth="sm">
        <Box mb={3}>
          <Typography variant="h2" align="center">
            Payment Method
          </Typography>
        </Box>
        <Box mb={3}>
          <FormControl variant="outlined" color="secondary" fullWidth>
            <InputLabel id="payment-method">Payment Method</InputLabel>
            <Select
              labelId="payment-method"
              value={paymentMethod}
              onChange={(event) => handleChange(event.target.value as PaymentMethod)}
              label="Payment Method"
            >
              <MenuItem value={WIRE_TRANSFER}>Wire Transfer</MenuItem>
              <MenuItem value={CREDIT_CARD} disabled={loading || !isCardPossible}>
                Credit Card
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {paymentMethod === WIRE_TRANSFER ? (
          <Alert severity="info">
            Please note that you will need to complete the wire transfer manually. It may take up to 2 business days to
            process your payment.
          </Alert>
        ) : null}
      </Container>
      {paymentMethod === CREDIT_CARD ? <StripePurchaseForm /> : null}
      <Container disableGutters maxWidth="sm">
        <Box my={3}>
          <Typography variant="h2" align="center">
            Contact Info
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField disabled label="First Name" value={currentUser?.firstName ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField disabled label="Last Name" value={currentUser?.lastName ?? ''} />
          </Grid>
          <Grid item xs={12}>
            <TextField disabled label="Email Address" value={currentUser?.email} />
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

export { DealPurchasePayment };
