import React from 'react';
import styled from 'styled-components';
import Hotel from '@mui/icons-material/Hotel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/FileCopy';
import HighlightOff from '@mui/icons-material/HighlightOff';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

type Props = {
  Icon: React.JSXElementConstructor<Record<string, unknown>>;
  name: string;
  size: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomIcon = ({ Icon, size }: Props) => {
  const StyledIcon = styled(Icon)`
    cursor: pointer;
    color: #adadad;
    :hover {
      color: #176fbf;
    }
  `;
  return <StyledIcon size={size} />;
};

export const HotelIcon = styled(Hotel)`
  cursor: pointer;
  color: #adadad;
  :hover {
    color: #176fbf;
  }
`;

export const AddIcon = styled(AddCircleOutlineIcon)`
  cursor: pointer;
  color: #adadad;
  :hover {
    color: #176fbf;
  }
`;

export const CloneIcon = styled(ContentCopyIcon)`
  cursor: pointer;
  color: #adadad;
  :hover {
    color: #176fbf;
  }
`;

export const DeleteIcon = styled(HighlightOff)`
  cursor: pointer;
  color: #adadad;
  :hover {
    color: #176fbf;
  }
`;

export const TrashIcon = styled(DeleteOutlineOutlinedIcon)`
  cursor: pointer;
  color: #adadad;
  :hover {
    color: #176fbf;
  }
`;
