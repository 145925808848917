import { ErrorResponse } from '@micro-frontends/vacayz-api-client';
import constate from 'constate';
import { useCallback, useState } from 'react';
import { extractFormErrors, FormError } from '../forms';

type FormErrorContext = Record<string, FormError[]>;

interface UseFormErrorsProvider {
  formErrorsContext: FormErrorContext;
  resetFormErrors: (formName: string) => void;
  setFormErrors: (formName: string, formErrors: FormError[]) => void;
}

const useFromErrorsProvider = (): UseFormErrorsProvider => {
  const [errors, setErrors] = useState<FormErrorContext>({});

  const resetFormErrors = useCallback(
    (formName: string) => {
      setErrors((_formErrors) => ({ ..._formErrors, ...{ [formName]: [] } }));
    },
    [setErrors]
  );

  const setFormErrors = useCallback(
    (formName: string, formErrors: FormError[]) => {
      setErrors((_formErrors) => ({ ..._formErrors, ...{ [formName]: formErrors } }));
    },
    [setErrors]
  );

  return { formErrorsContext: errors, setFormErrors, resetFormErrors };
};

const [FormErrorsProvider, useFormErrorsContext] = constate(useFromErrorsProvider);

export interface UseFormErrors {
  addError: (error: FormError) => void;
  formErrors: FormError[];
  getMessages: (fieldName?: string) => string[];
  hasErrors: (fieldName?: string) => boolean;
  resetFormErrors: () => void;
  setFormErrors: (formErrors: FormError[] | ErrorResponse) => void;
}

const useFormErrors = (formName: string): UseFormErrors => {
  const [name] = useState(formName);
  const {
    resetFormErrors: resetContext,
    setFormErrors: setContext,
    formErrorsContext: context,
  } = useFormErrorsContext();

  const resetFormErrors = useCallback(() => {
    resetContext(name);
  }, [name, resetContext]);

  const setFormErrors = useCallback(
    (_formErrors: FormError[] | ErrorResponse) => {
      setContext(name, Array.isArray(_formErrors) ? [..._formErrors] : extractFormErrors(_formErrors));
    },
    [name, setContext]
  );

  const getMessages = useCallback(
    (fieldName?: string) => {
      if (context?.[name] && context[name].length > 0) {
        const errors = fieldName
          ? context[name].filter((formError: FormError) => formError.field === fieldName)
          : context[name];
        return errors.map((formError: FormError) => formError.message);
      }
      return [];
    },
    [context, name]
  );

  const hasErrors = useCallback(
    (fieldName?: string) => {
      return getMessages(fieldName).length > 0;
    },
    [getMessages]
  );

  const addError = useCallback(
    (error: FormError) => {
      setFormErrors([...context[name], error]);
    },
    [context, name, setFormErrors]
  );
  return { resetFormErrors, setFormErrors, formErrors: context?.[name], getMessages, hasErrors, addError };
};

export { FormErrorsProvider, useFormErrors };
