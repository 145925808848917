import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { OptionPlanVersion } from '@micro-frontends/vacayz-api-client';

import { OptionKey } from '../OptionsTable';
import { COLORS } from '../../themes';
import { CurrencyText } from './GeneralTableTextDisplay';
import { BalanceProgress } from './BalanceProgress';
import { OptionsTableData } from './OptionsTableData';

const useStyles = makeStyles((theme: Theme) => ({
  brandLogo: {
    paddingLeft: 0,
    justifyContent: 'center',

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  statusCircle: {
    display: 'inline-block',
    width: 18,
    height: 18,
    borderRadius: '50%',
    verticalAlign: -3,
    marginRight: 5,

    '&.used': {
      background: theme.palette.grey[800],
    },
    '&.available': {
      background: `linear-gradient(90deg, ${COLORS.blue} 0%, ${COLORS.green} 100%)`,
    },
  },
  BalanceVisual: {
    maxWidth: 'calc(100% - 22px)',
  },
  capPriceLabel: {
    '&:after': {
      display: 'none',
    },
    '& svg': {
      color: theme.palette.secondary.main,
      marginRight: 4,
      fontSize: 30,
    },
  },
  capPriceValue: {
    '&:after': {
      display: 'none',
    },
  },
  infoText: {
    height: 111,
    alignItems: 'flex-start',
    fontSize: theme.typography.pxToRem(12),
    color: COLORS.grey,
  },
}));

const ValidForValue = (data: OptionPlanVersion): React.ReactNode => {
  const getValidForValue = () => {
    const years = data.validForYears;
    const days = data.validForDays;

    if (years > 0) return years > 1 ? years + ' Years' : years + ' Year';
    else return days > 1 ? days + ' Days' : days + ' Day';
  };

  return <div>{getValidForValue()}</div>;
};

const BrandLogo: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  const classes = useStyles();

  return (
    data && (
      <div className={classes.brandLogo}>
        <img src={(data[key] as unknown) as string} alt={key.toString()} />
      </div>
    )
  );
};

const BalanceVisual = (data: OptionsTableData): React.ReactNode => {
  const classes = useStyles();

  return (
    data &&
    data.used != null &&
    data.available != null && (
      <div>
        <BalanceProgress className={classes.BalanceVisual} used={data.used} available={data.available} />
      </div>
    )
  );
};

const BalanceValueTotal = (data: OptionsTableData): React.ReactNode => {
  if (data.available !== undefined && data.used !== undefined) {
    const total = data.available + data.used;
    return (
      <div>
        {total} {total === 1 ? 'Night' : 'Nights'}
      </div>
    );
  } else return <div></div>;
};

const StatusLabel: <T>(key: OptionKey<T>) => JSX.Element = (key) => {
  const classes = useStyles();

  return (
    <div>
      <span className={clsx(classes.statusCircle, key.value === 'used' ? 'used' : 'available')}></span>
      {key.label}
    </div>
  );
};

const CapPriceValue = (data: OptionsTableData, key: keyof OptionsTableData): React.ReactNode => {
  const classes = useStyles();

  return <div className={classes.capPriceValue}>{CurrencyText(data, key, data.collectionCurrency)}</div>;
};

const InfoLabel = (): React.ReactNode => {
  const classes = useStyles();

  return (
    <div className={classes.infoText}>
      The Guaranteed max nightly rate of your reservation, even if hotel prices go up. You’ll pay less if prices go
      down.
    </div>
  );
};
const Hotels = (data: OptionsTableData): React.ReactNode => {
  const classes = useStyles();

  if (data.listings) {
    if (data.listings.length == 1) {
      return <div className={classes.infoText}>{data.listings[0].name}</div>;
    } else if (data.listings.length > 1) {
      const hotelsNum = data.listings.length + 'Hotels';
      return <div>{hotelsNum}</div>;
    }
  }
  return null;
};

export { BrandLogo, Hotels, BalanceVisual, StatusLabel, ValidForValue, CapPriceValue, InfoLabel, BalanceValueTotal };
