import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import WarningIcon from '@mui/icons-material/Warning';

interface Props {
  open: boolean;
  roomCnt: number;
  selectedCnt: number;
  setHandleOpen: (isOpen: boolean, isContinue: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderWarnModal: React.FC<Props> = ({ open, setHandleOpen, roomCnt, selectedCnt }) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHandleOpen(false, false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid display="flex">
              <Grid display="flex" alignItems="center" sx={{ marginRight: 1 }}>
                <WarningIcon color="warning" fontSize="large" />
              </Grid>
              <Grid>
                <Typography variant="h6" color="warning">{`You have ${
                  roomCnt - selectedCnt
                }/${roomCnt} of rooms without a selected deal.`}</Typography>
                <Typography variant="body1">{'By clicking order you will lose the deals for those rooms.'}</Typography>
                <Typography variant="body1">{'Click back to go and select deals for them'}</Typography>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleOpen(false, true)} variant="contained">
            Order
          </Button>
          <Button onClick={() => setHandleOpen(false, false)}>Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderWarnModal;
