import React from 'react';
import styled from 'styled-components';

type Props = {
  placeholder: string;
  inputType?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const DealInput = ({ placeholder, inputType, value, onChange }: Props): JSX.Element => {
  return (
    <CustomInput
      type={inputType || 'text'}
      placeholder={placeholder}
      value={value || ''}
      onChange={(e) => onChange(e)}
    />
  );
};

const CustomInput = styled.input`
  text-indent: 10px;
  height: 70px;
  width: 90%;
  margin: 0 auto;
  border: none;
  background: transparent;
  cursor: pointer;
  :focus {
    background: #e8e8e8;
    outline: none;
  }
`;
export { DealInput };
