/* eslint-disable */
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { DateRange, RangeKeyDict } from 'react-date-range';

const DateRangeModal: React.FC<{
    open: boolean,
    checkInDate: Date,
    checkOutDate: Date,
    onOk: (checkInDate: Date, checkOutDate: Date) => void,
    onClose: () => void,
}> = ({ open, checkInDate, checkOutDate, onOk, onClose }) => {
    const today = new Date();
  
    const [date, setDate] = useState([
      {
        startDate: checkInDate,
        endDate: checkOutDate,
        key: 'selection',
      },
    ]);
  
    const handleChangeDate = (ranges: RangeKeyDict) => {
      setDate([
        {
          startDate: ranges.selection.startDate || new Date(),
          endDate: ranges.selection.endDate || new Date(),
          key: 'selection',
        },
      ]);
    };
  
    return (
      <Dialog open={open} maxWidth={'md'} onClose={onClose}>
        <DialogTitle>Check In - Check Out</DialogTitle>
        <DialogContent>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => handleChangeDate(item)}
            ranges={date}
            minDate={today}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => onOk(date[0].startDate, date[0].endDate)} fullWidth>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
};

/* eslint-enable */
export { DateRangeModal };
