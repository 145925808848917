import React from 'react';
import { DealQuestions } from '../../containers/Deal/DealQuestions/DealQuestions';

import { DealPurchaseContainer } from '../../containers/DealPurchase/DealPurchaseContainer';
import { DealPurchaseOption } from '../../containers/DealPurchase/DealPurchaseOption';
import { DealPurchasePayment } from '../../containers/DealPurchase/DealPurchasePayment';
import { DealPurchaseTerms } from '../../containers/DealPurchase/DealPurchaseTerms';

const DealPurchaseView: React.FC = () => {
  return (
    <DealPurchaseContainer withCalc>
      <DealPurchaseOption />
      <DealPurchasePayment />
      <DealPurchaseTerms />
      <DealQuestions />
    </DealPurchaseContainer>
  );
};

export { DealPurchaseView };
