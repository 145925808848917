import React from 'react';
import styled from 'styled-components';

import { Header } from '../../../containers/Header/Header';
import { HEADER_HEIGHT } from '@micro-frontends/shared-components';
import { Paragraph, Title } from '../Payment/PaymentSuccess/PaymentSuccess.view';
import { Button } from '@mui/material';
import IMG_SRC from '../../../assets/pana.png';
//import useMediaQuery from '@mui/material/useMediaQuery';
//import { useTheme } from '@mui/material/styles';

//const BRAND_LOGO = 'https://res.cloudinary.com/vacayz/image/upload/v1620648185/None34.png';
//const TRAVEL_ICON = 'https://res.cloudinary.com/vacayz/image/upload/v1630236632/travel_logo_pxvw4a.png';
const nextUrl = `${process.env.REACT_APP_SIGN_IN_URL}?next=${process.env.REACT_APP_USER_ACCOUNT_SETTINGS_URL}`;

const RequestSuccessView: React.FC = () => {
  //const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer headerSize={HEADER_HEIGHT}>
      <Header />
      <Container>
        <DescriptionContainer>
          <Title>Thank you for submitting your details!</Title>
          <Paragraph>
            We are working on getting you the best deal out there. We will get back to you with an offer within 24
            hours.
          </Paragraph>
          <Paragraph>
            Check your <Span>email</Span> for further information
          </Paragraph>
          <Paragraph>
            Same-day reservations or any urgent matter please contact us via chat or email at{' '}
            <EmailTag href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}>
              {process.env.REACT_APP_SUPPORT_EMAIL}
            </EmailTag>
          </Paragraph>
          <ParagraphCreateAccount>
            In the meantime, click below to set up your account and view your upcoming offers
          </ParagraphCreateAccount>
          <ButtonContainer>
            <Button
              sx={{ backgroundColor: '#176FBF' }}
              variant="contained"
              onClick={() => (document.location.href = nextUrl)}
            >
              Create Your Account
            </Button>
          </ButtonContainer>

          <TravelImgContainer>
            <TravelImg src={IMG_SRC} />
          </TravelImgContainer>
          <ParagraphQuestion>
            If you have questions on how to get started, you can message us on the chat or reach us at{' '}
            <EmailTag href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}>
              {process.env.REACT_APP_SUPPORT_EMAIL}
            </EmailTag>
          </ParagraphQuestion>
        </DescriptionContainer>
      </Container>
    </PageContainer>
  );
};

const Span = styled.span`
  font-weight: bold;
`;

const Container = styled.div`
  display: flex;
`;

const PageContainer = styled.div<{ headerSize: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ headerSize }) => headerSize * 1.5}px;
`;

const EmailTag = styled.a`
  text-decoration: none;
  color: #176fbf;
`;
const ButtonContainer = styled.div`
  margin: 20px;
`;

// const ParagraphContact = styled.p`
//   font-weight: bold;
//   font-size: 18px;
//   text-align: center;
// `;
const ParagraphCreateAccount = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: #176fbf;
  text-align: center;
`;

const ParagraphQuestion = styled.p`
  font-weight: 600;
  font-size: 18px;
  padding-top: 25px;
  color: #455a64;
  margin-top: auto;
  text-align: center;
`;

const TravelImg = styled.img`
  height: auto;
  max-width: 320px;
`;

const TravelImgContainer = styled.div`
  display: flex;
  align-items: end;
`;

const DescriptionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  height: 720px;
`;

export default RequestSuccessView;
