import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppContext, useDealContext } from '../../common/contexts';
import { PURCHASE } from '../../common/constants/Common';
import { DealPurchaseView } from './DealPurchase.view';

const DealPurchaseLogic: React.FC = () => {
  const { setRouterOption } = useAppContext();
  const { dealData, dealPayload, numberOfNights } = useDealContext();
  const history = useHistory();

  useEffect(() => {
    if (dealData && dealPayload && numberOfNights) setRouterOption(PURCHASE);
    else history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DealPurchaseView />;
};

export { DealPurchaseLogic };
