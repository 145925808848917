import React from 'react';
import clsx from 'clsx';

import { DropzoneArea } from 'material-ui-dropzone';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { CenteredProgress, IconButton, LazyBg } from '../_elements';

import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { EntityImage, VacayzClient } from '@micro-frontends/vacayz-api-client';
import { FONT_WEIGHT } from '../../themes';
import { useDropzoneImage } from './useDropzoneImage.hook';
import { FormError } from '../../common/forms/helpers';
import FormHelperText from '@mui/material/FormHelperText';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(1),
      overflow: 'hidden',

      '&.loading': {
        '& $body': {
          '& .lazy-bg': {
            opacity: 0.5,
          },
          '& .MuiDropzoneArea-root': {
            display: 'none',
          },
        },
      },
      '&.error': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',

      '& p': {
        padding: theme.spacing(1.5, 1, 1, 2),
      },
    },
    body: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      position: 'relative',
      paddingBottom: '90%',

      '& > *': {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      },
      '& .lazy-bg': {
        backgroundImage:
          'linear-gradient(45deg, #C9C9C9 25%, transparent 25%),\
          linear-gradient(45deg, transparent 75%, #C9C9C9 75%),\
          linear-gradient(45deg, transparent 75%, #C9C9C9 75%),\
          linear-gradient(45deg, #C9C9C9 25%, transparent 25%)',
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 0, -10px -10px, 10px 10px',
      },
    },
    dropzone: {
      backgroundImage:
        'linear-gradient(45deg, #C9C9C9 25%, transparent 25%),\
        linear-gradient(45deg, transparent 75%, #C9C9C9 75%),\
        linear-gradient(45deg, transparent 75%, #C9C9C9 75%),\
        linear-gradient(45deg, #C9C9C9 25%, transparent 25%)',
      backgroundSize: '20px 20px',
      backgroundPosition: '0 0, 0 0, -10px -10px, 10px 10px',
      border: 'none',
      minHeight: 0,
    },
    dropzoneText: {
      color: '#303030',
      fontWeight: FONT_WEIGHT.bold,
      fontSize: theme.typography.pxToRem(22),
    },
  });

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

interface ImageUploadProps {
  className?: string;
  client: VacayzClient;
  defaultValue?: EntityImage;
  errors?: string[];
  label: string;
  onChange: (imageId: number | null) => void;
  setError?: (errors?: FormError[]) => void;
}

const DropzoneImage = withStyles(styles)((props: WithStyles<typeof styles> & ImageUploadProps) => {
  const { className, classes, client, defaultValue, label, onChange, setError, errors } = props;
  const { sendImage, removeImage, loading, entityImage } = useDropzoneImage(client, onChange, setError, defaultValue);

  return (
    <div className={clsx(classes.root, className, loading && 'loading', errors?.length && 'error')}>
      <div className={classes.header}>
        <Typography variant="body1">{label}</Typography>
        {!!entityImage && (
          <IconButton
            onClick={() => removeImage(entityImage)}
            color="secondary"
            disabled={loading}
            size="large">
            <DeleteIcon />
          </IconButton>
        )}
      </div>
      {errors?.length
        ? errors.map((error) => (
            <FormHelperText key={error} error={true}>
              {error}
            </FormHelperText>
          ))
        : null}
      <div className={classes.body}>
        {loading ? (
          <CenteredProgress />
        ) : !!entityImage ? (
          <LazyBg src={entityImage.url} />
        ) : (
          <DropzoneArea
            showPreviews={false}
            filesLimit={1}
            maxFileSize={MAX_FILE_SIZE}
            showPreviewsInDropzone={false}
            dropzoneClass={classes.dropzone}
            dropzoneParagraphClass={classes.dropzoneText}
            acceptedFiles={['image/*']}
            showAlerts={['error', 'info']}
            onChange={(files) => (files.length === 1 ? sendImage(files[0]) : sendImage(undefined))}
          />
        )}
      </div>
    </div>
  );
});

export { DropzoneImage };
