import React, { ComponentType } from 'react';
import { useAuthContext } from './Auth.context';
import { toQuerystring } from '@micro-frontends/vacayz-api-client';

const Loading = (
  <div
    style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.5rem' }}
  >
    Loading...
  </div>
);

function withEnforceSignIn<P = Record<string, unknown>>(
  Component: ComponentType<P>,
  redirectUrl: string
): ComponentType<P> {
  const PageWithRedirectSignedIn = (props: P): JSX.Element => {
    const { currentUser, initiated, loading } = useAuthContext();

    if (!initiated || loading) {
      return Loading;
    } else if (currentUser) {
      return <Component {...props} />;
    } else {
      const newLocation = redirectUrl + toQuerystring({ next: window.location.toString() });
      window.location.assign(newLocation);
    }
    return <React.Fragment></React.Fragment>;
  };

  return PageWithRedirectSignedIn as ComponentType<P>;
}

export { withEnforceSignIn };
