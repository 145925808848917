import { useCallback, useState } from 'react';
import { toQuerystring } from '@micro-frontends/vacayz-api-client';

const useSignIn = (signInUri: string, signOutUri: string): [() => void, () => void] => {
  const [signInUrl] = useState(signInUri);
  const [signOutUrl] = useState(signOutUri);

  const redirectSignIn = useCallback((url) => {
    const newLocation = url + toQuerystring({ next: window.location.toString() });
    if (!url) {
      console.error("Couldn't load environment variables");
    } else {
      window.location.assign(newLocation);
    }
  }, []);

  const redirectSignOut = useCallback((url) => {
    if (!url) {
      console.error("Couldn't load environment variables");
    } else {
      window.location.assign(url);
    }
  }, []);

  const signIn = useCallback(() => redirectSignIn(signInUrl), [redirectSignIn, signInUrl]);
  const signOut = useCallback(() => redirectSignOut(signOutUrl), [redirectSignOut, signOutUrl]);

  return [signIn, signOut];
};

export { useSignIn };
