import {
  BookingDeal,
  BookingRequest,
  BookingRequestListItem,
  BookingRequestOrderId,
  AddGlobalOptionNightsParams,
  BookingRequestsCount,
  BookingUserOrderHistory,
  BookingUserUpcomingTrips,
  DealListItem,
  BookingClientStats,
  AskDealRefundParams,
  CreateBookingRequestFormNew,
  ViewOfferNotificationForm,
  CreateBookingRequestLandingForm,
  CreateBookingRequestHistoryForm,
  BookingGuestListItem,
  BookingforGuest,
  BookingMoreDealsReasonForm,
  CreatingBookingMoreDealsForm,
  TerminateReasonForm,
  BookingUserSalesStats,
  InvoiceDownloadForm,
  UpdateBookingRequestParams,
  InstantBookingResponse,
  BookingRequestContact,
  InstantBookingDealItem,
  CheckDates,
  SelectDealItem,
} from './typings';
import { PaginatedResult, VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';

const createBookingRequestNew = (
  client: VacayzClient,
  fields: CreateBookingRequestLandingForm
): VacayzResponse<boolean> => {
  const url = 'api/v1/booking/requests';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(url, fields, {
      cancelToken: source.token,
    });
    return response?.status === 201;
  };
  return [handleCancel(promise), source.cancel];
};

const createBookingRequestHistory = (
  client: VacayzClient,
  fields: CreateBookingRequestHistoryForm
): VacayzResponse<boolean> => {
  const url = 'api/v1/booking/users/request/update';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(url, fields, {
      cancelToken: source.token,
    });
    return response?.status === 201;
  };
  return [handleCancel(promise), source.cancel];
};

const updateBookingRequest = (client: VacayzClient, fields: UpdateBookingRequestParams): VacayzResponse<boolean> => {
  const { bookingRequestId, ...other } = fields;
  const url = `api/v1/booking/requests/${bookingRequestId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<boolean>(url, other, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingRequestNew = (
  client: VacayzClient,
  bookingRequestId: number
): VacayzResponse<CreateBookingRequestFormNew> => {
  const url = `api/v1/booking/requests/${bookingRequestId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<CreateBookingRequestFormNew>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingRequestbyNanoId = (
  client: VacayzClient,
  bookingRequestId: string
): VacayzResponse<CreateBookingRequestFormNew> => {
  const url = `api/v1/booking/new/requests/${bookingRequestId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<CreateBookingRequestFormNew>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getInvoiceDownloadUrl = (client: VacayzClient, invoiceId: string): VacayzResponse<InvoiceDownloadForm> => {
  const url = `api/v1/payments/invoice/${invoiceId}/download-link`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<InvoiceDownloadForm>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingUserSalesStats = (client: VacayzClient, userId: number): VacayzResponse<BookingUserSalesStats> => {
  const url = `api/v1/booking/users/sales/` + userId;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingUserSalesStats>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getViewOfferNotification = (
  client: VacayzClient,
  notificationId: string
): VacayzResponse<ViewOfferNotificationForm> => {
  const url = `api/v1/view/view_offers_notification/${notificationId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<ViewOfferNotificationForm>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingUserRequestsList = (
  client: VacayzClient,
  filter_created: boolean,
  userid?: number,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<BookingRequestListItem>> => {
  const qs = toQs({
    page,
    per_page: perPage,
    created: filter_created,
    ...(userid ? { user_id: userid } : { user_id: 0 }),
  });
  const url = `api/v1/booking/users/requests${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<BookingRequestListItem>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingUserRequestsbyEmailList = (
  client: VacayzClient,
  filter_created: boolean,
  email?: string,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<BookingRequestListItem>> => {
  const qs = toQs({
    page,
    per_page: perPage,
    created: filter_created,
    ...(email ? { email: email } : {}),
  });
  const url = `api/v1/booking/users/requests${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<BookingRequestListItem>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingRequest = (client: VacayzClient, bookingRequestId: number): VacayzResponse<BookingRequest> => {
  const url = `api/v1/booking/requests/${bookingRequestId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingRequest>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingRequestsDeals = (
  client: VacayzClient,
  bookingRequestId: number,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<BookingDeal>> => {
  const qs = toQs({ page, per_page: perPage });
  const url = `api/v1/booking/requests/${bookingRequestId}/deals${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<BookingDeal>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingDeal = (client: VacayzClient, dealId: number): VacayzResponse<BookingDeal> => {
  const url = `api/v1/booking/deals/${dealId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingDeal>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const resendBookingDealConfirmation = (client: VacayzClient, bookingDealId: number): VacayzResponse<boolean> => {
  const url = `api/v1/booking/deals/${bookingDealId}/resend_confirmation`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get(url, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const sendBookingDealGuestConfirmation = (
  client: VacayzClient,
  bookingDealId: number,
  guestName: string,
  guestEmail: string,
  includePrice: boolean
): VacayzResponse<boolean> => {
  const url = `api/v1/booking/deals/${bookingDealId}/send_confirm_to_guest`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post(
      url,
      { name: guestName, email: guestEmail, include_price: includePrice },
      {
        cancelToken: source.token,
      }
    );
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const sendBookingDealSelect = (
  client: VacayzClient,
  bookingRequestId: string,
  selected: SelectDealItem[]
): VacayzResponse<boolean> => {
  const url = `api/v1/booking/select_deal/${bookingRequestId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(
      url,
      { request: bookingRequestId, selected },
      {
        cancelToken: source.token,
      }
    );
    return response?.status === 201;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingRequestOrderId = (
  client: VacayzClient,
  bookingRequestId: number
): VacayzResponse<BookingRequestOrderId> => {
  const url = `api/v1/booking/requests/${bookingRequestId}/order_id`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingRequestOrderId>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const addGlobalOptionNights = (client: VacayzClient, fields: AddGlobalOptionNightsParams): VacayzResponse<boolean> => {
  const url = `api/v1/options/add_nights`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<boolean>(url, fields, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingUserRequestCount = (client: VacayzClient, clientId: number): VacayzResponse<BookingRequestsCount> => {
  const qs = toQs({ userid: clientId });
  const url = `api/v1/booking/users/requests/count${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingRequestsCount>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingUserOrderHistory = (
  client: VacayzClient,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<BookingUserOrderHistory>> => {
  const qs = toQs({ page, per_page: perPage });
  const url = `api/v1/booking/users/history${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<BookingUserOrderHistory>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingUserUpcomingTrips = (
  client: VacayzClient,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<BookingUserUpcomingTrips>> => {
  const qs = toQs({ page, per_page: perPage });
  const url = `api/v1/booking/users/upcoming${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<BookingUserUpcomingTrips>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getUserBookingDealsList = (
  client: VacayzClient,
  sort: boolean,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<DealListItem>> => {
  const qs = toQs({ page, sort, per_page: perPage });
  const url = `api/v1/booking/client/deals${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<DealListItem>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingClientStats = (client: VacayzClient): VacayzResponse<BookingClientStats> => {
  const url = `api/v1/booking/client/stats`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingClientStats>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const askDealRefund = (client: VacayzClient, fields: AskDealRefundParams): VacayzResponse<boolean> => {
  const { bookingDealId, ...other } = fields;
  const url = `api/v1/booking/deals/${bookingDealId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<boolean>(url, other, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingGuestList = (client: VacayzClient, dealId: number): VacayzResponse<BookingGuestListItem[]> => {
  const url = `api/v1/booking/guests?deal=${dealId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingGuestListItem[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const saveBookingGuestList = (
  client: VacayzClient,
  requestId: number,
  destinationId: number,
  dealId: number,
  guests: BookingforGuest[]
): VacayzResponse<boolean> => {
  const url = `api/v1/booking/guests`;
  const guestJson = {
    request_id: requestId,
    destination_id: destinationId,
    deal_id: dealId,
    guests: guests,
  };
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(url, guestJson, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const getBookingMoreDealReasons = (client: VacayzClient): VacayzResponse<BookingMoreDealsReasonForm[]> => {
  const url = `api/v1/booking/offer/more_deals_reasons`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<BookingMoreDealsReasonForm[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const requireBookingMoreDeals = (
  client: VacayzClient,
  reason: CreatingBookingMoreDealsForm
): VacayzResponse<boolean> => {
  const url = `api/v1/booking/offer/more_deals`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(url, reason, {
      cancelToken: source.token,
    });
    return response?.status === 201;
  };
  return [handleCancel(promise), source.cancel];
};

const getTerminateReasonList = (client: VacayzClient): VacayzResponse<TerminateReasonForm[]> => {
  const url = `api/v1/booking/request/terminated`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<TerminateReasonForm[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const postInstantBookingRequest = (
  client: VacayzClient,
  eventDealIds: InstantBookingDealItem[],
  contact: BookingRequestContact,
  guests: BookingforGuest[],
  dates: CheckDates
): VacayzResponse<InstantBookingResponse> => {
  const url = `api/v1/event/multi_instant`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<InstantBookingResponse>(
      url,
      { event_deal_ids: eventDealIds, contact: contact, guests, dates },
      {
        cancelToken: source.token,
      }
    );
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export {
  createBookingRequestHistory,
  getBookingRequest,
  getBookingRequestsDeals,
  getBookingDeal,
  resendBookingDealConfirmation,
  getBookingRequestOrderId,
  addGlobalOptionNights,
  getBookingUserOrderHistory,
  getBookingUserUpcomingTrips,
  getUserBookingDealsList,
  getBookingClientStats,
  askDealRefund,
  createBookingRequestNew,
  getBookingRequestNew,
  getViewOfferNotification,
  getBookingGuestList,
  saveBookingGuestList,
  getBookingUserRequestCount,
  getBookingUserRequestsList,
  getBookingUserRequestsbyEmailList,
  getBookingMoreDealReasons,
  requireBookingMoreDeals,
  getTerminateReasonList,
  sendBookingDealGuestConfirmation,
  getInvoiceDownloadUrl,
  getBookingRequestbyNanoId,
  getBookingUserSalesStats,
  updateBookingRequest,
  sendBookingDealSelect,
  postInstantBookingRequest,
};
