import React from 'react';
import { Link } from 'react-router-dom';

import { useSignIn } from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const useStyles = makeStyles((theme: Theme) => ({
  barContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.appBar,
    width: '100%',
    height: 70,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

const HeaderBar: React.FC = () => {
  const classes = useStyles();
  const { currentUser, isAdmin } = useAuthContext();
  const [sendToSignIn, sendToSignOut] = useSignIn(
    process.env.REACT_APP_SIGN_IN_URL ?? '',
    process.env.REACT_APP_SIGN_OUT_URL ?? ''
  );

  //the profile button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorEl);
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed">
        <Grid className={classes.barContainer}>
          <IconButton />
          <Link to="/">
            <img src={process.env.REACT_APP_COMPANY_WHITE_BRAND} alt="logo" style={{ height: '50px' }} />
          </Link>

          {!currentUser ? (
            <Button onClick={sendToSignIn} variant="outlined" color="inherit">
              Sign in
            </Button>
          ) : (
            <>
              <IconButton
                onClick={handleProfileClick}
                size="small"
                sx={{ flexGrow: 0, ml: 2 }}
                aria-controls={openProfile ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? 'true' : undefined}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openProfile}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  component={'a'}
                  href={
                    isAdmin()
                      ? process.env.REACT_APP_ADMIN_ACCOUNT_SETTINGS_URL
                      : process.env.REACT_APP_USER_ACCOUNT_SETTINGS_URL
                  }
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
                <Divider />
                <MenuItem onClick={sendToSignOut}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      </AppBar>
    </Box>
  );
};

export { HeaderBar };
