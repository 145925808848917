import React from 'react';
import clsx from 'clsx';

import Typography, { TypographyProps } from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { FONT_WEIGHT } from '@micro-frontends/shared-components';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(22),
      fontWeight: FONT_WEIGHT.medium,
    },
  });
// closing tag

const DealTitle = withStyles(styles)((props: WithStyles<typeof styles> & TypographyProps) => {
  const { classes, className, children, ...other } = props;
  return (
    <Typography className={clsx(classes.root, className)} variant="h3" align="center" {...other}>
      {children}
    </Typography>
  );
});

export { DealTitle };
