import React from 'react';
import clsx from 'clsx';

import MuiButton, { ButtonProps } from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from '../../themes';
import Tooltip from '@mui/material/Tooltip';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '40px',
      borderWidth: 2,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      letterSpacing: 0,
      fontSize: theme.typography.pxToRem(18),

      '&.MuiButton-contained': {
        color: COLORS.white,
      },
      '&:hover': {
        borderWidth: 2,
      },
    },
  });

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '0.8rem',
  },
}));

interface Props {
  className?: string;
  tooltip?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const Button = withStyles(styles)((props: WithStyles<typeof styles> & Omit<ButtonProps, keyof Props> & Props) => {
  const { classes, className, tooltip, tooltipPlacement, variant, size, ...other } = props;
  const tooltipClasses = useTooltipStyles();

  const StyleButton = (
    <MuiButton
      className={clsx(classes.root, className)}
      variant={variant ?? 'contained'}
      color="secondary"
      disableElevation
      size={size ?? 'medium'}
      {...other}
    />
  );
  if (tooltip?.length) {
    return (
      <Tooltip title={tooltip} aria-label={tooltip} placement={tooltipPlacement ?? 'bottom'} classes={tooltipClasses}>
        <span>{StyleButton}</span>
      </Tooltip>
    );
  }
  return StyleButton;
});

export { Button };
