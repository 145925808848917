import React from 'react';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS, FONT_WEIGHT } from '@micro-frontends/shared-components';
import { useDealContext } from '../../../common/contexts';
import { currencyValue } from '../../../common/utils/Format';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    flex: '0 0 144px',
    maxWidth: 144,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: FONT_WEIGHT.medium,
    whiteSpace: 'nowrap',

    '& + &': {
      marginLeft: theme.spacing(2),
    },
    '& span': {
      paddingRight: theme.spacing(1),
    },
    '& s': {
      color: COLORS.grey,
      paddingRight: theme.spacing(1),
    },
    '& b': {
      color: COLORS.green,
    },
  },
}));

const DealResult: React.FC = () => {
  const { dealData, calculator } = useDealContext();
  const classes = useStyles();

  return (
    <>
      <Typography className={clsx(classes.cell)}>
        <span>Total:</span>
        <s>{calculator && currencyValue(calculator.total, dealData?.collectionCurrency)}</s>
        <b>{calculator && currencyValue(calculator.total - calculator.saved, dealData?.collectionCurrency)}</b>
      </Typography>
      <Typography className={clsx(classes.cell)}>
        <span>Saved:</span>
        {calculator && currencyValue(calculator.saved, dealData?.collectionCurrency)}
      </Typography>
      <Typography className={clsx(classes.cell)}>
        <span style={{ color: COLORS.blue }}>Pay Now:</span>
        {calculator && currencyValue(calculator.paynow, dealData?.collectionCurrency)}
      </Typography>
    </>
  );
};

export { DealResult };
