import {
  CollectionOptionPlanTable,
  CreateOptionPlanVersionRequest,
  CreateOptionRequest,
  Option,
  OptionPlanVersion,
  OptionBalance,
  OptionPlan,
  OptionSold,
  UserTotalOptionSold,
} from './typings';
import { PaginatedResult, VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';

const getMyOptionPlanVersions = (
  client: VacayzClient,
  isArchived: 0 | 1,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<OptionPlan>> => {
  const qs = toQs({ page, per_page: perPage, is_archived: isArchived });
  const url = `api/v1/brands/me/option_plans${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<OptionPlan>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getOptionPlanVersionById = (
  client: VacayzClient,
  optionPlanVersionId: number
): VacayzResponse<OptionPlanVersion> => {
  const url = `api/v1/option_plans/${optionPlanVersionId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<OptionPlanVersion>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createOptionPlanVersion = (
  client: VacayzClient,
  fields: CreateOptionPlanVersionRequest
): VacayzResponse<OptionPlanVersion> => {
  const url = 'api/v1/option_plans/';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<OptionPlanVersion>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const updateOptionPlanVersion = (
  client: VacayzClient,
  optionPlanVersionId: number,
  fields: Partial<CreateOptionPlanVersionRequest>
): VacayzResponse<OptionPlanVersion> => {
  const url = `api/v1/option_plans/${optionPlanVersionId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<OptionPlanVersion>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getOptionPlanWithRelatedCollection = (
  client: VacayzClient,
  entityId: number,
  entityType: string,
  brandId?: number,
  page?: number,
  perPage?: number,
  includeNonApproved?: boolean
): VacayzResponse<PaginatedResult<CollectionOptionPlanTable>> => {
  const qs = toQs({
    entity_id: entityId,
    entity_type: entityType,
    brand_id: brandId,
    page,
    per_page: perPage,
    includes_non_approved: includeNonApproved ? 1 : null,
  });
  const url = `api/v1/option_plans_table/${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<CollectionOptionPlanTable>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getOptions = (
  client: VacayzClient,
  activeTab: number,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<Option>> => {
  const qs = toQs({ page, per_page: perPage, used_options: activeTab });
  const url = `api/v1/options/${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<Option>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createOption = (client: VacayzClient, fields: CreateOptionRequest): VacayzResponse<Option> => {
  const url = 'api/v1/options/';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<Option>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getOptionBalance = (client: VacayzClient, optionId: number): VacayzResponse<OptionBalance> => {
  const url = `api/v1/options/${optionId}/balance`;

  const source = getSource();
  const promise = async () => {
    const response = await client.get<OptionBalance>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const archiveOptionPlan = (client: VacayzClient, optionPlanId: number): VacayzResponse<boolean> => {
  const url = `api/v1/option_plans/${optionPlanId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.delete<boolean>(url, {
      cancelToken: source.token,
    });
    return response?.status >= 200 && response?.status < 300;
  };
  return [handleCancel(promise), source.cancel];
};

// options sold:

const getOptionsSold = (
  client: VacayzClient,
  userId: number,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<OptionSold>> => {
  const qs = toQs({
    page,
    per_page: perPage,
  });
  const url = `api/v1/options/sold/${userId}${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<OptionSold>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getUserTotalOptionsSold = (client: VacayzClient, userId: number): VacayzResponse<UserTotalOptionSold> => {
  const url = `api/v1/options/sold/${userId}/total`;

  const source = getSource();
  const promise = async () => {
    const response = await client.get<UserTotalOptionSold>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export {
  createOption,
  getOptions,
  getOptionPlanWithRelatedCollection,
  updateOptionPlanVersion,
  createOptionPlanVersion,
  getOptionPlanVersionById,
  getOptionBalance,
  getMyOptionPlanVersions,
  archiveOptionPlan,
  getOptionsSold,
  getUserTotalOptionsSold,
};
