import { History } from 'history';
import { useHistory } from 'react-router-dom';

interface UseLink {
  goTo: (link: string) => void;
  history: History;
}

const useLink = (): UseLink => {
  const history = useHistory();

  const goTo = (link: string) => {
    if (new RegExp(`^www.*$|^http.*$`).test(link)) window?.open(link, '_blank')?.focus();
    else if (new RegExp(`^/.*$`).test(link)) history.push(link);
  };

  return { goTo, history };
};

export { useLink };
