import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),

    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  track: {
    overflowX: 'auto',
    padding: `0 ${theme.spacing(1.5)} 22px`,
    marginBottom: -22,
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'nowrap',

    '& > div': {
      flex: '0 0 244px',
      maxWidth: 244,
      padding: `1px ${theme.spacing(1.5)} 3px`,
    },
    '&:after': {
      content: "''",
      display: 'block',
      maxWidth: theme.spacing(1.5),
      flex: `0 0 ${theme.spacing(1.5)}`,
    },

    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      '& > div': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      '&:after': {
        maxWidth: theme.spacing(1),
        flex: `0 0 ${theme.spacing(1)}`,
      },
    },
  },
}));

interface Props {
  className?: string;
  children: React.ReactNode;
}

const HomeHorizontalScroll: React.FC<Props> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.track}>{children}</Box>
    </Box>
  );
};

export { HomeHorizontalScroll };
