import React from 'react';

import Fade from '@mui/material/Fade';
import { COLORS, HEADER_HEIGHT, MainContainer } from '@micro-frontends/shared-components';
import { DealSubmit } from '../_components';
import { DealInputs } from './DealInputs';
import { DealResult } from './DealResult';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDealContext } from '../../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: COLORS.white,
    boxShadow: `0 0 7px ${COLORS.grey}`,

    position: 'fixed',
    zIndex: theme.zIndex.appBar - 1,
    top: HEADER_HEIGHT,
    left: 0,
  },
  fadeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: HEADER_HEIGHT,
  },
}));

const DealHeaderCalc: React.FC = () => {
  const { dealData } = useDealContext();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fade in={!!dealData}>
        <MainContainer className={classes.fadeContainer}>
          <DealInputs />
          <DealResult />
          <DealSubmit />
        </MainContainer>
      </Fade>
    </div>
  );
};

export { DealHeaderCalc };
