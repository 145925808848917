import axios, { CancelTokenSource } from 'axios';
import { stringify } from 'qs';
import { ErrorResponse, InnerError, ValidationError } from './typings';
import format from 'date-fns/format';

const toQuerystring = (object: Record<string, unknown>): string => {
  const qs = stringify(object, { addQueryPrefix: true });
  return qs === '' || qs === '?' ? '/' : qs;
};

const getSource = (): CancelTokenSource => {
  return axios.CancelToken.source();
};

const handleCancel = <T>(promise: () => Promise<T | undefined>): Promise<T | undefined> => {
  return new Promise<T | undefined>((resolve, reject) => {
    try {
      promise()
        .then(resolve)
        .catch((error) => {
          !axios.isCancel(error) ? reject(error?.response?.data ?? error) : reject(null);
        });
    } catch (error) {
      !axios.isCancel(error) ? reject(error?.response?.data ?? error) : reject(null);
    }
  });
};

// Type Guards for Vacayz Error
const isString = (value: unknown): value is string => {
  return !!value && typeof value === 'string';
};

const isInnerError = (value: unknown): value is InnerError => {
  const asError = value as InnerError;
  return !!asError && isString(asError?.message) && isString(asError?.title) && isString(asError?.type);
};

const isValidationError = (value: unknown): value is ValidationError => {
  return isInnerError(value) && isString((value as ValidationError)?.field);
};

const isVacayzError = (value: unknown): value is ErrorResponse => {
  const asError = value as ErrorResponse;
  return (
    !!asError &&
    !!asError?.error &&
    Number.isInteger(asError.error?.code) &&
    isString(asError.error?.message) &&
    Array.isArray(asError.error?.errors) &&
    asError.error.errors.every(isInnerError)
  );
};

const calcDateDiff = (date1: string, date2: string): number => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);

  return Math.trunc(Math.abs(d1.getTime() - d2.getTime()) / (1000 * 3600 * 24));
};

const calcNumberToCurrency = (val: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(val);
};

const getStringToDate = (date: string): Date => {
  const dates = date.split(/[-_/\\s]+/);
  if (dates.length > 2) {
    return new Date(parseInt(dates[0].trim()), parseInt(dates[1].trim()) - 1, parseInt(dates[2].trim()));
  }
  return new Date();
};

function formatDateRangeToString(startDate: Date, endDate: Date): string {
  //is it same day
  const _startDate = startDate;
  const _endDate = endDate;

  if (
    _startDate.getFullYear() === _endDate.getFullYear() &&
    _startDate.getMonth() === _endDate.getMonth() &&
    _startDate.getDate() === _endDate.getDate()
  ) {
    return `${format(_startDate, 'MMM dd, yyyy')}`;
  } else {
    const formatedEndDate =
      _startDate.getMonth() === _endDate.getMonth() ? format(_endDate, 'dd') : format(_endDate, 'MMM dd');
    return `${format(_startDate, 'MMM dd')}-${formatedEndDate}, ${format(_endDate, 'yyyy').slice(-2)}`;
  }
}

function formatDateToString(date: Date): string {
  const _date = date;
  let month = '' + (_date.getMonth() + 1);
  let day = '' + _date.getDate();
  const year = _date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

function formatStringDateToFormatedString(dateString: string): string {
  const dt = dateString.split(/\-|\s/);
  let date = new Date();
  if (dt.length === 2) {
    date = new Date(Number(dt[0].trim().replace(',', '')), Number(dt[1].trim().replace(',', '')) - 1);
    return `${format(date, 'MMM, yyyy')}`;
  }

  if (dt.length > 2) {
    date = new Date(
      Number(dt[0].trim().replace(',', '')),
      Number(dt[1].trim().replace(',', '')) - 1,
      Number(dt[2].trim().replace(',', ''))
    );
  }
  return `${format(date, 'MMM dd, yyyy')}`;
}

export {
  toQuerystring,
  getSource,
  handleCancel,
  isString,
  isInnerError,
  isVacayzError,
  isValidationError,
  calcDateDiff,
  calcNumberToCurrency,
  getStringToDate,
  formatDateRangeToString,
  formatDateToString,
  formatStringDateToFormatedString,
};
