import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CenteredProgress, useAsyncCleanup, useQueryStringParams } from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import { Routing } from '../../../common/constants/routing';

import { getViewOfferNotification } from '@micro-frontends/vacayz-api-client';
import { useHistory } from 'react-router-dom';
import { NotFound } from '../../NotFound/NotFound';
type QueryString = {
  notificationId: string;
};

const ViewHandle: React.FC = () => {
  const { notificationId: _notificationId } = useQueryStringParams<QueryString>(['notificationId']);
  const [viewNotificationtId] = useState(_notificationId);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const { client } = useAuthContext();

  const { addCleanup, isMounted } = useAsyncCleanup();
  const history = useHistory();

  const openRequestPage = useCallback(async () => {
    if (!viewNotificationtId) return;

    setLoading(true);
    try {
      const [_viewHandle, cancel] = getViewOfferNotification(client, viewNotificationtId);
      addCleanup(cancel);
      const handle = await _viewHandle;
      if (!isMounted() || !handle) {
        setLoading(false);
        setErr(true);
        console.error(`Request not found by id (id = ${viewNotificationtId})`);
        return;
      }
      history.push(Routing.offerDeals + '?requestId=' + handle.requestId);
    } catch (e) {
      setErr(true);
      console.error(`Request not found by id (id = ${viewNotificationtId})`);
    }
    setLoading(false);
  }, [addCleanup, client, isMounted, viewNotificationtId, history]);

  useEffect(() => {
    openRequestPage();
  }, [openRequestPage]);

  return <Container>{loading ? <CenteredProgress /> : err && <NotFound />}</Container>;
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-item: center;
`;

export default ViewHandle;
export { ViewHandle };
