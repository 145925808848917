import React from 'react';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SAFE_AND_SCURE_IMG } from '../../common/constants/images';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    minHeight: theme.spacing(20),
    padding: theme.spacing(2, 10, 2, 2),
    marginRight: theme.spacing(-8),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(4),
    textAlign: 'center',

    '& h2': {
      paddingBottom: theme.spacing(1),
    },
    '& p': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  image: {
    width: 144,
  },
}));

interface Props {
  className?: string;
}

const SafeSecure: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.box}>
        <Typography variant="h2">Changed your mind? No Worries</Typography>
        <Typography variant="body1">
          Travel Options have free cancellation, or you can resell them for extra profit.
        </Typography>
      </div>
      <img src={SAFE_AND_SCURE_IMG} alt="safe-and-secure" className={classes.image} />
    </div>
  );
};

export { SafeSecure };
