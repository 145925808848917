import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SecretRedirect: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const clientSecret = location.search.slice(1).split('&')[0].split('=')[1];

    if (window && window.parent) {
      window.parent.postMessage({ message: clientSecret, target: 'secretDialog' }, '*');
    }
  }, [location]);
  return <></>;
};

export default SecretRedirect;
export { SecretRedirect };
