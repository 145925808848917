import React, { useCallback } from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import styled from 'styled-components';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DefaultTheme, PREFIX, FONT_WEIGHT } from '../../themes';
import { UserInfo } from '@micro-frontends/vacayz-api-client';

const useStyles = makeStyles<Theme, Partial<Props>>(
  (theme: Theme) => ({
    root: {},
    userHeader: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      minHeight: 122,

      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(77),
      },
    },
    listItem: {
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(28),
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiTypography-body1': {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.primary.main,
        fontWeight: FONT_WEIGHT.medium,
      },
    },
  }),
  { classNamePrefix: PREFIX, defaultTheme: DefaultTheme }
);

interface Props {
  classes?: { [key: string]: string };
  className?: string;
  user: UserInfo;
  onSignOut?: () => void;
  settingsUrl: string;
}

const HeaderUserPopup: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  const goToSettings = useCallback(() => {
    if (props.settingsUrl) {
      window.location.assign(props.settingsUrl);
    }
  }, [props.settingsUrl]);

  return (
    <Box className={clsx(classes.root, props.className)}>
      {/* <Box className={classes.userHeader}>
        <AccountCircleIcon />
        <UserInfoText>{props.user.email}</UserInfoText>
        <UserInfoText>User Id #{props.user.id}</UserInfoText>
      </Box> */}
      <List component="nav">
        <ListItem button className={classes.listItem} onClick={() => goToSettings()}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {props.onSignOut && (
          <ListItem button className={classes.listItem} onClick={() => props.onSignOut?.()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

const UserInfoText = styled.div`
  font-size: 15px;
  margin-bottom: 4px;
  color: #7d7d7d;
`;

export { HeaderUserPopup };
