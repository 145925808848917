import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';

const MainFluidContainer = withStyles(() => ({
  root: {
    maxWidth: '100%',
    padding: 0,
  },
}))(Container);

export { MainFluidContainer };
