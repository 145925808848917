import React from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { EventDealFeeDescription } from '@micro-frontends/vacayz-api-client';

interface Props {
  open: boolean;
  feesDescription: EventDealFeeDescription[];
  openHandle: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '600px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '500px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 0,
    },
  },
}));

const FeesModal: React.FC<Props> = ({ open, feesDescription, openHandle }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>Fees</DialogTitle>
      <DialogContent className={classes.root} dividers>
        {feesDescription &&
          feesDescription.map((fee, i) => {
            return (
              !fee.isIncluded && (
                <Grid container key={i}>
                  <Grid item xs={12} sm={6} paddingRight={1}>
                    <Typography fontWeight={'bold'}>{`${fee.name}:`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography textAlign="right">{`US$${Number(fee.amount.toFixed(2)).toLocaleString()}`}</Typography>
                  </Grid>
                </Grid>
              )
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => openHandle(false)} size="small" color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeesModal;
export { FeesModal };
