import React from 'react';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS, FONT_WEIGHT } from '@micro-frontends/shared-components';
import { useDealContext } from '../../../common/contexts';
import { currencyValue } from '../../../common/utils/Format';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(18),

    '& span': {
      fontWeight: FONT_WEIGHT.medium,
      paddingRight: theme.spacing(1),
    },
    '& s': {
      color: COLORS.grey,
      paddingRight: theme.spacing(1),
      marginLeft: 'auto',
    },
    '& b': {
      color: COLORS.green,
    },
  },
}));

const DealResult: React.FC = () => {
  const { dealData, calculator } = useDealContext();
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" className={clsx(classes.row)}>
        <span>Total:</span>
        <s>{calculator && currencyValue(calculator.total, dealData?.collectionCurrency)}</s>
        <b>{calculator && currencyValue(calculator.total - calculator.saved, dealData?.collectionCurrency)}</b>
      </Typography>
      <Typography variant="body1" className={clsx(classes.row)}>
        <span>Saved:</span>
        {calculator && currencyValue(calculator.saved, dealData?.collectionCurrency)}
      </Typography>
      <Typography variant="body1" className={clsx(classes.row)}>
        <span style={{ color: COLORS.blue }}>Pay Now:</span>
        {calculator && currencyValue(calculator.paynow, dealData?.collectionCurrency)}
      </Typography>
    </>
  );
};

export { DealResult };
