export const ABOUT_BG = 'https://drive.google.com/uc?id=1KhY7uM6ElyoTobXza5HBXo12mdFA-uxd';
export const BUSINESS_BG = 'https://drive.google.com/uc?id=13zJOIapLz9LqD1PQ8zm-SozNLvM6wkUB';
export const SAFE_AND_SCURE_IMG = 'https://drive.google.com/uc?id=1CR0mO0PX5JHf_Pdo_AWWasG6gljrfBFC';
export const EXPLORE_BG = 'https://drive.google.com/uc?id=1CXDtk76aHs0EvMvhVlDZ8lxFRctahbIk';

export const HOWITWORKS_STEP_2 = 'https://drive.google.com/uc?id=1QxJ0zGTCuTlyQ1CJTE4Yvkqc20yHrMmr';
export const HOWITWORKS_STEP_3 = 'https://drive.google.com/uc?id=1ovmCraZLSZUk63cfAcELaGOy_f2qj2_5';
export const HOWITWORKS_STEP_1 = 'https://drive.google.com/uc?id=1qYLK3jhajeff1H--RdSXRn4Wdelb0I-S';

export const MAIN_SCREEN_BG = 'https://res.cloudinary.com/vacayz/image/upload/v1620038601/www/bg_marketplace.jpg';
export const FOOTER_BG = 'https://res.cloudinary.com/vacayz/image/upload/v1620038819/www/bg_footer.jpg';
export const BUSINESS_IMG_1 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045088/www/business/image_1.png';
export const BUSINESS_IMG_2 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045089/www/business/image_2.png';
export const COVID_BG = 'https://res.cloudinary.com/vacayz/image/upload/v1620038862/www/bg_covid.jpg';
export const HOTELIERS_BG = 'https://res.cloudinary.com/vacayz/image/upload/v1620045238/www/hoteliers/background.png';
export const HOTELIERS_IMG_1 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045237/www/hoteliers/image_1.png';
export const HOTELIERS_IMG_2 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045239/www/hoteliers/image_2.png';
export const HOTELIERS_IMG_3 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045238/www/hoteliers/image_3.png';
export const PMS_BG = 'https://res.cloudinary.com/vacayz/image/upload/v1620045414/www/pms/background.png';
export const PMS_IMG_1 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045412/www/pms/image_1.png';
export const PMS_IMG_2 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045413/www/pms/image_2.png';
export const PMS_IMG_3 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045413/www/pms/image_3.png';
export const PMS_SCREEN_1 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045410/www/pms/screen_1.png';
export const PMS_SCREEN_2 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045410/www/pms/screen_2.png';
export const PMS_SCREEN_3 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045411/www/pms/screen_3.png';
export const PMS_SCREEN_4 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045411/www/pms/screen_4.png';
export const PMS_SCREEN_5 = 'https://res.cloudinary.com/vacayz/image/upload/v1620045411/www/pms/screen_5.png';
