import React, { useState } from 'react';
import clsx from 'clsx';

import Drawer from '@mui/material/Drawer';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import { IconButton, Button } from '../_elements';

import { alpha, Typography, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { COLORS, HEADER_HEIGHT } from '../../themes';
import { UserInfo } from '@micro-frontends/vacayz-api-client';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      color: COLORS.white,
    },
    signinButton: {},
    userButton: {},
    userPaper: {
      width: 288,
      marginTop: HEADER_HEIGHT,
      marginRight: 4,
      height: 'auto',
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `4px 4px 4px ${alpha(theme.palette.primary.main, 0.5)}`,
    },
  });
// closing tag

interface Props extends WithStyles<typeof styles> {
  className?: string;
  user?: UserInfo;
  UserPopup?: React.ComponentType<{ user: UserInfo; onSignOut?: () => void; settingsUrl: string }>;
  onSignIn?: () => void;
  onSignOut?: () => void;
  settingsUrl: string;
}

const HeaderUser = withStyles(styles)((props: Props) => {
  const { classes, className, user, UserPopup, onSignIn, onSignOut, settingsUrl, ...other } = props;
  const [modal, setModal] = useState<boolean>(false);

  return (
    <div className={clsx(classes.root, className)} {...other}>
      {user ? (
        <IconButton
          className={classes.userButton}
          onClick={() => setModal(true)}
          color="inherit"
          edge="end"
          size="large"
        >
          <Grid container alignItems="center" gap="10px">
            <AccountCircleSharpIcon />
            <Typography variant="h3" component="span">{`${user.firstName} ${user.lastName}`}</Typography>
            <Typography variant="h3" component="span">{`#${user.id}`}</Typography>
          </Grid>
        </IconButton>
      ) : (
        <Button className={classes.signinButton} onClick={() => onSignIn?.()} variant="outlined" color="inherit">
          Sign in
        </Button>
      )}
      <Drawer
        anchor="right"
        open={modal}
        onClose={() => setModal(false)}
        classes={{ paper: classes.userPaper }}
        sx={{ '& .MuiDrawer-paper': { height: 'auto' } }}
      >
        {UserPopup && user && <UserPopup user={user} onSignOut={onSignOut} settingsUrl={settingsUrl} />}
      </Drawer>
    </div>
  );
});

export { HeaderUser };
