import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, CenteredProgress, useAsyncCleanup } from '@micro-frontends/shared-components';
import { useAppContext, useDealContext } from '../../../common/contexts';
import { useAuthContext } from '@micro-frontends/auth-context';
import { CONFIRM, DEAL, PURCHASE } from '../../../common/constants/Common';
import { Routing } from '../../../common/constants/routing';
import { createPaymentIntent, CreatePaymentIntentRequest, PaymentIntent } from '@micro-frontends/vacayz-api-client';
import { availabilityToPriceType } from '../utils';

const DealSubmit: React.FC = () => {
  const { routerOption } = useAppContext();
  const {
    dealData,
    numberOfNights,
    createCharge,
    acceptedByUser,
    setAcceptedByUserAlert,
    availability,
    loading,
    setLoading,
  } = useDealContext();
  const { client, currentUser } = useAuthContext();
  const { isMounted, addCleanup } = useAsyncCleanup();
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);

  const handlePaymentIntent = useCallback(async () => {
    if (dealData && availability && currentUser?.id && !loading && createCharge) {
      setLoading(true);
      const availabilityPriceType = availabilityToPriceType(availability);
      const paymentData: CreatePaymentIntentRequest = {
        collectionId: dealData.collectionId,
        optionPlanId: dealData.optionPlanId,
        nights: Number(numberOfNights),
        optionPlanVersion: dealData.optionPlanVersion,
        userId: currentUser.id,
        ...availabilityPriceType,
      };
      const [pmPromise, pmCancel] = createPaymentIntent(client, paymentData);
      addCleanup(pmCancel);
      const paymentIntent: PaymentIntent | undefined = await pmPromise;
      if (!paymentIntent || !isMounted()) throw new Error('Error while processing payment');
      const charged = await createCharge(paymentIntent.id);
      if (!charged || !isMounted()) {
        throw new Error('Error while completing payment');
      }
      setLoading(false);
    }
  }, [
    addCleanup,
    availability,
    client,
    createCharge,
    currentUser?.id,
    dealData,
    isMounted,
    loading,
    numberOfNights,
    setLoading,
  ]);

  const handleDealSubmit = async () => {
    switch (routerOption) {
      case DEAL:
        history.push(Routing.dealPurchase);
        break;
      case PURCHASE:
        if (acceptedByUser && !loading) {
          await handlePaymentIntent();
          history.push(Routing.dealConfirm);
        } else {
          setAcceptedByUserAlert(true);
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
        break;
      case CONFIRM:
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (routerOption === PURCHASE) setDisabled(acceptedByUser ? false : true);
    else setDisabled(false);
  }, [routerOption, acceptedByUser]);

  return (
    <Button
      disabled={Number(numberOfNights) && !loading ? false : true}
      color={disabled ? 'primary' : 'secondary'}
      onClick={handleDealSubmit}
    >
      {(() => {
        if (loading) {
          return <CenteredProgress />;
        } else {
          return routerOption === DEAL ? 'Buy now' : 'Confirm & Pay';
        }
      })()}
    </Button>
  );
};

export { DealSubmit };
