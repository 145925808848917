import React from 'react';
import { Dialog, DialogActions, DialogContent, Grid, DialogTitle, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { Button } from '@micro-frontends/shared-components';

interface Props {
  open: boolean;
  setOpenCondition: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '600px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '500px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 0,
    },
    color: '#1B528C',
  },
}));

const BookingConditionModal: React.FC<Props> = ({ open, setOpenCondition }) => {
  const classes = useStyles();
  const contents = [
    {
      title: 'Cancellation:',
      text: 'Free cancellation until the time and date stated on your booking details. If you fail to arrive for your reservation, you will be charged the full cost of your stay.',
    },
    {
      title: 'How to cancel:',
      text: (
        <Typography>
          {'Please contact us via the chat or email '}
          <Link href={'mailto:support@klerk.com'} sx={{ color: '#1B528C' }}>
            {'support@klerk.com'}
          </Link>
          {' before cancellation period date.'}
        </Typography>
      ),
    },
    {
      title: 'Prepayment:',
      text: 'In order to secure this rate, full payment is required when completing the reservation. Your booking will be 100% refundable as long as cancellation is made within the designated window.',
    },
    {
      title: 'Service charges:',
      text: 'Taxes are always included in the price. Reservations may be subject to additional resort fees when booking at select resorts.',
    },
  ];
  const lastContent =
    '*For any changes or cancellations contact us before the cancellation period via our chat or email to ';

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ color: '#1B528C' }}>Booking Conditions</DialogTitle>
      <DialogContent className={classes.root}>
        {contents.map((content, i) => (
          <Grid container key={i}>
            <Grid item xs={12} sm={4} sx={{ fontWeight: 'bold' }}>
              {content['title']}
            </Grid>
            <Grid item xs={12} sm={8}>
              {content['text']}
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <Typography sx={{ paddingX: 2, color: '#1B528C' }}>
        {lastContent}{' '}
        <Link underline="none" href={'mailto:support@klerk.com'} sx={{ color: '#1B528C', fontWeight: 'bold' }}>
          {'support@klerk.com'}
        </Link>
      </Typography>

      <DialogActions>
        <Button
          style={{ marginRight: 20, color: '#1B528C' }}
          size="small"
          onClick={() => setOpenCondition(false)}
          variant="text"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingConditionModal;
