import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { COLORS } from '../../themes';

const styles = () =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: `linear-gradient(180deg, ${COLORS.blueDark} 6%, ${COLORS.blue} 69%, ${COLORS.blueDark} 100%)`,
    },
    bgPlaceholder: {},
    bgImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'opacity 300ms',
      opacity: 0,

      '&.loaded': {
        opacity: 1,
      },
    },
  });
//

interface Props extends WithStyles<typeof styles> {
  src?: string;
  alt?: string;
  className?: string;
}

const LazyBg = withStyles(styles)((props: Props) => {
  const { classes, src, alt, className, ...other } = props;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(false), [src]);
  return (
    <Box className={clsx(classes.root, className, 'lazy-bg')} {...other}>
      <Box className={classes.bgPlaceholder} />
      <img
        loading="lazy"
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        className={clsx(classes.bgImage, loaded && 'loaded')}
      />
    </Box>
  );
});

export { LazyBg };
