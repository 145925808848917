import React from 'react';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { DealCard } from '../_components';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from '@micro-frontends/shared-components';
import { Availability, AVAILABILITY_LABELS, AVAILABILITY_TO_PRICE, useDealContext } from '../../../common/contexts';
import { currencyValue } from '../../../common/utils/Format';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[100],
    padding: 2,

    '&.active': {
      borderColor: COLORS.blue,
      backgroundColor: COLORS.white,
      transform: 'scale(1.1)',
      borderWidth: 3,
      padding: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    minHeight: '100%',
    padding: `6px 2px 2px !important`,

    '& p': {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: 1.3,
    },
    '& h3': {
      marginTop: 'auto',
      fontSize: theme.typography.pxToRem(16),
      padding: theme.spacing(0.5, 0, 0.5),
    },
  },
  price: {
    '& s': {
      color: COLORS.grey,
    },
    '& b': {
      color: COLORS.green,
      paddingLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(17),
    },
  },
}));

interface Props {
  availability: Availability;
}

const DealAvailabilityItem: React.FC<Props> = ({ availability }) => {
  const classes = useStyles();
  const { dealData, availability: selectedAvailability, setAvailability } = useDealContext();
  return (
    <DealCard
      className={clsx(classes.root, availability === selectedAvailability && 'active')}
      classes={{ cardContent: classes.content }}
      onClick={() => setAvailability(availability)}
    >
      <Typography variant="body1">{AVAILABILITY_LABELS[availability]}</Typography>
      <Typography variant="h3">Cap price</Typography>
      <div className={classes.price}>
        <s>{currencyValue(dealData?.[AVAILABILITY_TO_PRICE[availability]], dealData?.collectionCurrency)}</s>
        <b>
          {dealData &&
            currencyValue(
              dealData[AVAILABILITY_TO_PRICE[availability]] -
                dealData[AVAILABILITY_TO_PRICE[availability]] * dealData.discount,
              dealData?.collectionCurrency
            )}
        </b>
      </div>
    </DealCard>
  );
};

export { DealAvailabilityItem };
