import React from 'react';
import clsx from 'clsx';

import Skeleton from '@mui/material/Skeleton';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  keys: {
    flex: '0 0 calc(100% / 6)',
    maxWidth: 'calc(100% / 6)',
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      flex: '0 0 144px',
      maxWidth: 144,
    },
  },
  body: {
    flex: '0 0 calc(100% - 100% / 6)',
    maxWidth: 'calc(100% - 100% / 6)',
    display: 'flex',
  },
  col: {
    cursor: 'pointer',
    flex: '0 0 20%',
    maxWidth: '20%',
    paddingLeft: theme.spacing(1),
    height: '100%',

    [theme.breakpoints.down('md')]: {
      flex: '0 0 188px',
      maxWidth: 188,
    },
  },
  skeleton: {
    width: '100%',
    height: '100%',
    transform: 'none',
    borderRadius: 6,
  },
}));

const SKELETON_BODY_SIZE = 5;

interface Props {
  className?: string;
}

const OptionsTableSkeleton: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.keys}>
        <Skeleton className={classes.skeleton} />
      </div>
      <div className={classes.body}>
        {Array(SKELETON_BODY_SIZE)
          .fill(0)
          .map((_, i) => i)
          .map((num) => (
            <div className={classes.col} key={num}>
              <Skeleton className={classes.skeleton} animation="wave" />
            </div>
          ))}
      </div>
    </div>
  );
};

export { OptionsTableSkeleton };
