import React from 'react';
import clsx from 'clsx';

import { MainContainer, MainFluidContainer } from '../../components/_layout';
import { Pagination } from '../Paginate';
import { OptionsTableSkeleton } from './OptionsTableSkeleton';
import { usePagination } from '../Paginate';
import OptionsTableCard from './OptionsTableCard';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import CircularProgress from '@mui/material/CircularProgress';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    position: 'relative',
  },
  skeletonContainer: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  tableContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    opacity: 0,
    transform: 'translateY(11px)',
    transition: 'all .2s',

    '&.initialized': {
      opacity: 1,
      transform: 'translateY(0)',
    },
    '&.webLoading': {
      opacity: 0,
      transform: 'translateY(11px)',
    },

    [theme.breakpoints.down('md')]: {
      width: 'auto',
      maxWidth: '200%',
      paddingBottom: theme.spacing(3),
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  tableKeys: {
    flex: `0 0 calc(100% / 6)`,
    maxWidth: `calc(100% / 6)`,

    [theme.breakpoints.down('md')]: {
      paddingTop: 1,
      flex: '0 0 144px',
      maxWidth: 144,
    },
  },
  tableBody: {
    display: 'flex',
    padding: '1px 0 3px',
    flex: `0 0 calc(100% - 100% / 6)`,
    maxWidth: `calc(100% - 100% / 6)`,

    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      flex: '0 0 calc(100% - 144px)',
      maxWidth: 'calc(100% - 144px)',
    },
  },
  tableScroll: {
    overflowX: 'auto',
    flex: '0 0 100%',
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    paddingTop: 1,
    paddingBottom: 22,
    marginBottom: -22,
  },
  tableCardMob: {
    flex: '0 0 188px',
    maxWidth: 188,
    marginLeft: theme.spacing(1),
  },
  tableCardWeb: {
    cursor: 'pointer',
    flex: (props: { perPage: number }) => `0 0 calc(100% / ${props.perPage ? props.perPage : 5})`,
    maxWidth: (props: { perPage: number }) => `calc(100% / ${props.perPage ? props.perPage : 5})`,
    paddingLeft: theme.spacing(1),
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: `0 0 88px`,
    maxWidth: 88,

    '&.ended': {
      flex: `0 0 11px`,
      maxWidth: 11,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  pagination: {},
}));

export interface OptionKey<T> {
  label: string | React.ReactNode;
  value: keyof T;
  valueComponent?: (data: T, key: keyof T, index?: number) => React.ReactNode;
  labelComponent?: (key: OptionKey<T>) => React.ReactNode;
}

interface Props<T> {
  onClick?: (data: T) => void;
  fetcher: (page: number, perPage: number) => Promise<T[] | undefined>;
  perPage?: number;
  keys: OptionKey<T>[];
  classes?: { [key: string]: string };
  className?: string;
  cardClassName?: string;
}

const OptionsTable = <T extends unknown>(props: Props<T>): JSX.Element => {
  const { fetcher, keys, onClick, className, cardClassName, perPage = 5 } = props;
  const { state, data, pageData, fetchData, handlePagination } = usePagination<T>(fetcher, perPage);
  const { init, isEnd, currentPage, loader } = state;

  const theme = useTheme();
  const classes = useStyles({ perPage });
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (data) {
      const wrapperWidth = (e.target as HTMLElement).clientWidth;
      const scrollLeft = (e.target as HTMLElement).scrollLeft;
      const width: number = 188 * data.length + 88;
      if (wrapperWidth + scrollLeft === width && !isEnd) fetchData(currentPage + 1);
    }
  };

  const handleClick = (item: T) => {
    if (onClick) onClick(item);
  };

  const TableBodyWeb =
    !isMobile &&
    pageData.map((el: T, i: number) => (
      <Box key={i} className={classes.tableCardWeb} onClick={() => handleClick(el)}>
        <OptionsTableCard className={cardClassName} data={el} keys={keys} />
      </Box>
    ));

  const TableBodyMob = data && isMobile && (
    <div className={classes.tableScroll} onScroll={handleScroll}>
      {data.map((el: T, i: number) => (
        <Box key={i} className={classes.tableCardMob} onClick={() => handleClick(el)}>
          <OptionsTableCard className={cardClassName} data={el} keys={keys} />
        </Box>
      ))}
      <Box className={clsx(classes.progress, isEnd && 'ended')}>
        <Grow in={loader}>
          <CircularProgress color="secondary" />
        </Grow>
      </Box>
    </div>
  );

  return (
    <MainFluidContainer className={clsx(classes.root, className)}>
      <MainContainer classes={{ root: classes.mainContainer }}>
        {(!init || (!isMobile && loader)) && (
          <div className={classes.skeletonContainer}>
            <OptionsTableSkeleton />
          </div>
        )}
        <Box className={clsx(classes.tableContainer, init && 'initialized', loader && !isMobile && 'webLoading')}>
          <Box className={classes.tableKeys}>
            <OptionsTableCard header keys={keys} />
          </Box>
          <Box className={clsx(classes.tableBody)}>{isMobile ? TableBodyMob : TableBodyWeb}</Box>
        </Box>
      </MainContainer>
      {!isMobile && pageData.length > 0 && (
        <Fade in={init}>
          <Box className={classes.footer}>
            <Pagination state={state} handlePagination={handlePagination} className={classes.pagination} />
          </Box>
        </Fade>
      )}
    </MainFluidContainer>
  );
};

export { OptionsTable };
