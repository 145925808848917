import React from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS, FONT_WEIGHT } from '@micro-frontends/shared-components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(3),
    },

    '& h1': {
      fontSize: theme.typography.pxToRem(30),
      color: COLORS.darkGray,
      fontWeight: FONT_WEIGHT.medium,
      textAlign: 'center',

      [theme.breakpoints.down('lg')]: {
        paddingLeft: 2,
        fontSize: theme.typography.pxToRem(26),
        textAlign: 'left',
      },
    },
  },
}));

interface Props {
  className?: string;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
}

const HomeTitle: React.FC<Props> = ({ title, className }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Typography variant="h1">{title}</Typography>
    </Box>
  );
};

export { HomeTitle };
