import React from 'react';

import Fade from '@mui/material/Fade';
import { DealCard, DealTitle } from '../_components';

import { alpha, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS, FONT_WEIGHT } from '@micro-frontends/shared-components';
import { useDealContext } from '../../../common/contexts';
import { getValidForValue } from '../../../components/common/TableTextDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  barand: {
    '& img': {
      marginRight: theme.spacing(1),
      maxWidth: theme.spacing(33),
      maxHeight: theme.spacing(11),
    },
    '& span': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: FONT_WEIGHT.medium,
    },
  },
  about: {
    textAlign: 'right',

    '& h3': {
      margin: 0,
      padding: theme.spacing(0, 1),
      marginBottom: theme.spacing(1),
      backgroundColor: alpha(COLORS.green, 0.2),
      borderRadius: 4,

      textAlign: 'left',
      fontSize: theme.typography.pxToRem(28),
      color: COLORS.green,
    },
    '& p': {
      margin: 0,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.4,
    },
  },
}));

const DealHeaderTitle: React.FC = () => {
  const { dealData } = useDealContext();
  const classes = useStyles();

  return (
    <Fade in={!!dealData}>
      <DealCard>
        <DealTitle>Your Selected Travel Option</DealTitle>
        <div className={classes.body}>
          <div className={classes.barand}>
            <img src={dealData?.brandLogoUrl} alt="brandLogo" />
            <span>{dealData?.locationName}</span>
          </div>
          <div className={classes.about}>
            <h3>{dealData?.discount.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })} OFF</h3>
            <p>{dealData && dealData.upfront * 100}% Deposit</p>
            <p>Valid for {dealData && getValidForValue(dealData.validForYears, dealData.validForDays)}</p>
          </div>
        </div>
      </DealCard>
    </Fade>
  );
};

export { DealHeaderTitle };
