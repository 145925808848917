import { DeepMap, FieldError } from 'react-hook-form';
import { ErrorResponse, isValidationError } from '@micro-frontends/vacayz-api-client';
import { camelCase } from 'lodash';

export interface NestedField {
  prefix: string;
  suffix: string;
}

export interface FormError {
  field: string;
  message: string;
}

const returnErrorByType = (field: string, error: FieldError): string[] => {
  const errorType = error && 'type' in error ? error.type : '';

  if (error.message) return [error.message];

  switch (errorType) {
    case 'required':
      return [`${field} is a required field`];
    default:
      return [error.message ?? ''];
  }
};

const isNestedField = (field: string): boolean => {
  return field.includes('.');
};
const parseNestedField = (field: string): NestedField => {
  return { prefix: field.substring(0, field.indexOf('.')), suffix: field.substring(field.indexOf('.') + 1) };
};

const isFieldError: <T>(
  field: string,
  apiErrors: Record<string, string[]> | null,
  errors: DeepMap<T, FieldError>
) => boolean = (field, apiErrors, errors) => {
  if ((apiErrors && field in apiErrors) || field in errors) {
    return true;
  }

  if (isNestedField(field)) {
    const nestedField: NestedField = parseNestedField(field);
    const _errors = errors as Record<string, FieldError>;
    return nestedField.prefix in errors && nestedField.suffix in _errors[nestedField.prefix];
  }

  return false;
};

const getFieldError: <T>(
  field: string,
  apiErrors: Record<string, string[]> | null,
  errors: DeepMap<T, FieldError>
) => string[] | null = (field, apiErrors, errors) => {
  const _errors = errors as Record<string, FieldError>;
  if (field in _errors) {
    const error = _errors[field];
    return returnErrorByType(field, error);
  } else if (isNestedField(field)) {
    const nestedField: NestedField = parseNestedField(field);
    if (nestedField.prefix in _errors && nestedField.suffix in _errors[nestedField.prefix]) {
      const nestedErrors = (_errors[nestedField.prefix] as unknown) as Record<string, FieldError>;
      const error = nestedErrors[nestedField.suffix];
      return returnErrorByType(field, error);
    }
  } else if (apiErrors && field in apiErrors) return apiErrors[field];
  return null;
};

const extractFormErrors = ({ error }: ErrorResponse): FormError[] => {
  return error.errors.filter(isValidationError).map(({ field, message }) => ({ field: camelCase(field), message }));
};

export { getFieldError, isFieldError, extractFormErrors };
