import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Card, CardMedia, CardContent, Container, Button } from '@mui/material';
import { BookingDealNew, BookingforGuest } from '@micro-frontends/vacayz-api-client';
import GuestAddModal from './guest_modal';

const useStyles = makeStyles({
  cardContainer: {
    '&.MuiCard-root': {
      boxShadow: 'none',
    },
  },
});

interface Props {
  deal: BookingDealNew;
  nights: number;
  date: string;
  quantity: number;
  guestCnt: number;
  requestId: number;
  roomId: number;
  destinationId: number;
  dealId: number;
  guests: BookingforGuest[];
  updateHandle: (destId: number, roomId: number, dealId: number, guests: BookingforGuest[]) => void;
}

const PaymentDealCard: React.FC<Props> = ({
  deal,
  nights,
  requestId,
  roomId,
  dealId,
  destinationId,
  date,
  quantity,
  guestCnt,
  guests,
  updateHandle,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const setHandleOpen = (bOpen: boolean) => {
    setOpen(bOpen);
  };

  return (
    <Container maxWidth="lg">
      <Card className={classes.cardContainer}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <CardMedia
              component="img"
              image="https://i0.wp.com/www.visitbrasil.com/wp-content/uploads/2021/06/CO_Bonito0829cover.jpg"
              alt="hotel image"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <CardContent>
              <Grid container alignItems="center">
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {`${deal.name}, ${deal.address}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} textAlign="right">
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {'$' + (deal.price * nights * quantity).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="body2" sx={{ color: 'gray' }}>
                      {`${date}, ${quantity} Guests`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} textAlign="right">
                    <Typography variant="body1" sx={{ opacity: 0.6 }}>
                      {`Fee: $${deal.fees.toLocaleString()} `}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    {`${nights} Nights`}
                  </Typography>
                </Grid>
                <Grid container padding={2}>
                  <Button fullWidth variant="contained" onClick={() => setHandleOpen(true)}>
                    Add Guests
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
      <Grid pl={3} pr={3}>
        <Grid marginTop={1}></Grid>
      </Grid>
      {open && (
        <GuestAddModal
          open={open}
          setHandleOpen={setHandleOpen}
          quantity={quantity * guestCnt}
          guests={guests}
          requestId={requestId}
          destinationId={destinationId}
          roomId={roomId}
          dealId={dealId}
          updateHandle={updateHandle}
        />
      )}
    </Container>
  );
};

export default PaymentDealCard;
export { PaymentDealCard };
