import React from 'react';

import { Button, MainContainer } from '@micro-frontends/shared-components';
import { DealTitle } from '../_components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Routing } from '../../../common/constants/routing';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0, 6),
  },
  title: {
    padding: theme.spacing(6, 0, 3),
  },
  footer: {
    textAlign: 'center',

    '& .MuiTypography-root': {
      padding: theme.spacing(6, 0, 3),
    },
    '& .MuiButton-root': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const DealQuestions: React.FC = () => {
  const classes = useStyles();

  const handleLink = (href: string) => {
    window.open(href, '_blank')?.focus();
  };

  return (
    <div className={classes.root}>
      <MainContainer className={classes.footer}>
        <DealTitle>Still have questions?</DealTitle>
        <Button onClick={() => handleLink(Routing.howItWorks)}>How It Works?</Button>
        <Button onClick={() => handleLink(Routing.help)}>Help Center</Button>
      </MainContainer>
    </div>
  );
};

export { DealQuestions };
