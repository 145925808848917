import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  CenteredProgress,
  useAsyncCleanup,
  useQueryStringParams,
  AlertContext,
} from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import {
  CreateBookingRequestFormNew,
  getBookingRequestNew,
  isVacayzError,
  toQuerystring,
} from '@micro-frontends/vacayz-api-client';
import { useHistory } from 'react-router-dom';
import OfferDealsView, { OrderSummary, SelectedDealObjects } from './OfferDeals.view';
import { Routing } from '../../../common/constants/routing';

const PAYMENTS_PAGE = Routing.offerPayment;
const asNumber = (num: unknown): number | undefined => (Number.isFinite(Number(num)) ? Number(num) : undefined);

type QueryString = {
  requestId: number;
};

interface Props {
  selectedDeals?: SelectedDealObjects[];
  bookingRequestProp?: CreateBookingRequestFormNew;
  orderSummaryProp?: OrderSummary;
}
const OfferDealsLogic: React.FC<Props> = ({ selectedDeals, bookingRequestProp, orderSummaryProp }) => {
  const { requestId: _requestId } = useQueryStringParams<QueryString>(['requestId']);
  const [requestId] = useState(asNumber(_requestId));

  const { client } = useAuthContext();
  const { alert } = useContext(AlertContext);

  const { addCleanup, isMounted } = useAsyncCleanup();
  const [viewOnly, setViewOnly] = useState<boolean>(false);
  const [bookingRequest, setBookingRequest] = useState<CreateBookingRequestFormNew>();
  const [orderSummary, setOrderSummary] = useState<OrderSummary>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  let appleLink: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
  if (!appleLink) {
    appleLink = document.createElement('link');
    appleLink.rel = 'apple-touch-icon';
    document.getElementsByTagName('head')[0].appendChild(appleLink);
  }
  appleLink.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  const onSelect = useCallback(
    async (_selectedDealObjects: SelectedDealObjects[], _orderSummary: OrderSummary) => {
      const dataToSend = {
        selectedDeals: _selectedDealObjects,
        bookingRequest: bookingRequest,
        orderSummary: _orderSummary,
      };
      history.push(PAYMENTS_PAGE + toQuerystring({ requestId: requestId }), dataToSend);
    },
    [bookingRequest, history, requestId]
  );

  const getData = useCallback(async () => {
    if (!requestId) return;
    try {
      setLoading(true);
      const [_request, cancel] = getBookingRequestNew(client, requestId);
      addCleanup(cancel);
      const request = await _request;
      if (!isMounted()) return;
      if (!request) return alert('Request Not Found', 'error');

      if (!isMounted()) return;
      setBookingRequest(request);
      setLoading(false);
    } catch (err) {
      if (err && err.error === 'Authorization Required') {
        window.location.href = process.env.REACT_APP_SIGN_IN_URL
          ? `${process.env.REACT_APP_SIGN_IN_URL}?next=${window.location.href}`
          : '/';
      }
      if (err && isMounted() && isVacayzError(err)) {
        alert(err, 'error');
      }
    }
  }, [addCleanup, alert, client, isMounted, requestId]);

  useEffect(() => {
    if (bookingRequestProp) {
      setBookingRequest(bookingRequestProp);
      setViewOnly(true);
    } else {
      getData().then((r) => {
        //
      });
    }
    if (orderSummaryProp) {
      setOrderSummary(orderSummaryProp);
    }
  }, [bookingRequestProp, getData, orderSummaryProp]);

  if (!bookingRequest || loading) return <CenteredProgress />;
  return (
    <OfferDealsView
      requestId={requestId}
      bookingRequest={bookingRequest}
      onSelect={onSelect}
      orderSummaryProp={orderSummary}
      viewOnly={viewOnly}
    />
  );
};

export default OfferDealsLogic;
export { OfferDealsLogic };
