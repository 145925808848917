import React from 'react';
import clsx from 'clsx';

import { HEADER_HEIGHT, MainContainer } from '@micro-frontends/shared-components';
import { Header } from '../../containers/Header/Header';
import { DealHeaderCalc } from '../../containers/Deal/DealHeaderCalc/DealHeaderCalc.view';
import { DealMobCalc } from '../Deal/DealMobCalc/DealMobCalc';
import { Footer } from '../../components';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: HEADER_HEIGHT,

    '&.withCalc': {
      paddingTop: 2 * HEADER_HEIGHT,
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
}));

interface Props {
  className?: string;
  withCalc?: boolean;
}

const DealPurchaseContainer: React.FC<Props> = ({ className, withCalc, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className={clsx(classes.root, className, withCalc && 'withCalc')}>
      <Header />
      {withCalc ? isMobile ? <DealMobCalc /> : <DealHeaderCalc /> : null}
      <MainContainer className={classes.container}>{children ?? false}</MainContainer>
      <Footer />
    </div>
  );
};

export { DealPurchaseContainer };
