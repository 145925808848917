import {  createTheme } from '@mui/material/styles';


const PREFIX = 'SHARED';
const HEADER_HEIGHT = 70;
const SIDEBAR_PARAMS = {
  widthOpen: 288,
  widthClose: 82,
};

const COLORS = {
  black: '#000',
  blue: '#009FE0',
  blueDark: '#176FBF',
  darkGray: '#363B46',
  green: '#13AC22',
  grey: '#7E7E7E',
  red: '#F93823',
  white: '#fff',
  whiteSmoke: '#F5F5F5',
  yellow: '#FFB800',
};
const FONT_WEIGHT = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

/// Main theme, edit global changes through here, all components are adjustable
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  palette: {
    background: {
      default: COLORS.white,
    },
    primary: {
      main: '#152A40',
    },
    secondary: {
      main: COLORS.blueDark,
    },
    text: {
      primary: COLORS.black,
      secondary: COLORS.grey,
    },
    info: {
      main: COLORS.blueDark,
    },
  },
  components:{
    MuiDivider: {
      styleOverrides:{
        root: {
          backgroundColor: COLORS.green,
          height: '2px',
          width: '100%',
        },
        light: {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }
      }
    },
    MuiFab: {
      styleOverrides:{
        primary: {
          color: COLORS.white,
        },
      }
    },
    MuiLink: {
      styleOverrides:{
        root: {
          color: COLORS.blueDark,
          fontWeight: FONT_WEIGHT.medium,
        }
      }
    },
    MuiTooltip: {
      styleOverrides:{
        tooltip: {
          fontSize: '0.75rem',
        }
      }
    },
    MuiTypography: {
      styleOverrides:{
        body1: {
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: FONT_WEIGHT.normal,
        },
        h1: {
          fontSize: '2rem',
          fontWeight: FONT_WEIGHT.medium,
        },
        h2: {
          fontSize: '1.5rem',
          fontWeight: FONT_WEIGHT.medium,
        },
        h3: {
          fontSize: '1.2rem',
          fontWeight: FONT_WEIGHT.medium,
        },
        h4: {
          fontSize: '1rem',
          fontWeight: FONT_WEIGHT.medium,
        },
      }
    },
    MuiCssBaseline: {
      styleOverrides:{
        '@global': {
          'html, body': {
            position: 'absolute',
            minHeight: '100%',
            minWidth: '100%',
          },
        },
      }
    },
  } 
});

export { theme as DefaultTheme, COLORS, FONT_WEIGHT, PREFIX, HEADER_HEIGHT, SIDEBAR_PARAMS };
