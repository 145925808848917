/* eslint-disable */
import React, { useState } from 'react';
import { EventDealsResponse, formatDateRangeToString } from '@micro-frontends/vacayz-api-client';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import BedIcon from '@mui/icons-material/Bed';
import CoffeeOutlinedIcon from '@mui/icons-material/CoffeeOutlined';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  CardActions,
  Rating,
  Stack,
  Chip,
} from '@mui/material';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import { getStringToDate } from '@micro-frontends/vacayz-api-client';
import Carousel from 'react-material-ui-carousel';
import './DealCard.scss';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function displayPrice(price: number): string {
  return Number(price.toFixed(2)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

export function getTagName(strTags: string): string {
  const splited = strTags.split(':');
  if (splited.length > 0) {
    return splited[0];
  }
  return '';
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const DealCard: React.FC<{
  deal: EventDealsResponse;
  eventid: string;
}> = ({ deal, eventid }) => {
  const [showIncludedFeeAndTax, setShowIncludedFeeAndTax] = useState(false);
  const discount = 100 - Math.round((deal.nightPrice / deal.marketPrice) * 100);
  const startDate = getStringToDate(deal.activateStartAt);
  const endDate = getStringToDate(deal.activateEndAt);

  const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
  const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const getDiscount = (price: number, marketPrice: number) => {
    return Math.floor((1 - price / marketPrice) * 100) + '%';
  };

  return (
    <Card
      className="deal-card"
      variant="outlined"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 3 }}
    >
      <Grid position="relative">
        <Stack direction="row" spacing={1} sx={{ position: 'absolute', zIndex: 1050, padding: 1 }}>
          {deal.tags &&
            deal.tags.length > 0 &&
            deal.tags.map((tag, i) => (
              <Chip
                label={getTagName(tag)}
                key={i}
                sx={{ backgroundColor: '#FF8196	', color: 'white', fontWeight: 'bold' }}
              />
            ))}
        </Stack>
        {deal.roomImages.length > 0 ? (
          <Carousel
            animation="slide"
            autoPlay={false}
            navButtonsAlwaysVisible
            swipe
            navButtonsProps={{
              style: {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                borderRadius: '50%',
              },
            }}
            indicatorContainerProps={{
              style: {
                zIndex: 1,
                marginTop: '-25px',
                position: 'relative',
              },
            }}
          >
            {deal.roomImages.map((img, i) => (
              <CardMedia component="img" height="275" key={i} image={img} alt="Room Image" />
            ))}
          </Carousel>
        ) : (
          <CardMedia component="img" height="275" alt="Empty" />
        )}
        <Typography
          variant="h5"
          component="span"
          sx={{
            zIndex: 1,
            position: 'absolute',
            color: 'white',
            padding: 1,
            background: '#152A40',
            bottom: 0,
            right: '20px',
          }}
        >
          {`${getDiscount(deal.price, deal.marketPrice)} OFF`}
        </Typography>
      </Grid>

      <CardContent>
        <Grid display="right">
          <Typography variant="h6" textAlign="right">{`${displayPrice(deal.nightPrice)} / Night`}</Typography>
        </Grid>
        <Stack>
          <Typography variant="h5" gutterBottom paddingRight={1} sx={{ wordBreak: 'break-word' }}>
            {deal.hotelName}
          </Typography>
        </Stack>
        {deal.rate && deal.rate > 0 ? (
          <Rating name="hotel-rate" value={deal.rate} readOnly sx={{ marginY: 1 }} />
        ) : null}

        <Grid container>
          <Grid item xs={8}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText
                  // primary={`${deal.activateStartAt} until ${deal.activateEndAt} (${strNights})`}
                  primary={
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {formatDateRangeToString(startDate, endDate)}
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem key="room-type">
                <ListItemIcon>
                  <BedIcon />
                </ListItemIcon>
                <Grid display="flex" alignItems="center" flexWrap="wrap">
                  <Typography
                    component="div"
                    display="inline-flex"
                    sx={{
                      verticalAlign: 'text-bottom',
                      boxSizing: 'inherit',
                      textAlign: 'left',
                      alignItems: 'center',
                    }}
                  >
                    {deal.name}
                    <Typography component="span">{'('}</Typography>
                    {[...Array(deal.countGuests)].map((g, i) => (
                      <PersonIcon key={i} />
                    ))}
                    <Typography component="span">{')'}</Typography>
                  </Typography>
                </Grid>
              </ListItem>

              <ListItem key="location">
                <ListItemIcon>
                  <LocationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Grid container alignItems={'center'}>
                      {deal.distanceToVenueWalkInMinutes ? (
                        <Typography variant="body1">
                          {`${deal.distanceToVenueWalkInMinutes} Min`}
                          <DirectionsWalkIcon />
                        </Typography>
                      ) : null}
                      {deal.distanceToVenueWalkInMinutes && deal.distanceToVenueCarInMinutes ? (
                        <Typography variant="body1">{' / '}</Typography>
                      ) : null}
                      {deal.distanceToVenueCarInMinutes ? (
                        <Typography variant="body1">
                          {`${deal.distanceToVenueCarInMinutes} Min `}
                          <DirectionsCarIcon />
                        </Typography>
                      ) : null}
                    </Grid>
                  }
                  secondary={deal.address}
                />
              </ListItem>

              {deal.breakfastIncluded ? (
                <ListItem key="breakfast">
                  <ListItemIcon>
                    <CoffeeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Breakfast included" />
                </ListItem>
              ) : (
                <ListItem key="breakfast">
                  <ListItemText primary="" />
                </ListItem>
              )}
            </List>
          </Grid>

          <Grid item style={{ flex: '1' }}>
            <List dense>
              <ListItem dense disableGutters style={{ justifyContent: 'end' }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2', style: { color: 'rgba(0,0,0,0.5)', textAlign: 'end' } }}
                  primary={'Includes taxes and fees'}
                />
              </ListItem>

              <ListItem dense disableGutters>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    textAlign: 'end',
                    style: { textDecoration: 'line-through' },
                  }}
                  primary={displayPrice(deal.marketPrice)}
                />
              </ListItem>
              <ListItem dense disableGutters>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h6', textAlign: 'end', style: { fontWeight: 500 } }}
                  primary={displayPrice(deal.price)}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            {deal.feesDescription && deal.feesDescription.filter((d) => d.isIncluded).length > 0 && (
              <Button
                endIcon={showIncludedFeeAndTax ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                size="small"
                style={{ textTransform: 'none' }}
                onClick={() => setShowIncludedFeeAndTax(!showIncludedFeeAndTax)}
              >
                {'Included taxes and fees'}
              </Button>
            )}
            {showIncludedFeeAndTax && (
              <List dense>
                {deal.feesDescription &&
                  deal.feesDescription
                    .filter((d) => d.isIncluded)
                    .map((d) => (
                      <ListItem>
                        <ListItemText primary={<Typography variant="body2">{d.name}</Typography>} />
                        <ListItemText
                          primary={
                            <Typography variant="body2" style={{ textAlign: 'right' }}>
                              {displayPrice(d.amount)}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button
          variant="contained"
          size="large"
          href={`/vieweventdeal?eventid=${eventid}&deal=${deal.dealId}&from=${deal.activateStartAt}&to=${deal.activateEndAt}`}
        >
          Book
        </Button>
      </CardActions>
    </Card>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export default DealCard;

/* eslint-enable */
