import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import React from 'react';

const CenteredProgress: React.FC = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
};

export { CenteredProgress };
