/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  EventDealsResponse,
  Event as SourceDetailsResponse,
  FeeDescriptionForm,
  getStringToDate,
  formatDateRangeToString,
  sendVisitorViewData,
  formatStringDateToFormatedString,
} from '@micro-frontends/vacayz-api-client';
import { useAuthContext } from '@micro-frontends/auth-context';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  AppBar,
  Toolbar,
  CardActions,
  Rating,
  Typography,
  Stack,
  IconButton,
  Chip,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { getEventDealsWrapper, getUrlParam } from '../EventDealsView/EventDealsView';
import LocationIcon from '@material-ui/icons/LocationOn';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import BedIcon from '@mui/icons-material/Bed';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import CircularProgress from '@mui/material/CircularProgress';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CheckoutForm } from './CheckoutForm';
import Carousel from 'react-material-ui-carousel';
import './SingleEventDealView.scss';
import FeesModal from './FeesModal';
import InfoIcon from '@mui/icons-material/Info';
import { getTagName } from '../EventDealsView/DealCard';
import { customAlphabet } from 'nanoid';
import { useAsyncCleanup } from '@micro-frontends/shared-components';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BookingConditionModal from './BookingConditionModal';
import EventDetailContainer from '../EventDealsView/EventDetailContainer';
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export function displayPrice(price: number): string {
  if (price < 0) {
    return `US -$${Number(Math.abs(price).toFixed(2)).toLocaleString()}`;
  }
  return `US$${Number(price.toFixed(2)).toLocaleString()}`;
}

function displayFees(fees?: FeeDescriptionForm[]): string {
  let total = 0;
  if (fees) {
    fees.forEach((fee) => {
      if (fee.isIncluded === false) {
        total += +fee.amount;
      }
    });
  }

  return displayPrice(total);
}

export const customNanoIdFunc = customAlphabet('ABCDEFGHIJKLNMOPQRSTUVWXYZ1234567890abcdefghijklnmopqrstuvwxyz', 21);

export const getCookieValue = (key: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
  return null;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

document
  .querySelector('meta[name="description"]')
  ?.setAttribute('content', `Find best deals accommodation with ${process.env.REACT_APP_COMPANY_NAME}`);

const SingleEventDealView: React.FC = () => {
  const { currentUser, client } = useAuthContext();
  const { addCleanup } = useAsyncCleanup();
  const [isLoading, setIsLoading] = useState(true);
  const [deal, setDeal] = useState<EventDealsResponse>();
  const [eventId, setEventId] = useState<string>();
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [showExpandedInfoOnMobile, setShowExpandedInfoOnMobile] = useState(false);
  const [showIncludedFees, setShowIncludedFees] = useState(false);
  const [openFees, setOpenFees] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [openConditionModal, setOpenConditionModal] = useState(false);
  const [hasNoDeals, setHasNoDeals] = useState(false);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  document.title = `${process.env.REACT_APP_COMPANY_NAME}`;
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  let appleLink: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
  if (!appleLink) {
    appleLink = document.createElement('link');
    appleLink.rel = 'apple-touch-icon';
    document.getElementsByTagName('head')[0].appendChild(appleLink);
  }
  appleLink.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  useEffect(() => {
    (async () => {
      const dealId = getUrlParam('deal');
      const eventId = getUrlParam('eventid') || '';
      const from = getUrlParam('from');
      if (from) {
        setCheckInDate(getStringToDate(from));
      }
      const to = getUrlParam('to');
      if (to) {
        setCheckOutDate(getStringToDate(to));
      }
      setEventId(eventId);

      try {
        if (!currentUser) {
          let kaId = getCookieValue('ka-id');
          let kaCreated = getCookieValue('ka-created-at');

          if (!kaId || !kaCreated) {
            const now = new Date();
            kaId = customNanoIdFunc();
            kaCreated = `${now.getFullYear()}-${
              now.getMonth() + 1
            }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}`;
            document.cookie = `ka-id=${kaId}`;
            document.cookie = `ka-created-at=${kaCreated}`;
          }
          const [_sendVisitorData, _sendVisitorClean] = sendVisitorViewData(
            client,
            eventId,
            'eventdealdetails',
            kaId,
            kaCreated,
            dealId
          );
          addCleanup(_sendVisitorClean);
          await _sendVisitorData;
        }

        const deals = await getEventDealsWrapper(client, eventId, 'price', to, from);
        const deal = deals?.filter((deal) => deal.dealId === dealId)[0];
        if (deal === undefined) {
          throw new Error('A deal with this ID was not found');
        }

        setDeal(deal);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setHasNoDeals(true);
        setIsLoading(false);
      }
    })();
  }, []);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const loadingPleaseWait = (
    <Box sx={{ pt: 10, color: 'primary' }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress sx={{ color: 'primary' }} />
      <Box sx={{ mt: 2 }}>
        <Typography color="primary" variant="h4">
          Loading deal...
        </Typography>
      </Box>
    </Box>
  );
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let content = null;

  if (deal !== undefined) {
    const startDate = getStringToDate(deal.activateStartAt);
    const endDate = getStringToDate(deal.activateEndAt);
    const dateRange = formatDateRangeToString(startDate, endDate);
    const nights = Math.round((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24 ?? 1);
    const strNights = `${nights} ${nights > 1 ? 'nights' : 'night'}`;
    const discountTotal = Math.round(deal.price - deal.marketPrice);
    const discountPercent = 100 - Math.round((deal.price / deal.marketPrice) * 100);

    const bookingCondition = (
      <>
        <Link variant="body1" sx={{ color: '#1B528C' }} component="button" onClick={() => setOpenConditionModal(true)}>
          {'Booking Conditions'}
        </Link>
        {openConditionModal && (
          <BookingConditionModal
            open={openConditionModal}
            setOpenCondition={setOpenConditionModal}
          ></BookingConditionModal>
        )}
      </>
    );

    const checkoutDetailsList = (
      <List dense>
        <ListItem>
          <ListItemIcon>
            <BedIcon />
          </ListItemIcon>
          <Grid container>
            <Typography>
              {deal.name}
              {' ('}
            </Typography>
            {[...Array(deal.countGuests)].map((g, i) => (
              <PersonIcon key={i} />
            ))}
            {')'}
          </Grid>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LocationIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Grid container alignItems={'center'}>
                {deal.distanceToVenueWalkInMinutes ? (
                  <Typography variant="body1">
                    {`${deal.distanceToVenueWalkInMinutes} Min`}
                    <DirectionsWalkIcon />
                  </Typography>
                ) : null}
                {deal.distanceToVenueWalkInMinutes && deal.distanceToVenueCarInMinutes ? (
                  <Typography variant="body1">{' / '}</Typography>
                ) : null}
                {deal.distanceToVenueCarInMinutes ? (
                  <Typography variant="body1">
                    {`${deal.distanceToVenueCarInMinutes} Min `}
                    <DirectionsCarIcon />
                  </Typography>
                ) : null}
              </Grid>
            }
            secondary={deal.address}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText
            // primary={`${deal.activateStartAt} until ${deal.activateEndAt} (${strNights})`}
            primary={`${dateRange} (${strNights})`}
          />
        </ListItem>
        {deal.refundable && (
          <ListItem>
            <Grid container justifyContent="center">
              <Grid item xs>
                <ListItemText primary={`Free Cancellation Until`} />
              </Grid>
              <Grid item xs textAlign="right">
                <ListItemText primary={formatStringDateToFormatedString(deal.refundableUntil ?? '')} />
              </Grid>
            </Grid>
          </ListItem>
        )}

        {deal.quantity > 0 && (
          <ListItem>
            <Grid container justifyContent="center">
              <Grid item xs>
                <ListItemText primary={`Number of Rooms`} />
              </Grid>
              <Grid item xs textAlign="right">
                <FormControl variant="standard">
                  <Select
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value as number)}
                    fullWidth
                    color="primary"
                  >
                    {Array.from(Array(deal.quantity > 3 ? 3 : deal.quantity), (e, i) => (
                      <MenuItem value={i + 1} key={i}>
                        {i === 0 ? '1 Room' : `${i + 1} Rooms`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </ListItem>
        )}

        <Divider sx={{ my: 1 }} />

        <ListItem>
          <Grid container justifyContent="center">
            <Grid item xs>
              <ListItemText primary={`${displayPrice(deal.marketNightPrice)} × ${strNights}`} />
            </Grid>
            <Grid item xs textAlign="right">
              <ListItemText primary={displayPrice(deal.marketPrice)} />
            </Grid>
          </Grid>
        </ListItem>
        {deal.feesDescription && deal.feesDescription.filter((d) => d.isIncluded).length > 0 && (
          <ListItem>
            <Grid container justifyContent="center">
              <Grid item xs>
                <Button
                  endIcon={showIncludedFees ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  size="small"
                  style={{ textTransform: 'none' }}
                  onClick={() => setShowIncludedFees(!showIncludedFees)}
                >
                  {'Included taxes and fees'}
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {showIncludedFees &&
          deal.feesDescription &&
          deal.feesDescription
            .filter((d) => d.isIncluded)
            .map((d) => (
              <ListItem>
                <Grid container justifyContent="center">
                  <Grid item xs>
                    <ListItemText primary={d.name} />
                  </Grid>
                  <Grid item xs textAlign="right">
                    <ListItemText primary={displayPrice(d.amount)} />
                  </Grid>
                </Grid>
              </ListItem>
            ))}

        <ListItem sx={{ color: 'green' }}>
          <Grid container justifyContent="center">
            <Grid item xs>
              <ListItemText primary={`${discountPercent}% off - ${process.env.REACT_APP_COMPANY_NAME} discount`} />
            </Grid>
            <Grid item xs textAlign="right">
              <ListItemText primaryTypographyProps={{ noWrap: true }} primary={displayPrice(discountTotal)} />
            </Grid>
          </Grid>
        </ListItem>

        <ListItem>
          <Grid container justifyContent="center">
            <Grid item xs>
              <ListItemText primary={`Your rate ${displayPrice(deal.nightPrice)} × ${strNights}`} />
            </Grid>
            <Grid item xs textAlign="right">
              <ListItemText primary={displayPrice(deal.price)} />
            </Grid>
          </Grid>
        </ListItem>
        {quantity > 1 && (
          <ListItem>
            <Grid container justifyContent="center">
              <Grid item xs>
                <ListItemText primary={`${quantity - 1} More Rooms`} />
              </Grid>
              <Grid item xs textAlign="right">
                <ListItemText primary={displayPrice(deal.price * (quantity - 1))} />
              </Grid>
            </Grid>
          </ListItem>
        )}

        <Divider sx={{ my: 1 }} />

        <ListItem>
          <Grid container justifyContent="center">
            <Grid item xs>
              <ListItemText primary={`Total`} sx={{ '& span': { fontWeight: 'bold' } }} />
            </Grid>
            <Grid item xs textAlign="right">
              <ListItemText primary={displayPrice(deal.price * quantity)} sx={{ '& span': { fontWeight: 'bold' } }} />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container justifyContent="center">
            <Grid item xs>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">{'Excluded charges'}</Typography>
                {deal.feesDescription && deal.feesDescription.length > 0 && (
                  <IconButton onClick={() => setOpenFees(true)}>
                    <InfoIcon color="primary" />
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item xs textAlign="right">
              <ListItemText primary={displayFees(deal.feesDescription)} />
            </Grid>
          </Grid>
        </ListItem>
        <Divider sx={{ my: 1 }} />
        <ListItem>{bookingCondition}</ListItem>
      </List>
    );

    content = (
      <Grid container justifyContent="center">
        {/* Mobile view */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'block', md: 'none' } }}>
          <Card variant="outlined" sx={{ borderRadius: 3 }}>
            <CardContent>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={3} sx={{ flexGrow: 0 }}>
                  {deal.roomImages.length > 0 ? (
                    <Carousel animation="slide" duration={2000} swipe={true} indicators={false}>
                      {deal.roomImages.map((img, i) => (
                        <CardMedia component="img" key={i} image={img} height="140" alt="Room Image" />
                      ))}
                    </Carousel>
                  ) : (
                    <CardMedia component="img" alt="Empty" />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1}>
                    {deal.tags &&
                      deal.tags.length > 0 &&
                      deal.tags.map((tag, i) => (
                        <Chip
                          label={getTagName(tag)}
                          key={i}
                          sx={{ backgroundColor: '#FF8196	', color: 'white', fontWeight: 'bold' }}
                        />
                      ))}
                  </Stack>
                  <Stack>
                    {deal.rate && deal.rate > 0 ? (
                      <Rating name="hotel-rate" value={deal.rate} readOnly sx={{ marginY: 1 }} />
                    ) : null}
                    <Typography gutterBottom variant="subtitle1" sx={{ wordBreak: 'break-word' }}>
                      {`${deal.hotelName}`}
                    </Typography>
                  </Stack>
                  <Typography gutterBottom variant="subtitle1">
                    {`${strNights}`}
                  </Typography>
                </Grid>

                <Grid item xs={3} sx={{ flexGrow: 0 }}>
                  <Typography gutterBottom variant="subtitle1">
                    {displayPrice(deal.price)}
                  </Typography>
                </Grid>
              </Grid>

              {showExpandedInfoOnMobile && checkoutDetailsList}
            </CardContent>
            <CardActions>
              <Button
                endIcon={showExpandedInfoOnMobile ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                style={{ textTransform: 'none' }}
                onClick={() => setShowExpandedInfoOnMobile(!showExpandedInfoOnMobile)}
              >
                Show price breakdown
              </Button>
            </CardActions>
            <Box sx={{ marginX: 2 }}>{bookingCondition}</Box>
          </Card>
        </Grid>

        {/* Checkout form */}
        <Grid item xs={12} md={6}>
          {currentUser ? (
            eventId && (
              <CheckoutForm eventId={deal.eventDealId} totalAmount={deal.price} deal={deal} quantity={quantity} />
            )
          ) : (
            <Box sx={{ m: 3 }}>
              <Typography variant="h5">Your trip</Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Dates'}
                    secondary={`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Guests'}
                    secondary={`${deal.countGuests} ${deal.countGuests > 1 ? 'guests' : 'guest'}`}
                  />
                </ListItem>
              </List>
              <Typography>In order to book your room, you must be logged in or signed up.</Typography>
              <a
                href={`./loginredirect?url=${encodeURIComponent(
                  document.location.pathname + document.location.search
                )}`}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="contained" size="large" fullWidth sx={{ mt: 2 }}>
                  Log in
                </Button>
              </a>
              <Divider>{' or '}</Divider>
              <a
                href={`./logupredirect?url=${encodeURIComponent(
                  document.location.pathname + document.location.search
                )}`}
                style={{ textDecoration: 'none' }}
              >
                <Button variant="outlined" size="large" fullWidth sx={{ mt: 2 }}>
                  Sign Up
                </Button>
              </a>
            </Box>
          )}
        </Grid>

        {/* Desktop view */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Card variant="outlined" sx={{ borderRadius: 3 }}>
            <Stack direction="row" spacing={1} sx={{ position: 'absolute', zIndex: 1050, padding: 1 }}>
              {deal.tags &&
                deal.tags.length > 0 &&
                deal.tags.map((tag, i) => (
                  <Chip
                    label={getTagName(tag)}
                    key={i}
                    sx={{ backgroundColor: '#FF8196	', color: 'white', fontWeight: 'bold' }}
                  />
                ))}
            </Stack>
            {deal.roomImages.length > 0 ? (
              <Carousel
                animation="slide"
                autoPlay={false}
                navButtonsAlwaysVisible
                swipe
                navButtonsProps={{
                  style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                    borderRadius: '50%',
                  },
                }}
                indicatorContainerProps={{
                  style: {
                    zIndex: 1,
                    marginTop: '-25px',
                    position: 'relative',
                  },
                }}
              >
                {deal.roomImages.map((img, i) => (
                  <CardMedia component="img" height="250" key={i} image={img} alt="Room Image" />
                ))}
              </Carousel>
            ) : (
              <CardMedia component="img" height="250" alt="Empty" />
            )}
            <CardContent>
              <Stack>
                {deal.rate && deal.rate > 0 ? (
                  <Rating name="hotel-rate" value={deal.rate} readOnly sx={{ marginY: 1 }} />
                ) : null}

                <Typography
                  gutterBottom
                  variant="h5"
                  marginBottom={0}
                  paddingRight={1}
                  sx={{ wordBreak: 'break-word' }}
                >
                  {deal.hotelName}
                </Typography>
              </Stack>

              {checkoutDetailsList}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppBar position="static" sx={{ mb: 2 }}>
        <Toolbar>
          <img src={process.env.REACT_APP_COMPANY_WHITE_BRAND} alt="logo" style={{ height: '50px' }} />
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" className="single-event-deal-view">
        {isLoading ? (
          loadingPleaseWait
        ) : hasNoDeals ? (
          <EventDetailContainer
            minDate={checkInDate}
            maxDate={checkOutDate}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            deals={[]}
            guests={null}
            rooms={1}
            sortby={null}
            guestCounts={[]}
            eventId={eventId ?? ''}
          />
        ) : (
          content
        )}
      </Container>
      {openFees && deal && (
        <FeesModal open={openFees} openHandle={setOpenFees} feesDescription={deal?.feesDescription} />
      )}
    </LocalizationProvider>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/* eslint-enable */

export { SingleEventDealView };
