import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, IconButton } from '../_elements';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useLink } from '../../common/hooks/UseLink.hook';

const styles = () =>
  createStyles({
    appButton: {},
    appIcoButton: {},
    popup: {
      minWidth: 266,
    },
  });

const HeaderFeatures = withStyles(styles)((props: WithStyles<typeof styles> & { auth: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { auth, classes } = props;
  const { goTo, history } = useLink();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {auth || isMobile ? (
        <IconButton
          onClick={handleClick}
          className={classes.appIcoButton}
          color="inherit"
          size="large">
          <AppsSharpIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClick} className={classes.appButton} variant="text" color="inherit">
          Features
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.popup }}
      >
        <MenuItem divider onClick={handleClose}>
          <ListItemIcon>
            <AppsRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Features" />
          <CloseIcon />
        </MenuItem>

        <MenuItem
          onClick={() =>
            process.env.REACT_APP_MARKETPLACE_URL
              ? window.location.assign(process.env.REACT_APP_MARKETPLACE_URL + 'offer/request?source=website')
              : history.push('/')
          }
        >
          <ListItemIcon>
            <StoreMallDirectoryIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Marketplace" />
        </MenuItem>
        {/* <MenuItem
          onClick={() =>
            process.env.REACT_APP_OPTIONS_URL
              ? window.location.assign(process.env.REACT_APP_OPTIONS_URL)
              : history.push('/')
          }
        >
          <ListItemIcon>
            <LocalAtmIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="My Options" />
        </MenuItem> */}
        <MenuItem
          onClick={() =>
            process.env.REACT_APP_HOTELIERS_URL
              ? window.location.assign(process.env.REACT_APP_HOTELIERS_URL)
              : history.push('/')
          }
        >
          <ListItemIcon>
            <LocalHotelIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Hotels Login" />
        </MenuItem>
        <MenuItem onClick={() => goTo('https://app.vacayz.com')}>
          <ListItemIcon>
            <PeopleIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Property Management" />
        </MenuItem>
        {isMobile && (
          <MenuItem onClick={() => goTo((process.env.REACT_APP_HELP_CENTER_URL || '/help') as string)}>
            <ListItemIcon>
              <HelpOutlineIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Help Center" />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
});

export { HeaderFeatures };
