import React, { useCallback, useEffect } from 'react';
import { useAuthContext } from '@micro-frontends/auth-context';
import { useDealContext } from '../../common/contexts';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { TextField, useAsyncCleanup } from '@micro-frontends/shared-components';
import { createStripeCharge } from '@micro-frontends/vacayz-api-client';
import { useForm } from 'react-hook-form';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const StripePurchaseForm: React.FC = () => {
  const { client, currentUser } = useAuthContext();
  const {
    formState: { isValid },
    register,
    getValues,
  } = useForm({ mode: 'onTouched' });
  const { setCreateCharge, setLoading, loading } = useDealContext();
  const stripe = useStripe();
  const elements = useElements();
  const { isMounted, addCleanup } = useAsyncCleanup();

  const completeStripeCharge = useCallback(
    async (paymentIntentId: number) => {
      if (!currentUser || loading || !isValid || !paymentIntentId) {
        return false;
      }
      const [_stripeSecret, cancel] = createStripeCharge(client, { paymentIntentId, userId: currentUser.id });
      addCleanup(cancel);
      const stripeSecret = await _stripeSecret;
      if (!isMounted() || !stripeSecret || !stripe || !elements) {
        return false;
      }
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return false;
      }
      const paymentIntentResult = await stripe.confirmCardPayment(stripeSecret.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            email: currentUser.email,
            phone: currentUser.phone ? currentUser.phone : getValues('phone'),
            address: {
              line1: getValues('address'),
            },
          },
        },
      });

      if (paymentIntentResult.error) {
        // Error in payment
        throw paymentIntentResult.error;
      }
      // The payment has been processed!
      return paymentIntentResult.paymentIntent?.status === 'succeeded';
    },
    [addCleanup, client, currentUser, elements, getValues, isMounted, isValid, loading, stripe]
  );

  useEffect(() => {
    if (!stripe || !elements) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [elements, setLoading, stripe]);

  useEffect(() => {
    if (completeStripeCharge) {
      setCreateCharge(() => completeStripeCharge);
    }
  }, [completeStripeCharge, setCreateCharge]);

  return (
    <Container disableGutters maxWidth="sm">
      <Box my={3}>
        <Typography variant="h2" align="center">
          Card Info
        </Typography>
      </Box>
      <form>
        <Grid container spacing={4}>
          <Grid item container xs={12} md={12} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box px={1} py={1.5} borderRadius="4px" borderColor="rgba(0, 0, 0, 0.23)" border="1px solid">
                <CardElement />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField name="address" inputRef={register({ required: true })} label="Address" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="phone"
              inputRef={register({ required: true })}
              disabled={!!currentUser?.phone}
              label="Phone Number"
              defaultValue={currentUser?.phone ?? ''}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default StripePurchaseForm;
export { StripePurchaseForm };
