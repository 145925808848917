import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withEnforceSignIn } from '@micro-frontends/auth-context';
import { Routing } from './common/constants/routing';
import { Deal } from './pages/Deal/Deal';
import { EventDealsView } from './pages/EventDealsView/EventDealsView';
import { SingleEventDealView } from './pages/SingleEventDealView/SingleEventDealView';
import { DealPurchase } from './pages/DealPurchase/DealPurchase';
import { DealConfirm } from './pages/DealConfirm/DealConfirm';
import { NotFound } from './pages/NotFound/NotFound';
import { PaymentSuccess } from './pages/Booking/Payment/PaymentSuccess/PaymentSuccess';
import { RequestSuccess } from './pages/Booking/RequestSuccess/RequestSuccess';
import { OfferDeals } from './pages/Booking/Deals/OfferDeals';
import { BookingPaymentPage } from './pages/Booking/Payment/PaymentPage/BookingPaymentPage';
import DealsCreation from './pages/Booking/RequestForm/OfferCreation/DealsCreation';
import { SecretRedirect } from './pages/Booking/Payment/SecretRedirect';
import { ViewHandle } from './pages/Booking/Deals/ViewHandle';
import { RequestView } from './market/Request/requests';
import { DealsView } from './market/Request/deals';
import { PaymentDealsView } from './market/Payment/deals';
import { LoginRedirect } from './pages/LoginRedirect/LoginRedirect';
import { LogupRedirect } from './pages/LogupRedirect/LogupRedirect';
import { BookingSuccess } from './pages/BookingSuccess/BookingSuccess';

const AppRouter: React.FC = () => {
  const EnforcedPurchase = withEnforceSignIn(DealPurchase, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedConfirm = withEnforceSignIn(DealConfirm, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedBookingPaymentPage = withEnforceSignIn(BookingPaymentPage, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedRequests = withEnforceSignIn(RequestView, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedDeals = withEnforceSignIn(DealsView, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedPaymentDeals = withEnforceSignIn(PaymentDealsView, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedLoginRedirect = withEnforceSignIn(LoginRedirect, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedLogupRedirect = withEnforceSignIn(LogupRedirect, process.env.REACT_APP_SIGN_UP_URL ?? '');
  const EnforcedBookingSuccess = withEnforceSignIn(BookingSuccess, process.env.REACT_APP_SIGN_IN_URL ?? '');
  const EnforcedDealsCreation = withEnforceSignIn(DealsCreation, process.env.REACT_APP_SIGN_IN_URL ?? '');

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/">
            <EnforcedDealsCreation />
          </Route>
          <Route exact path={Routing.deal}>
            <Deal />
          </Route>
          <Route exact path={Routing.dealPurchase}>
            <EnforcedPurchase />
          </Route>
          <Route exact path={Routing.dealConfirm}>
            <EnforcedConfirm />
          </Route>
          <Route exact path={Routing.offerDeals}>
            <OfferDeals />
          </Route>
          <Route exact path={Routing.offerRequest}>
            <EnforcedDealsCreation />
          </Route>
          <Route exact path={Routing.offerRequestSuccess}>
            <RequestSuccess />
          </Route>
          <Route exact path={Routing.offerPayment}>
            <EnforcedBookingPaymentPage />
          </Route>
          <Route exact path={Routing.offerPaymentSuccess}>
            <PaymentSuccess />
          </Route>
          <Route exact path={Routing.secretRedirect}>
            <SecretRedirect />
          </Route>
          <Route exact path={Routing.viewHandle}>
            <ViewHandle />
          </Route>
          <Route exact path={Routing.eventDeals}>
            <EventDealsView />
          </Route>
          <Route exact path={Routing.viewEventDeal}>
            <SingleEventDealView />
          </Route>
          <Route exact path={Routing.viewHomeRequest}>
            <EnforcedRequests />
          </Route>
          <Route exact path={Routing.viewDeals}>
            <EnforcedDeals />
          </Route>
          <Route exact path={Routing.viewPaymentDeals}>
            <EnforcedPaymentDeals />
          </Route>
          <Route exact path={Routing.loginRedirect}>
            <EnforcedLoginRedirect />
          </Route>
          <Route exact path={Routing.logupRedirect}>
            <EnforcedLogupRedirect />
          </Route>
          <Route exact path={Routing.bookingSuccess}>
            <EnforcedBookingSuccess />
          </Route>

          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default AppRouter;
export { AppRouter };
