import React from 'react';
import clsx from 'clsx';

import { COLORS } from '../../themes';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { grey } from '@mui/material/colors';

const HEIGHT = 16;

const useStyles = makeStyles<Theme, Partial<Props>>(() => ({
  root: ({ available, used, height }) => ({
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: height ?? HEIGHT,
    borderRadius: height ? height / 2 : HEIGHT / 2,
    background: `linear-gradient(90deg, ${COLORS.blue} 0%, ${COLORS.green} 100%)`,

    '& > span': {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      borderRadius: 'inherit',
      width: available && used && `${(100 / available) * used}%`,
      backgroundColor: grey[800],
    },
  }),
}));

interface Props {
  used: number;
  available: number;
  className?: string;
  height?: number;
}

const BalanceProgress: React.FC<Props> = ({ className, available, used, height }) => {
  const classes = useStyles({ available, used, height });

  return (
    <div className={clsx(classes.root, className)}>
      <span />
    </div>
  );
};

export { BalanceProgress };
