/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import {
  getEventDetails,
  getEventDeals,
  EventDealsResponse,
  Event as SourceDetailsResponse,
  VacayzClient,
  getStringToDate,
  getEventGuestCounts,
  sendVisitorViewData,
} from '@micro-frontends/vacayz-api-client';
import { useAuthContext } from '@micro-frontends/auth-context';
import { AlertContext, useAsyncCleanup } from '@micro-frontends/shared-components';
import { Box, CircularProgress, Container, AppBar, Toolbar } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { EventDetailContainer } from './EventDetailContainer';
// import FilterModal from './Components/FilterModal';
import { customNanoIdFunc, getCookieValue } from '../SingleEventDealView/SingleEventDealView';

const isLoadErr = (value: unknown): value is LoadError => {
  return (value as LoadError).error !== undefined;
};

interface LoadError {
  error: string;
}

function getUrlParam(name: string) {
  const queryString = document.location.search;
  let urlString = '';
  let bSlash = false;
  for (let i = 0; i < queryString.length; i++) {
    if (queryString[i] !== '?') {
      urlString += queryString[i];
    } else {
      if (!bSlash) {
        urlString += '?';
        bSlash = true;
      } else {
        urlString += '&';
      }
    }
  }
  const urlParams = new URLSearchParams(urlString);
  const ret = urlParams.get(name) || '';

  return ret;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

async function getEventDealsWrapper(
  client: VacayzClient,
  eventId: string,
  sortby?: string,
  to?: string,
  from?: string,
  guests?: number,
  rooms?: number
) {
  const [_fetchEventDeals] = getEventDeals(client, eventId, sortby, to, from, guests, rooms);
  //const fetchDeals = localStorage.getItem('mockeventdeals') ? MOCK_EVENT_DEALS : _fetchEventDeals;
  const fetchDeals = _fetchEventDeals;
  const deals = await fetchDeals;
  return deals;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
document
  .querySelector('meta[name="description"]')
  ?.setAttribute('content', `Find best deals accommodation with ${process.env.REACT_APP_COMPANY_NAME}`);

const EventDealsView: React.FC = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const { currentUser, client } = useAuthContext();
  const [sourceDetails, setSourceDetails] = useState<SourceDetailsResponse>();
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [guests, setGuests] = useState<number | null>(null);
  const [guestCounts, setGuestCounts] = useState<number[]>([]);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [moreRooms, setMoreRooms] = useState(false);
  const [roomCounts, setRoomCounts] = useState(1);

  const { addCleanup } = useAsyncCleanup();

  const [isLoading, setIsLoading] = useState(true);
  const [deals, setDeals] = useState<EventDealsResponse[]>([]);
  const [eventId, setEventId] = useState<string>('');
  const { alert } = useContext(AlertContext);
  // const [filterOpen, setFilterOpen] = useState(false);
  const [sortOption, setSortOption] = useState('rank');

  useEffect(() => {
    (async () => {
      const eventId = getUrlParam('source');
      const filterTo = getUrlParam('to');
      const filterFrom = getUrlParam('from');
      const filterGuests = getUrlParam('guests') === '' ? 0 : parseInt(getUrlParam('guests'));
      const filterRooms = getUrlParam('rooms');
      const filterSortOpt = getUrlParam('sortby') === '' ? 'rank' : getUrlParam('sortby');
      setSortOption(filterSortOpt);

      if (!currentUser) {
        let kaId = getCookieValue('ka-id');
        let kaCreated = getCookieValue('ka-created-at');

        if (!kaId || !kaCreated) {
          const now = new Date();
          kaId = customNanoIdFunc();
          kaCreated = `${now.getFullYear()}-${
            now.getMonth() + 1
          }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}`;
          document.cookie = `ka-id=${kaId}`;
          document.cookie = `ka-created-at=${kaCreated}`;
        }

        const [_sendVisitorData, _sendVisitorClean] = sendVisitorViewData(
          client,
          eventId,
          'eventdeals',
          kaId,
          kaCreated
        );
        addCleanup(_sendVisitorClean);
        await _sendVisitorData;
      }

      const [handle, cancel] = getEventDetails(client, eventId);
      addCleanup(cancel);

      const sourceDetails = await handle;
      if (sourceDetails) {
        document.title = `${process.env.REACT_APP_COMPANY_NAME} & ${sourceDetails.name}`;

        let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

        let appleLink: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
        if (!appleLink) {
          appleLink = document.createElement('link');
          appleLink.rel = 'apple-touch-icon';
          document.getElementsByTagName('head')[0].appendChild(appleLink);
        }
        appleLink.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

        setEventId(eventId);
        setSourceDetails(sourceDetails);
        setGuests(filterGuests);

        if (filterRooms) {
          setMoreRooms(true);
          setRoomCounts(Number(filterRooms));
        }

        // if (filterFrom && filterTo) {
        //   setCheckInDate(getStringToDate(filterFrom));
        //   setCheckOutDate(getStringToDate(filterTo));
        // } else {
        setCheckInDate(getStringToDate(filterFrom || sourceDetails.minDate || sourceDetails.startAt));
        setCheckOutDate(getStringToDate(filterTo || sourceDetails.maxDate || sourceDetails.endAt));
        setMinDate(getStringToDate(sourceDetails.minDate || sourceDetails.startAt));
        setMaxDate(getStringToDate(sourceDetails.maxDate || sourceDetails.endAt));
        // }
      }

      try {
        let deals;
        if (filterRooms) {
          deals = await getEventDealsWrapper(
            client,
            eventId,
            filterSortOpt,
            filterTo,
            filterFrom,
            filterGuests,
            Number(filterRooms)
          );
        } else {
          deals = await getEventDealsWrapper(client, eventId, filterSortOpt, filterTo, filterFrom, filterGuests);
        }

        setDeals(deals as unknown as EventDealsResponse[]);

        const [guests_handle, cancel_handle] = getEventGuestCounts(client, eventId);
        const guestCnts = await guests_handle;
        addCleanup(cancel_handle);
        setGuestCounts(guestCnts ?? []);
      } catch (e) {
        setIsLoading(false);
        if (isLoadErr(e)) {
          alert(e.error, 'error');
        }
      }

      setIsLoading(false);
    })();
  }, []);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const loadingPleaseWait = (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '40px' }}>
      <CircularProgress />
      <div style={{ fontSize: '2em', marginTop: '24px', color: '#1086f0' }}>Loading hotel deals...</div>
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <img src={process.env.REACT_APP_COMPANY_WHITE_BRAND} alt="logo" style={{ height: '50px' }} />
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        {isLoading ? (
          loadingPleaseWait
        ) : (
          <EventDetailContainer
            sourceDetails={sourceDetails}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            minDate={minDate}
            maxDate={maxDate}
            deals={deals}
            eventId={eventId}
            guests={guests}
            sortby={sortOption}
            guestCounts={guestCounts}
            rooms={moreRooms ? roomCounts : null}
          />
        )}
      </Container>

      {/* {filterOpen && (
        <FilterModal
          open={filterOpen}
          checkIn={checkInDate}
          checkOut={checkOutDate}
          guests={guests}
          sourceId={eventId}
          guestCounts={guestCounts}
          handleOpen={setFilterOpen}
        />
      )} */}
    </LocalizationProvider>
  );
};

/* eslint-enable */

export { getEventDealsWrapper };
export { getUrlParam };
//export { MOCK_EVENT_DEALS };
export { EventDealsView };
