import React from 'react';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import one from '../../assets/howItWorks/one.svg';
import two from '../../assets/howItWorks/two.svg';
import three from '../../assets/howItWorks/three.svg';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from '@micro-frontends/shared-components';

const CONTENT = [
  {
    title: 'Choose Destination',
    text: 'Let us know where you’d like to go and the hotel you’d like to stay at.',
    caption: 'No dates needed',
  },
  {
    title: 'Choose Option',
    text: 'Pay only a small advance to secure your price and discount.',
    caption: 'Guaranteed discount',
  },
  {
    title: 'Complete Booking',
    text: 'Travel Options are valid for up to 5 years. Book dates whenever you’re ready.',
    caption: 'Commitment free',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  content: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(3),

    '& h2': {
      paddingBottom: theme.spacing(1),
    },
    '& p': {
      minHeight: theme.spacing(10),
    },
    '& h3': {
      color: COLORS.blue,
      textTransform: 'uppercase',
    },
  },
}));

export type Index = 0 | 1 | 2;
interface Props {
  index: Index;
  className?: string;
}

const HowItWorks: React.FC<Props> = ({ index, className }) => {
  const classes = useStyles();

  const getNumber = (idx: Index) => {
    switch (idx) {
      case 0:
        return <img src={one} alt="one" />;
      case 1:
        return <img src={two} alt="two" />;
      case 2:
        return <img src={three} alt="three" />;
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      {getNumber(index)}
      <div className={classes.content}>
        <Typography variant="h2">{CONTENT[index].title}</Typography>
        <Typography variant="body1">{CONTENT[index].text}</Typography>
        <Typography variant="h3">{CONTENT[index].caption}</Typography>
      </div>
    </div>
  );
};

export { HowItWorks, CONTENT };
