import React from 'react';

import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { DealCard, DealSubmit } from '../_components';
import { DealInputs } from './DealInputs';
import { DealResult } from './DealResult';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from '@micro-frontends/shared-components';
import { useDealContext } from '../../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  info: {
    display: 'flex',

    '& .MuiSvgIcon-root': {
      color: COLORS.blue,
    },
    '& span': {
      marginLeft: theme.spacing(1),
    },
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const DealBodyCalc: React.FC = () => {
  const { dealData } = useDealContext();
  const classes = useStyles();

  return (
    <DealCard>
      <Fade in={!!dealData}>
        <div className={classes.container}>
          <DealInputs />
          <DealResult />
          <Typography variant="body1" className={classes.info}>
            <InfoIcon />
            <span>Remaining balance will be paid when booking</span>
          </Typography>
          <DealSubmit />
        </div>
      </Fade>
    </DealCard>
  );
};

export { DealBodyCalc };
