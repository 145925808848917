import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAuthContext } from '@micro-frontends/auth-context';
import { Select, DialogTitle, Dialog, DialogActions, DialogContent, FormControl, MenuItem } from '@mui/material';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import {
  CreatingBookingMoreDealsForm,
  BookingMoreDealsReasonForm,
  getBookingMoreDealReasons,
} from '@micro-frontends/vacayz-api-client';
import { useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';

import { Button } from '@micro-frontends/shared-components';

interface Props {
  bookingMoreDeals: CreatingBookingMoreDealsForm;
  open: boolean;
  requireMoreDeals: (moreDealsForm: CreatingBookingMoreDealsForm) => void;
  setCloseModal: (isOpen: boolean) => void;
}

const MoreDealsModal: React.FC<Props> = ({ bookingMoreDeals, open, requireMoreDeals, setCloseModal }) => {
  const [notes, setNotes] = useState('');
  const [reasonList, setReasonList] = useState<BookingMoreDealsReasonForm[] | null>(null);
  const { client } = useAuthContext();
  const [moreDeals] = useState<CreatingBookingMoreDealsForm>(bookingMoreDeals);
  const { addCleanup, isMounted } = useAsyncCleanup();
  const [reasonId, setReasonId] = useState(0);
  const { alert } = useContext(AlertContext);

  const getReasonList = useCallback(async () => {
    try {
      const [_request, cancel] = getBookingMoreDealReasons(client);
      addCleanup(cancel);
      const request = await _request;
      if (!isMounted()) return;
      if (!request) return alert('Request Not Found', 'error');

      if (!isMounted()) return;
      setReasonList(request);
    } catch (err) {
      alert('Request Not Found', 'error');
    }
  }, [addCleanup, alert, client, isMounted]);

  useEffect(() => {
    getReasonList();
  }, [getReasonList]);

  const onSubmit = () => {
    if (reasonId < 1) {
      alert('Please select the reason for more offer.', 'error');
      return;
    }
    const requireDeals = moreDeals;
    requireDeals.reason_description = notes;
    requireDeals.reason_id = reasonId;
    requireMoreDeals(requireDeals);
    setCloseModal(false);
  };

  const selectReason = (value: number) => {
    setReasonId(value);
  };

  return (
    <Dialog open={open} onKeyDown={(e) => e.stopPropagation()}>
      <DialogTitle>Require More Deals</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ margin: '10px' }} component="div">
          {'Do you really want to cancel this booking reqest?'}
        </Typography>

        <FormControl variant="outlined" size="small" color="secondary" style={{ width: '100%' }}>
          <Select
            value={reasonId}
            onChange={(e) => selectReason(typeof e.target.value === 'number' ? e.target.value : 0)}
          >
            {reasonList?.map((reason, i) => (
              <MenuItem key={i} value={reason.id}>
                {reason.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body1" sx={{ fontWeight: 'bold', padding: '10px', marginRight: '15px' }} component="div">
          {'Description'}
        </Typography>
        <NotesArea value={notes} onChange={(e) => setNotes(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setCloseModal(false);
          }}
          size="small"
          variant="text"
        >
          CLOSE
        </Button>
        <Button onClick={onSubmit} size="small">
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NotesArea = styled.textarea`
  border: none;
  background: rgba(238, 238, 238, 0.65);
  width: 550px;
  height: 150px;
  text-indent: 10px;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 25px;
  :focus {
    outline: none;
    border: none;
    background: #e5e5e5;
  }
`;

export default MoreDealsModal;
export { MoreDealsModal };
