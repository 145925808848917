import React, { useCallback, useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Typography, TextField, Box, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { getPlacesPredictions, LocationOptions } from './google.utils';

const useOptionStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'inherit',
    },
    'location-icon': {
      marginRight: theme.spacing(2),
    },
  })
);

interface LocationAutocompleteProps {
  className?: string;
  placeholder: string;
  name: string;
  label: string;
  error: (string | undefined)[];
  disabled?: boolean;
  onChange: (city: string, stateAndCountry: string) => void;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = (props: LocationAutocompleteProps) => {
  const { className, placeholder, name, label, error, disabled, onChange } = props;
  const [locationOptions, setLocationOptions] = useState<LocationOptions[]>([]);
  const inputChange = useCallback(async (address: string) => {
    if (address) {
      const predictions = await getPlacesPredictions(address);
      if (predictions === undefined) return;
      setLocationOptions(predictions);
    }
  }, []);
  const optionStyles = useOptionStyles();

  return (
    <Autocomplete
      autoComplete
      id="location-autocomplete"
      className={className ?? ''}
      options={locationOptions}
      onInputChange={(_, newValue: string) => {
        inputChange(newValue.trim());
      }}
      disabled={disabled}
      onChange={(_, value) => {
        onChange(value?.name ?? '', value?.secondary ?? '');
      }}
      getOptionLabel={(option) => (option ? option.description : '')}
      noOptionsText="Type in your address"
      isOptionEqualToValue={(_, value) => (value ? true : false)}
      renderInput={(params) => (
        <TextField
          name={name}
          label={disabled ? placeholder : label}
          variant="outlined"
          placeholder={placeholder}
          error={error.length > 0}
          helperText={error}
          {...params}
        />
      )}
      renderOption={(element, option) => {
        const { description, name: optionName, secondary } = option as LocationOptions;

        return (
          <Box component="li" className={`location-option ${optionStyles.root}`} key={description} {...element}>
            <LocationOnIcon className={`location-icon ${optionStyles['location-icon']}`} color="primary" />
            <Grid className="option-description">
              <Grid className="option-name">
                <Typography>{optionName}</Typography>
              </Grid>
              <Grid className="option-secondary">
                <Typography color="primary">{secondary}</Typography>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    />
  );
};

export { LocationAutocomplete };
