import {
  CollectionData,
  CollectionDescription,
  ByBrandCollection,
  CollectionSearchOptions,
  CollectionSearchResult,
  CreateCollectionDescription,
  LimitedCreateCollection,
} from './typings';
import { IncludeNonApproved, PaginatedResult, VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';
import { EntityImage } from '..';

const updateCollection = (
  client: VacayzClient,
  collectionId: number,
  fields: LimitedCreateCollection
): VacayzResponse<CollectionData> => {
  const url = `api/v1/collections/${collectionId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<CollectionData>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const archiveCollection = (client: VacayzClient, collectionId: number): VacayzResponse<boolean> => {
  const url = `api/v1/collections/${collectionId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.delete<boolean>(url, {
      cancelToken: source.token,
    });
    return response?.status >= 200 && response?.status < 300;
  };
  return [handleCancel(promise), source.cancel];
};

const createCollection = (client: VacayzClient, fields: LimitedCreateCollection): VacayzResponse<CollectionData> => {
  const url = 'api/v1/collections/';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<CollectionData>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getCollectionByIdExtended = (client: VacayzClient, collectionId: number): VacayzResponse<CollectionData> => {
  const url = `api/v1/collections/${collectionId}/extended`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<CollectionData>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

// Collection Description calls:

const createCollectionDescription = (
  client: VacayzClient,
  collectionId: number,
  fields: CreateCollectionDescription
): VacayzResponse<CollectionDescription> => {
  const url = `api/v1/collections/${collectionId}/description`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<CollectionDescription>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getCollectionDescription = (
  client: VacayzClient,
  collectionId: number
): VacayzResponse<CollectionDescription> => {
  const url = `api/v1/collections/${collectionId}/description`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<CollectionDescription>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

// Collection Search calls:
const searchCollection = (
  client: VacayzClient,
  options: CollectionSearchOptions & IncludeNonApproved
): VacayzResponse<CollectionSearchResult[]> => {
  const qs = toQs({
    q: options.query,
    b: options.brandId,
    l: options.limit,
    includes_non_approved: options.includeNonApproved ? 1 : null,
  });
  const url = `api/v1/collections/search${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<CollectionSearchResult[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getCollectionsByBrandId = (
  client: VacayzClient,
  isArchived: 0 | 1,
  brandId?: number,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<ByBrandCollection>> => {
  const qs = toQs({ page: page, per_page: perPage, is_archived: isArchived });
  const url = `api/v1/brands/${brandId ?? 'me'}/collections${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<ByBrandCollection>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getCollectionImages = (
  client: VacayzClient,
  collectionId: number,
  page?: number,
  perPage?: number,
  includeNonApproved?: boolean
): VacayzResponse<PaginatedResult<EntityImage>> => {
  const qs = toQs({ page: page, per_page: perPage, includes_non_approved: includeNonApproved ? 1 : null });
  const url = `api/v1/collections/${collectionId}/images${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<EntityImage>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export {
  getCollectionDescription,
  createCollectionDescription,
  getCollectionByIdExtended,
  createCollection,
  searchCollection,
  updateCollection,
  getCollectionsByBrandId,
  archiveCollection,
  getCollectionImages,
};
