import React from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, COLORS, FONT_WEIGHT } from '@micro-frontends/shared-components';
import { DealPurchaseContainer } from '../../containers/DealPurchase/DealPurchaseContainer';
import { DealPurchaseOption } from '../../containers/DealPurchase/DealPurchaseOption';

import { Theme, alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  transferFundsInfo: {
    maxWidth: 711,
    width: '100%',
    margin: `${theme.spacing(4)} auto 0`,
    fontSize: theme.typography.pxToRem(16),

    '& .row': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${alpha(COLORS.grey, 0.3)}`,
      padding: '8px 0',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    '& .title': {
      color: theme.palette.secondary.main,
      fontWeight: FONT_WEIGHT.medium,
    },
  },
}));

const DealConfirmView: React.FC = () => {
  const classes = useStyles();

  return (
    <DealPurchaseContainer>
      <Alert severity="success">
        <Typography variant="body1" gutterBottom>
          {`Thank you for choosing ${process.env.REACT_APP_COMPANY_NAME} Travel Options: the best way to save money while keeping everything flexible!`}
        </Typography>
        <Typography variant="body1" gutterBottom>
          To complete your purchase - please wire the selected sum to the account detailed below. This information has
          also been sent to your email inbox.
        </Typography>
        <Typography variant="body1">
          When the payment is received, your Travel Option will be delivered via email and ready to be redeemed!
        </Typography>
      </Alert>
      <DealPurchaseOption />
      <Card fullWidth>
        <Box pb={2}>
          <Typography variant="h2" align="center" color="secondary">
            Transfer Funds
          </Typography>
        </Box>
        <Typography variant="body1" align="center" gutterBottom>
          {`Your payment must be transferred to ${process.env.REACT_APP_COMPANY_NAME} bank account.`}
        </Typography>
        <Typography variant="body1" align="center">
          Account details for IBAN transfer (fast rate lock):
        </Typography>
        <Box className={classes.transferFundsInfo}>
          <Box className="row">
            <Box className="title">Sum</Box>
            <Box>$ 350</Box>
          </Box>
          <Box className="row">
            <Box className="title">Transfer to</Box>
            <Box>{process.env.REACT_APP_COMPANY_NAME}</Box>
          </Box>
          <Box className="row">
            <Box className="title">Account Number</Box>
            <Box>888963D5674</Box>
          </Box>
          <Box className="row">
            <Box className="title">Bank Name</Box>
            <Box>34567</Box>
          </Box>
          <Box className="row">
            <Box className="title">Routing Number</Box>
            <Box>89234</Box>
          </Box>
          <Box className="row">
            <Box className="title">Swift Code</Box>
            <Box>6639461656</Box>
          </Box>
        </Box>
      </Card>
    </DealPurchaseContainer>
  );
};

export { DealConfirmView };
