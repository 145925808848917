import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Container, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Header } from '../Header/Header';
import { DealCard } from './deal_card';
import { getBudgetToString } from './requests';
import {
  BookingDealNew,
  BookingRoom,
  sendBookingDealSelect,
  calcDateDiff,
  getStringToDate,
  formatDateRangeToString,
} from '@micro-frontends/vacayz-api-client';
import { useAuthContext } from '@micro-frontends/auth-context';
import { useAsyncCleanup } from '@micro-frontends/shared-components';

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },

  title: {
    marginTop: '100px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
});

const DealsView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { room, requestId, dest, enable } = useLocation();
  const [selectedRoom, setSelectedRoom] = useState<BookingRoom | null>(null);
  const { client } = useAuthContext();
  const { addCleanup, isMounted } = useAsyncCleanup();

  useEffect(() => {
    setSelectedRoom(room);
  }, [room]);

  const sendSelectedDeal = useCallback(
    async (reqId: string, roomId: number, dealId: number) => {
      try {
        const _selected = [{ roomId, dealId }];
        const [_request, cancel] = sendBookingDealSelect(client, reqId, _selected);
        addCleanup(cancel);
        await _request;
        if (!isMounted() || !_request) return;
      } catch (err) {
        console.error('failed to send booking deal selected event');
      }
    },
    [addCleanup, client, isMounted]
  );

  const goHome = useCallback(async () => {
    history.push(`/new/requests/${requestId}`);
  }, [requestId, history]);

  const handleSelectDeal = useCallback(
    async (dealId: number) => {
      if (selectedRoom) {
        const _room = { ...selectedRoom };
        for (let i = 0; i < _room.deals.length; i++) {
          if (_room.deals[i].id === dealId) {
            _room.deals[i].isDealSelected = true;
            await sendSelectedDeal(requestId, selectedRoom.id ?? 0, _room.deals[i].id ?? 0);
            goHome();
          }
        }
      }
    },
    [selectedRoom, requestId, goHome, sendSelectedDeal]
  );

  return (
    <Container maxWidth="lg">
      <Grid className={classes.root}>
        <Header />
        {selectedRoom && (
          <React.Fragment>
            <Grid className={classes.title}>
              <IconButton onClick={goHome}>
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                {`${dest}. ${formatDateRangeToString(
                  getStringToDate(selectedRoom.checkIn),
                  getStringToDate(selectedRoom.checkOut)
                )}. ${getBudgetToString(selectedRoom.budget)}`}
              </Typography>
            </Grid>
            <Grid container justifyContent="center" spacing={2}>
              {selectedRoom.deals?.map((deal: BookingDealNew, i: number) => (
                <Grid item xs={12} key={i}>
                  <DealCard
                    selected={deal.isDealSelected ?? false}
                    deal={deal}
                    nights={calcDateDiff(selectedRoom.checkOut, selectedRoom.checkIn)}
                    quantity={selectedRoom.quantity}
                    selectDeal={handleSelectDeal}
                    enable={deal.isDealSelected ? false : enable}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Container>
  );
};

export default DealsView;
export { DealsView };
