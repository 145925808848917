import React from 'react';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { Button, FONT_WEIGHT, IconButton } from '@micro-frontends/shared-components';
import { DealSubmit } from '../../_components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDealContext } from '../../../../common/contexts';
import { currencyValue } from '../../../../common/utils/Format';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 'auto',
  },
  total: {
    fontWeight: FONT_WEIGHT.medium,
    fontSize: theme.typography.pxToRem(18),
    padding: `0 ${theme.spacing(0.5)}`,
  },
}));

interface Props {
  handleModal: (val: boolean) => void;
}

const DealMobCalcBody: React.FC<Props> = ({ handleModal }) => {
  const classes = useStyles();
  const { numberOfNights, dealData, calculator } = useDealContext();

  return (
    <>
      {numberOfNights ? (
        <>
          <IconButton onClick={() => handleModal(true)}>
            <EditIcon />
          </IconButton>
          <Box className={classes.total}>
            {currencyValue(calculator?.paynow, dealData?.collectionCurrency)} / {numberOfNights}{' '}
            {numberOfNights === '1' ? 'night' : 'nights'}
          </Box>
          <DealSubmit />
        </>
      ) : (
        <Button className={classes.button} onClick={() => handleModal(true)}>
          Add Number of Nights
        </Button>
      )}
    </>
  );
};

export { DealMobCalcBody };
