import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DateRange, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '300px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '300px',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
    },
  },
}));

interface Props {
  open: boolean;
  destinationIdx: number;
  roomIdx: number;
  checkin: Date;
  checkout: Date;
  updateCheckDates: (destinationIdx: number, roomIndex: number, datein: Date, dateout: Date) => void;
}

const CheckDateModal: React.FC<Props> = ({ open, destinationIdx, roomIdx, checkin, checkout, updateCheckDates }) => {
  const today = new Date();
  const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const classes = useStyles();
  const [cstartDate, setCurrentStartDate] = useState(checkin);
  const [cendDate, setCurrentEndDate] = useState(checkout);

  const [checkDate, setCheckDate] = useState([
    {
      startDate: checkin,
      endDate: checkout,
      key: 'selection',
    },
  ]);

  const handleChangeDate = (ranges: RangeKeyDict) => {
    const rangeStartDate = ranges.selection.startDate || new Date();
    const rangeEndDate = ranges.selection.endDate || new Date();

    const compareDate = (prev: Date, next: Date) => {
      return prev.getDate() !== next.getDate() || prev.getMonth() !== next.getMonth();
    };

    if (rangeStartDate > rangeEndDate || rangeStartDate < currentDate) return;

    if (compareDate(cstartDate, rangeStartDate)) {
      rangeStartDate.setHours(0, 0, 0, 0);
      setCurrentStartDate(rangeStartDate);
    }

    if (compareDate(cendDate, rangeEndDate)) {
      rangeEndDate.setHours(0, 0, 0, 0);
      setCurrentEndDate(rangeEndDate);
    }

    setCheckDate([
      {
        startDate: rangeStartDate,
        endDate: rangeEndDate,
        key: 'selection',
      },
    ]);
  };

  const onCheckDate = () => {
    updateCheckDates(destinationIdx, roomIdx, cstartDate, cendDate);
  };

  return (
    <Dialog open={open} maxWidth={'md'} id="checkdatemodal">
      <DialogTitle>Check In - Check Out</DialogTitle>
      <DialogContent className={classes.root}>
        <DateRange
          editableDateInputs={true}
          onChange={(item) => handleChangeDate(item)}
          moveRangeOnFirstSelection={true}
          ranges={checkDate}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCheckDate} fullWidth>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckDateModal;
export { CheckDateModal };
