/* eslint-disable */
import React from 'react';
import { Redirect } from 'react-router-dom';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const LogupRedirect: React.FC = () => {
  const queryString = document.location.search;
  const urlParams = new URLSearchParams(queryString);
  const url = urlParams.get('url') || '';

  return <Redirect to={url} />;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/* eslint-enable */

export { LogupRedirect };
