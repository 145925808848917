import React, { useState, useEffect, useCallback, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import {
  CenteredProgress,
  COLORS,
  TextField,
  Title,
  useFormErrors,
  AlertContext,
  useAsyncCleanup,
} from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import { getUserCreditTotalInfo, UserCreditTotalInfo } from '@micro-frontends/vacayz-api-client';

import { Typography, FormControlLabel, Grid, Switch } from '@mui/material';
import { has } from 'lodash';
import { CardElement } from '@stripe/react-stripe-js';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';
import { ConfirmText } from './ConfirmText';
import { SummaryItem } from '../../../Deals/OfferDeals.view';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import * as StripeJs from '@stripe/stripe-js';

const useStyles = makeStyles((theme) => ({
  creditCardText: {
    marginTop: '20px',
    color: 'red',
    fontWeight: 'bold',
  },
  titles: {
    color: COLORS.blue,
  },
  titleContainer: {
    justifyContent: 'flex-start',
  },
  centerTitleContainer: {
    justifyContent: 'center',
  },

  payButton: {
    width: 155,
  },
}));

export interface BookingDealPaymentForm {
  guestName: string;
  address: string;
  country: string;
  phone: string;
}

interface Props {
  submitRequest: (form: BookingDealPaymentForm, credit: number) => void;
  loading: boolean;
  isPaid: boolean;
  totalAmount: number;
}
const TITLE_SIZE = 22;

const BookingPaymentFormView: React.FC<Props> = ({ submitRequest, loading, totalAmount, isPaid }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<StripeJs.PaymentRequest>();
  const [validCard, setValidCard] = useState(false);
  const { isMounted, addCleanup } = useAsyncCleanup();
  const [creditTotalInfo, setCreditTotalInfo] = useState<UserCreditTotalInfo | null>(null);
  const [creditAmount, setCreditAmout] = useState(0);

  const classes = useStyles();
  const { currentUser, client } = useAuthContext();
  const {
    handleSubmit,
    errors,
    register,
    formState: { isDirty },
  } = useForm<BookingDealPaymentForm>();
  const { alert } = useContext(AlertContext);
  const { hasErrors, getMessages } = useFormErrors('BookingDealPaymentForm');
  const [phone, setPhone] = useState<string>(currentUser?.phone || '');

  const getUserCreditInformation = useCallback(async () => {
    try {
      const [_resCreditTotal, _cancel] = getUserCreditTotalInfo(client);
      addCleanup(_cancel);
      const res_data = await _resCreditTotal;
      if (!isMounted()) return;
      if (res_data && res_data.amount / 100 > 0) {
        const _creditAmount = res_data.amount / 100;
        setCreditTotalInfo(res_data);
        setCreditAmout(totalAmount > _creditAmount ? _creditAmount : totalAmount);
      }
    } catch (err) {}
  }, [addCleanup, client, isMounted, setCreditTotalInfo, totalAmount]);

  const onSubmit = useCallback(
    (form: BookingDealPaymentForm) => {
      if (creditAmount < totalAmount && !validCard) {
        alert('You must fill the card information');
        return;
      }

      if (!phone) {
        alert('You must fill your phone number in user settings');
        return;
      }
      if (!currentUser?.lastName) {
        alert('You must fill your full name in user settings');
        return;
      }
      form.phone = phone;
      form.guestName = currentUser.email;
      submitRequest(form, creditAmount);
      // reset();
    },
    [alert, submitRequest, currentUser, phone, validCard, creditAmount, totalAmount]
  );

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Check Apple Pay',
          amount: Math.round(totalAmount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on('paymentmethod', async () => {
        handleSubmit(onSubmit);
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, totalAmount, handleSubmit, onSubmit]);

  useEffect(() => {
    getUserCreditInformation();
  }, [getUserCreditInformation]);

  const handleCardChange = (event: StripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) {
      setValidCard(true);
    } else {
      setValidCard(false);
    }
    return;
  };

  const handleChangeCredit = () => {
    if (creditAmount === 0 && creditTotalInfo) {
      const _creditAmount = creditTotalInfo.amount / 100;
      setCreditAmout(totalAmount > _creditAmount ? _creditAmount : totalAmount);
    } else {
      setCreditAmout(0);
    }
  };

  return (
    <Container>
      <BillingInformation>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
          {'Billing Info'}
        </Typography>

        <SummaryItem>
          <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
            {'First Name'}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#56bd6d' }} component="div">
            {currentUser?.firstName ?? ''}
          </Typography>
        </SummaryItem>

        <SummaryItem>
          <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
            {'Last Name'}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#56bd6d' }} component="div">
            {currentUser?.lastName ?? ''}
          </Typography>
        </SummaryItem>

        <SummaryItem>
          <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
            {'Email Address'}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#56bd6d' }} component="div">
            {currentUser?.email}
          </Typography>
        </SummaryItem>
      </BillingInformation>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RightContent>
          <Title fontSize={TITLE_SIZE} textClassName={classes.titles} containerClassName={classes.centerTitleContainer}>
            Payment Method
          </Title>
          {creditTotalInfo && (
            <TextFieldContainer>
              <Grid container marginBottom={1}>
                <Grid item xs={6}>
                  <Typography variant="body1" component="span" marginRight={2}>
                    Credit
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch defaultChecked checked={creditAmount > 0 ? true : false} onChange={handleChangeCredit} />
                    }
                    label=""
                  />
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography>{`US$ ${Number(creditAmount.toFixed(2)).toLocaleString()}`}</Typography>
                </Grid>
              </Grid>
            </TextFieldContainer>
          )}

          {creditAmount < totalAmount && (
            <TextFieldContainer>
              <Grid container marginBottom={1}>
                <Grid item xs={6}>
                  <Typography variant="body1" component="span" marginRight={2}>
                    Card
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography>{`US$ ${Number((totalAmount - creditAmount).toFixed(2)).toLocaleString()}`} </Typography>
                </Grid>
              </Grid>
              <Box px={1} py={1.5} borderRadius="4px" borderColor="rgba(0, 0, 0, 0.23)" border="1px solid">
                <CardElement onChange={handleCardChange} />
              </Box>
            </TextFieldContainer>
          )}

          <BillingRight>
            <RightBillingTextFieldContainer>
              <TextField
                label="Address"
                name="address"
                inputRef={register({
                  required: 'Address is required',
                  maxLength: { value: 512, message: 'Address must be shorter than 512 characters' },
                })}
                error={hasErrors('address') || has(errors, 'address')}
                helperText={[...getMessages('address'), errors.address?.message]}
              />
            </RightBillingTextFieldContainer>

            <RightBillingTextFieldContainer>
              <TextField
                label="Country/Region"
                name="country"
                inputRef={register({
                  required: 'Country is required',
                  maxLength: { value: 512, message: 'Country must be shorter than 512 characters' },
                })}
                error={hasErrors('country') || has(errors, 'country')}
                helperText={[...getMessages('country'), errors.country?.message]}
              />
            </RightBillingTextFieldContainer>

            <RightBillingTextFieldContainer>
              {currentUser?.phone ? (
                <TextField disabled label="Phone" value={phone} />
              ) : (
                <TextField
                  name="phone"
                  label="Phone"
                  value={phone ?? ''}
                  onChange={({ target: { value } }) => setPhone(value)}
                  error={hasErrors('phone')}
                  helperText={[...getMessages('phone')]}
                />
              )}
            </RightBillingTextFieldContainer>
          </BillingRight>
        </RightContent>
        <ConfirmContainer>
          <ConfirmText />
          {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
          <BottomConfirmContainer>
            <SubTextContainer>
              <Typography variant="body2">Don’t worry! we won&#39;t save your payment information.</Typography>
            </SubTextContainer>
            <ConfirmButton>
              {isPaid ? (
                <PaidButton>Paid</PaidButton>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.payButton}
                  disabled={
                    !isDirty ||
                    loading ||
                    !phone ||
                    (creditTotalInfo && creditTotalInfo.amount / 100 > totalAmount ? false : !validCard)
                  }
                >
                  {loading ? <CenteredProgress /> : 'Pay'}
                </Button>
              )}
            </ConfirmButton>
          </BottomConfirmContainer>
        </ConfirmContainer>
      </Form>
    </Container>
  );
};

export const BillingInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 125px;
  box-shadow: -3px 3px 12px 1px #00000014;
  width: 100%;
  background: #ffffff;
  padding: 20px;
`;

const Container = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  flex-direction: column;
`;

const RightContent = styled.div`
  width: 600px;
  @media screen and (max-width: 767px) {
    width: 400px;
  }
`;

const TextFieldContainer = styled.div`
  padding-left: 7%;
  padding-right: 7%;
`;

const RightBillingTextFieldContainer = styled(TextFieldContainer)`
  padding-top: 20px;
`;

const BillingRight = styled.div`
  margin-top: 35px;
`;

const ConfirmContainer = styled.div`
  margin-top: 50px;
`;

const BottomConfirmContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

const SubTextContainer = styled.div`
  flex: 1;
`;

const ConfirmButton = styled.div`
  flex: 1;
  display: flex;
  padding-right: 150px;
  justify-content: flex-end;
`;

const PaidButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: white;
  width: 125px;
  height: 50px;
  font-size: 18px;
  background-color: green;
  transition: all 0.1s linear;
  pinter-events: none;
  opacity: 0.7;
`;

export { BookingPaymentFormView };
