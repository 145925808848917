import { useCallback, useEffect, useState } from 'react';
import { useAsyncCleanup, useLogger } from '@micro-frontends/shared-components';
import { getPayoutMethods, PayoutMethodStatus } from '@micro-frontends/vacayz-api-client';
import { useDealContext } from '../contexts';
import { useAuthContext } from '@micro-frontends/auth-context';

export interface PurchaseWithCard {
  loading: boolean;
  isCardPossible: boolean;
}

export const CONNECTED: PayoutMethodStatus = 'CONNECTED';

const usePurchaseWithCard = (): PurchaseWithCard => {
  const { client } = useAuthContext();
  const { isMounted, addCleanup } = useAsyncCleanup();
  const [loading, setLoading] = useState(false);
  const logger = useLogger('usePurchaseWithCard.hook');
  const [isCardPossible, setIsCardPossible] = useState<boolean>(false);
  const { dealData } = useDealContext();

  const fetchPayoutMethod = useCallback(async () => {
    if (!dealData || loading) {
      return;
    }
    setLoading(true);
    const [_payoutMethod, cancel] = getPayoutMethods(client, { brandId: dealData.brandId });
    addCleanup(cancel);
    const payoutMethod = await _payoutMethod;
    if (!isMounted()) {
      return;
    }
    setIsCardPossible(payoutMethod?.stripeAccountStatus === CONNECTED);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCleanup, client, dealData?.brandId, isMounted]);

  useEffect(() => {
    try {
      fetchPayoutMethod();
    } catch (error) {
      if (error) {
        logger.error(error?.message ?? error);
      }
    }
  }, [fetchPayoutMethod, logger]);

  return { loading, isCardPossible };
};

export default usePurchaseWithCard;
export { usePurchaseWithCard };
