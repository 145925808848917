import styled from 'styled-components';

export const TableHeaderContainer = styled.thead`
  height: 65px;
`;

export const TableCell = styled.th<{ centered?: boolean }>`
  text-align: start;
  height: 65px;
`;

export const TableHeader = styled.th`
  text-align: left;
  font-weight: bold;
  font-size: 15px;
`;

export const Table = styled.table`
  display: table;
  width: 100%;
  padding-bottom: 25px;
`;

export const CustomInput = styled.input<{ activated?: boolean }>`
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  text-indent: 3px;
  height: 40px;
  outline: none;
  border: none;
  background: ${({ activated }) => (activated ? 'transparent' : '#EEEEEEA5')};
  cursor: text;
  border-radius: 20px;
  transition: all 0.1s ease-in;
  :focus {
    background: #e5e5e5;
  }
`;
