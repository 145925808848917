import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiSkeleton from '@mui/material/Skeleton';
import { MainContainer } from '@micro-frontends/shared-components';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const Skeleton = withStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    transform: 'none',
    borderRadius: 6,
  },
}))(MuiSkeleton);

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: '100%',
    height: 153,
  },
  gallery: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    height: 166,

    '& > div': {
      flex: `0 0 277px`,
      maxWidth: 277,
      marginRight: theme.spacing(1),
    },
  },
  body: {
    '& > div': {
      borderRadius: theme.spacing(1),
      overflow: 'hidden',
      height: 355,
    },
  },
}));

interface Props {
  className?: string;
}

const DealSkeleton: React.FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <MainContainer>
      <Box className={classes.header}>
        <Skeleton animation="wave" />
      </Box>
      <Box className={classes.gallery} my={3}>
        {[1, 2, 3, 4, 5].map((num, i) => (
          <div key={num}>
            <Skeleton animation="wave" />
          </div>
        ))}
      </Box>
      <Grid container className={classes.body} spacing={3}>
        <Grid item xs={12} md={8}>
          <Skeleton animation="wave" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton animation="wave" />
        </Grid>
      </Grid>
      <Box pt={3} />
    </MainContainer>
  );
};

export { DealSkeleton };
