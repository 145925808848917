import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useStyles } from './NotFound.styles';
import Button from '@mui/material/Button';
import { Routing } from '../../common/constants/routing';

const NotFoundView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const goBackMarketplace = () => {
    history.push(Routing.root);
  };

  return (
    <NotificationContainer className={classes.root}>
      <NotInterestedIcon color="primary" style={{ fontSize: 142 }} />
      <Title>Whoops!</Title>
      <Title>This page is unavailable</Title>
      <Content>You don&apos;t have access or this link doesn&apos;t exist anymore.</Content>
      <Button variant="contained" color="primary" style={{ color: 'white', marginTop: 30 }} onClick={goBackMarketplace}>
        Go Back Marketplace
      </Button>
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 26px;
  margin: 10px;
`;

const Content = styled.div`
  margin-top: 30px;
`;

export { NotFoundView };
