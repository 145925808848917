import React from 'react';
import clsx from 'clsx';

import Container, { ContainerProps } from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { DefaultTheme, PREFIX, HEADER_HEIGHT } from '../../themes';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      left: 0,
      top: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#152A40',
      zIndex: theme.zIndex.appBar,
      maxWidth: '100%',
      height: HEADER_HEIGHT,

      '&.MuiContainer-root': {
        maxWidth: '100%',
        display: 'flex',
      },
    },
  }),
  { defaultTheme: DefaultTheme, classNamePrefix: PREFIX }
);

interface Props extends ContainerProps {
  children: NonNullable<React.ReactNode>;
  className?: string;
}

const Header: React.FC<Props> = (props) => {
  const { children, className } = props;
  const classes = useStyles();

  return (
    <Container {...props} className={clsx(classes.root, className)}>
      {children ?? false}
    </Container>
  );
};

export { Header };
