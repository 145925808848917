import {
  CreateListingRequest,
  Listing,
  CreateListingAmenitiesRequest,
  ListingAmenities,
  Amenity,
  ListingToApproveItem,
  UpdateListingApprovalParams,
} from './typings';
import { PaginatedResult, VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';

const createListingAmenities = (
  client: VacayzClient,
  listingId: number,
  fields: CreateListingAmenitiesRequest
): VacayzResponse<ListingAmenities> => {
  const url = `api/v1/listings/${listingId}/amenities`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<ListingAmenities>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createListing = (client: VacayzClient, fields: CreateListingRequest): VacayzResponse<Listing> => {
  const url = 'api/v1/listings/';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<Listing>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getListingById = (client: VacayzClient, listingId: number): VacayzResponse<Listing> => {
  const url = `api/v1/listings/${listingId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Listing>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const updateListing = (
  client: VacayzClient,
  listingId: number,
  fields: Partial<CreateListingRequest>
): VacayzResponse<Listing> => {
  const url = `api/v1/listings/${listingId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<Listing>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getListingsByBrandId = (
  client: VacayzClient,
  isArchived: 0 | 1,
  brandId?: number,
  page?: number,
  perPage?: number,
  includeNonApproved?: boolean
): VacayzResponse<PaginatedResult<Listing>> => {
  const qs = toQs({
    page,
    per_page: perPage,
    is_archived: isArchived,
    includes_non_approved: includeNonApproved ? 1 : null,
  });
  const url = `api/v1/brands/${brandId ?? 'me'}/listings${qs}`;
  const source = getSource();

  const promise = async () => {
    const response = await client.get<PaginatedResult<Listing>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };

  return [handleCancel(promise), source.cancel];
};

const getListingsByCollectionId = (
  client: VacayzClient,
  collectionId: number,
  page?: number,
  perPage?: number,
  includeNonApproved?: boolean
): VacayzResponse<PaginatedResult<Listing>> => {
  const qs = toQs({ page, per_page: perPage, includes_non_approved: includeNonApproved ? 1 : null });
  const url = `api/v1/collections/${collectionId}/listings${qs}`;
  const source = getSource();

  const promise = async () => {
    const response = await client.get<PaginatedResult<Listing>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };

  return [handleCancel(promise), source.cancel];
};

const deleteListingById = (client: VacayzClient, listingId: number): VacayzResponse<boolean> => {
  const url = `api/v1/listings/${listingId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.delete<boolean>(url, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

const getListingAmenities = (client: VacayzClient, listingId: number): VacayzResponse<ListingAmenities> => {
  const url = `api/v1/listings/${listingId}/amenities`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<ListingAmenities>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getAmenities = (
  client: VacayzClient,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<Amenity>> => {
  const qs = toQs({ page, per_page: perPage });
  const url = `api/v1/amenities/${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<Amenity>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getHealthAndSafety = (
  client: VacayzClient,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<Amenity>> => {
  const qs = toQs({ page, per_page: perPage });
  const url = `api/v1/amenities/health_safety/${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<PaginatedResult<Amenity>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getHotelApprovalList = (
  client: VacayzClient,
  page?: number,
  perPage?: number
): VacayzResponse<PaginatedResult<ListingToApproveItem>> => {
  const qs = toQs({
    page,
    per_page: perPage,
  });
  const url = `api/v1/listings/to_approve${qs}`;
  const source = getSource();

  const promise = async () => {
    const response = await client.get<PaginatedResult<ListingToApproveItem>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };

  return [handleCancel(promise), source.cancel];
};

const updateHotelStatus = (client: VacayzClient, fields: UpdateListingApprovalParams): VacayzResponse<boolean> => {
  const { listingId, ...other } = fields;
  const url = `api/v1/listings/${listingId}/approve`;
  const source = getSource();
  const promise = async () => {
    const response = await client.put<boolean>(url, other, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

export {
  createListing,
  deleteListingById,
  getListingById,
  getListingsByCollectionId,
  updateListing,
  createListingAmenities,
  getListingAmenities,
  getAmenities,
  getHealthAndSafety,
  getHotelApprovalList,
  updateHotelStatus,
  getListingsByBrandId,
};
