import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  Switch,
  Box,
  FormControlLabel,
} from '@mui/material';
import { useAuthContext } from '@micro-frontends/auth-context';
import { CardElement, PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { useFormErrors, AlertContext, useAsyncCleanup } from '@micro-frontends/shared-components';
import { has } from 'lodash';
import * as StripeJs from '@stripe/stripe-js';

import { getUserCreditTotalInfo, UserCreditTotalInfo } from '@micro-frontends/vacayz-api-client';

const useStyles = makeStyles({
  paymentContainer: {
    bottom: '20px',
    position: 'absolute',
    justifyContent: 'center',
    width: 'calc(100% - 40px)',
    display: 'flex',
  },
  payButton: {
    width: 155,
  },
});

export interface BookingDealPaymentForm {
  guestName: string;
  address: string;
  country: string;
  phone: string;
}

interface Props {
  submitRequest: (form: BookingDealPaymentForm, creditAmount: number) => void;
  loading: boolean;
  totalAmount: number;
}

const PaymentDealsForm: React.FC<Props> = ({ submitRequest, loading, totalAmount }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<StripeJs.PaymentRequest>();
  const [validCard, setValidCard] = useState(false);
  const { isMounted, addCleanup } = useAsyncCleanup();

  const classes = useStyles();
  const { currentUser, client } = useAuthContext();
  const {
    handleSubmit,
    errors,
    register,
    formState: { isDirty },
  } = useForm<BookingDealPaymentForm>();
  const { alert } = useContext(AlertContext);
  const { hasErrors, getMessages } = useFormErrors('BookingDealPaymentForm');
  const [phone, setPhone] = useState<string>(currentUser?.phone || '');
  const [creditTotalInfo, setCreditTotalInfo] = useState<UserCreditTotalInfo | null>(null);
  const [creditAmount, setCreditAmout] = useState(0);

  const getUserCreditInformation = useCallback(async () => {
    try {
      const [_resCreditTotal, _cancel] = getUserCreditTotalInfo(client);
      addCleanup(_cancel);
      const res_data = await _resCreditTotal;
      if (!isMounted()) return;
      if (res_data && res_data.amount / 100 > 0) {
        const _creditAmount = res_data.amount / 100;
        setCreditTotalInfo(res_data);
        setCreditAmout(totalAmount > _creditAmount ? _creditAmount : totalAmount);
      }
    } catch (err) {}
  }, [addCleanup, client, isMounted, setCreditTotalInfo, totalAmount]);

  useEffect(() => {
    getUserCreditInformation();
  }, [getUserCreditInformation]);

  const handleCardChange = (event: StripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) {
      setValidCard(true);
    } else {
      setValidCard(false);
    }
    return;
  };

  const handleChangeCredit = () => {
    if (creditAmount === 0 && creditTotalInfo) {
      const _creditAmount = creditTotalInfo.amount / 100;
      setCreditAmout(totalAmount > _creditAmount ? _creditAmount : totalAmount);
    } else {
      setCreditAmout(0);
    }
  };

  const handlePay = useCallback(
    (form: BookingDealPaymentForm) => {
      if (creditAmount < totalAmount && !validCard) {
        alert('You must fill the card information');
        return;
      }

      if (!phone) {
        alert('You must fill your phone number in user settings');
        return;
      }
      if (!currentUser?.lastName) {
        alert('You must fill your full name in user settings');
        return;
      }
      form.phone = phone;
      form.guestName = currentUser.email;
      submitRequest(form, creditAmount);
      // reset();
    },
    [alert, submitRequest, currentUser, phone, creditAmount, totalAmount, validCard]
  );

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Check Apple Pay',
          amount: Math.round(totalAmount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on('paymentmethod', async () => {
        handleSubmit(handlePay);
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, totalAmount, handleSubmit, handlePay]);

  return (
    <Container>
      <Typography variant="h2" textAlign="center" mb={4}>
        {`Send $${totalAmount.toLocaleString()} to ${process.env.REACT_APP_COMPANY_NAME}`}
      </Typography>
      <form onSubmit={handleSubmit(handlePay)}>
        <Box sx={{ margin: '10px' }}>
          <TextField
            label="Address"
            name="address"
            fullWidth
            inputRef={register({
              required: 'Address is required',
              maxLength: { value: 512, message: 'Address must be shorter than 512 characters' },
            })}
            error={hasErrors('address') || has(errors, 'address')}
            helperText={[...getMessages('address'), errors.address?.message]}
          />
        </Box>
        <Box sx={{ margin: '10px' }}>
          <TextField
            label="Country/Region"
            name="country"
            fullWidth
            inputRef={register({
              required: 'Country is required',
              maxLength: { value: 512, message: 'Country must be shorter than 512 characters' },
            })}
            error={hasErrors('country') || has(errors, 'country')}
            helperText={[...getMessages('country'), errors.country?.message]}
          />
        </Box>
        <Box sx={{ margin: '10px' }}>
          {currentUser?.phone ? (
            <TextField disabled label="Phone" value={phone} fullWidth />
          ) : (
            <TextField
              name="phone"
              label="Phone"
              fullWidth
              value={phone ?? ''}
              onChange={({ target: { value } }) => setPhone(value)}
              error={hasErrors('phone')}
              helperText={[...getMessages('phone')]}
            />
          )}
        </Box>

        <Box px={1} py={3} borderRadius="4px" borderColor="rgba(0, 0, 0, 0.23)" border="1px solid">
          {creditTotalInfo && (
            <Container>
              <Grid container marginBottom={1}>
                <Grid item xs={6}>
                  <Typography variant="body1" component="span" marginRight={2}>
                    Credit
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch defaultChecked checked={creditAmount > 0 ? true : false} onChange={handleChangeCredit} />
                    }
                    label=""
                  />
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography>{`US$ ${Number(creditAmount.toFixed(2)).toLocaleString()}`}</Typography>
                </Grid>
              </Grid>
            </Container>
          )}

          {creditAmount < totalAmount && (
            <Container>
              <Grid container marginBottom={1}>
                <Grid item xs={6}>
                  <Typography variant="body1" component="span" marginRight={2}>
                    Card
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography>{`US$  ${Number((totalAmount - creditAmount).toFixed(2)).toLocaleString()}`} </Typography>
                </Grid>
              </Grid>
              <Box px={1} py={1.5} borderRadius="4px" borderColor="rgba(0, 0, 0, 0.23)" border="1px solid">
                <CardElement onChange={handleCardChange} />
              </Box>
            </Container>
          )}
        </Box>

        {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
        <Grid className={classes.paymentContainer}>
          <Button
            type="submit"
            variant="contained"
            className={classes.payButton}
            disabled={
              !isDirty ||
              loading ||
              !phone ||
              (creditTotalInfo && creditTotalInfo.amount / 100 > totalAmount ? false : !validCard)
            }
            sx={{ borderRadius: '50px' }}
          >
            {loading ? <CircularProgress /> : 'Pay'}
          </Button>
        </Grid>
      </form>
    </Container>
  );
};

export default PaymentDealsForm;
export { PaymentDealsForm };
