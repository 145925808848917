import React from 'react';
import clsx from 'clsx';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { LazyBg } from '@micro-frontends/shared-components';
import { EntityImage } from '@micro-frontends/vacayz-api-client';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  track: {
    overflowX: 'auto',
    padding: `0 0 22px`,
    marginBottom: -22,
    display: 'flex',
    flexWrap: 'nowrap',
  },
  image: {
    flex: `0 0 277px`,
    maxWidth: 277,
    marginRight: theme.spacing(1),

    '& > div': {
      position: 'relative',
      paddingBottom: '60%',
    },
    '& .lazy-bg': {
      borderRadius: theme.spacing(1),
    },
  },
}));

interface Props {
  className?: string;
  images?: EntityImage[];
}
export const IMAGE_GALLERY_SIZE = 5;
const DealGallery: React.FC<Props> = ({ className, images }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.track}>
        {images
          ? images.slice(0, IMAGE_GALLERY_SIZE).map((src, index) => (
              <div key={index} className={classes.image}>
                <div>
                  <LazyBg src={src.url} />
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export { DealGallery };
