import React, { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@micro-frontends/shared-components';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  slick: {
    flex: `0 0 calc(100% - ${theme.spacing(8)} - ${theme.spacing(3)})`,
    maxWidth: `calc(100% - ${theme.spacing(8)} - ${theme.spacing(3)})`,
    overflow: 'hidden',

    '& .slick-list': {
      marginLeft: theme.spacing(-1.5) + 4,
      marginRight: theme.spacing(-1.5) + 4,
    },
    '& .slick-track': {
      display: 'flex !important',
    },
    '& .slick-slide': {
      height: 'inherit !important',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),

      '& > div': {
        height: '100%',
      },
      '& *:focus': {
        outline: 'none',
      },
    },
  },
  arrow: {
    boxShadow: `0 0 2px ${theme.palette.secondary.main}`,

    '& svg': {
      color: theme.palette.primary.main,
      transform: 'translateX(2px)',
    },
  },
}));

interface Props {
  className?: string;
  settings?: Settings;
  children: React.ReactNode;
}

const HomeSlick: React.FC<Props> = ({ settings: mySettings, className, children }) => {
  const classes = useStyles();
  const slickRef = useRef<Slider>(null);
  const settings: Settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    dots: false,
    ...mySettings,
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <IconButton
        className={classes.arrow}
        style={{ transform: 'rotate(180deg)' }}
        onClick={() => slickRef.current?.slickPrev()}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <Slider ref={slickRef} className={classes.slick} {...settings}>
        {children}
      </Slider>
      <IconButton className={classes.arrow} onClick={() => slickRef.current?.slickNext()}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export { HomeSlick };
