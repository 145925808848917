import { BookingPaymentIntent, CreateBookingPaymentIntentRequest } from './typings';
import { VacayzClient, VacayzResponse } from '../../common/typings';
import { getSource, handleCancel } from '../../common/utils';

const createBookingPaymentIntent = (
  client: VacayzClient,
  fields: CreateBookingPaymentIntentRequest
): VacayzResponse<BookingPaymentIntent> => {
  const url = 'api/v1/payments/booking/payment_intents';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<BookingPaymentIntent>(url, fields, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export { createBookingPaymentIntent };
