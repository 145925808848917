import React, { useImperativeHandle, forwardRef } from 'react';
import clsx from 'clsx';

import { DataGridSkeleton } from './DataGridSkeleton';
import { NoRowsOverlay } from './NoRowsOverlay';
import { usePagination, Pagination } from '../Paginate';
import { DataGrid as MuiDataGrid, DataGridProps, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<Theme, { minWidth: number }>((theme: Theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    minHeight: 215,
    position: 'relative',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
  scrollBox: {
    flex: '1',
    display: 'flex',
    overflow: 'auto',
  },
  track: {
    display: 'flex',
    flex: '0 0 100%',
    maxWidth: '100%',
    minWidth: 'min-content',
  },
  dataGrid: ({ minWidth }) => ({
    minWidth: minWidth,
    position: 'static',
    border: 'none',

    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: theme.palette.grey[100],
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    '& .MuiDataGrid-dataContainer': {
      minWidth: '0 !important',
    },
    '& .MuiDataGrid-selectedRowCount': {
      display: 'none',
    },
    '& .MuiDataGrid-colCellWrapper .MuiDataGrid-colCell:last-child .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      display: 'none',
    },
  }),
  pagination: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
}));

interface Props<T> extends Partial<DataGridProps> {
  className?: string;
  columns: GridColDef[];
  minWidth?: number;
  pageSize?: number;
  fetcher: (page: number, perPage: number) => Promise<T[] | undefined>;
}

const DataGrid = forwardRef(<T extends unknown>(props: Props<T>, ref?: React.Ref<unknown>): JSX.Element => {
  const { minWidth = 644, className, pageSize = 10, fetcher, columns } = props;
  const { state, pageData, handlePagination, resetPagination } = usePagination<T>(fetcher, pageSize);
  const classes = useStyles({ minWidth });

  useImperativeHandle(
    ref,
    () => ({
      resetDataGrid: () => {
        resetPagination();
      },
    }),
    [resetPagination]
  );

  return state.loader ? (
    <DataGridSkeleton />
  ) : (
    <div className={clsx(classes.root, className)}>
      <div className={classes.scrollBox}>
        <div className={classes.track}>
          <MuiDataGrid
            components={{ NoRowsOverlay }}
            disableColumnMenu
            {...props}
            rowsPerPageOptions={[5, 10, 25, 50]}
            columns={columns}
            rows={pageData as GridRowsProp}
            className={classes.dataGrid}
          />
        </div>
      </div>
      {pageData.length > 0 && (
        <Pagination state={state} handlePagination={handlePagination} className={classes.pagination} />
      )}
    </div>
  );
});

export type DataGridRef = { resetDataGrid: () => void };

export { DataGrid };
