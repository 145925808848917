import { useCallback, useState } from 'react';

import { EntityImage, uploadImage, VacayzClient, deleteImage, isVacayzError } from '@micro-frontends/vacayz-api-client';
import { useAsyncCleanup } from '../../common/hooks';
import { extractFormErrors, FormError } from '../../common';

const useDropzoneImage = (
  client: VacayzClient,
  onChange: (imageId: number | null) => void,
  setError?: (errors?: FormError[]) => void,
  defaultValue?: EntityImage
): {
  sendImage: (image?: File | undefined) => Promise<void>;
  removeImage: (image: EntityImage) => Promise<void>;
  loading: boolean;
  entityImage: EntityImage | null;
} => {
  const [loading, setLoading] = useState(false);
  const [entityImage, setEntityImage] = useState<EntityImage | null>(defaultValue ? defaultValue : null);
  const { isMounted, addCleanup } = useAsyncCleanup();

  const sendImage = useCallback(
    async (image?: File) => {
      if (image) {
        setLoading(true);
        try {
          const [imagePromise, cancel] = uploadImage(client, image);
          addCleanup(cancel);
          const uploadResponse = await imagePromise;
          if (uploadResponse && isMounted()) {
            setEntityImage(uploadResponse);
            onChange(uploadResponse.id);
          }
        } catch (error) {
          if (error && isMounted() && isVacayzError(error)) {
            setError?.(extractFormErrors(error));
          }
        } finally {
          if (isMounted()) {
            setLoading(false);
          }
        }
      }
    },
    [client, addCleanup, isMounted, onChange, setError]
  );

  const removeImage = useCallback(
    async (image: EntityImage) => {
      setLoading(true);
      try {
        const [deletePromise, cancel] = deleteImage(client, image.id);
        addCleanup(cancel);
        await deletePromise;
        if (isMounted()) {
          setEntityImage(null);
          onChange(null);
        }
      } catch (error) {
        if (error && isMounted() && isVacayzError(error)) {
          setError?.(extractFormErrors(error));
        }
      } finally {
        if (isMounted()) {
          setLoading(false);
        }
      }
    },
    [client, addCleanup, isMounted, onChange, setError]
  );

  return { sendImage, removeImage, loading, entityImage };
};

export { useDropzoneImage };
