import React from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FONT_WEIGHT, IconButton, TextField } from '@micro-frontends/shared-components';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDealContext } from '../../../../common/contexts';
import { currencyValue } from '../../../../common/utils/Format';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 62,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    '& h3': {
      padding: `${theme.spacing(1)} 0`,
      fontWeight: FONT_WEIGHT.medium,
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(22),
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  input: {
    maxWidth: 566,
    margin: '0 auto',
  },
  result: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 455,
    margin: '0 auto',
    fontSize: theme.typography.pxToRem(16),

    '& span': {},
    '& b': {},
    '&.deposit span': {
      fontWeight: FONT_WEIGHT.medium,
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(18),
    },
    '&.deposit b': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    height: 62,
  },
}));

interface Props {
  onClose: () => void;
}

const DealMobCalcModal: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const { dealData, numberOfNights, setNumberOfNights, calculator } = useDealContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dealData) {
      setNumberOfNights(event.target.value);
    }
  };

  return (
    <>
      <Container className={classes.header}>
        <Typography variant="h3">Add Number of Nights</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Container>
      <Container className={classes.body}>
        <TextField
          className={classes.input}
          value={numberOfNights ?? ''}
          onChange={handleChange}
          label="Number of Nights"
        />
        <Box className={clsx(classes.result, 'amount')}>
          <span>Amount Saved:</span>
          <b>{currencyValue(calculator?.saved, dealData?.collectionCurrency)}</b>
        </Box>
        <Box className={clsx(classes.result, 'deposit')}>
          <span>Total Deposit:</span>
          <b>{currencyValue(calculator?.paynow, dealData?.collectionCurrency)}</b>
        </Box>
      </Container>
      <Container className={classes.footer}>
        <Button disabled={!numberOfNights} onClick={onClose}>
          Save
        </Button>
      </Container>
    </>
  );
};

export { DealMobCalcModal };
