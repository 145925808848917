import { Option } from '../types';
import { OptionKey } from '@micro-frontends/shared-components';
import {
  BrandLogo,
  BrandLogoDealData,
  CapPriceLabel,
  CapPriceLabelDealData,
  CapPriceValue,
  CapPriceValueDealData,
  CardImage,
  CardImageDealData,
  Discount,
  Hotels,
  HotelsDealData,
  InfoLabel,
  PercentText,
  ValidForValue,
  ValidForValueDealData,
  ViewMore,
} from '../../components/common';
import { DealData } from '../contexts';

const KEYS_OPTION_PLAN_W_LOGO: OptionKey<Option>[] = [
  { label: 'Brand', value: 'brandLogoUrl', valueComponent: BrandLogo },
  { label: 'Hotel(s)', value: 'listings', valueComponent: Hotels },
  { label: 'Destination', value: 'parentLocationName' },
  { label: 'Discount', value: 'discount', valueComponent: Discount },
  { label: 'Valid For', value: 'validForYears', valueComponent: ValidForValue },
  {
    label: 'Cap Price',
    value: 'highSeasonWithWeekendsPrice',
    labelComponent: CapPriceLabel,
    valueComponent: CapPriceValue,
  },
  {
    label: 'Card Image',
    value: 'collectionBackgroundImg',
    labelComponent: InfoLabel,
    valueComponent: CardImage,
  },
  {
    label: '',
    value: 'brandUrlName',
    valueComponent: ViewMore,
  },
];

const KEYS_OPTION_PLAN: OptionKey<Option>[] = [
  { label: 'Name', value: 'optionPlanName' },
  { label: 'Hotel(s)', value: 'listings', valueComponent: Hotels },
  { label: 'Destination', value: 'parentLocationName' },
  { label: 'Discount', value: 'discount', valueComponent: Discount },
  { label: 'Valid For', value: 'validForYears', valueComponent: ValidForValue },
  {
    label: 'Cap Price',
    value: 'highSeasonWithWeekendsPrice',
    labelComponent: CapPriceLabel,
    valueComponent: CapPriceValue,
  },
  {
    label: 'Card Image',
    value: 'collectionBackgroundImg',
    labelComponent: InfoLabel,
    valueComponent: CardImage,
  },
];

const KEYS_DEAL_OPTION_PLAN: OptionKey<DealData>[] = [
  { label: 'Brand', value: 'brandLogoUrl', valueComponent: BrandLogoDealData },
  { label: 'Hotel(s)', value: 'listings', valueComponent: HotelsDealData },
  { label: 'Destination', value: 'locationName' },
  { label: 'Discount', value: 'discount', valueComponent: Discount },
  { label: 'Valid For', value: 'validForYears', valueComponent: ValidForValueDealData },
  { label: 'Deposit', value: 'upfront', valueComponent: PercentText },
  {
    label: 'Cap Price',
    value: 'highSeasonWithWeekendsPrice',
    labelComponent: CapPriceLabelDealData,
    valueComponent: CapPriceValueDealData,
  },
  {
    label: 'Card Image',
    value: 'collectionBackgroundImg',
    labelComponent: InfoLabel,
    valueComponent: CardImageDealData,
  },
];

export { KEYS_DEAL_OPTION_PLAN, KEYS_OPTION_PLAN, KEYS_OPTION_PLAN_W_LOGO };
