import { VacayzClient, VacayzResponse } from '../common/typings';

import { UserInfo, UserInfoUpdate, UserCreditTotalInfo } from './typings';
import { getSource } from '../common';
import { handleCancel } from '../common/utils';

const getMyUser = (client: VacayzClient): VacayzResponse<UserInfo | null> => {
  const url = 'api/v1/users/me';
  const source = getSource();
  const promise = async () => {
    try {
      const response = await client.get<{ user: UserInfo }>(url, { cancelToken: source.token });
      return response?.data?.user;
    } catch (err) {
      return null;
    }
  };
  return [handleCancel(promise), source.cancel];
};

const createUser = (
  client: VacayzClient,
  user: Partial<UserInfo>,
  visitorId: string | null,
  visitorDate?: string | null
): VacayzResponse<UserInfo | null> => {
  const url = `api/v1/users/me`;
  const source = getSource();
  const promise = async () => {
    try {
      const response = await client.post<UserInfo>(
        url,
        {
          user,
          ...(visitorId ? { visitor_id: visitorId } : {}),
          ...(visitorDate ? { visitor_date: visitorDate } : {}),
        },
        { cancelToken: source.token }
      );
      return response?.data;
    } catch (err) {
      return null;
    }
  };
  return [handleCancel(promise), source.cancel];
};

const updateUserInfo = (client: VacayzClient, updatedUser: Partial<UserInfo>): VacayzResponse<UserInfoUpdate> => {
  const url = 'api/v1/users/me';
  const source = getSource();
  const promise = async () => {
    const response = await client.put<UserInfoUpdate>(url, updatedUser, { cancelToken: source.token });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getUserInfo = (client: VacayzClient, userInfoVersionId: number): VacayzResponse<UserInfo> => {
  const url = `api/v1/users/versions/${userInfoVersionId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<{ user: UserInfo }>(url, { cancelToken: source.token });
    return response?.data?.user;
  };
  return [handleCancel(promise), source.cancel];
};

const getUserCreditTotalInfo = (client: VacayzClient): VacayzResponse<UserCreditTotalInfo> => {
  const url = 'api/v1/credit/user/credits';
  const source = getSource();
  const promise = async () => {
    const response = await client.get<UserCreditTotalInfo>(url, { cancelToken: source.token });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const sendVisitorViewData = (
  client: VacayzClient,
  eventId: string,
  view: string,
  visitorId: string,
  visitorCreated: string,
  dealId?: string
): VacayzResponse<boolean> => {
  const url = 'api/v1/users/view_user';
  const source = getSource();
  const promise = async () => {
    const response = await client.post<boolean>(
      url,
      {
        event_id: eventId,
        view,
        visitor_id: visitorId,
        visitor_created_at: visitorCreated,
        ...(dealId ? { deal_id: dealId } : {}),
      },
      { cancelToken: source.token }
    );
    return response?.status === 201;
  };
  return [handleCancel(promise), source.cancel];
};

export { createUser, getMyUser, updateUserInfo, getUserInfo, getUserCreditTotalInfo, sendVisitorViewData };
