import { PaginatedResult, VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../common/utils';

import { ByBrandLocation, Location, EventDealsResponse } from './typings';

const getLocationById = (client: VacayzClient, locationId: number): VacayzResponse<Location> => {
  const url = `api/v1/locations/${locationId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Location>(url, { cancelToken: source.token });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getLocation = (
  client: VacayzClient,
  options: { latitude: number; longitude: number } | { type: string; name: string }
): VacayzResponse<Location> => {
  const qs = toQs(options);
  const url = `api/v1/locations${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Location>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const getLocationsByBrandId = (
  client: VacayzClient,
  brandId?: number,
  page?: number,
  perPage?: number,
  includeNonApproved?: boolean
): VacayzResponse<PaginatedResult<ByBrandLocation>> => {
  const qs = toQs({ page: page, per_page: perPage, includes_non_approved: includeNonApproved ? 1 : null });
  const url = `api/v1/brands/${brandId ?? 'me'}/locations${qs}`;
  const source = getSource();

  const promise = async () => {
    const response = await client.get<PaginatedResult<ByBrandLocation>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };

  return [handleCancel(promise), source.cancel];
};

const getEventDeals = (
  client: VacayzClient,
  eventId: string,
  sortby?: string,
  to?: string,
  from?: string,
  guests?: number,
  rooms?: number
): VacayzResponse<Array<EventDealsResponse>> => {
  const qs = toQs({
    ...(sortby && sortby !== '' ? { sort: sortby } : {}),
    ...(to && to !== '' ? { to } : {}),
    ...(from && from !== '' ? { from } : {}),
    ...(guests && guests !== 0 ? { guests } : {}),
    ...(rooms && rooms !== 0 ? { rooms } : {}),
  });
  const url = `api/v1/hotels/event-deals/${eventId}${qs}`;
  const source = getSource();

  const promise = async () => {
    const response = await client.get<Array<EventDealsResponse>>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };

  return [handleCancel(promise), source.cancel];
};

const getEventGuestCounts = (client: VacayzClient, eventId: string): VacayzResponse<number[]> => {
  const url = `api/v1/hotels/event-guests/${eventId}`;
  const source = getSource();

  const promise = async () => {
    const response = await client.get<number[]>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };

  return [handleCancel(promise), source.cancel];
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export { getLocation, getLocationById, getLocationsByBrandId, getEventDeals, getEventGuestCounts };
