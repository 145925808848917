import React from 'react';

import Fade from '@mui/material/Fade';
import { DealCard, DealTitle } from '../_components';
import { DealAvailabilityItem } from './DealAvailabilityItem';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AVAILABILITIES, useDealContext } from '../../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(2, -2, -2, 0),
  },
  item: {
    flex: `0 0 calc(100% / 5)`,
    maxWidth: `calc(100% / 5)`,
    padding: theme.spacing(0, 2, 2, 0),
  },
}));

const DealAvailability: React.FC = () => {
  const classes = useStyles();
  const { dealData } = useDealContext();

  return (
    <DealCard>
      <Fade in={!!dealData}>
        <div>
          <DealTitle>Select The Desired Availability</DealTitle>
          <div className={classes.container}>
            {dealData &&
              AVAILABILITIES.map((el) => (
                <div key={el} className={classes.item}>
                  <DealAvailabilityItem availability={el} />
                </div>
              ))}
          </div>
        </div>
      </Fade>
    </DealCard>
  );
};

export { DealAvailability };
