import React from 'react';
import clsx from 'clsx';

import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(1),
      height: '100%',
    },
    cardContent: {
      padding: theme.spacing(2),

      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
  });

interface Props {
  className?: string;
}

const DealCard = withStyles(styles)((props: WithStyles<typeof styles> & Omit<CardProps, keyof Props> & Props) => {
  const { classes, className, children, ...other } = props;
  return (
    <Card className={clsx(classes.root, className)} variant="outlined" {...other}>
      <CardContent className={classes.cardContent}>{children}</CardContent>
    </Card>
  );
});

export { DealCard };
