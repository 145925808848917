import React from 'react';
import { Link } from 'react-router-dom';

import { COLORS, LazyBg, MainContainer, MainFluidContainer } from '@micro-frontends/shared-components';
import { alpha, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FOOTER_BG } from '../../common/constants/images';

import facebook from '../../assets/social/facebook.svg';
import instagram from '../../assets/social/instagram.svg';
import linkedin from '../../assets/social/linkedin.svg';
import twitter from '../../assets/social/twitter.svg';

const ASSETS = [
  {
    src: facebook,
    label: 'facebook',
    link: 'https://www.facebook.com/Vacayzz ',
  },
  {
    src: twitter,
    label: 'twitter',
    link: 'https://twitter.com/vacayz ',
  },
  {
    src: linkedin,
    label: 'linkedin',
    link: 'https://www.linkedin.com/company/vacayz',
  },
  {
    src: instagram,
    label: 'instagram',
    link: 'https://www.instagram.com/nextvacayz',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 500,
    backgroundColor: COLORS.blue,
    backgroundSize: 'cover',
    position: 'relative',
  },
  lazyBg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'none',
    opacity: 0.8,
  },
  innerWarpper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex: 1,
  },
  logoBox: {
    padding: `${theme.spacing(4)} 0`,

    '& > img': {
      width: 188,
    },
  },
  socialBox: {
    display: 'flex',

    '& a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 44,
      height: 44,
      borderRadius: '100%',
      backgroundColor: alpha(COLORS.white, 0.3),
      transition: 'all .2s',

      '&:hover': {
        backgroundColor: alpha(COLORS.white, 0.5),
      },
    },
    '& a + a': {
      marginLeft: theme.spacing(2),
    },
    '& img': {
      width: 22,
      height: 22,
    },
  },
  footer: {
    padding: `${theme.spacing(3)} 0`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 'auto',

    fontSize: theme.typography.pxToRem(18),
    color: COLORS.white,

    '& a': {
      color: COLORS.white,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& a + a': {
      marginLeft: theme.spacing(3),
    },
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <MainFluidContainer className={classes.root}>
      <LazyBg src={FOOTER_BG} className={classes.lazyBg} />
      <MainContainer className={classes.innerWarpper}>
        <div className={classes.logoBox}>
          <img src={process.env.REACT_APP_COMPANY_WHITE_BRAND} alt="logo" />
        </div>
        <div className={classes.socialBox}>
          {ASSETS.map((el) => (
            <a key={el.src} className={el.label} href={el.link} target="_blank" rel="noreferrer">
              <img src={el.src} alt={el.label} />
            </a>
          ))}
        </div>
        <div className={classes.footer}>
          <p>© Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
          <nav>
            <Link to="/contact">Contact Us</Link>
            <a href="https://res.cloudinary.com/vacayz/image/upload/v1610380885/www/Vacayz_Inc_Terms_of_Service.pdf">
              Terms of Use
            </a>
            <a href="https://res.cloudinary.com/vacayz/image/upload/v1610379733/www/Vacayz_Inc_Privacy_Policy.pdf">
              Privacy Policy
            </a>
          </nav>
        </div>
      </MainContainer>
    </MainFluidContainer>
  );
};

export { Footer };
