import React from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, COLORS, FONT_WEIGHT, LazyBg, MainContainer, useSignIn } from '@micro-frontends/shared-components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BUSINESS_BG } from '../../common/constants/images';
import { Routing } from '../../common/constants/routing';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1300,
    margin: '0 auto',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: 12,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  left: {
    flex: '0 0 50%',
    maxWidth: '50%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 100%',
      maxWidth: '100%',
    },
  },
  right: {
    flex: '0 0 50%',
    maxWidth: '50%',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  title: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: 1.4,
  },
  subtitle: {
    padding: theme.spacing(2, 0),
    fontWeight: FONT_WEIGHT.medium,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
  text: {
    padding: theme.spacing(0, 0, 3),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.5,

    '& a': {
      textDecoration: 'none',
      color: COLORS.blue,
    },
    '& a svg': {
      fontSize: theme.typography.pxToRem(18),
      verticalAlign: -3,
    },
  },
}));

const HomeForBusiness: React.FC = () => {
  const classes = useStyles();
  const [sendToSignIn] = useSignIn(process.env.REACT_APP_SIGN_IN_URL ?? '', '');

  return (
    <MainContainer>
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.title}>Vacayz for Business</div>
          <div className={classes.subtitle}>Reduce Your Company’s Travel Expenses</div>
          <Typography variant="body1" className={classes.text}>
            Book accommodations in advance without committing to dates using the Vacayz Travel Options Marketplace and
            reduce business travel expenses.{' '}
            <a href={Routing.business} target="_blank" rel="noreferrer">
              Learn more <ArrowForwardIosIcon />
            </a>
          </Typography>
          <Button onClick={sendToSignIn}>Create Business Account</Button>
        </div>
        <div className={classes.right}>
          <LazyBg src={BUSINESS_BG} />
        </div>
      </div>
    </MainContainer>
  );
};

export { HomeForBusiness };
