import { EntityImage } from '../../images/typings';
import { ContactInfo } from '../../listings/typings';

export type BookingRequestStatus =
  | 'NEW'
  | 'PENDING_CUSTOMER'
  | 'PENDING_BOOKING'
  | 'PENDING_NEW_DEAL'
  | 'PENDING_MORE_OFFERS'
  | 'APPROVED'
  | 'CANCELED'
  | 'TERMINATED'
  | 'REFUNDED'
  | 'REFUND'
  | 'CREDITED'
  | 'PENDING_PAYMENT'
  | 'REFUNDED_PARTIAL';

export type DealType = 'flight' | 'hotel';

type OfferStatusType = {
  [key: string]: BookingRequestStatus;
};
export const OfferStatus: OfferStatusType = {
  NEW_REQUEST: 'NEW',
  PENDING_CUSTOMER: 'PENDING_CUSTOMER',
  PENDING_BOOKING: 'PENDING_BOOKING',
  PENDING_NEW_DEAL: 'PENDING_NEW_DEAL',
  PENDING_MORE_OFFERS: 'PENDING_MORE_OFFERS',
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
  REFUND: 'REFUND',
  CREDITED: 'CREDITED',
  TERMINATED: 'TERMINATED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  REFUNDED_PARTIAL: 'REFUNDED_PARTIAL',
};

export const getStatusText = (status: string): string => {
  switch (status) {
    case OfferStatus.APPROVED:
      return 'Approved';
    case OfferStatus.PENDING_CUSTOMER:
      return 'Pending Customers';
    case OfferStatus.PENDING_BOOKING:
      return 'Pending Bookings';
    case OfferStatus.PENDING_NEW_DEAL:
      return 'Changed';
    case OfferStatus.NEW_REQUEST:
      return 'New Request';
    case OfferStatus.PENDING_MORE_OFFERS:
      return 'More Offers';
    case OfferStatus.CANCELED:
      return 'Canceled';
    case OfferStatus.REFUND:
      return 'Refund';
    case OfferStatus.REFUNDED:
      return 'Refunded';
    case OfferStatus.TERMINATED:
      return 'Terminated';
    case OfferStatus.PENDING_PAYMENT:
      return 'Pending Payment';
    case OfferStatus.REFUNDED_PARTIAL:
      return 'Partial Refunded';
    default:
      return 'Unknown booking request status';
  }
};

export interface BookingRequestListItem {
  confirmationNumber: string | null;
  vendorConfirmNumber: string | null;
  createdAt: string;
  deals: number;
  email: string;
  id: number;
  firstname: string;
  lastname: string;
  orderId: string | null;
  status: BookingRequestStatus;
  source?: string;
  assignee?: string;
  company?: string;
  waiting: number;
  checkIn?: string;
  checkOut?: string;
  cities?: string;
  isViewed: boolean;
  priority?: string;
  invoices?: InvoiceForm[];
}

export interface BookingRequest {
  id: number;
  contact: ContactInfo;
  adminNotes: string;
  notes: string;
  status: string;
  destinations: BookingRequestDestination[];
  guests: BookingDestinationsforGuest[];
  invoices: InvoiceForm[];
}
export interface BookingDeal {
  address: string;
  breakfast: boolean;
  checkIn: string;
  checkOut: string;
  createdAt: string;
  destination: string;
  fees?: number;
  id: number;
  imageId?: number;
  image?: EntityImage;
  marketPrice: number;
  name: string;
  notes?: string;
  parking: boolean;
  price: number;
  refundable: boolean;
  refundableUntil?: string | null;
  requestId: number;
}

export interface BookingRequestContact {
  firstname: string;
  lastname: string;
  companyName: string;
  email: string;
  phone: string;
}

export interface BookingRoomChangedOptions {
  checkIn: boolean;
  checkOut: boolean;
  budget: boolean;
}

export interface FlightDetails {
  airportArrival: string;
  airportDeparture: string;
  datetimeArrival: string;
  datetimeDeparture: string;
  flightNumber: string;
}

export interface BookingDealNew {
  id?: number;
  name: string;
  address: string;
  refundable: boolean;
  refundableUntil: string | null;
  marketPrice: number;
  price: number;
  roomType: string;
  parking: boolean;
  breakfast: boolean;
  fees: number;
  bookingDestinationRoomId: number;
  isDealSelected?: boolean;
  confirmationNumber?: string;
  vendorConfirmNumber?: string;
  vendorId?: number;
  vendorNightPrice?: number;
  expiration: number;
  commission?: number;
  marketTotalPrice?: number;
  feesDescription?: FeeDescriptionForm[];
  imageUrl?: string;
  vendorPriceTotal: number;
  ourPriceTotal: number;
  vendorFinalPriceTotal?: number;
  vendorFinalNightPrice?: number;
  isDeleted?: number;
  flightDetails: FlightDetails;
  dealType: string;
}

export interface BookingUserSalesStats {
  totalNights: number;
  totalMarketplace: number;
  totalOur: number;
  topDestinations: TopDestinations[];
}

export interface UpdateBookingRequestParams {
  bookingRequestId: number;
  confirmationNumber?: string;
  vendorConfirmNumber?: string;
  updateStatus: string;
  refundReason?: string;
  cancel_notes?: string;
}

export interface BookingRoom {
  id?: number;
  checkIn: string;
  checkOut: string;
  parking: boolean;
  breakfast: boolean;
  roomSpecification: string;
  preference: string;
  budget: number;
  guests: number;
  location: string;
  quantity: number;
  deals: BookingDealNew[];
  changeOption?: BookingRoomChangedOptions;
  beds: number;
  bedsType: string;
}

export type BookingRoomFields =
  | 'location'
  | 'guests'
  | 'budget'
  | 'preference'
  | 'roomSpecification'
  | 'breakfast'
  | 'parking'
  | 'checkIn'
  | 'checkOut'
  | 'quantity'
  | 'beds'
  | 'bedsType';

export interface BookingRequestDestination {
  id?: number;
  city: string;
  state: string;
  region: string;
  rooms: BookingRoom[];
}

export interface BookingforGuest {
  name: string;
  address: string;
  roomNumber?: number;
}
export interface CheckDates {
  start: string;
  end: string;
}
export interface BookingDealsforGuest {
  dealId: number;
  item: BookingforGuest[];
}
export interface BookingRoomsforGuest {
  roomId: number;
  deals: BookingDealsforGuest[];
}
export interface BookingDestinationsforGuest {
  destinationId: number;
  rooms: BookingRoomsforGuest[];
}

export interface BookingNoteHistory {
  note: string;
  time: string;
  owner: string;
}
export type BookingDestinationFields = 'city' | 'state' | 'region';

export interface InvoiceForm {
  id: string;
  name?: string;
}
export interface CreateBookingRequestFormNew {
  id?: number;
  contact: BookingRequestContact;
  notes: string;
  adminNotes: string;
  destinations: BookingRequestDestination[];
  guests: BookingDestinationsforGuest[];
  status?: string;
  noteHistories: BookingNoteHistory[];
  invoices?: InvoiceForm[];
}

export interface ViewOfferNotificationForm {
  requestId: number;
}

export interface CreateBookingRequestLandingForm {
  contact: BookingRequestContact;
  notes: string;
  destinations: BookingRequestDestination[];
  original: string;
  eid: string | null;
}

export interface CreateBookingRequestHistoryForm {
  id: number;
  contact: BookingRequestContact;
  notes: string;
  destinations: BookingRequestDestination[];
}

export interface BookingRequestOrderId {
  orderId: string;
}

export interface AddGlobalOptionNightsParams {
  globalOptionId: number;
  nights: number;
}

export interface BookingRequestsCount {
  new?: number;
  pendingCustomer?: number;
  pendingBooking?: number;
  pendingNewDeal?: number;
  pendingMoreOffers?: number;
  approved?: number;
  canceled?: number;
}

export interface TopDestinations {
  name: string;
  count: number;
}

export interface BookingUserOrderHistory {
  createdAt: string;
  requestId: number;
  id: number;
  orderId: number;
  email: string;
  status: string;
  destination: string;
  name: string;
  price: number;
  checkIn: string;
  checkOut: string;
  quantity: number;
  confirmationNumber: string;
  vendorConfirmNumber: string;
  refundableText: string;
}

export interface BookingUserUpcomingTrips {
  createdAt: string;
  requestId: number;
  id: number;
  checkIn: string;
  checkOut: string;
  guests: number;
  destination: string;
  name: string;
  price: number;
  quantity: number;
  notes: string;
  refundableText: string;
}

export type DailyGraphData = {
  new?: number;
  canceled?: number;
  approved?: number;
  pendingCustomer?: number;
  pendingBooking?: number;
};

export interface DealListItem {
  id: number;
  createdAt: string;
  companyName: string;
  orderId: string;
  status: BookingRequestStatus;
  destination: string;
  hotel: string;
  checkIn: string;
  checkOut: string;
  price: number;
  marketPrice: number;
  confirmationNumber?: string;
  vendorConfirmNumber?: string;
  refundableText?: string;
}
export interface BookingClientStats {
  totalOptionPrice: number;
  totalOptionMarketPrice: number;
  globalOptionUsed: number;
  globalOptionAvailable: number;
  credit: number;
}

export interface AskDealRefundParams {
  bookingDealId: number;
  refundReason?: string;
}

export type BookingDealFields =
  | 'name'
  | 'address'
  | 'refundable'
  | 'refundableUntil'
  | 'marketPrice'
  | 'price'
  | 'roomType'
  | 'parking'
  | 'breakfast'
  | 'fees'
  | 'vendorId'
  | 'vendorNightPrice'
  | 'commission'
  | 'expiration'
  | 'feesDescription'
  | 'marketTotalPrice'
  | 'confirmationNumber'
  | 'vendorConfirmNumber';

export interface BookingGuestListItem {
  id: number;
  name: string | null;
  address: string | null;
  includePrice?: boolean;
  roomNumber?: number;
  color?: string;
}

export const budgetOptions = [
  { name: 0, text: '0 - $100' },
  { name: 100, text: '$100 - $200' },
  { name: 200, text: '$200 - $300' },
  { name: 300, text: '$300 - $400' },
  { name: 400, text: '$400 - $500' },
  { name: 500, text: '$500 + ' },
] as const;
export type BudgetOptions = typeof budgetOptions[number];

export const bedTypeOptions = [
  { name: 'KING', title: 'King Bed' },
  { name: 'QUEEN', title: 'Queen Bed' },
  { name: 'SUITE', title: 'Suite' },
];

export type BedTypeOptions = typeof bedTypeOptions[number];
export interface CreatingBookingMoreDealsForm {
  request_id: number;
  destination_request_id: number;
  reason_id: number;
  reason_description?: string;
}
export interface BookingMoreDealsReasonForm {
  id: number;
  name: string;
}

export interface TerminateReasonForm {
  name: string;
  description?: string;
  notifyUser: boolean;
}

export interface FeeDescriptionForm {
  name: string;
  amount: number;
  isIncluded: boolean;
}

export interface InvoiceDownloadForm {
  downloadUrl: string;
}

export interface InstantBookingResponse {
  requestId: number;
  deals: BookingDealNew[];
}

export interface InstantBookingDealItem {
  dealId: string;
  quantity: number;
}

export interface SelectDealItem {
  roomId: number;
  dealId: number;
}
