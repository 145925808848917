import capitalize from 'lodash/capitalize';

function numberWithCommas(num: number): string {
  return num.toLocaleString();
}

function snakeToCapitalized(text: string): string {
  return text.split('_').map(capitalize).join(' ');
}

function currencyValue(value?: string | number, currency?: string): string | null {
  if (!value) return null;
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    notation: 'compact',
    currency: currency ?? 'USD',
  });
}
export { numberWithCommas, snakeToCapitalized, currencyValue };
