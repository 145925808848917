import React from 'react';
import { format } from 'date-fns';

import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { OptionKey } from '../OptionsTable';
import { LazyBg } from '../../components/_elements';
import { COLORS } from '../../themes';

const useStyles = makeStyles((theme: Theme) => ({
  statusCircle: {
    display: 'inline-block',
    width: 18,
    height: 18,
    borderRadius: '50%',
    verticalAlign: -3,
    marginRight: 5,

    '&.used': {
      background: theme.palette.grey[800],
    },
    '&.available': {
      background: `linear-gradient(90deg, ${COLORS.blue} 0%, ${COLORS.green} 100%)`,
    },
  },
  BalanceVisual: {
    maxWidth: 'calc(100% - 22px)',
  },
  capPriceLabel: {
    '&:after': {
      display: 'none',
    },
    '& svg': {
      color: theme.palette.secondary.main,
      marginRight: 4,
      fontSize: 30,
    },
  },
  capPriceValue: {
    '&:after': {
      display: 'none',
    },
  },
  infoText: {
    height: 111,
    alignItems: 'flex-start',
    fontSize: theme.typography.pxToRem(12),
    color: COLORS.grey,
  },
  cardImage: {
    height: 111,
    width: '100%',
    padding: 0,

    '&:after': {
      display: 'none',
    },
  },
}));

const DateText: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  const epochDate = Number(data[key]);
  return <Typography>{format(new Date(epochDate), 'yyyy-MM-dd')}</Typography>;
};

const PercentText: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  const percent = Number(data[key]);
  return <Typography>{percent.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 })}</Typography>;
};

const CurrencyText: <T>(data: T, key: keyof T, currency?: string) => JSX.Element = (data, key, currency) => {
  const price = Number(data[key]);
  return (
    <Typography>
      {price?.toLocaleString('en-US', {
        style: 'currency',
        currency: currency ?? 'USD',
      })}
    </Typography>
  );
};

const BalanceValue: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  return (
    data && (
      <div>
        {data[key]} {((data[key] as unknown) as number) === 1 ? 'Night' : 'Nights'}{' '}
      </div>
    )
  );
};

const CapPriceLabel: <T>(key: OptionKey<T>) => JSX.Element = (key) => {
  const classes = useStyles();

  return (
    <div className={classes.capPriceLabel}>
      <InfoIcon />
      {key.label}
    </div>
  );
};

const CardImage: <T>(data: T, key: keyof T) => JSX.Element = (data, key) => {
  const classes = useStyles();

  return (
    <div className={classes.cardImage}>
      <LazyBg src={(data[key] as unknown) as string} />
    </div>
  );
};

export { DateText, PercentText, CurrencyText, BalanceValue, CapPriceLabel, CardImage };
