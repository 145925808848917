import React from 'react';
import clsx from 'clsx';

import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { SvgIconComponent } from '@mui/icons-material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 4,

      '&.MuiIconButton-edgeStart': {
        marginLeft: -4,
      },
      '&.MuiIconButton-edgeEnd': {
        marginRight: -4,
      },
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(33),
      },
    },
  });
// closing tag

interface Props extends IconButtonProps {
  children?: React.ReactElement<SvgIconComponent>;
  className?: string;
}

const IconButton = withStyles(styles)(
  (props: WithStyles<typeof styles> & Omit<IconButtonProps, keyof Props> & Props) => {
    const { classes, className, children, ...other } = props;

    return (
      <MuiIconButton className={clsx(classes.root, className)} {...other}>
        {children}
      </MuiIconButton>
    );
  }
);

export { IconButton };
