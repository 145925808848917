import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: grey[100],
    display: 'flex',
    height: '100vh',

    '& h1': {
      margin: 'auto',
    },
  },
}));

export { useStyles };
