import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PaymentSuccess from './PaymentSuccess.view';

const PaymentSuccessLogic: React.FC = () => {
  const [counter, setCounter] = useState(60);
  const navigation = useHistory();

  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  let appleLink: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
  if (!appleLink) {
    appleLink = document.createElement('link');
    appleLink.rel = 'apple-touch-icon';
    document.getElementsByTagName('head')[0].appendChild(appleLink);
  }
  appleLink.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    if (counter <= 0) {
      navigation.push('/');
    }
    return () => {
      clearInterval(interval);
    };
  }, [navigation, counter]);

  const search = useLocation().search;
  const orderId = new URLSearchParams(search).get('orderId');

  return <PaymentSuccess orderId={orderId ?? 'invalidOrderId'} counter={counter} />;
};

export { PaymentSuccessLogic };
