import { useCallback } from 'react';

import { useAuthContext } from '@micro-frontends/auth-context';
import {
  getListingsByCollectionId,
  getOptionPlanWithRelatedCollection,
  Metadata,
} from '@micro-frontends/vacayz-api-client';

import { Option } from '../types';

const PAGE = 1;
const PER_PAGE = 5;

const useOptionFetcher = (
  metadata: Metadata | null,
  brandId?: number | undefined
): ((page: number, perPage: number) => Promise<Option[] | undefined>) => {
  const { client } = useAuthContext();

  const fetcher = useCallback(
    async (page: number, perPage: number): Promise<Option[] | undefined> => {
      if (metadata) {
        const [optionPlanWithRelatedCollectionPromise] = getOptionPlanWithRelatedCollection(
          client,
          metadata.entityId,
          metadata.entityType,
          brandId,
          page,
          perPage
        );

        const optionPlanWithRelatedCollection = (await optionPlanWithRelatedCollectionPromise)?.data;
        if (!optionPlanWithRelatedCollection) return undefined;

        const optionPromises = optionPlanWithRelatedCollection.map(async (el) => {
          const [listingsByCollectionIdPromise] = getListingsByCollectionId(client, el.collectionId, PAGE, PER_PAGE);
          const listings = (await listingsByCollectionIdPromise)?.data;

          return {
            ...el,
            listing: listings,
          };
        });

        return await Promise.all(optionPromises);
      }

      return undefined;
    },
    [brandId, client, metadata]
  );

  return fetcher;
};

export { useOptionFetcher };
