import React, { useState } from 'react';
import clsx from 'clsx';

import Container from '@mui/material/Container';
import { AppContainerHeader } from './AppContainerHeader';
import { AppContainerSidebar } from './AppContainerSidebar';
import { AppContainerNav } from './AppContainerNav';
import { UserInfo } from '@micro-frontends/vacayz-api-client';

import { SIDEBAR_PARAMS } from '../../themes';
import { History } from 'history';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: '100vh',
    width: '100%',
    transition: 'all .2s',
    paddingTop: 102,
    paddingBottom: 16,
    marginLeft: 0,

    [theme.breakpoints.up('md')]: {
      marginLeft: `${SIDEBAR_PARAMS.widthClose}px !important` as any,
      maxWidth: `calc(100% - ${SIDEBAR_PARAMS.widthClose}px)`,
      paddingBottom: 24,

      '&.sidebarOpen': {
        marginLeft: `${SIDEBAR_PARAMS.widthOpen}px !important` as any,
        maxWidth: `calc(100% - ${SIDEBAR_PARAMS.widthOpen}px)`,
      },
    },
  },
}));

export interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  adminOnly?: boolean;
}

interface Props {
  notify?: boolean;
  children?: React.ReactNode;
  title?: string;
  navbar?: ListItemLinkProps[];
  history?: History;
  user?: UserInfo;
  settingsUrl?: string;
  isAdmin?: boolean;
  onSignIn?: () => void;
  onSignOut?: () => void;
}

const AppContainer: React.FC<Props> = ({
  children,
  navbar,
  title,
  history,
  user,
  settingsUrl,
  isAdmin,
  onSignIn,
  onSignOut,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const classes = useStyles();

  return (
    <Container className={clsx(classes.root)} maxWidth={false}>
      <AppContainerHeader
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        title={title}
        user={user}
        settingsUrl={settingsUrl ?? ''}
        onSignIn={onSignIn}
        onSignOut={onSignOut}
      />
      <AppContainerSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
        <AppContainerNav listItems={navbar} history={history} admin={isAdmin} />
      </AppContainerSidebar>
      <Container className={clsx(sidebarOpen && 'sidebarOpen', classes.body)} maxWidth={false}>
        {children ?? false}
      </Container>
    </Container>
  );
};

export { AppContainer };
