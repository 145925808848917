import React from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import AddIcon from '@mui/icons-material/Add';

import { Header, HeaderUser, HeaderUserPopup /*, HeaderFeatures*/ } from '../Header';
import { IconButton /*, Button*/ } from '../_elements';
import { logo } from '../../assets/logo';
// import styled from 'styled-components';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { COLORS, FONT_WEIGHT } from '../../themes';
import { UserInfo } from '@micro-frontends/vacayz-api-client';
import { useLink } from '../../common/hooks/UseLink.hook';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    sidebarOpen: {
      '& $left': {
        '& .menu': {
          backgroundColor: COLORS.white,

          '& svg': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    left: {
      display: 'flex',
      alignItems: 'center',

      '& .menu': {
        marginLeft: -4,
      },
      '& .menu svg': {
        color: COLORS.white,
      },
      '& .logo': {
        width: 155,
        marginLeft: 14,
      },
      '& h6': {
        padding: '6px 0 0 22px',
        fontSize: theme.typography.pxToRem(20),
        fontWeight: FONT_WEIGHT.light,
        color: COLORS.white,
      },

      [theme.breakpoints.down('sm')]: {
        '& .logo': {
          width: 100,
          marginLeft: 4,
        },
        '& h6': {
          padding: '6px 0 0 11px',
          fontSize: theme.typography.pxToRem(12),
          fontWeight: FONT_WEIGHT.light,
          color: COLORS.white,
        },
      },
    },
    right: {
      display: 'flex',
      color: COLORS.white,
    },
  });
// closing tag

interface Props extends WithStyles<typeof styles> {
  notify?: boolean;
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  user?: UserInfo;
  settingsUrl: string;
  onSignIn?: () => void;
  onSignOut?: () => void;
}

const AppContainerHeader = withStyles(styles)((props: Props) => {
  const { classes, sidebarOpen, setSidebarOpen, title, user, settingsUrl, onSignIn, onSignOut, ...other } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { goTo } = useLink();

  return (
    <Header className={clsx(classes.root, sidebarOpen && classes.sidebarOpen)} {...other}>
      <Box className={classes.left}>
        <IconButton className="menu" onClick={() => setSidebarOpen(!sidebarOpen)} size="large">
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="logo" className="logo" />
        <h6>{title}</h6>
      </Box>
      <div className={classes.right}>
        {/* <NewRequestButton
          onClick={() => goTo((process.env.REACT_APP_MARKETPLACE_URL + 'offer/request?source=website') as string)}
        >
          <Button startIcon={<AddIcon />} sx={{ backgroundColor: '#152a40' }}>
            <LabelRequest>New Request</LabelRequest>
          </Button>
        </NewRequestButton>
        {!isMobile && (
          <IconButton
            color="inherit"
            onClick={() => goTo((process.env.REACT_APP_HELP_CENTER_URL || '/help') as string)}
            size="large"
          >
            <HelpOutlineIcon />
          </IconButton>
        )}
        <IconButton color="inherit" size="large">
          <NotificationsIcon />
        </IconButton>
        <HeaderFeatures auth={!!user} /> */}
        <HeaderUser
          user={user}
          UserPopup={HeaderUserPopup}
          settingsUrl={settingsUrl}
          onSignIn={onSignIn}
          onSignOut={onSignOut}
        />
      </div>
    </Header>
  );
});

// const NewRequestButton = styled.div`
//   display: flex;
//   margin-right: 10px;
// `;

// const LabelRequest = styled.span`
//   margin-top: auto;
//   margin-bottom: auto;
//   font-size: 20px;
//   background: #152a40;
// `;
export { AppContainerHeader };
