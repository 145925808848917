import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { VacayzClient, createClient, getMyUser } from '@micro-frontends/vacayz-api-client';

import { CognitoUser, CurrentUser } from '../typings/CurrentUser';
import constate from 'constate';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

let _client: VacayzClient;
interface UseAuthProvider {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: CurrentUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser | null>>;
  cognitoUser: CognitoUser | null;
  setCognitoUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  initiated: boolean;
  setInitiated: React.Dispatch<React.SetStateAction<boolean>>;
  isAdmin: () => boolean;
  client: VacayzClient;
}

const useAuthProvider = (): UseAuthProvider => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [initiated, setInitiated] = useState(false);

  Sentry.init({
    dsn: 'https://c29c31eb8c064187b3d6119d58ddd5d3@o1228008.ingest.sentry.io/6373770',
    environment: process.env.NODE_ENV,
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({ id: currentUser.id.toString() });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [currentUser]);

  const isAdmin = useCallback(() => {
    return currentUser?.role === 'admin';
  }, [currentUser?.role]);

  return {
    loading,
    setLoading,
    currentUser,
    setCurrentUser,
    cognitoUser,
    setCognitoUser,
    initiated,
    setInitiated,
    isAdmin,
    client: _client,
  };
};

const [AuthContextProvider, _useAuthContext] = constate(useAuthProvider);
const useAuthContext: () => UseAuthProvider = _useAuthContext;

function withAuthContext(Component: ComponentType, baseUrl: string): ComponentType {
  if (!_client) _client = createClient({ baseUrl });
  const AppWithEffects: React.FC = () => {
    const { setCurrentUser, client, cognitoUser, setInitiated, setLoading } = useAuthContext();

    useEffect(() => {
      let cancel: () => void;
      const setUser = async () => {
        setLoading(true);
        const [userPromise, cancelRequest] = getMyUser(client);
        cancel = cancelRequest;
        try {
          const userInfo = await userPromise;
          if (userInfo) {
            setCurrentUser({ ...userInfo });
          }
        } catch (err) {
          if (err) {
            if (!client?.loggedIn) {
              setCurrentUser(null);
            }
          }
        } finally {
          setInitiated(true);
          setLoading(false);
        }
      };

      setUser();

      return () => cancel?.();
    }, [client, cognitoUser, setCurrentUser, setInitiated, setLoading]);

    return client && client !== undefined ? <Component /> : <React.Fragment> Loading... </React.Fragment>;
  };

  const AppWithAuth: React.FC = () => {
    return (
      <AuthContextProvider>
        <AppWithEffects />
      </AuthContextProvider>
    );
  };

  return AppWithAuth;
}

export { AuthContextProvider, useAuthContext, withAuthContext };
