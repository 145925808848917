import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { format } from 'date-fns';
import { Theme } from '@mui/material';

export interface StayDateBoxProps {
  date: Date;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'date-box': {
      height: '60px',
      width: '60px',
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const StayDateBox = ({ date }: StayDateBoxProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper elevation={0} square className={classes['date-box']}>
      <Box p={1}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
          <Typography variant="body1" className="month">
            {format(date, 'MMM')}
          </Typography>
          <Typography variant="body1" className="day">
            {format(date, 'd')}
          </Typography>
        </Grid>
      </Box>
    </Paper>
  );
};

export { StayDateBox };
