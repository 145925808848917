import Amplify, { Auth } from 'aws-amplify';
import { camelCase, isObjectLike, snakeCase } from 'lodash';
import axios from 'axios';

import { VacayzClient } from './typings';
import awsConfig from './aws-config';

// setup amplify
// https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
Amplify.configure(awsConfig);

function createClient(config: { baseUrl: string }): VacayzClient {
  const instance = axios.create() as VacayzClient;
  instance.defaults.baseURL = config.baseUrl;
  instance.interceptors.request.use(async (request) => {
    try {
      const jwtToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      request.headers['Authorization'] = `Bearer ${jwtToken}`;
      instance.loggedIn = true;
    } catch (error) {
      instance.loggedIn = false;
    } finally {
      return request;
    }
  });
  instance.interceptors.request.use(async (request) => {
    if (request.data && request.headers['Content-Type'] !== 'multipart/form-data') {
      request.data = changeKeysCase(request.data, snakeCase);
    }
    return request;
  });
  instance.interceptors.response.use((response) => {
    if (response.data) {
      response.data = changeKeysCase(response.data, camelCase);
    }
    return response;
  });
  return instance;
}

type Dict = Record<string, unknown>;

const isObject = (value: unknown): value is Dict => {
  return isObjectLike(value);
};

const changeKeysCase = (obj: unknown | Dict | unknown[], caseModifier: (value?: string) => string): unknown => {
  if (Array.isArray(obj)) {
    return obj.map((v: unknown) => changeKeysCase(v, caseModifier));
  } else if (obj !== null && isObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [caseModifier(key)]: changeKeysCase(obj[key], caseModifier),
      }),
      {}
    );
  }
  return obj;
};

export { createClient };
