import React, { createContext, useContext, useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { ErrorResponse } from '@micro-frontends/vacayz-api-client';
import Slide, { SlideProps } from '@mui/material/Slide';

interface AlertContextType {
  showAlert: boolean;
  handleCloseAlert: () => void;
  message: React.ReactNode;
  variant: AlertColor;
  alert: (message: string | ErrorResponse, variant?: AlertColor) => void;
}

const AlertContext = createContext<AlertContextType>({
  showAlert: true,
  handleCloseAlert: () => {
    //
  },
  message: '',
  variant: 'success',
  alert: (message: string | ErrorResponse, variant?: AlertColor) => {
    //
  },
});

const AlertProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState<React.ReactNode>('');
  const [variant, setVariant] = useState<AlertColor>('success');
  const alert = (msg: string | ErrorResponse, alertType: AlertColor = 'info') => {
    if (typeof msg === 'string') {
      setMessage(msg);
    } else {
      try {
        setMessage(msg.error.message);
      } catch (e) {
        setMessage('Unknow message.');
      }
    }
    setShowAlert(true);
    setMessage(msg);
    setVariant(alertType);
  };

  function closeAlert() {
    setShowAlert(false);
  }

  return (
    <AlertContext.Provider value={{ alert, showAlert, handleCloseAlert: closeAlert, message, variant }}>
      {children}
    </AlertContext.Provider>
  );
};

interface MessageProps {
  message?: string;
  type?: AlertColor;
  open?: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertSnackbar: React.FC<MessageProps> = () => {
  const { showAlert, handleCloseAlert, message, variant } = useContext(AlertContext);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    handleCloseAlert();
  };
  const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export { AlertProvider, AlertSnackbar, AlertContext };
