/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { getBookingRequest, BookingRequest, getStringToDate } from '@micro-frontends/vacayz-api-client';
import { getUrlParam } from '../EventDealsView/EventDealsView';
import { Box, Typography, Card, Container, Divider, Grid, AppBar, Toolbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuthContext } from '@micro-frontends/auth-context';
import { currencyValue } from '../../common/utils/Format';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const BookingSuccess: React.FC = () => {
  const { currentUser, client } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [bookingRequest, setBookingRequest] = useState<BookingRequest>();

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect(() => {
    (async () => {
      const bookingRequestId = parseInt(getUrlParam('id'));

      try {
        const [getBookingRequestHandle, getBookingRequestCancel] = getBookingRequest(client, bookingRequestId || 0);
        const bookingRequest = await getBookingRequestHandle;
        setBookingRequest(bookingRequest);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    })();
  }, []);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const loadingPleaseWait = (
    <Box sx={{ pt: 10, color: 'primary' }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress sx={{ color: 'primary' }} />
      <Box sx={{ mt: 2 }}>
        <Typography color="primary" variant="h4">
          Loading, please wait...
        </Typography>
      </Box>
    </Box>
  );

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  let content = null;

  if (bookingRequest !== undefined) {
    const destination = bookingRequest.destinations[0];
    const room = destination.rooms[0];
    const deal = room.deals[0];

    const checkIn = `${dayNames[getStringToDate(room.checkIn).getDay()]}, ${room.checkIn}`;
    const checkOut = `${dayNames[getStringToDate(room.checkOut).getDay()]}, ${room.checkOut}`;
    const nights = Math.round(
      (getStringToDate(room.checkOut).getTime() - getStringToDate(room.checkIn).getTime()) / 1000 / 60 / 60 / 24 ?? 1
    );

    const gridCell = (title: string, content: string | null) => (
      <Grid item xs={4}>
        <Typography fontWeight="bold">{title}</Typography>
        <Typography>{content}</Typography>
      </Grid>
    );

    content = (
      <Grid container>
        <Grid item xs={12} sx={{ mt: 5, textAlign: 'center' }}>
          <Typography variant="h4">Thank you!</Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: 5, textAlign: 'center' }}>
          <Typography variant="h6">
            We are pleased to inform you that your reservation request has been received and confirmed.
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mb: 5 }}>
          <Card variant="outlined" sx={{ mt: 5, p: 5, typography: 'body1' }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6">Booking Details</Typography>
            </Box>

            <Divider sx={{ mt: 4, mb: 4 }} />

            <Grid container>
              {gridCell('Hotel', deal.name)}
              {gridCell('Location', deal.address)}
              {gridCell('Extras', deal.breakfast ? 'Breakfast included' : '')}
            </Grid>

            <Divider sx={{ my: 4 }} />

            <Grid container>
              {gridCell('Check in', checkIn)}
              {gridCell('Check out', checkOut)}
              {gridCell('Nights', `${nights} ${nights > 1 ? 'nights' : 'night'}`)}
            </Grid>

            <Divider sx={{ my: 4 }} />

            <Grid container>
              {gridCell(
                'Guests',
                `${bookingRequest.guests.length} ${bookingRequest.guests.length > 1 ? 'guests' : 'guest'}`
              )}
              {gridCell('Amount', currencyValue(deal.vendorPriceTotal) || '')}
              {gridCell('Payment method', `(This doesn't appear to be in the response data)`)}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 5, textAlign: 'center' }}>
          <Typography variant="h6">A receipt will be sent to your email address.</Typography>
        </Grid>
      </Grid>
    );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <Box>
      <AppBar position="static" sx={{ mb: 2 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Klerk
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">{isLoading ? loadingPleaseWait : content}</Container>
    </Box>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/* eslint-enable */

export { BookingSuccess };
