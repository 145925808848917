import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { useAuthContext, withAuthContext } from '@micro-frontends/auth-context';
import { FormErrorsProvider, AlertSnackbar, AlertProvider, useAsyncCleanup } from '@micro-frontends/shared-components';
import { AppProvider, BrandProvider, DealProvider, MultiProvider, StripeElementsProvider } from './common/contexts';
import AppRouter from './AppRouter';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { IdentificationToken, isVacayzError } from '@micro-frontends/vacayz-api-client';

export interface RoutingParams {
  urlName?: string;
  brandName?: string;
}

interface HubSpotStatus {
  loaded: boolean;
}

interface GtmLayer {
  event: string;
}

declare global {
  interface Window {
    hsConversationsSettings: {
      loadImmediately?: boolean;
      identificationEmail?: string;
      identificationToken?: string;
    };
    dataLayer: {
      push: (gtm: GtmLayer) => void;
    };
    HubSpotConversations: {
      widget: {
        load: () => void;
        refresh: () => void;
        status: () => HubSpotStatus;
      };
    };
    hsConversationsOnReady: { (): void }[];
  }
}

const providers = [AppProvider, BrandProvider, DealProvider, StripeElementsProvider, FormErrorsProvider];

TagManager.initialize({ gtmId: process.env.REACT_APP_MEASUREMENT_ID ?? 'G-GNE7RH8RTN' });

window.hsConversationsSettings = {
  loadImmediately: false,
};

const App: React.FC = () => {
  const { currentUser } = useAuthContext();
  const { isMounted, addCleanup } = useAsyncCleanup();
  const { client } = useAuthContext();
  const [userUniqueChatId, setUniqueChatId] = useState<string>();

  const onConversationsAPIReady = () => {
    const status: HubSpotStatus = window.HubSpotConversations.widget.status();
    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    } else {
      window.HubSpotConversations.widget.load();
    }
  };

  const getUserUniqueToken = useCallback(async () => {
    const [_request, cancel] = IdentificationToken(client);
    addCleanup(cancel);
    try {
      const requestToken = await _request;
      if (!isMounted()) return;
      if (requestToken && currentUser) {
        setUniqueChatId(requestToken.token);
      }
    } catch (err) {
      if (err && isMounted() && isVacayzError(err)) {
        if (err.error.code === 404) return [];
      }
    }
  }, [addCleanup, client, isMounted, currentUser]);

  useEffect(() => {
    const initialApp = async () => {
      if (currentUser && currentUser.id) {
        getUserUniqueToken();
      }
    };

    initialApp();
  }, [currentUser, getUserUniqueToken]);

  useEffect(() => {
    if (userUniqueChatId && currentUser?.email) {
      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: currentUser?.email || '',
        identificationToken: userUniqueChatId || '',
      };

      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }
    }
  }, [currentUser?.email, userUniqueChatId]);

  window.dataLayer.push({
    event: 'pageview',
  });

  return (
    <React.Fragment>
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src={process.env.REACT_APP_HUBSPOT_JS} />
      </Helmet>
      <AlertProvider>
        <MultiProvider providers={providers}>
          <AppRouter />
        </MultiProvider>
        <AlertSnackbar />
      </AlertProvider>
    </React.Fragment>
  );
};

const runContainers = (Component: ComponentType): ComponentType => {
  const WrappedComponent = withAuthContext(Component, process.env.REACT_APP_BASE_URL ?? '');
  return WrappedComponent;
};

export default runContainers(App);
