import React from 'react';

import { HeaderBar } from './components/Header';

interface Props {
  transparent?: boolean;
}

const HeaderView: React.FC<Props> = ({ transparent }) => {
  return <HeaderBar />;
};

export { HeaderView };
