import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { MainContainer, OptionsTable } from '@micro-frontends/shared-components';
import { DealSubmit, DealTitle } from '../_components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Metadata } from '@micro-frontends/vacayz-api-client';
import { useOptionFetcher } from '../../../common/hooks';
import { Option } from '../../../common/types';
import { KEYS_OPTION_PLAN } from '../../../common/constants/keys';
import { DealQuestions } from '../DealQuestions/DealQuestions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0, 6),
  },
  title: {
    padding: theme.spacing(6, 0, 3),
  },
  footer: {
    textAlign: 'center',

    '& .MuiTypography-root': {
      padding: theme.spacing(6, 0, 3),
    },
    '& .MuiButton-root': {
      margin: theme.spacing(0, 1),
    },
  },
}));

interface Props {
  metadata: Metadata;
  brandId?: number;
}

const DealOptions: React.FC<Props> = ({ metadata, brandId }) => {
  const classes = useStyles();
  const history = useHistory();
  const fetcher = useOptionFetcher(metadata, brandId);

  const handleOption = useCallback(
    (data: Option) => {
      history.push(`/deal?optionPlanId=${data.optionPlanId}&collectionId=${data.collectionId}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [history]
  );

  return (
    <div className={classes.root}>
      <MainContainer style={{ textAlign: 'center' }}>
        <DealSubmit />
        <DealTitle className={classes.title}>More Travel Options</DealTitle>
      </MainContainer>
      <OptionsTable fetcher={fetcher} onClick={handleOption} keys={KEYS_OPTION_PLAN} />
      <DealQuestions />
    </div>
  );
};

export { DealOptions };
