import React from 'react';

import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { DealGallery } from '../DealGallery/DealGallery';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Listing } from '@micro-frontends/vacayz-api-client';
import { useDealContext } from '../../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  address: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginLeft: -4,
  },
  logo: {
    display: 'block',
    maxWidth: 177,
    maxHeight: 77,
    margin: theme.spacing(2, 0),
  },
  stars: {},
}));

interface Props {
  hotel: Listing;
  showImages: boolean;
}

const DealHotelHeader: React.FC<Props> = ({ hotel, showImages }) => {
  const classes = useStyles();
  const { dealData } = useDealContext();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" className={classes.address}>
            <RoomOutlinedIcon />
            <span>{hotel.address}</span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            ____
          </Typography>
          <img src={dealData?.brandLogoUrl} alt="brandLogo" className={classes.logo} />
          <Rating readOnly value={hotel.stars} className={classes.stars} />
        </Grid>
        {showImages ? (
          <Grid item xs={12} md={8}>
            <DealGallery images={hotel.images} />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export { DealHotelHeader };
