import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
  Button,
  Grid,
  TextField,
  DialogTitle,
  Divider,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { BookingforGuest, saveBookingGuestList } from '@micro-frontends/vacayz-api-client';
import { useAuthContext } from '@micro-frontends/auth-context';
import { useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';
interface Props {
  open: boolean;
  requestId: number;
  roomId: number;
  dealId: number;
  quantity: number;
  destinationId: number;
  guests: BookingforGuest[];
  setHandleOpen: (isOpen: boolean) => void;
  updateHandle: (destId: number, roomId: number, dealId: number, guests: BookingforGuest[]) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GuestAddModal: React.FC<Props> = ({
  open,
  requestId,
  roomId,
  dealId,
  destinationId,
  quantity,
  guests,
  setHandleOpen,
  updateHandle,
}) => {
  const { client } = useAuthContext();
  const { isMounted, addCleanup } = useAsyncCleanup();
  const { alert } = useContext(AlertContext);
  const [dealGuests, setDealGuests] = useState<BookingforGuest[]>(
    guests.length
      ? [
          ...guests,
          ...Array.from({ length: quantity - guests.length }, (_) => ({
            address: '',
            name: '',
          })),
        ]
      : Array.from({ length: quantity }, (_) => ({ address: '', name: '' }))
  );

  const saveGuests = async () => {
    try {
      const [confirm, cancel] = saveBookingGuestList(client, requestId, roomId, dealId, dealGuests);
      addCleanup(cancel);
      const success = await confirm;
      if (success && isMounted()) {
        updateHandle(destinationId, roomId, dealId, dealGuests);
        setHandleOpen(false);
        alert('Guests are saved.', 'success');
      }
    } catch (err) {
      alert("Couldn't save guests please try again later", 'error');
    }
  };

  const changeEvent = (id: number, value: string, fieldName: string) => {
    const newGuests: BookingforGuest[] = [...dealGuests];
    if (fieldName === 'name') {
      newGuests[id].name = value;
    } else {
      newGuests[id].address = value;
    }
    setDealGuests(newGuests);
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={() => setHandleOpen(false)}>
      <DialogTitle style={{ textAlign: 'center' }}>Add Guests</DialogTitle>
      <DialogContent>
        {dealGuests.map((guest, index) => (
          <Grid container key={index}>
            <Grid item xs={6} padding={1}>
              <TextField
                label="Name"
                name="name"
                variant="standard"
                value={guest.name}
                onChange={(e) => changeEvent(index, e.target.value, 'name')}
              />
            </Grid>
            <Grid item xs={6} padding={1}>
              <TextField
                label="Email"
                name="address"
                variant="standard"
                value={guest.address}
                onChange={(e) => changeEvent(index, e.target.value, 'address')}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => setHandleOpen(false)}>Close</Button>
        <Button onClick={() => saveGuests()} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuestAddModal;
