import React from 'react';
import clsx from 'clsx';

import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HEADER_HEIGHT, SIDEBAR_PARAMS } from '../../themes';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: HEADER_HEIGHT,
    width: SIDEBAR_PARAMS.widthOpen,
  },
  paperWeb: {
    transition: 'all .2s',
    overflowX: 'hidden',
    width: SIDEBAR_PARAMS.widthClose,

    '& .MuiList-root .MuiListItemText-root': {
      transition: 'all .2s',
      opacity: 0,
    },
    '&.active, &:hover': {
      width: SIDEBAR_PARAMS.widthOpen,

      '& .MuiList-root .MuiListItemText-root': {
        opacity: 1,
      },
    },
  },
}));

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

const AppContainerSidebar: React.FC<Props> = ({ sidebarOpen, setSidebarOpen, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const drawerMob = (
    <Drawer
      variant="temporary"
      classes={{ paper: classes.paper }}
      open={sidebarOpen}
      onClose={() => setSidebarOpen(false)}
    >
      {children}
    </Drawer>
  );

  const drawerWeb = (
    <Drawer variant="permanent" classes={{ paper: clsx(classes.paper, classes.paperWeb, sidebarOpen && 'active') }}>
      {children}
    </Drawer>
  );

  return isMobile ? drawerMob : drawerWeb;
};

export { AppContainerSidebar };
