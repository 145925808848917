import React from 'react';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import { FONT_WEIGHT } from '../../themes/default.theme';

const useStyles = makeStyles<Theme, { fontSize: number }>((theme) => ({
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  headerTitle: {
    fontSize: (props) => theme.typography.pxToRem(props.fontSize),
    fontWeight: FONT_WEIGHT.medium,
  },
}));

const Title: React.FC<{ fontSize?: number; textClassName?: string; containerClassName?: string }> = ({
  fontSize,
  children,
  textClassName,
  containerClassName,
}) => {
  const classes = useStyles({ fontSize: fontSize ?? 28 });
  return (
    <div className={`${classes.textContainer} ${containerClassName}`}>
      <Typography variant="h3" className={`${classes.headerTitle} ${textClassName}`}>
        {children}
      </Typography>
    </div>
  );
};

export { Title };
