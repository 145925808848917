export const Routing = {
  root: '/',
  blank: '/_',
  deal: '/deal',
  hoteliers: '/hoteliers',
  howItWorks: '/howitworks',
  destination: '/destination/:name',
  dealPurchase: '/purchase',
  dealConfirm: '/confirm',
  business: '/business',
  contact: '/contact',
  covid: '/covid',
  about: '/about',
  help: process.env.REACT_APP_HELP_CENTER_URL || '/help',
  faq: '/faq',
  pms: '/pms',
  offerRequest: '/offer/request',
  offerRequestSuccess: '/offer/request/success', // move to request page with inner router!
  offerRequestFailed: '/offer/request/failed', // move to request page with inner router!
  offerPayment: '/offer/payment',
  offerPaymentSuccess: '/offer/payment/success', // move to payment page with inner router!
  offerPaymentFailed: '/payment/failed', // move to request payment page with inner router!
  offerDeals: '/offer/deals',
  secretRedirect: '/secret',
  viewHandle: '/offer/viewhandle',
  eventDeals: '/eventdealsview',
  viewEventDeal: '/vieweventdeal',
  viewHomeRequest: '/new/requests/:nanoid',
  viewDeals: '/new/requests/:nanoid/deals',
  viewPaymentDeals: '/new/payment/deals',
  loginRedirect: '/loginredirect',
  logupRedirect: '/logupredirect',
  bookingSuccess: '/bookingsuccess',
};
