import { IdentificationType } from './typings';
import { VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel } from '../common/utils';

const IdentificationToken = (client: VacayzClient): VacayzResponse<IdentificationType> => {
  const url = `api/v1/identification/token`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<IdentificationType>(
      url,
      {},
      {
        cancelToken: source.token,
      }
    );
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

export { IdentificationToken };
