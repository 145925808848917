import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const ConfirmText: React.FC = () => {
  return (
    <React.Fragment>
      <Typography variant="subtitle1" fontWeight="500">
        By clicking{' '}
        <Box fontWeight="900" display="inline">
          Confirm & Pay
        </Box>{' '}
        {`below, you agree to ${process.env.REACT_APP_COMPANY_NAME} `}
        <Link href="#" variant="subtitle1" fontWeight="500" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link
          href="https://res.cloudinary.com/vacayz/image/upload/v1610379733/www/Vacayz_Inc_Privacy_Policy.pdf"
          variant="subtitle1"
          fontWeight="500"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </React.Fragment>
  );
};

export { ConfirmText };
