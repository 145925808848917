import { Metadata } from './typings';
import { VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel } from '../common/utils';

const getMetadata = (client: VacayzClient, urlName: string, brandId: number): VacayzResponse<Metadata> => {
  const url = `api/v1/brands/${brandId}/metadata/${urlName}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.get<Metadata>(url, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};
export { getMetadata };
