import { useArray } from './UseArray.hook';
import { useIsMounted } from './UseIsMounted.hook';
import { useUnmount } from './UseUnmount.hook';

export interface AsyncCleanupMethods {
  isMounted: () => boolean; // component is mounted (on screen)
  addCleanup: (cleanup: () => void) => void; // adds cleanup method or cancel method
}

const useAsyncCleanup = (): AsyncCleanupMethods => {
  const isMounted = useIsMounted();
  const { value: cancellables, add: addCleanup } = useArray<() => void>([]);
  useUnmount(() => cancellables.forEach((cancel) => cancel?.()));

  return { isMounted, addCleanup };
};

export default useAsyncCleanup;
export { useAsyncCleanup };
