import React, { useCallback, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DealCard, DealTitle } from '../_components';
import { DealHotelHeader } from './DealHotelHeader';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '@mui/material/Icon';
import {
  Amenity,
  getListingAmenities,
  getListingById,
  Listing,
  ListingAmenities,
} from '@micro-frontends/vacayz-api-client';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { COLORS, useArray, useUnmount } from '@micro-frontends/shared-components';
import { useDealContext } from '../../../common/contexts';
import { useAuthContext } from '@micro-frontends/auth-context';

import AccordionDetails from '@mui/material/AccordionDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(3, 0, 2),
  },
  tabs: {
    margin: theme.spacing(0, 1),

    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    minWidth: 'auto',
    maxWidth: '100%',
    marginRight: theme.spacing(1),

    color: COLORS.black,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.spacing(1, 1, 0, 0),

    '&.Mui-selected': {
      color: COLORS.white,
      backgroundColor: COLORS.blue,
      borderRadius: theme.spacing(1, 1, 0, 0),
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  gridContainer: {
    margin: theme.spacing(-1, 0, 3),
  },
  container: {},
}));
interface AmenityIconProps {
  key: number;
  iconName: string;
  displayName: string;
}
const DEFAULT_ICON = 'hotel_icon';

const AmenityIcon: React.FC<AmenityIconProps> = ({ key, iconName, displayName }) => {
  return (
    <Grid zeroMinWidth key={key} container item xs={12} md={4}>
      <Grid item xs={2}>
        <Icon>{iconName}</Icon>
      </Grid>
      <Grid item xs={10}>
        <Typography noWrap>{displayName}</Typography>
      </Grid>
    </Grid>
  );
};

const Amenities = (amenities: Amenity[]) => {
  return amenities.map((el) => {
    return <AmenityIcon key={el.id} iconName={el.iconName ? el.iconName : DEFAULT_ICON} displayName={el.displayName} />;
  });
};

const HealthAndSafetyAmenities = (amenities: Amenity[]) => {
  return amenities.map((el) => (
    <Grid key={el.id} container item xs={12} md={12}>
      <List component="nav" aria-label="contacts">
        <ListItem>
          <Typography noWrap>{el.displayName}</Typography>
        </ListItem>
      </List>
    </Grid>
  ));
};
const DealHotels: React.FC = () => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [hotel, setHotel] = useState<Listing>();
  const [hotelAmenities, setHotelAmenities] = useState<ListingAmenities>();

  const { dealData } = useDealContext();
  const { client } = useAuthContext();
  const { value: cancellables, add } = useArray<() => void>([]);
  useUnmount(() => cancellables.forEach((cancel) => cancel?.()));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const fetchListing = useCallback(
    async (listingId: number) => {
      const [listingPromise, listingCancel] = getListingById(client, listingId);
      add(listingCancel);
      const listingReceived = await listingPromise;

      if (listingReceived) setHotel(listingReceived);
    },
    [add, client]
  );

  const fetchListingAmenities = useCallback(
    async (listingId: number) => {
      const [listingAmenityPromise, listingAmenitiesCancel] = getListingAmenities(client, listingId);
      add(listingAmenitiesCancel);
      const listingAmenityReceived = await listingAmenityPromise;
      if (listingAmenityReceived) setHotelAmenities(listingAmenityReceived);
    },
    [add, client]
  );

  useEffect(() => {
    if (dealData && dealData.listingsNames[index]) {
      fetchListing(dealData.listingsNames[index].id);
      fetchListingAmenities(dealData.listingsNames[index].id);
    }
  }, [index, dealData, fetchListing, fetchListingAmenities]);

  return (
    <div className={classes.root}>
      {dealData && (
        <>
          <DealTitle className={classes.title}>Hotels Included in Selected Travel Option</DealTitle>
          <Tabs
            value={index}
            className={classes.tabs}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {dealData &&
              dealData.listingsNames?.map((listing) => (
                <Tab key={listing.id} label={listing.name} className={classes.tab} />
              ))}
          </Tabs>
        </>
      )}
      {dealData && hotel && (
        <>
          <DealCard className={classes.container}>
            <DealHotelHeader hotel={hotel} showImages={dealData.listingsNames.length > 1} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DealCard>
                  <DealTitle align="left" gutterBottom>
                    About This Hotel
                  </DealTitle>
                  <Typography variant="body1">{hotel.description}</Typography>
                </DealCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <DealCard></DealCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <DealCard>
                  <DealTitle align="left" gutterBottom>
                    Amenities
                    <Box mt={4}>
                      <Grid container className={classes.gridContainer}>
                        {hotelAmenities?.amenities &&
                          hotelAmenities.amenities?.length > 0 &&
                          Amenities(hotelAmenities.amenities)}
                      </Grid>
                    </Box>
                  </DealTitle>
                </DealCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <DealCard>
                  <DealTitle align="left" gutterBottom>
                    Need To Know
                  </DealTitle>
                  <Typography variant="body1">
                    Check In at {hotel.checkInFrom}, check-out at {hotel.checkOutTo}
                  </Typography>
                  <Typography variant="body1">This Travel Option is guaranteed to fit up to 2 people;</Typography>
                  <Typography variant="body1" gutterBottom>
                    Must be {hotelAmenities?.minimumAgeToCheckIn}+ to check in Photo ID and a credit card are required
                    at check-in.
                  </Typography>
                  <Accordion>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <Typography>Read More</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body1">
                        Please note that all Special Requests are subject to availability and additional charges may
                        apply. Your reservation and stay at any hotel are subject to the hotel&apos;s terms of service
                        and policies; It is recommended to make a reservation at least 30-60 days in advance to
                        guarantee availability.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </DealCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <DealCard>
                  <DealTitle align="left" gutterBottom>
                    Room Ameneties
                  </DealTitle>
                </DealCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <DealCard>
                  <DealTitle align="left" gutterBottom>
                    Health & Safety
                  </DealTitle>
                  <Typography variant="body1">
                    In response to the coronavirus (COVID-19), additional safety and sanitation measures are taken in
                    effect at this hotel:
                  </Typography>
                  <Accordion>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <Typography>Read More</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box mt={4}>
                        <Grid container className={classes.gridContainer}>
                          {hotelAmenities?.healthAndSafety &&
                            hotelAmenities.healthAndSafety.length > 0 &&
                            HealthAndSafetyAmenities(hotelAmenities.healthAndSafety)}
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </DealCard>
              </Grid>
            </Grid>
          </DealCard>
        </>
      )}
    </div>
  );
};

export { DealHotels };
