import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getBrandById,
  getCollectionByIdExtended,
  getCollectionImages,
  getLocationById,
  getMetadata,
  getOptionPlanVersionById,
} from '@micro-frontends/vacayz-api-client';
import { DealData, useAppContext, useDealContext } from '../../common/contexts';
import { Routing } from '../../common/constants/routing';
import { useAuthContext } from '@micro-frontends/auth-context';
import { DEAL } from '../../common/constants/Common';

import { DealView } from './Deal.view';
import { useArray, useIsMounted, useUnmount } from '@micro-frontends/shared-components';
import { IMAGE_GALLERY_SIZE } from '../../containers/Deal/DealGallery/DealGallery';

const DealLogic: React.FC = () => {
  const { setRouterOption } = useAppContext();
  const { setDealPayload, setDealData, resetDealContext } = useDealContext();
  const { client } = useAuthContext();

  const { value: cancellables, add } = useArray<() => void>([]);
  useUnmount(() => cancellables.forEach((cancel) => cancel?.()));
  const isMounted = useIsMounted();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const collectionId = query.get('collectionId') && Number(query.get('collectionId'));
  const optionPlanId = query.get('optionPlanId') && Number(query.get('optionPlanId'));

  const dataFetcher = async (): Promise<DealData | undefined> => {
    if (!collectionId || !optionPlanId) {
      return;
    }
    const [_optionPlan, opCancel] = getOptionPlanVersionById(client, optionPlanId);
    add(opCancel);
    const [_collection, colCancel] = getCollectionByIdExtended(client, collectionId);
    add(colCancel);

    const [optionPlan, collection] = await Promise.all([_optionPlan, _collection]);
    if (!collection || !optionPlan) return;

    const [_images] = getCollectionImages(client, collectionId, 1, IMAGE_GALLERY_SIZE);

    const [_brand] = getBrandById(client, collection.brandId);
    const [_location] = getLocationById(client, collection.locationId);
    const [brand, location, images] = await Promise.all([_brand, _location, _images]);
    if (!brand || !location || !images) return;

    const [_metadata] = getMetadata(client, collection.urlName, brand.id);
    const metadata = await _metadata;
    if (!metadata) return;

    return {
      brandId: brand.id,
      brandLogoUrl: brand.darkLogoImage ? brand.darkLogoImage.url : brand.lightLogoImage?.url,
      brandUrlName: '',
      collectionBackgroundImg: collection.image?.url,
      collectionCurrency: collection.currency,
      collectionDisplayName: collection.collectionDisplayName,
      collectionId: collection.id,
      createdAt: optionPlan.createdAt,
      discount: optionPlan.discount,
      locationName: location.name,
      highSeasonWithWeekendsPrice: collection.highSeasonWithWeekendsPrice,
      highSeasonWithoutWeekendsPrice: collection.highSeasonWithoutWeekendsPrice,
      lowSeasonWithWeekendsPrice: collection.lowSeasonWithWeekendsPrice,
      lowSeasonWithoutWeekendsPrice: collection.lowSeasonWithoutWeekendsPrice,
      listingsNames: collection.listingsNames,
      holidaysPrice: collection.holidaysPrice,
      optionPlanId,
      optionPlanName: optionPlan.name,
      optionPlanVersion: optionPlan.updatedAt,
      upfront: optionPlan.upfront,
      validForDays: optionPlan.validForDays,
      validForYears: optionPlan.validForYears,
      metadata,
      images: images.data,
    };
  };

  useEffect(() => {
    if (collectionId && optionPlanId) {
      setRouterOption(DEAL);
      resetDealContext();
      dataFetcher().then((data) => {
        if (!isMounted()) {
          return;
        }
        if (data) {
          setDealData(data);
          setDealPayload({ collectionId, optionPlanId });
        } else history.push(Routing.root);
      });
    } else history.push(Routing.root);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, optionPlanId]);

  return <DealView />;
};

export { DealLogic };
