import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {
  BookingRequestDestination,
  CreatingBookingMoreDealsForm,
  requireBookingMoreDeals,
  calcDateDiff,
} from '@micro-frontends/vacayz-api-client';
import { SelectedDeal } from '../OfferDeals.view';
import { Table, TableCell, TableHeader, TableHeaderContainer } from '../../RequestForm/OfferCreation/DealsCreation';
import {
  CheckBox,
  FreeTextArea,
  QuantityInput,
  Span,
  TableRoom,
} from '../../RequestForm/OfferCreation/DestinationItem';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import OffersScreen from './OffersScreen';
import { MoreDealsModal } from './MoreDealsModal';
import { useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import { Typography } from '@mui/material';

type Props = {
  requestId?: number;
  destination: BookingRequestDestination;
  changeSelectedDeals?: (selectedDeal: SelectedDeal) => void;
  viewOnly: boolean;
  isCanceled: boolean;
  isPaid: boolean;
};

const DestinationCard: React.FC<Props> = ({
  requestId,
  destination,
  changeSelectedDeals,
  viewOnly,
  isCanceled,
  isPaid,
}) => {
  const current = new Date();
  const currentDate = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
  const moreOfferDeal = {
    request_id: requestId ?? 0,
    destination_request_id: destination.id ?? 0,
    reason_id: 0,
    reason_description: '',
  };

  const [moreDealsModalOpen, setMoreDealsModalOpen] = useState(false);
  const { client } = useAuthContext();
  const { addCleanup, isMounted } = useAsyncCleanup();
  const { alert } = useContext(AlertContext);

  const moreDealsOpenWrapper = useCallback(
    (newOpenState) => {
      if (moreDealsModalOpen && !newOpenState) {
        setMoreDealsModalOpen(newOpenState);
      } else if (!moreDealsModalOpen && newOpenState) {
        setMoreDealsModalOpen(newOpenState);
      }
    },
    [moreDealsModalOpen]
  );

  const sendMoreDeals = useCallback(
    async (moreDealsForm: CreatingBookingMoreDealsForm) => {
      try {
        const [_request, cancel] = requireBookingMoreDeals(client, moreDealsForm);
        addCleanup(cancel);
        const request = await _request;
        if (!isMounted()) return;
        if (!request) return alert('Request Not Found', 'error');

        if (!isMounted()) return;
        alert('It is required to create more deals successfully.', 'success');
      } catch (err) {
        alert('It is failed to require Booking More Deals', 'error');
      }
    },
    [addCleanup, alert, client, isMounted]
  );

  return (
    <>
      <DestinationDetails>
        <DestinationTitle>
          <Destination>
            <LocationCityIcon sx={{ color: '#176FBF' }} />
            Destination
          </Destination>
          <DestinationName>
            {isCanceled ? (
              <>
                <CanceledTitle>TERMINATED</CanceledTitle>
                <NormalTitle>{' (' + destination.city + ')'}</NormalTitle>
              </>
            ) : (
              <NormalTitle>{destination.city}</NormalTitle>
            )}
          </DestinationName>
        </DestinationTitle>
        {destination.rooms.map((room, index) => (
          <div key={index}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Table>
                <TableHeaderContainer>
                  <tr>
                    <TableHeader>Check in</TableHeader>
                    <TableHeader>Check out</TableHeader>
                    <TableHeader>
                      # Guests <Span>(per room)</Span>
                    </TableHeader>
                    <TableHeader>
                      Budget <Span>(per night)</Span>
                    </TableHeader>
                    <TableHeader>Desired location</TableHeader>
                    <TableHeader>Hotel preferences</TableHeader>
                    <TableHeader>Room Type</TableHeader>
                    <TableHeader>Parking </TableHeader>
                    <TableHeader>Breakfast</TableHeader>
                    <TableHeader>Quantity</TableHeader>
                  </tr>
                </TableHeaderContainer>
                <tbody>
                  <TableRoom>
                    <TableCell>
                      <Typography paddingRight={1} color="GrayText">
                        {room.checkIn ?? currentDate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography paddingRight={1} color="GrayText">
                        {room.checkOut ?? currentDate}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography paddingRight={1} color="GrayText" textAlign="center">
                        {room.guests}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <BudgetContainer>
                        <BudgetPrefix>$</BudgetPrefix>
                        <QuantityInput disabled type="number" value={room.budget} />
                      </BudgetContainer>
                    </TableCell>
                    <TableCell>
                      <FreeTextArea disabled placeholder="Your desire Location" value={room.location} />
                    </TableCell>
                    <TableCell>
                      <FreeTextArea disabled placeholder="Your preferred Hotel " value={room.preference} />
                    </TableCell>
                    <TableCell>
                      <FreeTextArea disabled placeholder="1 or 2 beds" value={room.roomSpecification} />
                    </TableCell>
                    <TableCell>
                      <CheckBox disabled type="checkbox" checked={room.parking} />
                    </TableCell>
                    <TableCell>
                      <CheckBox disabled type="checkbox" checked={room.breakfast} />
                    </TableCell>

                    <TableCell>
                      <QuantityInput disabled type="number" value={room.quantity} />
                    </TableCell>
                  </TableRoom>
                </tbody>
              </Table>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Table>
                <tbody>
                  <TableRoom>
                    <td colSpan={10}>
                      <OffersScreen
                        destinationId={destination.id}
                        roomId={room.id}
                        deals={room.deals}
                        changeSelectedDeals={changeSelectedDeals}
                        nights={calcDateDiff(room.checkIn, room.checkOut)}
                        quantity={room.quantity}
                        viewOnly={viewOnly}
                      />
                    </td>
                  </TableRoom>
                </tbody>
              </Table>
            </LocalizationProvider>
          </div>
        ))}
        {!isPaid && !isCanceled && (
          <ButtonContainer>
            <MoreButton onClick={() => setMoreDealsModalOpen(true)}>More Offer</MoreButton>
          </ButtonContainer>
        )}
        {moreDealsModalOpen ? (
          <MoreDealsModal
            bookingMoreDeals={moreOfferDeal}
            open={moreDealsModalOpen}
            setCloseModal={moreDealsOpenWrapper}
            requireMoreDeals={sendMoreDeals}
          />
        ) : null}
      </DestinationDetails>
    </>
  );
};

const BudgetContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BudgetPrefix = styled.span`
  font-size: 12px;
`;

const CanceledTitle = styled.span`
  color: red;
  margin-left: -50px;
`;

const NormalTitle = styled.span`
  color: black;
`;

const DestinationDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 40px;
  box-shadow: -3px 3px 12px 1px #00000014;
  border-radius: 15px;
  background: #ffffff;
  padding: 20px;
  margin-top: 10px;
`;

const DestinationTitle = styled.div`
  display: flex;
  width: 100%;
`;

const DestinationName = styled.div`
  flex: 1;
  font-weight: lighter;
  color: #363636;
  align-items: center;
  display: flex;
`;
const Destination = styled.div`
  flex: 1;
  display: flex;
  line-height: 125px;
  justify-content: flex-start;
  align-items: center;
  font-size: 25px;
  color: #176fbf;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const MoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: white;
  width: 125px;
  height: 50px;
  font-size: 18px;
  background-color: #176fbf;
  transition: all 0.1s linear;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
`;

export default DestinationCard;
