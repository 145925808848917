import React from 'react';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@micro-frontends/shared-components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AVAILABILITIES, Availability, AVAILABILITY_LABELS, useDealContext } from '../../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 160,
    marginRight: theme.spacing(2),
  },
  availability: {
    width: 210,

    '& .MuiOutlinedInput-root': {
      borderRadius: 33,
    },
  },
}));

const DealInputs: React.FC = () => {
  const { dealData, numberOfNights, setNumberOfNights, availability, setAvailability } = useDealContext();
  const classes = useStyles();

  const handleNumberOfNight = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dealData) setNumberOfNights(event.target.value);
  };

  const handleAvailability = (event: SelectChangeEvent) => {
    setAvailability(event.target.value as Availability);
  };

  return (
    <div>
      <TextField
        rounded
        value={numberOfNights ?? ''}
        onChange={handleNumberOfNight}
        className={classes.input}
        label="Number of Nights"
      />
      {dealData && (
        <FormControl variant="outlined" size="small" color="secondary" className={classes.availability}>
          <InputLabel>Availability Policy</InputLabel>
          <Select label="Availability" value={availability} onChange={handleAvailability}>
            {AVAILABILITIES.map((el) => (
              <MenuItem key={el} value={el}>
                {AVAILABILITY_LABELS[el]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export { DealInputs };
