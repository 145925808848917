import React from 'react';

interface Props {
  providers: React.FC<unknown>[];
  children: JSX.Element;
}

const MultiProvider: React.FC<Props> = ({ providers, children }) => {
  return providers.reduce((Prev, Curr) => <Curr>{Prev}</Curr>, children);
};

export { MultiProvider };
