import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Card, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HotelIcon from '@mui/icons-material/Hotel';
import { BookingDealNew } from '@micro-frontends/vacayz-api-client';

const MOCK_DEFAULT_HOTEL_IMAGE =
  'https://i0.wp.com/www.visitbrasil.com/wp-content/uploads/2021/06/CO_Bonito0829cover.jpg';

const useStyles = makeStyles({
  subKey: {
    fontWeight: 500,
    fontSize: '14px',
    opacity: 0.4,
  },

  subValue: {
    fontWeight: 500,
    fontSize: '14px',
  },

  discontContainer: {
    position: 'absolute',
    color: 'white',
    background: '#5BCCCC',
    bottom: 0,
    right: '20px',
  },

  dealButton: {
    '&.MuiButton-root': {
      marginBottom: '25px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#5BCCCC',
    },
  },
});

interface Props {
  selected: boolean;
  deal: BookingDealNew;
  nights: number;
  quantity: number;
  enable: boolean;
  selectDeal: (id: number) => void;
}

const DealCard: React.FC<Props> = ({ selected, deal, nights, quantity, enable, selectDeal }) => {
  const classes = useStyles();

  const handleSelectDeal = useCallback(
    async (selectedDeal: BookingDealNew) => {
      if (enable) {
        selectDeal(selectedDeal.id ?? 0);
      }
    },
    [selectDeal, enable]
  );

  const getDiscount = (price: number, marketPrice: number) => {
    return Math.floor((1 - price / marketPrice) * 100) + '%';
  };

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <Grid container>
        <Grid item xs={12} md={2}>
          <Grid position="relative">
            <CardMedia
              component="img"
              height="180px"
              image={deal.imageUrl ? deal.imageUrl : MOCK_DEFAULT_HOTEL_IMAGE}
              alt="hotel image"
            />
            <Typography variant="h5" component="span" className={classes.discontContainer}>
              {`${getDiscount(deal.price, deal.marketPrice)} OFF`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
                  {deal.name}
                </Typography>
                <Grid container alignItems="center">
                  <LocationOnOutlinedIcon sx={{ margin: '3px 10px 3px 0' }} />
                  <Typography variant="body2" component="span">
                    {deal.address}
                  </Typography>
                </Grid>
                <Grid container alignItems="center">
                  <HotelIcon sx={{ margin: '3px 10px 3px 0' }} />
                  <Typography variant="body2" component="span">
                    {deal.roomType}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Grid container marginTop="8px">
                  <Grid item xs={6} className={classes.subKey}>
                    {'$' + deal.marketPrice.toLocaleString() + '/Night'}
                  </Grid>
                  <Grid item xs={6} className={classes.subValue}>
                    {'$' + deal.price.toLocaleString() + '/Night'}
                  </Grid>
                </Grid>
                <Grid container marginTop="8px">
                  <Grid item xs={6} className={classes.subKey} sx={{ textDecoration: 'line-through' }}>
                    {'$' + (deal.marketPrice * nights * quantity).toLocaleString()}
                  </Grid>
                  <Grid item xs={6} className={classes.subValue} sx={{ fontSize: '22px' }}>
                    {'$' + (deal.price * nights * quantity).toLocaleString()}
                  </Grid>
                </Grid>
                <Grid container marginTop="8px">
                  <Grid item xs={6} />
                  <Grid item xs={6} className={classes.subKey} sx={{ fontSize: '12px' }}>
                    Included Fee
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={2} display="flex" alignItems="center" justifyContent="center">
          <CardActions>
            <Grid sx={{ margin: 'auto' }}>
              <Button
                variant="contained"
                sx={{ background: selected ? '#5BCCCC' : '#152A40' }}
                className={classes.dealButton}
                onClick={() => handleSelectDeal(deal)}
              >
                {selected ? 'Selected' : 'Select Deal'}
              </Button>
            </Grid>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DealCard;
export { DealCard };
