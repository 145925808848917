import React, { useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { COLORS, MainContainer } from '@micro-frontends/shared-components';
import { DealMobCalcModal } from './components/DealMobCalcModal';
import { DealMobCalcBody } from './components/DealMobCalcBody';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    left: 0,
    bottom: 0,
    minHeight: 66,
    backgroundColor: COLORS.white,
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: theme.zIndex.appBar,
  },
}));

const DealMobCalcView: React.FC = () => {
  const [calcisActive, setCalcIsActive] = useState(false);
  const classes = useStyles();

  return (
    <MainContainer className={classes.root}>
      <DealMobCalcBody handleModal={setCalcIsActive} />
      <Drawer anchor="bottom" open={calcisActive} onClose={() => setCalcIsActive(false)}>
        <DealMobCalcModal onClose={() => setCalcIsActive(false)} />
      </Drawer>
    </MainContainer>
  );
};

export { DealMobCalcView };
