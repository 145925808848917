import React from 'react';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@micro-frontends/shared-components';
import { AVAILABILITIES, Availability, AVAILABILITY_LABELS, useDealContext } from '../../../common/contexts';

const DealInputs: React.FC = () => {
  const { dealData, numberOfNights, setNumberOfNights, availability, setAvailability } = useDealContext();

  const handleNumberOfNight = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dealData) setNumberOfNights(event.target.value);
  };

  const handleAvailability = (event: SelectChangeEvent) => {
    setAvailability(event.target.value as Availability);
  };

  return (
    <>
      <TextField fullWidth value={numberOfNights ?? ''} onChange={handleNumberOfNight} label="Number of Nights" />
      <FormControl variant="outlined" size="small" color="secondary" fullWidth>
        <InputLabel>Availability Policy</InputLabel>
        <Select label="Room Type" value={availability} onChange={handleAvailability}>
          {dealData &&
            AVAILABILITIES.map((el) => (
              <MenuItem key={el} value={el}>
                {AVAILABILITY_LABELS[el]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export { DealInputs };
