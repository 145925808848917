import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HEADER_HEIGHT } from '@micro-frontends/shared-components';
import {
  BookingDealNew,
  BookingforGuest,
  BookingRequestDestination,
  BookingRoom,
  calcDateDiff,
  calcNumberToCurrency,
  CreateBookingRequestFormNew,
  OfferStatus,
} from '@micro-frontends/vacayz-api-client';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import DestinationCard from './Components/Destination';
import styled from 'styled-components';
import { Header } from '../../../containers/Header/Header';
import Typography from '@mui/material/Typography';
import OrderWarnModal from './Components/OrderWarnModal';
import PaidWarnModal from './Components/PaidWarnModal';

interface Props {
  requestId?: number;
  bookingRequest: CreateBookingRequestFormNew;
  onSelect: (selectedDeals: SelectedDealObjects[], orderSummary: OrderSummary) => void;
  viewOnly: boolean;
  orderSummaryProp?: OrderSummary;
}
export interface SelectedDeal {
  destinationId: number;
  roomId: number;
  dealId: number;
  oldDealId: number;
}

export interface SelectedDealObjects {
  guests?: BookingforGuest[];
  destination: BookingRequestDestination;
  room: BookingRoom;
  deal: BookingDealNew;
}

export interface OrderSummary {
  totalMarketPrice: number;
  totalPrice: number;
  deals: number;
  totalNights: number;
}

const findObjectsById = (
  bookingRequest: CreateBookingRequestFormNew,
  destinationId: number,
  roomId: number,
  dealId: number
): SelectedDealObjects | null => {
  const filteredDestinations = bookingRequest.destinations.filter((destination) => destination.id === destinationId);
  if (filteredDestinations.length !== 1) return null;
  const filteredRooms = filteredDestinations[0].rooms.filter((room) => room.id === roomId);
  if (filteredRooms.length !== 1) return null;
  const filteredDeals = filteredRooms[0].deals.filter((deal) => deal.id === dealId);
  if (filteredDeals.length !== 1) return null;

  const guets = bookingRequest.guests
    .filter((destination) => destination.destinationId === destinationId)[0]
    .rooms.filter((room) => room.roomId === roomId)[0]
    .deals.filter((deal) => deal.dealId === dealId)[0].item;

  const selectedDealObjects: SelectedDealObjects = {
    destination: filteredDestinations[0],
    room: filteredRooms[0],
    deal: filteredDeals[0],
    guests: guets,
  };
  return selectedDealObjects;
};

const getDealObjects = (
  bookingRequest: CreateBookingRequestFormNew,
  selectedDeals: Record<number, Record<number, number[]>>
): SelectedDealObjects[] => {
  const dealObjects: SelectedDealObjects[] = [];

  for (const destination in selectedDeals) {
    for (const room in selectedDeals[destination]) {
      for (const deal in selectedDeals[destination][room]) {
        const dealId = selectedDeals[destination][room][deal];
        const _dealObjects: SelectedDealObjects | null = findObjectsById(bookingRequest, +destination, +room, +dealId);
        if (_dealObjects) dealObjects.push(_dealObjects);
      }
    }
  }
  return dealObjects;
};

export const calcDiscount = (orderSummary: OrderSummary): string =>
  orderSummary.deals > 0 ? (100 * (1 - orderSummary.totalPrice / orderSummary.totalMarketPrice)).toFixed(2) : '0';

const calculateOrderSummary = (
  bookingRequest: CreateBookingRequestFormNew,
  selectedDeals: Record<number, Record<number, number[]>>
): OrderSummary => {
  let totalMarketPrice = 0;
  let totalPrice = 0;
  let deals = 0;
  let totalNights = 0;

  for (const destination in selectedDeals) {
    for (const room in selectedDeals[destination]) {
      for (const deal in selectedDeals[destination][room]) {
        const dealId = selectedDeals[destination][room][deal];
        const dealObjects: SelectedDealObjects | null = findObjectsById(bookingRequest, +destination, +room, +dealId);
        if (dealObjects) {
          const nights = calcDateDiff(dealObjects.room.checkIn, dealObjects.room.checkOut);
          totalMarketPrice += dealObjects.deal.marketTotalPrice ?? 0;
          totalNights += nights * dealObjects.room.quantity;
          totalPrice += dealObjects.deal.ourPriceTotal;
          deals += 1;
        }
      }
    }
  }
  const orderSummary: OrderSummary = {
    deals: deals,
    totalPrice: totalPrice,
    totalMarketPrice: totalMarketPrice,
    totalNights: totalNights,
  };
  return orderSummary;
};

const OfferDealsView: React.FC<Props> = ({ requestId, bookingRequest, onSelect, viewOnly, orderSummaryProp }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedDeals] = useState<Record<number, Record<number, number[]>>>({});
  const [orderSummary, setOrderSummary] = useState<OrderSummary>({
    deals: 0,
    totalPrice: 0,
    totalMarketPrice: -1,
    totalNights: 0,
  });
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openPaidModal, setOpenPaidModal] = useState(false);

  useEffect(() => {
    if (orderSummaryProp) {
      setOrderSummary(orderSummaryProp);
    }
  }, [orderSummaryProp]);

  const changeSelectedDeals = useCallback(
    async (deal: SelectedDeal) => {
      if (!deal.destinationId || !deal.roomId || !deal.dealId) return;
      if (!(deal.destinationId in selectedDeals)) {
        const innerRecord: Record<number, number[]> = {
          [deal.roomId]: [deal.dealId],
        };

        selectedDeals[deal.destinationId] = innerRecord;
      } else {
        if (deal.dealId > -1) {
          selectedDeals[deal.destinationId][deal.roomId] = [
            ...selectedDeals[deal.destinationId][deal.roomId],
            ...[deal.dealId],
          ];
        } else {
          const _removedRecord = selectedDeals[deal.destinationId][deal.roomId].filter((d) => d !== deal.oldDealId);
          const innerRecord: Record<number, number[]> = {
            [deal.roomId]: [..._removedRecord],
          };
          selectedDeals[deal.destinationId] = innerRecord;
        }
      }

      const _orderSummary = calculateOrderSummary(bookingRequest, selectedDeals);
      setOrderSummary(_orderSummary);
    },
    [bookingRequest, selectedDeals]
  );

  const hasDealSelected = useMemo(() => {
    return orderSummary && orderSummary.deals > 0;
  }, [orderSummary]);

  const getRoomsCount = () => {
    let rooms = 0;
    for (let i = 0; i < bookingRequest.destinations.length; i++) {
      rooms += bookingRequest.destinations[i].rooms.length;
    }

    return rooms;
  };

  const getSelectedDealsCount = () => {
    const selectedDealObjects = getDealObjects(bookingRequest, selectedDeals);
    return selectedDealObjects.length;
  };

  const handleSelectedDealPurchase = () => {
    const selectedCount = getSelectedDealsCount();
    const roomCount = getRoomsCount();

    if (selectedCount < roomCount) {
      setOpenOrderModal(true);
    } else {
      const selectedDealObjects = getDealObjects(bookingRequest, selectedDeals);
      onSelect(selectedDealObjects, orderSummary);
    }
  };

  const handleOpenOrderModal = useCallback(
    async (open: boolean, isContinue: boolean) => {
      setOpenOrderModal(open);
      if (isContinue) {
        const selectedDealObjects = getDealObjects(bookingRequest, selectedDeals);
        onSelect(selectedDealObjects, orderSummary);
      }
    },
    [setOpenOrderModal, bookingRequest, onSelect, orderSummary, selectedDeals]
  );

  const handleOpenPaidModal = useCallback(
    async (open: boolean) => {
      setOpenPaidModal(open);
    },
    [setOpenPaidModal]
  );

  const isActiveDeal = () => {
    return bookingRequest.status !== OfferStatus.CANCELED && bookingRequest.status !== OfferStatus.TERMINATED;
  };

  const isPaidedDeal = () => {
    return bookingRequest.status === OfferStatus.APPROVED || bookingRequest.status === OfferStatus.PENDING_BOOKING;
  };

  return (
    <Container headerSize={HEADER_HEIGHT}>
      <Header />
      <DealsContainer isActive={isActiveDeal()}>
        <Typography
          variant="h4"
          sx={{ textAlign: 'center', padding: '10px', fontWeight: 'bold', color: '#176FBF' }}
          component="div"
        >
          {
            'Here are your curated offers! Please select your preferred accommodation, To secure this rate you will need to order and complete payment in the next 48 hours!'
          }
        </Typography>

        {isMobile ? (
          <MobileDealsSummery>
            <MobileOrderSection>
              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Price'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${calcNumberToCurrency(orderSummary.totalPrice)}`}
                </Typography>
              </MobileSummaryItem>

              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Nights'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }} component="div">
                  {`${orderSummary.totalNights}`}
                </Typography>
              </MobileSummaryItem>

              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Deals Selected'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }} component="div">
                  {`${orderSummary.deals}`}
                </Typography>
              </MobileSummaryItem>

              <MobileSummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Discount'}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: '#56bd6d', textAlign: 'center' }}
                  component="div"
                >
                  {`${calcDiscount(orderSummary)}%`}
                </Typography>
              </MobileSummaryItem>
            </MobileOrderSection>
            <NoteSection>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                {'Notes'}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'lighter', wordWrap: 'break-word' }} component="div">
                {bookingRequest.adminNotes}
              </Typography>
            </NoteSection>
            {!viewOnly && isActiveDeal() && (
              <MobileOrderSection>
                {isPaidedDeal() ? (
                  <PaidButton onClick={() => handleOpenPaidModal(true)}>Paid</PaidButton>
                ) : (
                  <OrderButton onClick={handleSelectedDealPurchase} isActive={hasDealSelected}>
                    Order
                  </OrderButton>
                )}
              </MobileOrderSection>
            )}
          </MobileDealsSummery>
        ) : (
          <DealsSummery>
            <OrderSection>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
                {'Order Summary'}
              </Typography>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Price'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${calcNumberToCurrency(orderSummary.totalPrice)}`}
                </Typography>
              </SummaryItem>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Nights'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${orderSummary.totalNights}`}
                </Typography>
              </SummaryItem>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Deals Selected'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                  {`${orderSummary.deals}`}
                </Typography>
              </SummaryItem>

              <SummaryItem>
                <Typography variant="body1" sx={{ fontWeight: 'lighter' }} component="div">
                  {'Total Discount'}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#56bd6d' }} component="div">
                  {`${calcDiscount(orderSummary)}%`}
                </Typography>
              </SummaryItem>

              {!viewOnly && isActiveDeal() && (
                <SummaryItem>
                  {isPaidedDeal() ? (
                    <PaidButton onClick={() => handleOpenPaidModal(true)}>Paid</PaidButton>
                  ) : (
                    <OrderButton onClick={handleSelectedDealPurchase} isActive={hasDealSelected}>
                      Order
                    </OrderButton>
                  )}
                </SummaryItem>
              )}
            </OrderSection>
            <NoteSection>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                {'Notes'}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'lighter', wordWrap: 'break-word' }} component="div">
                {bookingRequest.adminNotes}
              </Typography>
            </NoteSection>
          </DealsSummery>
        )}

        <Content>
          {bookingRequest.destinations.map((destination) => (
            <DestinationCard
              isCanceled={!isActiveDeal()}
              isPaid={isPaidedDeal()}
              requestId={requestId}
              changeSelectedDeals={changeSelectedDeals}
              destination={destination}
              key={destination.id}
              viewOnly={viewOnly}
            />
          ))}
        </Content>
      </DealsContainer>
      {openOrderModal && (
        <OrderWarnModal
          open={openOrderModal}
          setHandleOpen={handleOpenOrderModal}
          roomCnt={getRoomsCount()}
          selectedCnt={getSelectedDealsCount()}
        />
      )}
      {openPaidModal && <PaidWarnModal open={openPaidModal} setHandleOpen={handleOpenPaidModal} />}
    </Container>
  );
};

const OrderButton = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: ${({ isActive }) => (isActive ? 'white' : 'black')};
  width: 125px;
  height: 50px;
  font-size: 18px;
  background-color: ${({ isActive }) => (isActive ? '#176FBF' : '#e5e5e5')};
  transition: all 0.1s linear;
  cursor: pointer;
  :hover {
    transform: scale(1.05);
  }
`;

const PaidButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: white;
  width: 125px;
  height: 50px;
  font-size: 18px;
  background-color: green;
  transition: all 0.1s linear;
`;

const DealsContainer = styled.div<{ isActive: boolean }>`
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;
export const SummaryItem = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileSummaryItem = styled.div`
  width: 120px;
  justify-content: space-between;
`;

export const DealsSummery = styled.div`
  position: fixed;
  width: 85%;
  bottom: 0;
  z-index: 100;
  min-width: 1024px;
  align-items: center;
  box-shadow: -3px 3px 12px 1px #00000014;
  background: #ffffff;
  padding: 20px;
  border-top: 2px dotted rgba(0, 157, 255, 0.51);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;
export const MobileDealsSummery = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
  align-items: center;
  box-shadow: -3px 3px 12px 1px #00000014;
  background: #ffffff;
  padding: 20px;
  border-top: 2px dotted rgba(0, 157, 255, 0.51);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const OrderSection = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  @media screen and (min-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const MobileOrderSection = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-around;
`;

export const NoteSection = styled.div`
  display: block;
  @media screen and (min-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Container = styled.div<{ headerSize: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ headerSize }) => headerSize * 1.5}px;
  flex: 1;
  width: 85%;
  margin-right: auto;
  margin-left: auto;
`;
const Content = styled.div`
  flex: 1;
  width: 100%;
  min-width: 1024px;
  margin: 0 auto;
  padding-bottom: 160px;
  @media screen and (max-width: 1024px) {
    padding-bottom: 250px;
  }
`;

export default OfferDealsView;

export { OfferDealsView };
