import React from 'react';

import { HEADER_HEIGHT, MainContainer } from '@micro-frontends/shared-components';
import { Header } from '../../containers/Header/Header';
import { DealHeaderCalc } from '../../containers/Deal/DealHeaderCalc/DealHeaderCalc.view';
import { DealHeaderTitle } from '../../containers/Deal/DealHeaderTitle/DealHeaderTitle.view';
import { DealBodyView } from '../../containers/Deal/DealBodyView/DealBodyView';
import { DealHotels } from '../../containers/Deal/DealHotels/DealHotels.view';
import { DealOptions } from '../../containers/Deal/DealOptions/DealOptions';
import { Footer } from '../../components';
import { DealSkeleton } from './DealSkeleton';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDealContext } from '../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: HEADER_HEIGHT * 2,
    paddingTop: theme.spacing(3),
  },
  progress: {
    width: '100%',
    position: 'fixed',
    top: HEADER_HEIGHT * 2,
    left: 0,
  },
}));

const DealView: React.FC = () => {
  const classes = useStyles();
  const { dealData } = useDealContext();

  return (
    <div className={classes.root}>
      <Header />
      <DealHeaderCalc />
      {!!dealData ? (
        <MainContainer>
          <DealHeaderTitle />
          <DealBodyView />
          <DealHotels />
        </MainContainer>
      ) : (
        <DealSkeleton />
      )}

      {dealData && <DealOptions metadata={dealData.metadata} brandId={dealData.brandId} />}
      <Footer />
    </div>
  );
};

export { DealView };
