import { useLocation } from 'react-router-dom';

type StringDict<T extends Record<string, unknown>> = { [key in keyof T]?: string };

const useQueryStringParams = <T extends Record<string, unknown>>(keys: (keyof T)[]): StringDict<T> => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const params: StringDict<T> = {};
  keys.forEach((_key) => {
    const key = String(_key);
    if (queryParams.has(key)) {
      params[_key] = queryParams.get(key) ?? undefined;
    }
  });
  return params;
};

export default useQueryStringParams;
export { useQueryStringParams };
