/* eslint-disable no-console */
import { useCallback, useState } from 'react';

interface Logger {
  debug: (message: string, extraParams?: unknown) => void;
  info: (message: string, extraParams?: unknown) => void;
  warning: (message: string, extraParams?: unknown) => void;
  error: (message: string, exception?: Error | unknown, extraParams?: unknown) => void;
}

const jsonFormat = (time: Date, origin: string, level: string, message: string, extra?: string): string => {
  return JSON.stringify(
    {
      timestamp: time,
      function: origin,
      level,
      message,
      extra,
    },
    null,
    2
  );
};

const lineFormat = (time: Date, origin: string, level: string, message: string, extra?: string): string => {
  let log = `[${time.toISOString()}] [${origin}] [${level}] ${message}`;
  if (extra) {
    log += `\nExtra: ${extra}`;
  }
  return log;
};

const useLogger = (origin: string, format: 'LINE' | 'JSON' = 'LINE'): Logger => {
  const [source] = useState(origin);
  const [logFormat] = useState(format);

  const formatLog = useCallback(
    (message: string, level: string, extraParams?: unknown) => {
      const extra = extraParams ? JSON.stringify({ extraParams }, null, 2) : undefined;
      const date = new Date(Date.now());

      const log =
        logFormat === 'LINE'
          ? lineFormat(date, source, level, message, extra)
          : jsonFormat(date, source, level, message, extra);
      return log;
    },
    [logFormat, source]
  );
  const debug = useCallback(
    (message: string, extraParams?: unknown) => console.debug(formatLog(message, 'DEBUG', extraParams)),
    [formatLog]
  );
  const info = useCallback(
    (message: string, extraParams?: unknown) => console.info(formatLog(message, 'INFO', extraParams)),
    [formatLog]
  );
  const warning = useCallback(
    (message: string, extraParams?: unknown) => console.warn(formatLog(message, 'WARNING', extraParams)),
    [formatLog]
  );
  const error = useCallback(
    (message: string, exception?: Error | unknown, extraParams?: unknown) =>
      console.error(formatLog(message, 'ERROR', { error: exception, details: extraParams })),
    [formatLog]
  );

  const [logger] = useState({ debug, info, warning, error });
  return logger;
};

export { useLogger };
