import { Routing } from './routing';

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  OPTIONS: 'OPTIONS',
  HEAD: 'HEAD',
};

export type Root = 'ROOT';
export type Collection = 'COLLECTION';
export type Brand = 'BRAND';
export type Deal = 'DEAL';
export type Purchase = 'PURCHASE';
export type Confirm = 'CONFIRM';

export const ROOT: Root = 'ROOT';
export const COLLECTION: Collection = 'COLLECTION';
export const BRAND: Brand = 'BRAND';
export const DEAL: Deal = 'DEAL';
export const PURCHASE: Purchase = 'PURCHASE';
export const CONFIRM: Confirm = 'CONFIRM';

export const ANY_URL_NAME = 'WORLDWIDE';
export const NO_BRAND_CHOSEN = 'any';

export const ABOUT_LINKS = [
  { title: 'About Us', href: Routing.about },
  { title: 'How It Works', href: Routing.howItWorks },
  { title: 'For Businesses', href: Routing.business },
  { title: 'For Hotels', href: Routing.hoteliers },
  { title: 'For Property Managers', href: Routing.pms },
  { title: 'COVID-19 Resources', href: Routing.covid },
  { title: 'Contact Us', href: Routing.contact },
];
