import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CONFIRM } from '../../common/constants/Common';
import { useAppContext, useDealContext } from '../../common/contexts';
import { DealConfirmView } from './DealConfirm.view';

const DealConfirmLogic: React.FC = () => {
  const { setRouterOption } = useAppContext();
  const { dealData, dealPayload, numberOfNights } = useDealContext();
  const history = useHistory();

  useEffect(() => {
    if (dealData && dealPayload && numberOfNights) setRouterOption(CONFIRM);
    else history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DealConfirmView />;
};

export { DealConfirmLogic };
