import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, OptionsTableCard } from '@micro-frontends/shared-components';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { KEYS_DEAL_OPTION_PLAN } from '../../common/constants/keys';
import { useDealContext } from '../../common/contexts';

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(22),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    lineHeight: 1.3,
  },
  optionContainer: {
    display: 'flex',
    maxWidth: theme.spacing(50),
    margin: '0 auto',
  },
  optionCard: {
    flex: '0 0 50%',
    maxWidth: '50%',

    '&:first-child': {
      paddingRight: 11,
    },
  },
}));

const DealPurchaseOption: React.FC = () => {
  const classes = useStyles();
  const { dealData } = useDealContext();

  return (
    <Card fullWidth>
      <Typography variant="h1" align="center">
        Your Selected Travel Option
      </Typography>
      <Typography variant="body1" className={classes.caption}>
        {dealData?.optionPlanName}
      </Typography>
      <Box className={classes.optionContainer}>
        <OptionsTableCard header className={classes.optionCard} keys={KEYS_DEAL_OPTION_PLAN} />
        {dealData && <OptionsTableCard data={dealData} className={classes.optionCard} keys={KEYS_DEAL_OPTION_PLAN} />}
      </Box>
    </Card>
  );
};

export { DealPurchaseOption };
