import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import DangerousIcon from '@mui/icons-material/Dangerous';

interface Props {
  open: boolean;
  setHandleOpen: (isOpen: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaidWarnModal: React.FC<Props> = ({ open, setHandleOpen }) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHandleOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid display="flex" alignItems="center">
              <DangerousIcon color="error" fontSize="large" sx={{ marginRight: 1 }} />
              <Typography variant="h6" component="span" color="error">
                {'Your request had been paid already.'}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleOpen(false)}>Back</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaidWarnModal;
