import React from 'react';
import { HomeLogic } from './components/Header';
interface Props {
  transparent?: boolean;
  search?: boolean;
}

const HeaderView: React.FC<Props> = () => {
  return <HomeLogic />;
};

export { HeaderView };
