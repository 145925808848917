import React, { useEffect } from 'react';
import { Header } from '../../../../containers/Header/Header';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '@micro-frontends/shared-components';

const TRAVEL_ICON = 'https://res.cloudinary.com/vacayz/image/upload/v1630236632/travel_logo_pxvw4a.png';

type Props = {
  orderId: string;
  counter: number;
};

const PaymentSuccess: React.FC<Props> = ({ orderId, counter }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer headerSize={HEADER_HEIGHT}>
      <Header />
      <Container>
        <TravelLogo src={TRAVEL_ICON} alt="travel icon" />
        <BrandLogo src={process.env.REACT_APP_COMPANY_DARK_BRAND} alt="brand logo" />

        <Title>Woohoo! You just got yourself a hot deal.</Title>
        <Paragraph>Sit back and relax while your booking is being processed.</Paragraph>
        <Paragraph>
          Order number <Span>{orderId || 'invalid order number'}</Span> Check your <Span>email</Span> for your final
          confirmation.
        </Paragraph>
        <Paragraph size={14}>
          Continue your journey at <Span>{process.env.REACT_APP_COMPANY_NAME}</Span> in {counter}...
        </Paragraph>
      </Container>
    </PageContainer>
  );
};

export const Title = styled.h2`
  color: #176fbf;
  padding-bottom: 5px;
  text-align: center;
`;

const Span = styled.span`
  font-weight: bold;
`;

export const Paragraph = styled.p<{ color?: string; size?: number }>`
  margin: 0;
  line-height: 32px;
  font-size: ${({ size }) => (size ? size : 18)}px;
  font-weight: lighter;
  color: ${({ color }) => (color ? color : '#505564')};
  text-align: center;
`;

const PageContainer = styled.div<{ headerSize: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ headerSize }) => headerSize * 2}px;
  padding-top: 10px;
`;

const BrandLogo = styled.img`
  height: 70px;
  margin: 10px 10px;
`;

const TravelLogo = styled.img`
  height: 150px;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  height: 720px;
  width: 90%;
`;

export default PaymentSuccess;
