import { useState, useCallback, SetStateAction, Dispatch } from 'react';

// Generics are always your friend ♥
interface UseArrayHook<T> {
  value: T[];
  setValue: Dispatch<SetStateAction<T[]>>;
  add: (arrayItem: T) => void;
  clear: () => void;
  removeByValue: (arrayItem: T) => void;
  removeIndex: (index: number) => void;
}

const useArray = <T>(initial: T[]): UseArrayHook<T> => {
  const [value, setValue] = useState<T[]>(initial);
  return {
    value,
    setValue,
    add: useCallback((arrayItem) => setValue((arr) => [...arr, arrayItem]), []),
    clear: useCallback(() => setValue(() => []), []),
    removeByValue: useCallback((arrayItem) => setValue((arr) => arr.filter((val) => val !== arrayItem)), []),
    removeIndex: useCallback(
      (index) =>
        setValue((arr) => {
          const newArr = [...arr];
          newArr.splice(index, 1);
          return newArr;
        }),
      []
    ),
  };
};

export { useArray };
