import React from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { OptionKey } from './OptionsTable';
import { COLORS, FONT_WEIGHT } from '../../themes';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: COLORS.white,
    border: `1px solid ${grey[300]}`,
    boxShadow: `0 2px 2px ${grey[200]}`,
    overflow: 'hidden',
    borderRadius: 8,
  },
  rootKeys: {
    border: 'none',
    boxShadow: 'none',
    paddingTop: 2,

    '& $row': {
      justifyContent: 'flex-start',
      fontWeight: FONT_WEIGHT.medium,

      '& > *': {
        justifyContent: 'flex-start',
      },
      '&:not(:last-child) > *:first-child:after': {
        width: '100%',
        left: 0,
      },
    },
  },
  row: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
    color: COLORS.black,
    position: 'relative',

    '& > *:not(:first-child)': {
      display: 'none',
    },
    '& > *': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px 0 6px 6px',
      height: theme.spacing(5),
    },
    '&:not(:last-child) > *:first-child:after': {
      content: '""',
      width: 'calc(100% - 12px)',
      borderBottom: `1px solid ${grey[300]}`,
      position: 'absolute',
      left: 6,
      bottom: 0,
    },
  },
  textCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface Props<T> {
  className?: string;
  data?: T;
  header?: boolean;
  keys: OptionKey<T>[];
}

const OptionsTableCard = <T extends unknown>({ data, header, className, keys }: Props<T>): JSX.Element | null => {
  const classes = useStyles();

  const TableTitles: React.FC = () => (
    <div className={clsx(classes.root, classes.rootKeys, className)}>
      {keys.map((el, index) => (
        <Box key={index} className={clsx(classes.row, el.value as string)}>
          {el.labelComponent ? (
            el.labelComponent(el)
          ) : (
            <div>
              <Box className={classes.textCell}>{el.label}</Box>
            </div>
          )}
        </Box>
      ))}
    </div>
  );
  const TableData: React.FC<{ data: T }> = ({ data: _data }) => (
    <div className={clsx(classes.root, className)}>
      {keys.map((key, index) => (
        <Box key={index} className={clsx(classes.row, key.value as string)}>
          {key.valueComponent ? (
            key.valueComponent(_data, key.value, index)
          ) : (
            <div>
              <Box className={classes.textCell}>{_data[key.value]}</Box>
            </div>
          )}
        </Box>
      ))}
    </div>
  );
  return header ? <TableTitles /> : data ? <TableData data={data} /> : null;
};

export default React.memo(OptionsTableCard) as typeof OptionsTableCard;
