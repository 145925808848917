import React from 'react';
import { Dialog, DialogContent, Slide, DialogActions, Button, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

interface Props {
  open: boolean;
  invaildDeals: string[];
  setHandleOpen: (isOpen: boolean) => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GuestConfirmModal: React.FC<Props> = ({ open, invaildDeals, setHandleOpen }) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHandleOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography variant="body1">{`You have "${invaildDeals.toString()}" of deals without the guest.`}</Typography>
          <Typography variant="body1">{'By clicking "Add Guest", you will add the guests.'}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GuestConfirmModal;
