/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

import {
  TextField,
  Typography,
  Box,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { useAuthContext } from '@micro-frontends/auth-context';
import * as StripeJs from '@stripe/stripe-js';
import { useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';
import {
  postInstantBookingRequest,
  InstantBookingResponse,
  updateUserInfo,
  createBookingPaymentIntent,
  CreateBookingPaymentIntentRequest,
  BookingPaymentIntent,
  isVacayzError,
  createBookingStripeCharge,
  StripePaymentSecret,
  secretPaymentIntent,
  toQuerystring,
  SecretPaymentIntentData,
  getUserCreditTotalInfo,
  UserCreditTotalInfo,
  BookingforGuest,
  paymentFailedMethod,
  EventDealsResponse,
  InstantBookingDealItem,
  CheckDates,
} from '@micro-frontends/vacayz-api-client';
import { Routing } from '../../common/constants/routing';
import { ThreeSecretModal } from './ThreeSecretModal';
import AddGuestsModal from './AddGuestsModal';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export interface BookingDealPaymentForm {
  guestName: string;
  address: string;
  country: string;
  phone: string;
  instant: InstantBookingResponse;
}

interface Props {
  eventId: string;
  totalAmount: number;
  deal: EventDealsResponse;
  quantity: number;
}

interface StripeError {
  error: string;
}

const isValidStripeError = (value: unknown): value is StripeError => {
  return (value as StripeError).error !== undefined;
};

const CheckoutForm: React.FC<Props> = ({ eventId, totalAmount, deal, quantity }) => {
  const { client, currentUser } = useAuthContext();
  const { addCleanup, isMounted } = useAsyncCleanup();
  const [isLoading, setIsLoading] = useState(false);

  const { alert } = useContext(AlertContext);
  const [firstName, setFirstName] = useState<string>(currentUser?.firstName || '');
  const [lastName, setLastName] = useState<string>(currentUser?.lastName || '');
  const [email, setEmail] = useState<string>(currentUser?.email || '');
  const [phone, setPhone] = useState<string>(currentUser?.phone || '');
  const [company, setCompany] = useState<string>('');
  // const [payerFirstName, setPayerFirstName] = useState<string>('');
  // const [payerLastName, setPayerLastName] = useState<string>('');
  const [payerAddress, setPayerAddress] = useState<string>('');
  const [payerCountry, setPayerCountry] = useState<string>('');
  const [cardValidate, setCardValidate] = useState(false);
  const [creditTotalInfo, setCreditTotalInfo] = useState<UserCreditTotalInfo | null>(null);
  const [creditAmount, setCreditAmout] = useState(0);
  const [bookingPaymentId, setBookingPaymentId] = useState(0);
  const [paymentErr, setPaymentErr] = useState(false);
  const [paymentErrMsg, setPaymentErrMsg] = useState('');

  const elements = useElements();
  const history = useHistory();
  const stripe = useStripe();
  const [receiveMessage, setReceiveMessage] = useState<boolean>(false);
  const [secretSrc, setSecretSrc] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [dealGuests, setDealGuests] = useState<BookingforGuest[]>([
    ...[
      {
        name: currentUser ? `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''}` : '',
        address: currentUser ? currentUser.email : '',
        roomNumber: 1,
      } as BookingforGuest,
    ],
    ...Array.from({ length: deal.countGuests * 3 - 1 }, (_, i) => ({
      name: '',
      address: '',
      roomNumber: Math.trunc((i + 1) / deal.countGuests) + 1,
    })),
  ]);

  const getUserCreditInformation = async () => {
    try {
      const [_resCreditTotal, _cancel] = getUserCreditTotalInfo(client);
      addCleanup(_cancel);
      const res_data = await _resCreditTotal;
      if (!isMounted()) return;
      if (res_data && res_data.amount / 100 > 0) {
        const _creditAmount = res_data.amount / 100;
        const _total = totalAmount * quantity;
        setCreditTotalInfo(res_data);
        setCreditAmout(_total > _creditAmount ? _creditAmount : _total);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getUserCreditInformation();
  }, [eventId]);

  useEffect(() => {
    if (creditTotalInfo) {
      setCreditAmout(
        creditTotalInfo.amount / 100 > totalAmount * quantity ? totalAmount * quantity : creditTotalInfo.amount / 100
      );
    }
  }, [quantity]);

  const handleChangeCredit = () => {
    if (creditAmount === 0 && creditTotalInfo) {
      setCreditAmout(
        creditTotalInfo.amount / 100 > totalAmount * quantity ? totalAmount * quantity : creditTotalInfo.amount / 100
      );
    } else {
      setCreditAmout(0);
    }
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const confirmStripe = async (secret: StripePaymentSecret, cardElement: StripeJs.StripeCardElement) => {
    if (!stripe || !isMounted()) {
      return false;
    }

    try {
      setClientSecret(secret.clientSecret);
      const confirmCard = await stripe?.confirmCardPayment(
        secret.clientSecret,
        {
          payment_method: { card: cardElement },
          return_url:
            window.location.origin + Routing.secretRedirect + toQuerystring({ client_secret: secret.clientSecret }),
        },
        {
          handleActions: false,
        }
      );

      if (confirmCard.paymentIntent?.status === 'requires_action') {
        setSecretSrc(confirmCard.paymentIntent?.next_action?.redirect_to_url?.url ?? '');
        setOpen(true);
        return;
      } else if (confirmCard.paymentIntent?.status === 'succeeded') {
        const [secretPayment, cancel] = secretPaymentIntent(client, {
          id: confirmCard.paymentIntent?.id,
          status: confirmCard.paymentIntent?.status,
          amount: confirmCard.paymentIntent?.amount,
        });
        addCleanup(cancel);
        await secretPayment;

        history.push(Routing.offerPaymentSuccess + toQuerystring({ eventId: eventId }));
        return;
      } else if (confirmCard.paymentIntent?.status === 'requires_payment_method') {
        alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
        return;
      }
    } catch (err) {
      if (err && isMounted() && isVacayzError(err)) {
        alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
        return;
      }
    }

    alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
    return;
  };

  const completeStripeCharge = async (
    vacayzPaymentIntent: BookingPaymentIntent,
    cardElement: StripeJs.StripeCardElement
  ) => {
    if (!currentUser || isLoading || !eventId || !vacayzPaymentIntent) {
      return false;
    }

    if (!stripe || !isMounted()) {
      return false;
    }

    const _card = elements?.getElement(CardElement);
    let secret;
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        card: cardElement,
        billing_details: {
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: currentUser.email,
          phone: currentUser.phone,
          address: {
            line1: vacayzPaymentIntent.address,
          },
        },
        type: 'card',
      });

      if (paymentMethod.error || !paymentMethod.paymentMethod?.id) {
        alert('The payment is unsuccessful. The payment mothod is not working.', 'error');
        return false;
      }

      const [_stripeSecret, cancel] = createBookingStripeCharge(client, {
        bookingPaymentIntentId: vacayzPaymentIntent?.id,
        userId: currentUser?.id,
        paymentMethodId: paymentMethod.paymentMethod?.id,
      });
      addCleanup(cancel);
      secret = await _stripeSecret;
    } catch (err) {
      if (isValidStripeError(err)) {
        setPaymentErr(true);
        setPaymentErrMsg(err.error);
      }
      alert('PaymentIntent client secret was invalid. Please retry with another card', 'error');
      return false;
    }

    if (!secret) {
      return false;
    }

    try {
      if (secret && secret.status === 'succeeded') {
        const [secretPayment, paymentCancel] = secretPaymentIntent(client, {
          id: secret.stripePaymentIntentId,
          status: secret.status,
          amount: vacayzPaymentIntent.amount,
        });
        addCleanup(paymentCancel);
        await secretPayment;

        history.push(Routing.offerPaymentSuccess + toQuerystring({ eventId: eventId, orderId: eventId }));
        return true;
      }
    } catch (err) {
      alert('It is failed to complete the payment', 'error');
      return false;
    }

    setReceiveMessage(false);
    confirmStripe(secret, cardElement);
    return true;
  };

  const handlePaymentIntent = async (bookingFormData: BookingDealPaymentForm, creditAmount: number) => {
    const card = elements?.getElement(CardElement);
    if (creditAmount < totalAmount * quantity && !card) {
      alert('The card element is not exist. Please refresh the page to pay the deals again.', 'error');
      return;
    }

    if (currentUser?.id && !isLoading) {
      try {
        setIsLoading(true);
        const [userInfoPromise, useInfoCancel] = updateUserInfo(client, { phone: bookingFormData.phone });
        addCleanup(useInfoCancel);
        await userInfoPromise;

        const selectedDealIds: number[] = [];

        for (let i = 0; i < bookingFormData.instant.deals.length; i++) {
          selectedDealIds.push(bookingFormData.instant.deals[i]?.id ?? 0);
        }

        const paymentData: CreateBookingPaymentIntentRequest = {
          address: bookingFormData.address,
          bookingRequestId: bookingFormData.instant.requestId,
          country: bookingFormData.country,
          guestName: bookingFormData.guestName,
          dealsSelected: selectedDealIds,
          userId: currentUser?.id,
          isCredit: creditAmount > 0 ? true : false,
        };

        const [pmPromise, pmCancel] = createBookingPaymentIntent(client, paymentData);
        addCleanup(pmCancel);
        const _paymentIntent: BookingPaymentIntent | undefined = await pmPromise;
        if (!_paymentIntent || !isMounted()) {
          throw new Error('Error while processing payment');
        }
        setBookingPaymentId(_paymentIntent?.id);

        if (_paymentIntent.amount === 0 && _paymentIntent.usedCredits > 0) {
          const intentData: SecretPaymentIntentData = {
            id: '',
            status: 'succeeded',
            amount: _paymentIntent.amount,
            bookingPaymentId: _paymentIntent?.id,
          };

          const [secretPayment, cancel] = secretPaymentIntent(client, intentData);
          addCleanup(cancel);
          await secretPayment;
          setIsLoading(false);
          history.push(Routing.offerPaymentSuccess + toQuerystring({ eventId: eventId }));
          return;
        }

        if (card) {
          const bComplete = await completeStripeCharge(_paymentIntent, card);
          if (!bComplete) {
            setIsLoading(false);
            return;
          }
        }

        if (!isMounted()) {
          setIsLoading(false);
          return;
        }
      } catch (err) {
        if (err && isMounted() && isVacayzError(err)) {
          const errors = err.error.errors;
          if (errors.length > 0) {
            alert(errors[0].message, 'error');
          } else {
            alert(err, 'error');
          }
        }
        setIsLoading(false);
      }
    }
  };

  const retrievePayment = async () => {
    const result = await stripe?.retrievePaymentIntent(clientSecret);

    if (result?.error) {
      alert('PaymentIntent client secret was invalid', 'error');
    } else {
      if (result?.paymentIntent.status === 'succeeded') {
        const intentData: SecretPaymentIntentData = {
          id: result.paymentIntent?.id ?? '',
          status: result.paymentIntent?.status ?? '',
          amount: result.paymentIntent?.amount ?? 0,
        };

        const [secretPayment, cancel] = secretPaymentIntent(client, intentData);
        addCleanup(cancel);
        await secretPayment;
        setIsLoading(false);
        history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: eventId }));
        return;
      } else if (result?.paymentIntent.status === 'requires_payment_method') {
        alert(' Authentication failed. Please retry with another payment method.', 'error');
      }
    }

    const [secretPaymentFailed, failedCancel] = paymentFailedMethod(
      client,
      bookingPaymentId,
      result?.error?.message ?? 'It is failed to retrieve the payment intent'
    );
    addCleanup(failedCancel);
    await secretPaymentFailed;

    setIsLoading(false);
  };

  useEffect(() => {
    if (!!receiveMessage) {
      setReceiveMessage(false);
      retrievePayment();
    }
  }, [receiveMessage]);

  window.addEventListener('message', (e) => {
    if (e.origin !== window.origin || e.data.target !== 'secretDialog') {
      return;
    }

    if (e.data.message === clientSecret && !receiveMessage) {
      setReceiveMessage(true);
      setSecretSrc('');
      setOpen(false);
    }
  });
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const checkValidation = () => {
    let isContinue = true;
    for (let i = 1; i <= quantity; i++) {
      let isFilled = false;
      for (let j = 0; j < dealGuests.length; j++) {
        if (dealGuests[j].roomNumber === i && dealGuests[j].name !== '' && dealGuests[j].address !== '') {
          isFilled = true;
        }
      }
      if (isFilled === false && isContinue === true) {
        isContinue = false;
      }
    }

    if (isContinue === false) {
      alert('You must fill the name and email at least with a guest.', 'warning');
      return false;
    }

    if (creditAmount < totalAmount * quantity) {
      if (!cardValidate) {
        alert('You must fill the card information', 'warning');
        return false;
      }
    }

    if (phone.length === 0) {
      alert('You must fill your phone number in user settings', 'warning');
      return false;
    }

    // if (creditAmount < totalAmount && (payerFirstName.length === 0 || payerLastName.length === 0)) {
    //   alert('You must fill your full name in user settings', 'warning');
    //   return false;
    // }

    return true;
  };

  const createEventInstant = async (creditAmount: number) => {
    if (!checkValidation()) return;

    const _eventIds: InstantBookingDealItem[] = [];
    _eventIds.push({ dealId: eventId, quantity });
    const _guests = dealGuests.filter((g) => g.roomNumber && g.roomNumber <= quantity);

    try {
      const [_request, _cancel] = postInstantBookingRequest(
        client,
        _eventIds,
        {
          firstname: firstName,
          lastname: lastName,
          companyName: company,
          email: email,
          phone: phone,
        },
        _guests,
        {
          start: deal.activateStartAt,
          end: deal.activateEndAt,
        }
      );
      addCleanup(_cancel);
      const res = await _request;
      if (res) {
        await handlePaymentIntent(
          {
            guestName: email,
            address: payerAddress,
            country: payerCountry,
            phone: phone,
            instant: res,
          },
          creditAmount
        );
      }
    } catch (err) {
      alert('It is failed to create instant', 'error');
    }
  };

  const handleCardChange = (event: StripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) {
      setCardValidate(true);
    } else {
      setCardValidate(false);
    }
    return;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPaymentErr(false);
    setPaymentErrMsg('');
    createEventInstant(creditAmount);
  };

  const changeGuestHandle = (rId: number, index: number, value: string, fieldName: string) => {
    const _guests = [...dealGuests];
    const _gItem = _guests.filter((g) => g.roomNumber === rId)[index];
    if (_gItem) {
      if (fieldName === 'name') {
        _gItem.name = value;
      } else {
        _gItem.address = value;
      }
    }
    setDealGuests(_guests);
  };

  return (
    <React.Fragment>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box sx={{ m: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">User Info</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6">Guest Rooms</Typography>
            </Grid>

            <Grid item xs={12}>
              {Array.from(Array(quantity), (_, index) => (
                <Card key={index} variant="outlined" sx={{ marginBottom: 1 }}>
                  <CardHeader subheader={`Room ${index + 1}`} sx={{ paddingY: 1 }} />
                  <CardContent sx={{ paddingY: 0 }}>
                    {dealGuests
                      .filter((g) => g.roomNumber === index + 1)
                      .map((g, i) => (
                        <Grid container key={i}>
                          <Grid item xs={6} paddingBottom={1} paddingX={1}>
                            <TextField
                              onChange={(e) => changeGuestHandle(index + 1, i, e.target.value, 'name')}
                              fullWidth
                              label={i === 0 ? 'Guest name' : i === 1 ? '2nd guest name' : '3rd guest name'}
                              value={g.name}
                            />
                          </Grid>
                          <Grid item xs={6} paddingBottom={1} paddingX={1}>
                            <TextField
                              onChange={(e) => changeGuestHandle(index + 1, i, e.target.value, 'address')}
                              fullWidth
                              label={i === 0 ? 'Guest email' : i === 1 ? '2nd guest email' : '3rd guest email'}
                              value={g.address}
                            />
                          </Grid>
                        </Grid>
                      ))}
                  </CardContent>
                </Card>
              ))}
            </Grid>
            {/* {creditAmount < totalAmount && (
              <React.Fragment>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography variant="h6">Payment info</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="outlined"
                    label="First name"
                    value={payerFirstName}
                    onChange={(e) => setPayerFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Last name"
                    value={payerLastName}
                    onChange={(e) => setPayerLastName(e.target.value)}
                  />
                </Grid>
              </React.Fragment>
            )} */}

            {creditTotalInfo ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="span" marginRight={2}>
                      Credit
                    </Typography>

                    <FormControlLabel
                      control={<Switch checked={creditAmount > 0 ? true : false} onChange={handleChangeCredit} />}
                      label=""
                    />
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography>{`US$ ${creditAmount}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {creditAmount < totalAmount * quantity && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body1" component="span" marginRight={2}>
                    Card
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography>{`US$ ${Number(totalAmount * quantity - creditAmount)
                    .toFixed(2)
                    .toLocaleString()}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box px={1} py={1.5} borderRadius="4px" border="1px solid rgba(0, 0, 0, 0.23)">
                    <CardElement onChange={handleCardChange} />
                  </Box>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Address"
                value={payerAddress}
                onChange={(e) => setPayerAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Country"
                value={payerCountry}
                onChange={(e) => setPayerCountry(e.target.value)}
              />
            </Grid>
            {isLoading ? (
              <LoadingButton loading fullWidth size="large" variant="outlined">
                Loading
              </LoadingButton>
            ) : (
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{ ml: 2, mt: 5 }}
              >
                Book
              </Button>
            )}
            {paymentErr && (
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="body1" component="span" marginRight={2} color={'red'}>
                  {paymentErrMsg}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
      {open && secretSrc && <ThreeSecretModal open={open} srcPath={secretSrc} />}
    </React.Fragment>
  );
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/* eslint-enable */

export { CheckoutForm };
