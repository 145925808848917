import React from 'react';
import clsx from 'clsx';

import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 755,
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(1),

      '&.fluid': {
        maxWidth: '100%',
      },

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
        border: 'none',
      },
    },
  });

export interface CardProps {
  className?: string;
  fullWidth?: boolean;
}

const Card = withStyles(styles)((props: WithStyles<typeof styles> & Omit<BoxProps, keyof CardProps> & CardProps) => {
  const { classes, className, fullWidth, children, ...other } = props;
  return (
    <Box className={clsx(classes.root, className, fullWidth && 'fluid')} {...other}>
      {children}
    </Box>
  );
});

export { Card };
