/* eslint-disable */
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const LoginRedirect: React.FC = () => {
    const queryString = document.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url = urlParams.get('url') || '';

    return <Redirect to={url} />;
};

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/* eslint-enable */

export { LoginRedirect };
