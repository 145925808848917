// eslint-disable-next-line no-var
declare var process: {
  env: {
    NODE_ENV: string;
    REACT_APP_USER_POOL_ID: string;
    REACT_APP_USER_POOL_WEB_CLIENT_ID: string;
    REACT_APP_DOMAIN: string;
  };
};

const awsConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_DOMAIN,
      secure: process.env.NODE_ENV === 'production',
    },
  },
};

export default awsConfig;
