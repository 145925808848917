import React, { useCallback, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { CustomInput, Table, TableCell, TableHeader, TableHeaderContainer } from './DealsCreation';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  BookingDestinationFields,
  BookingRequestDestination,
  BookingRoom,
  BookingRoomFields,
  budgetOptions,
  formatDateRangeToString,
  getStringToDate,
} from '@micro-frontends/vacayz-api-client';
import { CloneIcon, DeleteIcon } from '../../Deals/Components/CustomIcon';
import { FormControl, MenuItem, Select } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CheckDateModal from './CheckDateModal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { LocationAutocomplete } from '../../LocationAutocomplete/LocationAutocomplete';

const useStyles = makeStyles({
  selectOutline: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
  },
});

export const bedTypes = ['2 Beds', 'Queen/King', 'Deluxe', 'Suite', '1 Bedroom'];

type Props = {
  destination: BookingRequestDestination;
  destinationIndex: number;
  actions: {
    roomEdit: (destinationIdx: number, roomIdx: number, p: BookingRoomFields, val: never) => void;
    roomAdding: (destinationIdx: number) => void;
    roomClone: (destinationIdx: number, roomIdx: number) => void;
    roomRemove: (destinationIdx: number, roomIdx: number) => void;
    destinationRemove: (destinationIdx: number) => void;
    destinationEdit: (p: BookingDestinationFields, e: string, index: number) => void;
  };
};

const BookingRequestDestinationItem: React.FC<Props> = ({ actions, destination, destinationIndex }) => {
  const classes = useStyles();
  const [openCheck, setOpenCheck] = useState(false);
  const [openRoomIndex, setOpenRoomIndex] = useState(0);
  const [checkinDate, setOpenCheckInDate] = useState(new Date());
  const [checkoutDate, setOpenCheckoutDate] = useState(new Date());

  const handleDateSelection = (
    destinationIdx: number,
    roomIdx: number,
    selectedDate: Date | null,
    direction: 'checkIn' | 'checkOut'
  ) => {
    const current = new Date();
    const handleDate = selectedDate
      ? `${selectedDate.getFullYear()}/${selectedDate.getMonth() + 1}/${selectedDate.getDate()}`
      : `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;

    handleRoomEditing(destinationIdx, roomIdx, direction, handleDate);
  };

  const handleRemoveBookingRequestDestination = (destinationIdx: number) => {
    actions.destinationRemove(destinationIdx);
  };

  const handleAddRoom = (destinationIdx: number) => {
    actions.roomAdding(destinationIdx);
  };

  const handleRemoveRoom = (destinationIdx: number, roomIdx: number) => {
    actions.roomRemove(destinationIdx, roomIdx);
  };

  const handleCloneRoom = (destinationIdx: number, roomIdx: number) => {
    actions.roomClone(destinationIdx, roomIdx);
  };

  const handleRoomEditing = (destinationIdx: number, roomIdx: number, p: BookingRoomFields, val: unknown | never) => {
    actions.roomEdit(destinationIdx, roomIdx, p, val as never);
  };

  const handleBookingRequestDestinationEdit = useCallback(
    (p: BookingDestinationFields, e: React.ChangeEvent<HTMLInputElement>, destinationIdx: number) => {
      actions.destinationEdit(p, e.target.value, destinationIdx);
    },
    [actions]
  );

  const handleBookingRequestDestinationAddress = useCallback(
    (p: BookingDestinationFields, value: string, destinationIdx: number) => {
      actions.destinationEdit(p, value, destinationIdx);
    },
    [actions]
  );

  const isAdmin = () => false;

  const checkDatesUpdate = (destinationIdx: number, roomIdx: number, datein: Date, dateout: Date) => {
    handleDateSelection(destinationIdx, roomIdx, datein, 'checkIn');
    handleDateSelection(destinationIdx, roomIdx, dateout, 'checkOut');
    setOpenCheck(false);
  };

  const onOpenCheckDateModal = (roomIdx: number, inDate: Date, outDate: Date) => {
    setOpenRoomIndex(roomIdx);
    setOpenCheckInDate(inDate);
    setOpenCheckoutDate(outDate);
    setOpenCheck(true);
  };

  const setDestinationAddress = (city: string, stateAndCountry: string) => {
    handleBookingRequestDestinationAddress('city', city, destinationIndex);

    const lstAddress = stateAndCountry.split(',');
    handleBookingRequestDestinationAddress('region', lstAddress[lstAddress.length - 1], destinationIndex);

    handleBookingRequestDestinationAddress(
      'state',
      lstAddress.splice(lstAddress.length - 2, 1).join(','),
      destinationIndex
    );
  };

  return (
    <Container>
      {process.env.NODE_ENV === 'development' && (
        <Box sx={{ width: '100%' }}>
          <LocationAutocomplete
            label="Location"
            name="location"
            placeholder={'Please enter location'}
            error={[]}
            onChange={(city, stateAndCountry) => setDestinationAddress(city, stateAndCountry)}
          />
        </Box>
      )}

      <BookingRequestDestinationContainer>
        <BookingRequestDestinationTitle>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
            {'Destination'}
          </Typography>
          <CustomInput
            activated={isAdmin()}
            disabled={isAdmin()}
            placeholder="City"
            value={destination.city}
            onChange={(e) => {
              handleBookingRequestDestinationEdit('city', e, destinationIndex);
            }}
          />
        </BookingRequestDestinationTitle>
        <div>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
            {'State'}
          </Typography>
          <CustomInput
            activated={isAdmin()}
            disabled={isAdmin()}
            placeholder="State"
            value={destination.state}
            onChange={(e) => {
              handleBookingRequestDestinationEdit('state', e, destinationIndex);
            }}
          />
        </div>

        <div>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
            {'Country'}
          </Typography>
          <CustomInput
            activated={isAdmin()}
            disabled={isAdmin()}
            placeholder="Country"
            value={destination.region}
            onChange={(e) => {
              handleBookingRequestDestinationEdit('region', e, destinationIndex);
            }}
          />
        </div>

        <RemoveContainer>
          {!isAdmin() && (
            <HighlightOffIcon
              onClick={() => handleRemoveBookingRequestDestination(destinationIndex)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </RemoveContainer>
      </BookingRequestDestinationContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Table>
          <TableHeaderContainer>
            <tr>
              <TableHeader>Check in - Check out</TableHeader>
              <TableHeader>
                # Guests <Span>(per room)</Span>
              </TableHeader>
              <TableHeader>
                Budget <Span>(per night)</Span>
              </TableHeader>
              <TableHeader>
                Desired location<RequireField>*</RequireField>
              </TableHeader>
              <TableHeader>
                Hotel preferences<RequireField>*</RequireField>
              </TableHeader>
              <TableHeader>
                Room Type<RequireField>*</RequireField>
              </TableHeader>
              <TableHeader>Parking </TableHeader>
              <TableHeader>Breakfast</TableHeader>
              <TableHeader># Rooms</TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </TableHeaderContainer>

          <tbody>
            {destination.rooms?.map((room: BookingRoom, roomIdx: number) => (
              <React.Fragment key={roomIdx}>
                <TableRoom key={roomIdx}>
                  <TableCell>
                    <Typography
                      variant="body2"
                      onClick={() =>
                        onOpenCheckDateModal(roomIdx, getStringToDate(room.checkIn), getStringToDate(room.checkOut))
                      }
                    >{`${formatDateRangeToString(
                      getStringToDate(room.checkIn),
                      getStringToDate(room.checkOut)
                    )}`}</Typography>
                  </TableCell>
                  <TableCell>
                    <QuantityInput
                      disabled={isAdmin()}
                      type="number"
                      value={
                        room.guests === 0
                          ? 0
                          : room.guests.toString().startsWith('0')
                          ? room.guests.toString().slice(1)
                          : room.guests
                      }
                      onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'guests', +e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl classes={{ root: classes.selectOutline }}>
                      <BudgetSelect
                        value={room.budget}
                        onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'budget', e.target.value)}
                      >
                        {budgetOptions.map((bitem, index) => (
                          <BudgetMenuItem key={index} value={bitem.name}>
                            {bitem.text}
                          </BudgetMenuItem>
                        ))}
                      </BudgetSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FreeTextArea
                      disabled={isAdmin()}
                      placeholder="Your desire Location"
                      value={room.location}
                      onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'location', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <FreeTextArea
                      disabled={isAdmin()}
                      placeholder="Your preferred Hotel "
                      value={room.preference}
                      onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'preference', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl classes={{ root: classes.selectOutline }}>
                      <Select
                        value={room.roomSpecification}
                        fullWidth
                        onChange={(e) =>
                          handleRoomEditing(destinationIndex, roomIdx, 'roomSpecification', e.target.value)
                        }
                      >
                        {bedTypes.map((bed, i) => (
                          <MenuItem value={bed} key={i}>
                            {bed}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <CheckBox
                      disabled={isAdmin()}
                      type="checkbox"
                      checked={room.parking}
                      onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'parking', e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>
                    <CheckBox
                      disabled={isAdmin()}
                      type="checkbox"
                      checked={room.breakfast}
                      onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'breakfast', e.target.checked)}
                    />
                  </TableCell>

                  <TableCell>
                    <QuantityInput
                      disabled={isAdmin()}
                      type="number"
                      value={
                        room.quantity === 0
                          ? 0
                          : room.quantity.toString().startsWith('0')
                          ? room.quantity.toString().slice(1)
                          : room.quantity
                      }
                      onChange={(e) => handleRoomEditing(destinationIndex, roomIdx, 'quantity', +e.target.value)}
                    />
                  </TableCell>

                  <TableCell>
                    {!isAdmin() && (
                      <Actions>
                        <CloneIcon onClick={() => handleCloneRoom(destinationIndex, roomIdx)} />
                        <DeleteIcon onClick={() => handleRemoveRoom(destinationIndex, roomIdx)} />
                      </Actions>
                    )}
                  </TableCell>
                </TableRoom>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </LocalizationProvider>
      <AddContainer>
        {!isAdmin() && (
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleAddRoom(destinationIndex)}
          >
            Add Room
          </Button>
        )}
      </AddContainer>
      {openCheck && (
        <CheckDateModal
          open={openCheck}
          destinationIdx={destinationIndex}
          roomIdx={openRoomIndex}
          checkin={checkinDate}
          checkout={checkoutDate}
          updateCheckDates={checkDatesUpdate}
        />
      )}
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1
  }
`;

const RequireField = styled.span`
  color: red;
`;

const BudgetSelect = styled(Select)`
  border: none;

  width: 100%;
`;
const BudgetMenuItem = styled(MenuItem)`
  width: 100%;
`;

export const Span = styled.span`
  font-size: 10px;
`;

export const DealTableDataCell = styled.th`
  text-align: center;
  vertical-align: center;
  font-weight: bold;
  font-size: 15px;
  height: 100%;
`;

export const DealTableHeader = styled.th`
  text-align: left;
  font-weight: bold;
  font-size: 15px;
`;

export const TableRoom = styled.tr`
  width: 100%;
  height: 65px;
  animation: ${fadeIn} 0.35s linear;
`;

const Actions = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
`;

export const FreeTextArea = styled.input`
  resize: vertical;
  outline: none;
  border: none;
  background: ${({ disabled }) => (disabled ? 'transparent' : '#f8f8f8')};
  height: 40px;
  width: 90%;
  text-indent: 16px;
  border-radius: 20px;
  transition: all 0.1s ease-in;
  :focus {
    background: #e1e1e1;
  }
`;

export const QuantityInput = styled.input<{ disabled?: boolean }>`
  width: 100px;
  border-radius: 22px;
  padding: 10px;
  border: none;
  background: ${({ disabled }) => (disabled ? 'transparent' : '#f8f8f8')};
  :focus {
    border: none;
    outline: none;
    background: #e5e5e5;
  }
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
`;

const RemoveContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 150px;
  justify-content: space-between;
  align-items: center;
`;

const AddContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const BookingRequestDestinationTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BookingRequestDestinationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 25px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  animation: ${fadeIn} 0.35s linear;
  box-shadow: 0 0 4px 0px #00000014;
  clip-path: inset(0px -15px 0px -15px);
  position: relative;
`;

export default BookingRequestDestinationItem;
