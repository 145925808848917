/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EventDealsResponse,
  Event as SourceDetailsResponse,
  getStringToDate,
  formatDateRangeToString,
  formatDateToString,
} from '@micro-frontends/vacayz-api-client';
import {
  Grid,
  Box,
  Button,
  Container,
  Chip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import DealCard from './DealCard';
import { DateRangeModal } from '../../components/common';

interface Props {
  sourceDetails?: SourceDetailsResponse;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  minDate: Date | null;
  maxDate: Date | null;
  deals: EventDealsResponse[];
  eventId: string;
  guests: number | null;
  rooms: number | null;
  sortby: string | null;
  guestCounts: number[];
}

enum EventDetailType {
  LogoToRightofDetails = 'logo-to-the-right-of-details',
  LogoAboveDetailsNoTitle = 'logo-above-details-no-title',
}

const SortOptions = [
  {
    text: 'Our Pick',
    value: 'rank',
  },
  {
    text: 'Price (Lowest first)',
    value: 'price',
  },
  {
    text: 'Distance (Closest to venue)',
    value: 'venue',
  },
];

const EventDetailContainer: React.FC<Props> = ({
  sourceDetails,
  checkInDate,
  checkOutDate,
  guests,
  rooms,
  minDate,
  maxDate,
  deals,
  eventId,
  sortby,
  guestCounts,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const params = new URLSearchParams(document.location.search);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMoreRoomEl, setAnchorMoreRoomEl] = React.useState<null | HTMLElement>(null);
  const openPeopleInRoom = Boolean(anchorEl);
  const openMoreInRoom = Boolean(anchorMoreRoomEl);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const moreRooms = [1, 2, 3];

  const handlePeopleInRoomClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePeopleInRoomClose = () => {
    setAnchorEl(null);
  };
  const handleMoreInRoomClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMoreRoomEl(event.currentTarget);
  };
  const handleMoreInRoomClose = () => {
    setAnchorMoreRoomEl(null);
  };

  const selectPeopleinRoom = (people: number) => {
    params.set('guests', people.toString());
    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  const selectMoreinRoom = (rooms: number) => {
    params.set('rooms', rooms.toString());
    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  const strEventDates = sourceDetails!
    ? formatDateRangeToString(getStringToDate(sourceDetails.startAt), getStringToDate(sourceDetails.endAt))
    : '';
  const strEventLocation = [sourceDetails?.location.city, sourceDetails?.location.state].join(', ');
  const strEventSubtitle = [strEventDates, sourceDetails?.hostedAt, strEventLocation].join(' | ');
  const strEventSubtitleNoDates = [sourceDetails?.hostedAt, strEventLocation].join(' | ');

  const compareDates = (a: Date, b: Date) => {
    const aSum = a.getDate() + 100 * a.getMonth() + 10000 * a.getFullYear();
    const bSum = b.getDate() + 100 * b.getMonth() + 10000 * b.getFullYear();

    if (aSum < bSum) {
      return -1;
    } else if (aSum === bSum) {
      return 0;
    } else {
      return 1;
    }
  };

  const relevantDeals = deals.filter((deal) => {
    if (deal) return true;
    else return false;
  });

  const resetFilterDate = async () => {
    params.delete('from');
    params.delete('to');

    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  const resetGuests = async () => {
    params.delete('guests');

    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  const resetRooms = async () => {
    params.delete('rooms');

    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  const sortByEventDeals = async (sortBy: string | null) => {
    if (sortBy) {
      params.set('sortby', sortBy);
    }

    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  const checkDates = async (checkIn: Date, checkOut: Date) => {
    params.set('from', formatDateToString(checkIn));
    params.set('to', formatDateToString(checkOut));
    history.push({ pathname: '/eventdealsview', search: params.toString() });
    history.go(0);
  };

  return (
    <Container maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ pt: 3 }}>
        {sourceDetails ? (
          isMobile ? (
            <Box sx={{ textAlign: 'center' }}>
              <img src={sourceDetails?.logofile} style={{ maxHeight: '60px', marginRight: '12px' }} />
              <Typography variant="subtitle2" style={{ marginTop: '12px' }}>
                {strEventSubtitle}
              </Typography>
            </Box>
          ) : sourceDetails.eventDetailsDisplayType === EventDetailType.LogoAboveDetailsNoTitle ? (
            <Box sx={{ textAlign: 'center' }}>
              <img src={sourceDetails.logofile} style={{ maxHeight: '60px', marginRight: '12px' }} />
              <Typography variant="subtitle2" style={{ marginTop: '12px' }}>
                {strEventSubtitle}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                <img src={sourceDetails.logofile} style={{ maxHeight: '96px' }} />
              </Box>
              <Typography variant="subtitle1">{`${sourceDetails?.title || sourceDetails?.name} |`}&nbsp;</Typography>
              <Typography variant="subtitle1">{`${strEventDates} |`}&nbsp;</Typography>
              <Typography variant="subtitle1">{strEventSubtitleNoDates}</Typography>
            </Box>
          )
        ) : null}

        <Grid item xs={12}>
          <Grid container display="flex" justifyContent="center" spacing={4} sx={{ mt: 1 }}>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h6" style={{ fontWeight: 'bold', marginRight: 5 }}>
                {'Filter:'}
              </Typography>
              {compareDates(checkInDate ?? new Date(), minDate ?? new Date()) === 0 &&
              compareDates(checkOutDate ?? new Date(), maxDate ?? new Date()) === 0 ? (
                <Chip
                  label={'Select Dates'}
                  color="error"
                  sx={{ marginX: 1 }}
                  onClick={() => setShowDatePicker(true)}
                />
              ) : (
                <Chip
                  label={formatDateRangeToString(checkInDate ?? new Date(), checkOutDate ?? new Date())}
                  onDelete={resetFilterDate}
                  color="error"
                  sx={{ marginX: 1 }}
                />
              )}

              {guests && guests > 0 ? (
                <Chip
                  label={`${guests} People in Room`}
                  color="primary"
                  onDelete={resetGuests}
                  sx={{ marginRight: 1 }}
                />
              ) : (
                <Chip
                  id="people-in-button"
                  aria-controls={openPeopleInRoom ? 'people-in-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openPeopleInRoom ? 'true' : undefined}
                  label={'People in Room'}
                  color="primary"
                  sx={{ marginX: 1 }}
                  onClick={handlePeopleInRoomClick}
                />
              )}
              <Menu
                anchorEl={anchorEl}
                id="people-in-menu"
                open={openPeopleInRoom}
                onClose={handlePeopleInRoomClose}
                MenuListProps={{
                  'aria-labelledby': 'people-in-button',
                }}
              >
                {guestCounts.map((g, i) => (
                  <MenuItem key={i} onClick={() => selectPeopleinRoom(g)}>{`${g} People in Room`}</MenuItem>
                ))}
              </Menu>
              {rooms && rooms > 0 ? (
                <Chip label={`${rooms} rooms`} color="primary" onDelete={resetRooms} sx={{ marginRight: 1 }} />
              ) : (
                <Chip
                  label={'Rooms'}
                  id="rooms-in-button"
                  aria-controls={openMoreInRoom ? 'rooms-in-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMoreInRoom ? 'true' : undefined}
                  color="primary"
                  onClick={handleMoreInRoomClick}
                  sx={{ marginRight: 1 }}
                />
              )}
              <Menu
                anchorEl={anchorMoreRoomEl}
                id="rooms-in-menu"
                open={openMoreInRoom}
                onClose={handleMoreInRoomClose}
                MenuListProps={{
                  'aria-labelledby': 'rooms-in-button',
                }}
              >
                {moreRooms.map((m, i) => (
                  <MenuItem key={i} onClick={() => selectMoreinRoom(m)}>{`${m} rooms`}</MenuItem>
                ))}
              </Menu>

              {/* <Button variant="outlined" onClick={() => openFilter(true)} startIcon={<TuneIcon />}>
                <Typography variant="h6">Filter</Typography>
              </Button> */}
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
              <FormControl sx={{ width: 250 }}>
                <InputLabel id="deal-sort-options-label">Sort By:</InputLabel>
                <Select
                  labelId="deal-sort-options-label"
                  value={sortby}
                  label="Sort By:"
                  onChange={(e) => sortByEventDeals(e.target.value)}
                >
                  {SortOptions.map((t, i) => (
                    <MenuItem value={t.value} key={i}>
                      {t.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
        <Grid container spacing={2}>
          {relevantDeals.length > 0 ? (
            relevantDeals.map((deal) => (
              <Grid item xs={12} sm={12} md={6} key={deal.dealId}>
                <DealCard deal={deal} eventid={eventId} />
              </Grid>
            ))
          ) : (
            <Grid container padding={5} justifyContent="center">
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {'Oops. It seems we don'}&apos;{'t have the result you'}&apos;{'re looking for.'}
                </Typography>
                <Typography variant="subtitle1">{'Want to submit a request?'}</Typography>
                <Typography variant="subtitle1">
                  {'Click the link below and we will get back to you within 24 hours!'}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box sx={{ marginTop: 3, marginBottom: 5, marginX: 0 }}>
        <Button fullWidth variant="contained" size="large" href={`/?eid=${eventId}`}>
          {relevantDeals.length === 0 ? 'Place a request' : 'Submit a Personal Request'}
        </Button>
      </Box>
      {showDatePicker && (
        <DateRangeModal
          open={showDatePicker}
          checkInDate={checkInDate ?? new Date()}
          checkOutDate={checkOutDate ?? new Date()}
          onOk={(inDate, outDate) => {
            checkDates(inDate, outDate);
            setShowDatePicker(false);
          }}
          onClose={() => setShowDatePicker(false)}
        />
      )}
    </Container>
  );
};

/* eslint-enable */

export { EventDetailContainer };
export default EventDetailContainer;
