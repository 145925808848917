import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Typography, Button, CircularProgress, Divider } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Header } from '../Header/Header';
import { PaymentDealCard } from './payment_deal';
import { PaymentDealsForm, BookingDealPaymentForm } from './payment';
import SecretModal from './secret_modal';
import {
  BookingPaymentIntent,
  BookingDealNew,
  BookingRequestDestination,
  BookingRoom,
  toQuerystring,
  createBookingPaymentIntent,
  CreateBookingPaymentIntentRequest,
  createBookingStripeCharge,
  SecretPaymentIntentData,
  secretPaymentIntent,
  updateUserInfo,
  StripePaymentSecret,
  BookingDestinationsforGuest,
  BookingRoomsforGuest,
  BookingDealsforGuest,
  BookingforGuest,
  getBookingRequestOrderId,
  calcDateDiff,
  getStringToDate,
  formatDateRangeToString,
} from '@micro-frontends/vacayz-api-client';
import { useAsyncCleanup, AlertContext } from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Routing } from '../../common/constants/routing';
import GuestConfirmModal from './confirm_modal';

interface SelectedDealObject {
  destination: BookingRequestDestination;
  room: BookingRoom;
  deal: BookingDealNew;
}

const useStyles = makeStyles({
  root: {
    padding: '20px',
    minHeight: '100vh',
    position: 'relative',
  },

  title: {
    marginTop: '100px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },

  paymentContainer: {
    display: 'flex',
    bottom: '20px',
    position: 'absolute',
    width: 'calc(100% - 40px)',
    justifyContent: 'center',
  },
});

const getSelectedDealIds = (selectedDeals: SelectedDealObject[]): number[] => {
  const dealsIds: number[] = [];
  selectedDeals.forEach((dealObjs) => {
    if (dealObjs.deal.id) dealsIds.push(dealObjs.deal.id);
  });
  return dealsIds;
};

const PaymentDealsView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const { client, currentUser } = useAuthContext();

  const { requestId, bookingRequest } = useLocation();
  const [detailView, setDetailView] = useState(true);
  const { addCleanup, isMounted } = useAsyncCleanup();
  const { alert } = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [secretSrc, setSecretSrc] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [receiveMessage, setReceiveMessage] = useState<boolean>(false);
  const [selectedDeals, setSelectedDeals] = useState<SelectedDealObject[]>([]);
  const [total, setTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [invalidDeal, setInvalidDeal] = useState<string[]>([]);
  const [bookingRequestOrderId, setBookingRequestOrderId] = useState<string>();

  useEffect(() => {
    if (bookingRequest && bookingRequest.id > 0) {
      const _selectedDeals: SelectedDealObject[] = [];
      let _total = 0;

      bookingRequest.destinations.forEach((dest: BookingRequestDestination) => {
        dest.rooms.forEach((room: BookingRoom) => {
          room.deals.forEach((deal: BookingDealNew) => {
            if (deal.isDealSelected) {
              _selectedDeals.push({ destination: dest, room, deal });
              _total += deal.price * room.quantity * calcDateDiff(room.checkOut, room.checkIn);
            }
          });
        });
      });

      setSelectedDeals(_selectedDeals);
      setTotal(_total);
    }
  }, [bookingRequest]);

  const getRequestData = useCallback(async () => {
    try {
      setLoading(true);
      const [_requestOrderId, cancel] = getBookingRequestOrderId(client, bookingRequest.id);
      addCleanup(cancel);
      const requestOrderId = await _requestOrderId;
      if (requestOrderId) {
        setBookingRequestOrderId(requestOrderId.orderId);
      }
    } catch (err) {
      alert(`It is failed to get Booking Request Id = ${bookingRequest.id}`, 'error');
    } finally {
      if (isMounted()) setLoading(false);
    }
  }, [addCleanup, client, alert, isMounted, bookingRequest]);

  const goHome = () => {
    history.push(`/new/requests/${requestId}`);
  };

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  const viewPaymentPage = (isPayView: boolean) => {
    if (isPayView && invalidGuestDeal()) {
      setOpenConfirm(true);
    } else {
      setDetailView(!isPayView);
    }
  };

  const confirmHandleOpen = (bOpen: boolean) => {
    setOpenConfirm(bOpen);
  };

  const confirmStripe = useCallback(
    async (secret: StripePaymentSecret) => {
      if (!stripe || !elements || !isMounted()) {
        return false;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return false;
      }

      try {
        setClientSecret(secret.clientSecret);
        const confirmCard = await stripe?.confirmCardPayment(
          secret.clientSecret,
          {
            payment_method: { card: cardElement },
            return_url:
              window.location.origin + Routing.secretRedirect + toQuerystring({ client_secret: secret.clientSecret }),
          },
          {
            handleActions: false,
          }
        );

        if (confirmCard.paymentIntent?.status === 'requires_action') {
          setSecretSrc(confirmCard.paymentIntent?.next_action?.redirect_to_url?.url ?? '');
          setOpen(true);
          return true;
        } else if (confirmCard.paymentIntent?.status === 'succeeded') {
          const [secretPayment, cancel] = secretPaymentIntent(client, {
            id: confirmCard.paymentIntent?.id,
            status: confirmCard.paymentIntent?.status,
            amount: confirmCard.paymentIntent?.amount,
          });
          addCleanup(cancel);
          await secretPayment;

          history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: requestId }));
          return true;
        } else if (confirmCard.paymentIntent?.status === 'requires_payment_method') {
          alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
          return false;
        }
      } catch (err) {
        alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
        return false;
      }

      alert('The payment is unsuccessful. Please kindly check the property and attempt to try again.', 'error');
      return false;
    },
    [alert, addCleanup, requestId, client, elements, history, isMounted, stripe]
  );

  const completeStripeCharge = useCallback(
    async (vacayzPaymentIntent: BookingPaymentIntent) => {
      if (!currentUser || loading || !requestId || !vacayzPaymentIntent) {
        return false;
      }
      if (!stripe || !elements || !isMounted()) {
        return false;
      }
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        alert('The cared element is not exist. Please refresh the page to pay the deals again.', 'error');
        return false;
      }

      let secret;
      try {
        const paymentMethod = await stripe.createPaymentMethod({
          card: cardElement,
          billing_details: {
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            email: currentUser.email,
            phone: currentUser.phone,
            address: {
              line1: vacayzPaymentIntent.address,
            },
          },
          type: 'card',
        });

        if (!isMounted()) {
          return false;
        }

        if (paymentMethod.error || !paymentMethod.paymentMethod?.id) {
          alert('The payment is unsuccessful. The payment mothod is not working.', 'error');
          return false;
        }

        const [_stripeSecret, cancel] = createBookingStripeCharge(client, {
          bookingPaymentIntentId: vacayzPaymentIntent.id,
          userId: currentUser.id,
          paymentMethodId: paymentMethod.paymentMethod.id,
        });
        addCleanup(cancel);
        secret = await _stripeSecret;
      } catch (err) {
        alert('PaymentIntent client secret was invalid. Please retry with another card', 'error');
        return false;
      }

      if (!secret) {
        alert('It is failed to creat the payment intent', 'error');
        return false;
      }

      try {
        if (secret && secret.status === 'succeeded') {
          const [secretPayment, paymentCancel] = secretPaymentIntent(client, {
            id: secret.stripePaymentIntentId,
            status: secret.status,
            amount: vacayzPaymentIntent.amount,
          });
          addCleanup(paymentCancel);
          await secretPayment;

          history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: bookingRequestOrderId }));
          return true;
        }
      } catch (err) {
        alert('It is failed to complete the payment', 'error');
      }

      setReceiveMessage(false);
      confirmStripe(secret);
      return true;
    },
    [
      addCleanup,
      alert,
      requestId,
      client,
      bookingRequestOrderId,
      history,
      confirmStripe,
      currentUser,
      elements,
      isMounted,
      loading,
      stripe,
      setReceiveMessage,
    ]
  );

  const handlePaymentIntent = useCallback(
    async (bookingFormData: BookingDealPaymentForm, creditAmount: number) => {
      if (currentUser?.id && !loading) {
        try {
          setLoading(true);
          const [userInfoPromise, useInfoCancel] = updateUserInfo(client, { phone: bookingFormData.phone });
          addCleanup(useInfoCancel);
          await userInfoPromise;

          const deals: number[] = getSelectedDealIds(selectedDeals);
          const paymentData: CreateBookingPaymentIntentRequest = {
            address: bookingFormData.address,
            bookingRequestId: bookingRequest.id,
            country: bookingFormData.country,
            guestName: bookingFormData.guestName,
            dealsSelected: deals,
            userId: currentUser.id,
            isCredit: creditAmount > 0 ? true : false,
          };
          const [pmPromise, pmCancel] = createBookingPaymentIntent(client, paymentData);
          addCleanup(pmCancel);
          const _paymentIntent: BookingPaymentIntent | undefined = await pmPromise;
          if (!_paymentIntent || !isMounted()) {
            throw new Error('Error while processing payment');
          }
          if (_paymentIntent.amount === 0 && _paymentIntent.usedCredits > 0) {
            const intentData: SecretPaymentIntentData = {
              id: '',
              status: 'succeeded',
              amount: _paymentIntent.amount,
              bookingPaymentId: _paymentIntent.id,
            };

            const [secretPayment, cancel] = secretPaymentIntent(client, intentData);
            addCleanup(cancel);
            await secretPayment;
            setLoading(false);
            history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: bookingRequestOrderId }));
            return;
          }

          const bComplete = await completeStripeCharge(_paymentIntent);
          if (!isMounted() || !bComplete) {
            setLoading(false);
            return;
          }
        } catch (err) {
          alert(err, 'error');
        }
      }
      setLoading(false);
    },
    [
      addCleanup,
      client,
      completeStripeCharge,
      currentUser?.id,
      isMounted,
      alert,
      loading,
      selectedDeals,
      bookingRequest,
      bookingRequestOrderId,
      history,
    ]
  );

  const submitRequest = useCallback(
    async (bookingFormData: BookingDealPaymentForm, credit: number) => {
      handlePaymentIntent(bookingFormData, credit);
    },
    [handlePaymentIntent]
  );

  const retrievePayment = useCallback(async () => {
    const result = await stripe?.retrievePaymentIntent(clientSecret);

    if (result?.error) {
      alert('PaymentIntent client secret was invalid', 'error');
    } else {
      if (result?.paymentIntent.status === 'succeeded') {
        history.push(Routing.offerPaymentSuccess + toQuerystring({ orderId: requestId }));
      } else if (result?.paymentIntent.status === 'requires_payment_method') {
        alert(' Authentication failed. Please retry with another payment method.', 'error');
      }
    }

    if (result) {
      const intentData: SecretPaymentIntentData = {
        id: result.paymentIntent?.id ?? '',
        status: result.paymentIntent?.status ?? '',
        amount: result.paymentIntent?.amount ?? 0,
      };

      const [secretPayment, cancel] = secretPaymentIntent(client, intentData);
      addCleanup(cancel);
      await secretPayment;
    }
  }, [addCleanup, alert, requestId, client, clientSecret, history, stripe]);

  useEffect(() => {
    if (receiveMessage) {
      retrievePayment();
    }
  }, [receiveMessage, retrievePayment]);

  if (!requestId) {
    return <CircularProgress color="secondary" size={33} />;
  }

  window.addEventListener('message', (e) => {
    if (e.origin !== window.origin || e.data.target !== 'secretDialog') {
      return;
    }

    if (e.data.message === clientSecret) {
      setReceiveMessage(true);
      setSecretSrc('');
      setOpen(false);
    }
  });

  const getGuestsForDeal = (destId?: number, roomId?: number, dealId?: number): BookingforGuest[] => {
    let _guests: BookingforGuest[] = [];
    if (destId && roomId && dealId) {
      bookingRequest.guests.forEach((dest: BookingDestinationsforGuest) => {
        if (dest.destinationId === destId) {
          dest.rooms.forEach((room: BookingRoomsforGuest) => {
            if (room.roomId === roomId) {
              room.deals.forEach((deal: BookingDealsforGuest) => {
                if (deal.dealId === dealId) _guests = [...deal.item];
              });
            }
          });
        }
      });
    }
    return _guests;
  };

  const updateGuestsForDeal = (destId: number, roomId: number, dealId: number, guests: BookingforGuest[]) => {
    bookingRequest.guests.forEach((dest: BookingDestinationsforGuest) => {
      if (dest.destinationId === destId) {
        dest.rooms.forEach((room: BookingRoomsforGuest) => {
          if (room.roomId === roomId) {
            room.deals.forEach((deal: BookingDealsforGuest) => {
              if (deal.dealId === dealId) {
                deal.item = [...guests];
              }
            });
          }
        });
      }
    });
  };

  const invalidGuestDeal = () => {
    let _invalid = false;
    const _invalidDeals: string[] = [];

    selectedDeals.forEach((selected: SelectedDealObject) => {
      bookingRequest.guests.forEach((dest: BookingDestinationsforGuest) => {
        dest.rooms.forEach((room: BookingRoomsforGuest) => {
          room.deals.forEach((deal: BookingDealsforGuest) => {
            if (deal.dealId === selected.deal.id) {
              if (deal.item.length === 0) {
                _invalidDeals.push(selected.deal.name);
                _invalid = true;
                return;
              } else {
                deal.item.forEach((item: BookingforGuest) => {
                  if (item.name.length === 0 || item.address.length === 0) {
                    _invalid = true;
                    _invalidDeals.push(selected.deal.name);
                    return;
                  }
                });
              }
            }
          });
        });
      });
    });

    setInvalidDeal(_invalidDeals);
    return _invalid;
  };

  return (
    <Grid className={classes.root}>
      <Header />

      <Grid className={classes.title}>
        <IconButton onClick={goHome}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h4" fontWeight="bold">
          CheckOut
        </Typography>
      </Grid>
      {detailView ? (
        <React.Fragment>
          <Grid container>
            {selectedDeals.map((obj, i) => (
              <Grid item xs={12} key={i} sx={{ margin: '10px' }}>
                <PaymentDealCard
                  deal={obj.deal}
                  date={formatDateRangeToString(getStringToDate(obj.room.checkIn), getStringToDate(obj.room.checkOut))}
                  nights={calcDateDiff(obj.room.checkOut, obj.room.checkIn)}
                  quantity={obj.room.quantity}
                  guestCnt={obj.room.guests}
                  requestId={bookingRequest.id ?? 0}
                  roomId={obj.room.id ?? 0}
                  destinationId={obj.destination.id ?? 0}
                  dealId={obj.deal.id ?? 0}
                  guests={getGuestsForDeal(obj.destination.id, obj.room.id, obj.deal.id)}
                  updateHandle={updateGuestsForDeal}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center">
            <Grid maxWidth="lg" sx={{ width: '100%' }}>
              <Divider />
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">{`$${total.toLocaleString()}`}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.paymentContainer}>
            <Button variant="contained" sx={{ borderRadius: '50px' }} onClick={() => viewPaymentPage(true)}>
              {'Confirm & Pay'}
            </Button>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PaymentDealsForm submitRequest={submitRequest} loading={loading} totalAmount={total} />
          {open && secretSrc && <SecretModal open={open} srcPath={secretSrc} />}
        </React.Fragment>
      )}
      {openConfirm && (
        <GuestConfirmModal open={openConfirm} setHandleOpen={confirmHandleOpen} invaildDeals={invalidDeal} />
      )}
    </Grid>
  );
};

export default PaymentDealsView;
export { PaymentDealsView };
