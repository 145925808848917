import React, { useEffect, useState } from 'react';
import { CreateBookingRequestFormNew } from '@micro-frontends/vacayz-api-client';
import { useQueryStringParams } from '@micro-frontends/shared-components';
import CircularProgress from '@mui/material/CircularProgress';
import { BookingPaymentPageView } from './BookingPaymentPage.view';
import { useHistory } from 'react-router-dom';
import { OrderSummary, SelectedDealObjects } from '../../Deals/OfferDeals.view';

type QueryString = {
  requestId: number;
};
const asNumber = (num: unknown): number | undefined => (Number.isFinite(Number(num)) ? Number(num) : undefined);

const BookingPaymentPageLogic: React.FC = () => {
  const history = useHistory();
  const [bookingRequest, setBookingRequest] = useState<CreateBookingRequestFormNew>();
  const [selectedDeals, setSelectedDeals] = useState<SelectedDealObjects[]>();
  const [orderSummary, setOrderSummary] = useState<OrderSummary>();
  const { requestId: _requestId } = useQueryStringParams<QueryString>(['requestId']);
  const [requestId] = useState(asNumber(_requestId));

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const _selectedDeals: SelectedDealObjects[] = history.location?.state?.selectedDeals;
    const _bookingRequest: CreateBookingRequestFormNew = history.location?.state?.bookingRequest;
    const _orderSummary: OrderSummary = history.location?.state?.orderSummary;
    setSelectedDeals(_selectedDeals || []);
    setBookingRequest(_bookingRequest);
    setOrderSummary(_orderSummary);
    setLoading(false);
  }, [history.location.state]);

  if (loading || !bookingRequest || !requestId || !selectedDeals || !orderSummary) {
    return <CircularProgress color="secondary" size={35} />;
  }

  return (
    <BookingPaymentPageView
      orderSummary={orderSummary}
      selectedDeals={selectedDeals}
      bookingRequest={bookingRequest}
      requestId={requestId}
    />
  );
};

export { BookingPaymentPageLogic };
