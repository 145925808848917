/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DestinationItem, { CheckBox } from './DestinationItem';
import MobileDestinationItem from './MobileDestinationItem';
import {
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid,
  Button,
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useAsyncCleanup, useFormErrors, AlertContext } from '@micro-frontends/shared-components';
import { useAuthContext } from '@micro-frontends/auth-context';
import {
  BookingDestinationFields,
  BookingRequestContact,
  BookingRequestDestination,
  BookingRoomFields,
  CreateBookingRequestLandingForm,
  createBookingRequestNew,
  getEventDetails,
  calcDateDiff,
} from '@micro-frontends/vacayz-api-client';
import { useHistory } from 'react-router-dom';
import { Routing } from '../../../../common/constants/routing';
import { Header } from '../../../../containers/Header/Header';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  accordionDetail: {
    display: 'grid',
  },
});

const DealsCreation: React.FC<Record<string, unknown>> = (): JSX.Element => {
  const current = new Date();
  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [loading, setLoading] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [notes, setNotes] = useState('');
  const { alert } = useContext(AlertContext);
  const { addCleanup, isMounted } = useAsyncCleanup();
  const [original, setOriginal] = useState('');
  const [contact, setContact] = useState<BookingRequestContact>({
    firstname: '',
    lastname: '',
    email: '',
    companyName: '',
    phone: '',
  });
  const history = useHistory();
  const [destinations, setDestinations] = useState<BookingRequestDestination[]>([
    {
      city: '',
      state: '',
      region: '',
      rooms: [
        {
          location: '',
          guests: 1,
          budget: 500,
          preference: '',
          roomSpecification: 'Queen/King',
          breakfast: false,
          parking: false,
          checkIn: `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`,
          checkOut: `${nextDay.getFullYear()}/${nextDay.getMonth() + 1}/${nextDay.getDate()}`,
          quantity: 1,
          beds: 1,
          bedsType: 'SUITE',
          deals: [],
        },
      ],
    },
  ]);
  const { setFormErrors } = useFormErrors('BookingRequestForm');
  const { currentUser, client } = useAuthContext();
  const [eventId, setEventId] = useState<string | null>(null);

  document.title = `${process.env.REACT_APP_COMPANY_NAME} - Place Request`;
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  let appleLink: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
  if (!appleLink) {
    appleLink = document.createElement('link');
    appleLink.rel = 'apple-touch-icon';
    document.getElementsByTagName('head')[0].appendChild(appleLink);
  }
  appleLink.href = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_LOGO_PATH}`;

  document
    .querySelector('meta[name="description"]')
    ?.setAttribute('content', `Find best deals accommodation with ${process.env.REACT_APP_COMPANY_NAME}`);

  const handleSourceDetails = useCallback(
    async (source: string) => {
      try {
        const [handle, cancel] = getEventDetails(client, source);
        addCleanup(cancel);
        const response = await handle;
        if (!isMounted()) return;
        return response;
      } catch (err) {
        // if (err && isMounted() && isVacayzError(err)) {
        //   alertAPIError(err, 'error');
        // }
      }
    },

    [addCleanup, /*alertAPIError,*/ client, isMounted]
  );

  useEffect(() => {
    (async () => {
      const queryString = document.location.search;
      const urlParams = new URLSearchParams(queryString);
      const source = urlParams.get('eid');

      if (source) {
        const sourceDetails = await handleSourceDetails(source);
        if (typeof sourceDetails === 'object' && Object.keys(sourceDetails).length > 0) {
          handleDestinationInformation('city', sourceDetails?.location?.city, 0);
          handleDestinationInformation('state', sourceDetails?.location?.state, 0);
          handleDestinationInformation('region', sourceDetails?.location?.country, 0);
          handleRoomEdit(0, 0, 'checkIn', sourceDetails?.startAt as never);
          handleRoomEdit(0, 0, 'checkOut', sourceDetails?.endAt as never);
        }
      }
    })();
  }, [handleSourceDetails]);

  useEffect(() => {
    if (currentUser) {
      setContact({
        firstname: currentUser.firstName || '',
        lastname: currentUser.lastName || '',
        phone: currentUser.phone || '',
        email: currentUser.email || '',
        companyName: '',
      });
    }

    const queryString = document.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (!original) {
      const source = urlParams.get('source');
      setOriginal(source ?? '');
    }

    if (!eventId) {
      const eid = urlParams.get('eid');
      setEventId(eid ?? null);
    }
  }, [currentUser]);

  /**
   * Handlers
   */

  const handleAddDestination = useCallback(() => {
    const newDestinations = [...destinations];
    const today = new Date();
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);

    newDestinations.push({
      city: '',
      state: '',
      region: '',
      rooms: [
        {
          location: '',
          guests: 1,
          budget: 500,
          preference: '',
          roomSpecification: 'Queen/King',
          breakfast: false,
          parking: false,
          checkIn: `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`,
          checkOut: `${nextDay.getFullYear()}/${nextDay.getMonth() + 1}/${nextDay.getDate()}`,
          quantity: 1,
          beds: 1,
          bedsType: 'SUITE',
          deals: [],
        },
      ],
    });
    setDestinations(newDestinations);
  }, [destinations]);

  const handleContactInformation = useCallback(
    (p: string, e: React.ChangeEvent<HTMLInputElement>) => {
      setContact({
        ...contact,
        [p]: e.target.value,
      });
    },
    [contact]
  );

  const handleDestinationInformation = useCallback(
    (p: BookingDestinationFields, val: string, destinationIdx: number) => {
      const newDestinations = [...destinations];
      newDestinations[destinationIdx][p] = val;
      setDestinations(newDestinations);
    },
    [destinations]
  );

  const handleRoomEdit = useCallback(
    (destinationIdx: number, roomIdx: number, p: BookingRoomFields, val: never) => {
      const newDestinations = [...destinations];
      newDestinations[destinationIdx].rooms[roomIdx][p] = val;
      setDestinations(newDestinations);
    },
    [destinations]
  );

  const handleUserAgreementStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserAgreement(e.target.checked);
  };
  const handleRemoveDestination = useCallback(
    (destinationIdx: number): void => {
      const newDestinations = [...destinations];
      newDestinations.splice(destinationIdx, 1);
      setDestinations(newDestinations);
    },
    [destinations]
  );

  const handleRoomAdding = useCallback(
    (destinationIdx: number) => {
      const newDestinations = [...destinations];
      const today = new Date();
      const nextDay = new Date();
      nextDay.setDate(nextDay.getDate() + 1);

      newDestinations[destinationIdx].rooms.push({
        location: '',
        guests: 1,
        budget: 500,
        preference: '',
        roomSpecification: 'Queen/King',
        breakfast: false,
        parking: false,
        checkIn: `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`,
        checkOut: `${nextDay.getFullYear()}/${nextDay.getMonth() + 1}/${nextDay.getDate()}`,
        quantity: 1,
        beds: 1,
        bedsType: 'SUITE',
        deals: [],
      });
      setDestinations(newDestinations);
    },
    [destinations]
  );

  const handleRoomRemove = useCallback(
    (destinationIdx: number, roomIdx: number) => {
      const newDestinations = [...destinations];
      newDestinations[destinationIdx].rooms.splice(roomIdx, 1);
      setDestinations(newDestinations);
    },
    [destinations]
  );

  const handleRoomClone = useCallback(
    (destinationIdx: number, roomIdx: number) => {
      const newDestinations = [...destinations];
      const clonedRoom = { ...newDestinations[destinationIdx].rooms[roomIdx] };
      newDestinations[destinationIdx].rooms.push(clonedRoom);
      setDestinations(newDestinations);
    },
    [destinations]
  );

  const isAdmin = () => false;
  /**
   * Memos
   */
  const destinationActions = useMemo(() => {
    return {
      roomEdit: handleRoomEdit,
      roomAdding: handleRoomAdding,
      roomClone: handleRoomClone,
      roomRemove: handleRoomRemove,
      destinationRemove: handleRemoveDestination,
      destinationEdit: handleDestinationInformation,
    };
  }, [
    handleRoomEdit,
    handleRoomAdding,
    handleRoomClone,
    handleRoomRemove,
    handleRemoveDestination,
    handleDestinationInformation,
  ]);

  const allowedToSubmit = useMemo(() => {
    return !!(
      userAgreement &&
      destinations.length > 0 &&
      contact.firstname &&
      contact.lastname &&
      contact.phone &&
      contact.email
    );
  }, [userAgreement, destinations, contact.firstname, contact.lastname, contact.phone, contact.email]);

  const submitRequest = useCallback(
    async (bookingRequestForm: CreateBookingRequestLandingForm) => {
      setLoading(true);
      try {
        const [create, cancel] = createBookingRequestNew(client, bookingRequestForm);
        addCleanup(cancel);
        const response = await create;
        setLoading(false);
        if (!isMounted()) return;
        if (!response) return alert("Couldn't create request", 'error');
        history.push(Routing.offerRequestSuccess);
      } catch (err) {
        alert('It is failed to create request.', 'error');
      }
      setLoading(false);
    },
    [addCleanup, alert, client, history, isMounted, setFormErrors, loading]
  );
  const handleSubmit = () => {
    let validation = true;
    if (destinations.length === 0) {
      alert('It must contain at least 1 Destination', 'error');
      validation = false;
    }

    for (let i = 0; i < destinations.length; i++) {
      if (destinations[i].rooms.length === 0) {
        alert('Destination must contain at least 1 Room', 'error');
        validation = false;
      }

      if (validation) {
        for (let j = 0; j < destinations[i].rooms.length; j++) {
          if (validation) {
            const roomItem = destinations[i].rooms[j];
            if (calcDateDiff(roomItem.checkOut, roomItem.checkIn) <= 0) {
              const strChekout =
                '[' + destinations[i].region + ' ' + destinations[i].city + ' ' + destinations[i].state + ']';
              const strCheckin = '[' + roomItem.checkIn + ']';
              alert(strChekout + ' checkout date is the same as check in: ' + strCheckin, 'error');
              validation = false;
            }

            if (roomItem.budget < 0) {
              alert('Please enter rooms budget per night.', 'error');
              validation = false;
            }

            if (!roomItem.location || !roomItem.preference || !roomItem.roomSpecification) {
              alert('Please make sure "Desired location", "Hotel preference" and "Room Type".', 'error');
              validation = false;
            }
          }
        }
      }
    }

    if (!validation) {
      return;
    }

    if (allowedToSubmit) {
      const requestDocument: CreateBookingRequestLandingForm = {
        contact,
        notes,
        destinations: [...destinations],
        original: original,
        eid: eventId,
      };
      submitRequest(requestDocument);
    } else {
      if (contact?.firstname.trim() === '' || contact?.lastname.trim() === '') {
        alert('Please insert the contact name', 'error');
        return;
      }
      if (contact?.email.trim() === '') {
        alert('Please insert the contact email.', 'error');
        return;
      }
      if (contact?.phone.trim() === '') {
        alert('Please insert the phone number.', 'error');
        return;
      }

      if (destinations.length === 0) {
        alert('Please add the detination.', 'error');
        return;
      }

      if (!userAgreement) {
        alert('Please check the confirmation.', 'error');
      }
    }
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };

  const goTotheEventDeal = () => {
    if (eventId) {
      history.push(`${Routing.eventDeals}?source=${eventId}`);
    }
  };

  return (
    <>
      <Header />
      <Banner>
        <Slogan>
          <Typography
            variant="body1"
            sx={{ fontSize: isMobile ? '36px' : '45px', fontWeight: 'bold', paddingRight: '20px', paddingLeft: '20px' }}
            component="div"
          >
            {'Corporate Discounts for ALL Businesses'}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: isMobile ? '23px' : '24px',
              width: '70%',
              fontWeight: 'bold',
              paddingRight: '20px',
              paddingLeft: '20px',
              color: 'gray',
            }}
            component="div"
          >
            {'Being as specific as possible helps us accommodate for the BEST possible rates up to 40% OFF!'}
          </Typography>
        </Slogan>
      </Banner>
      <Container maxWidth="xl">
        <TravelingTo style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
            {'Traveling To'}
          </Typography>
          <Grid>
            {eventId && (
              <Button
                variant="contained"
                onClick={goTotheEventDeal}
                startIcon={<TaskAltIcon />}
                sx={{ marginBottom: 1, width: '100%' }}
                color="info"
              >
                Deals
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleAddDestination}
              startIcon={<AddCircleOutlineIcon />}
              sx={{ width: '100%' }}
            >
              Add Destination
            </Button>
          </Grid>
        </TravelingTo>

        {isMobile ? (
          <>
            <Destinations>
              {destinations.map((destination: BookingRequestDestination, index: number) => (
                <MobileDestinationItem
                  actions={destinationActions}
                  destination={destination}
                  destinationIndex={index}
                  key={index}
                />
              ))}
            </Destinations>
            <MobileAccordionContainer>
              <CustomAccordion>
                <CustomAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="contact-content"
                  id="contact-header"
                >
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
                    {'Contact Details'}
                  </Typography>
                </CustomAccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetail }}>
                  <CustomInput
                    disabled={isAdmin()}
                    placeholder="First Name"
                    value={contact.firstname}
                    style={{ marginBottom: '10px' }}
                    onChange={(e) => handleContactInformation('firstname', e)}
                  />
                  <CustomInput
                    disabled={isAdmin()}
                    placeholder="Last Name"
                    value={contact.lastname}
                    style={{ marginBottom: '10px' }}
                    onChange={(e) => handleContactInformation('lastname', e)}
                  />
                  <CustomInput
                    disabled={isAdmin()}
                    placeholder="Company"
                    value={contact.companyName}
                    style={{ marginBottom: '10px' }}
                    onChange={(e) => handleContactInformation('companyName', e)}
                  />
                  <CustomInput
                    disabled={isAdmin()}
                    placeholder="Email"
                    value={contact.email}
                    style={{ marginBottom: '10px' }}
                    onChange={(e) => handleContactInformation('email', e)}
                  />
                  <CustomInput
                    disabled={isAdmin()}
                    placeholder="Phone"
                    value={contact.phone}
                    onChange={(e) => handleContactInformation('phone', e)}
                  />
                </AccordionDetails>
              </CustomAccordion>
            </MobileAccordionContainer>
          </>
        ) : (
          <>
            <Destinations>
              {destinations.map((destination: BookingRequestDestination, index: number) => (
                <DestinationItem
                  actions={destinationActions}
                  destination={destination}
                  destinationIndex={index}
                  key={index}
                />
              ))}
            </Destinations>

            <ContactInformation>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#176FBF' }} component="div">
                {'Contact Details'}
              </Typography>
              <CustomInput
                disabled={isAdmin()}
                placeholder="First Name"
                value={contact.firstname}
                onChange={(e) => handleContactInformation('firstname', e)}
              />
              <CustomInput
                disabled={isAdmin()}
                placeholder="LastName"
                value={contact.lastname}
                onChange={(e) => handleContactInformation('lastname', e)}
              />
              <CustomInput
                disabled={isAdmin()}
                placeholder="Company"
                value={contact.companyName}
                onChange={(e) => handleContactInformation('companyName', e)}
              />
              <CustomInput
                disabled={isAdmin()}
                placeholder="Email"
                value={contact.email}
                onChange={(e) => handleContactInformation('email', e)}
              />
              <CustomInput
                disabled={isAdmin()}
                placeholder="Phone"
                value={contact.phone}
                onChange={(e) => handleContactInformation('phone', e)}
              />
            </ContactInformation>
          </>
        )}
        <AgreementContainer>
          <Typography
            variant="body2"
            sx={{
              fontSize: isMobile ? '15px' : '16px',
              textAlign: 'center',
              width: '100%',
              fontWeight: 'bold',
              paddingRight: '20px',
              paddingLeft: '20px',
              color: 'gray',
            }}
            component="div"
          >
            {'* For same day reservations and urgent matters please send your request and use our chat '}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              padding: '10px',
            }}
            component="div"
          >
            {'Approve Request'}
          </Typography>

          <NotesArea
            placeholder="Any other special requests?"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleNotesChange(e)}
            style={isMobile ? { width: '100%' } : { width: 550 }}
          />
          <Col style={isMobile ? { width: '100%' } : { width: 550 }}>
            <AgreementDetails style={isMobile ? { alignItems: 'start' } : { alignItems: 'center' }}>
              <CheckBox type="checkbox" onChange={handleUserAgreementStatus} checked={userAgreement} value={notes} />
              <div onClick={() => setUserAgreement(!userAgreement)}>
                I confirm that all the information submitted is correct
              </div>
            </AgreementDetails>
            {loading ? (
              <SubmitButton active={false} style={isMobile ? { width: '80%' } : { width: 200 }}>
                Loading...
              </SubmitButton>
            ) : (
              <SubmitButton
                active={allowedToSubmit}
                onClick={handleSubmit}
                style={isMobile ? { width: '80%' } : { width: 200 }}
              >
                Submit
              </SubmitButton>
            )}
          </Col>
        </AgreementContainer>
      </Container>
    </>
  );
};

const Slogan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 55px;
  width: 100%;
  font-weight: bolder;
  text-align: center;
`;

const CustomAccordion = styled(Accordion)`
  width: 100%;
`;

const CustomAccordionSummary = styled(AccordionSummary)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-
`;

const MobileAccordionContainer = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 3px 2px 1px #00000014;
`;

const Banner = styled.header`
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 120px;
  margin-bottom: 30px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  overflow: hidden;
`;

export const SubmitButton = styled.div<{ active: boolean }>`
  display: flex;
  color: ${({ active }) => (active ? '#FFF' : '#000000')};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  height: 50px;
  border-radius: 25px;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  background: ${({ active }) => (active ? '#176FBF' : '#e5e5e5')};
  transition: all 0.1s linear;
  :hover {
    transform: scale(1.05);
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 150px;
  padding: 0 20px 20px 20px;
`;

export const AgreementDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const AgreementContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  padding-top: 22px;
`;

export const TableHeaderContainer = styled.thead`
  height: 65px;
`;

export const TableCell = styled.th<{ centered?: boolean }>`
  text-align: start;
  height: 65px;
`;

export const TableHeader = styled.th`
  text-align: left;
  font-weight: bold;
  font-size: 15px;
`;

export const Table = styled.table`
  display: table;
  width: 100%;
  padding-bottom: 25px;
  border-collapse: collapse;
`;

export const NotesArea = styled.textarea`
  border: none;
  background: rgba(238, 238, 238, 0.65);
  height: 150px;
  text-indent: 10px;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 25px;
  :focus {
    outline: none;
    border: none;
    background: #e5e5e5;
  }
`;

export const CustomInput = styled.input<{ activated?: boolean }>`
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  text-indent: 3px;
  height: 40px;
  outline: none;
  border: none;
  background: ${({ activated }) => (activated ? 'transparent' : '#EEEEEEA5')};
  cursor: text;
  border-radius: 20px;
  transition: all 0.1s ease-in;
  :focus {
    background: #e5e5e5;
  }
`;

const TravelingTo = styled.section`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0px -3px 2px 1px #00000014;
`;

const Destinations = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const ContactInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 125px;
  width: 100%;
  padding: 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 3px 2px 1px #00000014;
`;

export default DealsCreation;
