import React, { useEffect, useRef, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import clsx from 'clsx';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS, HEADER_HEIGHT, MainFluidContainer } from '@micro-frontends/shared-components';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: theme.zIndex.appBar - 1,
  },
  secondHeader: {
    width: '100%',
    backgroundColor: alpha(COLORS.white, 0),
    transition: 'background-color .2s',
    borderTop: '2px solid transparent',

    '&.fixed': {
      position: 'fixed',
      borderColor: COLORS.whiteSmoke,
      backgroundColor: alpha(COLORS.white, 1),
      boxShadow: `0 0 7px ${COLORS.grey}`,
      top: HEADER_HEIGHT,
      left: 0,
    },
  },
}));

interface Props {
  className?: string;
  onFixed?: (isFixed: boolean) => void;
  children?: React.ReactNode;
}

const SecondHeader: React.FC<Props> = ({ className, onFixed, children }) => {
  const [isFixed, setIsFixed] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleScroll = () => {
    if (headerRef.current) {
      const curScroll = window.scrollY;
      const topPosition = headerRef.current.getBoundingClientRect().top + curScroll - HEADER_HEIGHT;
      if (curScroll > topPosition) setIsFixed(true);
      else setIsFixed(false);
    }
  };

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onFixed) onFixed(isFixed);
  }, [isFixed, onFixed]);

  useScrollPosition(handleScroll);

  return (
    <MainFluidContainer className={clsx(classes.root, className)} {...{ ref: headerRef }}>
      <div className={clsx(classes.secondHeader, isFixed && 'fixed')}>{children}</div>
    </MainFluidContainer>
  );
};

export { SecondHeader };
