import React from 'react';
import { NoRowsImage } from '../../assets/NoRowsImage';
import { GridOverlay } from '@mui/x-data-grid';

const NoRowsOverlay: React.FC = () => {
  return (
    <GridOverlay>
      <NoRowsImage />
    </GridOverlay>
  );
};

export { NoRowsOverlay };
