import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useLogger } from '../../common';
import { FONT_WEIGHT, SIDEBAR_PARAMS } from '../../themes';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemLinkProps } from './AppContainer';
import { History } from 'history';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: SIDEBAR_PARAMS.widthOpen,

    '& .MuiListItem-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 23,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(33),
    },
    '& .MuiTypography-body1': {
      fontWeight: FONT_WEIGHT.medium,
      fontSize: theme.typography.pxToRem(20),
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiListItem-gutters': {
        paddingLeft: 17,
      },
      '& .MuiListItemIcon-root': {
        minWidth: 48,
      },
    },
  },
}));

interface Props {
  listItems?: ListItemLinkProps[];
  history?: History;
  admin?: boolean;
}

const AppContainerNav: React.FC<Props> = ({ listItems, history, admin }) => {
  const classes = useStyles();
  const logger = useLogger('AppContainerNav');

  const handleClick = (_e: React.MouseEvent, el: ListItemLinkProps) => {
    if (!history) logger.error('Missing history instance');
    history?.push(el.to);
  };

  if (!listItems) return null;
  return (
    <List className={classes.root}>
      {listItems
        .filter((li) => (!admin ? !li.adminOnly : true))
        .map((el) => (
          <ListItem
            key={el.to}
            button
            selected={history ? new RegExp(`^${el.to}.*$`).test(history.location.pathname) : false}
            onClick={(event) => handleClick(event, el)}
          >
            {el.icon ? <ListItemIcon>{el.icon}</ListItemIcon> : null}
            <ListItemText primary={el.primary} />
          </ListItem>
        ))}
    </List>
  );
};

export { AppContainerNav };
