import { VacayzClient, VacayzResponse } from '../../common/typings';
import { getSource, handleCancel, toQuerystring as toQs } from '../../common/utils';
import { StripeAccountLink, CreateStripeCharge, StripePaymentSecret, CreateBookingStripeCharge, SecretPaymentIntentData, RetrievePaymentResult } from './typings';

const createStripeAccount = (client: VacayzClient, userId: number): VacayzResponse<StripeAccountLink> => {
  const qs = toQs({ user_id: userId });
  const url = `api/v1/payments/payout_methods/stripe${qs}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<StripeAccountLink>(url, null, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createStripeCharge = (
  client: VacayzClient,
  paymentData: CreateStripeCharge
): VacayzResponse<StripePaymentSecret> => {
  const url = `api/v1/payments/payment_intents/stripe_charge`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<StripePaymentSecret>(url, paymentData, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const createBookingStripeCharge = (
  client: VacayzClient,
  paymentData: CreateBookingStripeCharge
): VacayzResponse<StripePaymentSecret> => {
  const url = `api/v1/payments/booking/payment_intents/stripe_charge`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<StripePaymentSecret>(url, paymentData, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const secretPaymentIntent = (
  client:VacayzClient, secretIntent:SecretPaymentIntentData
):VacayzResponse<RetrievePaymentResult> =>{
  const url = `api/v1/payments/booking/payment_intents/booking_stripe_secret_payment`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<RetrievePaymentResult>(url, secretIntent, {
      cancelToken: source.token,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
}

export { createStripeAccount, createStripeCharge, createBookingStripeCharge, secretPaymentIntent };
