import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookingDealNew, calcNumberToCurrency } from '@micro-frontends/vacayz-api-client';
import { SelectedDeal } from '../OfferDeals.view';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import HotelIcon from '@mui/icons-material/Hotel';
import FlightIcon from '@mui/icons-material/Flight';
type Props = {
  deals: BookingDealNew[];
  destinationId?: number;
  roomId?: number;
  nights: number;
  quantity: number;
  changeSelectedDeals?: (selectedDeal: SelectedDeal) => void;
  viewOnly: boolean;
};

export const renderDiscount = (price: number, marketPrice: number): string => {
  const closeDiscount = ((1 - marketPrice / price) * 100).toFixed(0);
  return isNaN(+closeDiscount) ? '0' : closeDiscount + '%';
};

// const calcPricePerNight = (price: number, nights: number) => {
//   return (price / nights).toFixed(2);
// };
const OffersScreen: React.FC<Props> = ({
  deals,
  destinationId,
  roomId,
  nights,
  quantity,
  changeSelectedDeals,
  viewOnly,
}) => {
  const [selectedDeal, setSelectedDeal] = useState<number[]>([]);

  useEffect(() => {
    deals.forEach((deal) => {
      if (deal.isDealSelected && deal.id && destinationId && roomId && changeSelectedDeals) {
        const _selectedDeal: SelectedDeal = {
          destinationId: destinationId,
          roomId: roomId,
          dealId: deal.id,
          oldDealId: -1,
        };
        changeSelectedDeals(_selectedDeal);
        const _selectedDealIds = selectedDeal;
        _selectedDealIds.push(deal.id);
        setSelectedDeal(_selectedDealIds);
      }
    });
  }, [destinationId, roomId, changeSelectedDeals, selectedDeal, deals]);

  const selectDeal = useCallback(
    (dealId: number | undefined) => {
      if (!dealId || !destinationId || !roomId || !changeSelectedDeals) return;

      if (selectedDeal.includes(dealId)) {
        const _selectedDeal: SelectedDeal = {
          destinationId: destinationId,
          roomId: roomId,
          dealId: -1,
          oldDealId: dealId,
        };
        changeSelectedDeals(_selectedDeal);
        const _selectedDeals = selectedDeal.filter((d) => d !== dealId);

        setSelectedDeal(_selectedDeals);
      } else {
        const _selectedDeal: SelectedDeal = {
          destinationId: destinationId,
          roomId: roomId,
          dealId: dealId,
          oldDealId: -1,
        };
        changeSelectedDeals(_selectedDeal);
        const _selectedDealIds = selectedDeal;
        _selectedDealIds.push(dealId);
        setSelectedDeal(_selectedDealIds);
      }
    },
    [changeSelectedDeals, destinationId, roomId, selectedDeal]
  );

  return (
    <DealsList>
      <DealsContainer>
        {deals
          .filter((d) => d.isDeleted === null || d.isDeleted === undefined)
          .map((deal) =>
            deal.id ? (
              deal.dealType === 'hotel' ? (
                <DealCard key={deal.id} isSelected={selectedDeal.includes(deal.id)}>
                  <HotelName>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'lighter', textAlign: 'center', display: 'flex' }}
                      component="div"
                    >
                      <HotelIcon sx={{ marginRight: 1 }} />
                      {deal.name}
                    </Typography>
                  </HotelName>
                  <DealInfoContainer>
                    {deal.imageUrl && (
                      <Box component="img" src={deal.imageUrl} alt="the room for deal" maxHeight="180px" />
                    )}
                    <DealAddressItem>
                      <Typography variant="body1" sx={{ textAlign: 'center', color: '#a4a4a4' }} component="div">
                        {deal.address}
                      </Typography>
                    </DealAddressItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Room Type'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {deal.roomType}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Total Nights'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {`${nights * quantity}`}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <DealInfoSubItem>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                          {'Market Price'}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'lighter', color: '#a4a4a4' }} component="div">
                          {`$${deal.marketPrice}(per night)`}
                        </Typography>
                      </DealInfoSubItem>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'line-through',
                        }}
                        component="div"
                      >
                        {`${calcNumberToCurrency(deal.marketTotalPrice ?? 0)}`}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Discount'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#11B968' }} component="div">
                        {`${renderDiscount(deal.marketTotalPrice ?? 0, deal.ourPriceTotal)}`}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Fee'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#11B968' }} component="div">
                        {`$${deal.fees}`}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <DealInfoSubItem>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                          {`${process.env.REACT_APP_COMPANY_NAME} Price`}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'lighter', color: '#a4a4a4' }} component="div">
                          {`$${deal.price}(per night)`}
                        </Typography>
                      </DealInfoSubItem>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#176FBF', display: 'flex', alignItems: 'center' }}
                        component="div"
                      >
                        {`${calcNumberToCurrency(deal.ourPriceTotal)}`}
                      </Typography>
                    </DealInfoItem>
                  </DealInfoContainer>
                  {!viewOnly && changeSelectedDeals && (
                    <DealSelection isSelected={selectedDeal.includes(deal.id)} onClick={() => selectDeal(deal.id)}>
                      <Typography
                        variant="h4"
                        sx={{ color: selectedDeal.includes(deal.id) ? 'white' : '#000000' }}
                        component="div"
                      >
                        {'Select'}
                      </Typography>
                    </DealSelection>
                  )}
                </DealCard>
              ) : (
                <DealCard key={deal.id} isSelected={selectedDeal.includes(deal.id)}>
                  <HotelName>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'lighter', textAlign: 'center', display: 'flex' }}
                      component="div"
                    >
                      <FlightIcon sx={{ marginRight: 1 }} />
                      {deal.name}
                    </Typography>
                  </HotelName>
                  <DealInfoContainer>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Departure'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {deal.flightDetails?.airportDeparture}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography component="div"></Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {deal.flightDetails?.datetimeDeparture}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Arrival'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {deal.flightDetails?.airportArrival}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography component="div"></Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {deal.flightDetails?.datetimeArrival}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Flight Number'}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }} component="div">
                        {deal.flightDetails?.flightNumber}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {'Market Price'}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'lighter', color: '#a4a4a4' }} component="div">
                        {`$${deal.marketTotalPrice?.toFixed(2)}`}
                      </Typography>
                    </DealInfoItem>
                    <DealInfoItem>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#a4a4a4' }} component="div">
                        {`${process.env.REACT_APP_COMPANY_NAME} Price`}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', color: '#176FBF', display: 'flex', alignItems: 'center' }}
                        component="div"
                      >
                        {`${calcNumberToCurrency(deal.ourPriceTotal)}`}
                      </Typography>
                    </DealInfoItem>
                  </DealInfoContainer>
                  {!viewOnly && changeSelectedDeals && (
                    <DealSelection isSelected={selectedDeal.includes(deal.id)} onClick={() => selectDeal(deal.id)}>
                      <Typography
                        variant="h4"
                        sx={{ color: selectedDeal.includes(deal.id) ? 'white' : '#000000' }}
                        component="div"
                      >
                        {'Select'}
                      </Typography>
                    </DealSelection>
                  )}
                </DealCard>
              )
            ) : null
          )}
      </DealsContainer>
    </DealsList>
  );
};

const DealsList = styled.div`
  width: 100%;
`;

const DealsContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
`;

const DealSelection = styled.div<{ isSelected: boolean }>`
  background: ${({ isSelected }) => (isSelected ? '#176FBF' : '#EDEDED')};
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const HotelName = styled.div`
  background: #dcdcdc;
  width: 100%;
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DealInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 200px;
`;

export const DealAddressItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
`;

export const DealInfoItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`;

export const DealInfoSubItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DealCard = styled.div<{ isSelected?: boolean }>`
  box-shadow: ${({ isSelected }) => (isSelected ? '-7px 9px 6px #e3e3e3c7' : 'none')};
  border: ${({ isSelected }) => (isSelected ? '3px dotted #bbbbbb' : 'none')};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  width: 265px;
  margin: 10px;
  border-radius: 20px;
  background: #f1f1f1;
  transition: all 0.1s ease;
  :hover {
    transform: scale(1.02);
  }
`;

export default OffersScreen;
