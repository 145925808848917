import { EntityImage } from './typings';
import { VacayzClient, VacayzResponse } from '../common/typings';
import { getSource, handleCancel } from '../common/utils';

const uploadImage = (client: VacayzClient, file: File): VacayzResponse<EntityImage> => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const url = `api/v1/images/`;
  const source = getSource();
  const promise = async () => {
    const response = await client.post<EntityImage>(url, bodyFormData, {
      cancelToken: source.token,
      headers,
    });
    return response?.data;
  };
  return [handleCancel(promise), source.cancel];
};

const deleteImage = (client: VacayzClient, imageId: number): VacayzResponse<boolean> => {
  const url = `api/v1/images/${imageId}`;
  const source = getSource();
  const promise = async () => {
    const response = await client.delete<boolean>(url, {
      cancelToken: source.token,
    });
    return response?.status === 200;
  };
  return [handleCancel(promise), source.cancel];
};

export { uploadImage, deleteImage };
